export default {
	discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
	lookUpSpreadsheetId: "1hMKADFhGiO7lOZ60Xgy3AkLDeL8s7i364y3QgMapdSA",
	fabLabsheetId: "870270855",
	saveOutputspreadsheetId: "1MAWhnVq05eeo-NSlS4o1swTUpnduFyPEMgn726Gr6Y4",
	saveOutputsheetId: "1285439780",
	client : "4b6e6f016f8ac153825004d86951ddfcc7a624e0",
	secret : "Xbciby55FX/LgLxobkTj9KgKpsM9yCq03VvlMHU/fnS4uwnXMK2M8zPWQb2KN1BgqQm0WnJVNjhbZKragiARHHaozdGFh/xeM7/unXxM/stX53pN40i2JPMcxera8feZ",
	access : "a0a4f973cb8e8646aca7f4fd46f6e903",
}; 
