import React, { Component } from 'react';
import $ from 'jquery';
import config from "../config";
import { gapi } from 'gapi-script';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'

export default class MongoDBMdsManager extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			mdsJsonFilesVersion:new Map(),
		}

    }
	
	componentDidMount() {
		this.getMdsJsonFileVersion();
	}

	uploadMdsJsonMongoDb =(spreadsheetId,sheetName)=> {					
		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:sheetName, 
		})
		.then(
			response => {
				const allData = response.result.values;
				let currentDate = new Date().toLocaleString("en-US").replace(',','');
				let parentMds = [];
				if(allData.length > 0){
					let mdsGuid = allData[1][91];
					let version = allData[1][59] ? allData[1][59] : '1.01';
					
					let dates = [];
					let fields = [];
					allData.map((field,i)=>{
						if(i > 0){
							
							let date = new Date(field[98]);
							if(date != 'Invalid Date'){
								dates.push(date);
							}
							
							let notes = field[12] = field[12].replaceAll('\n','');
							notes = notes.replaceAll('<p></p>','');
							let customNotes = field[116] = field[116].replaceAll('\n','');
							customNotes = customNotes.replaceAll('<p></p>','');
							
							let preId = '';
							let parentGuid = '';
							allData.map((pre_field,i)=>{
								if(field[7] && field[7] == pre_field[0]){
									preId = pre_field[117];
								}
								
								if(pre_field[1] == field[2]){
									parentGuid = pre_field[117];
								}
							})

							let oldP = field[16];
							let userArray = oldP.split(',');
							userArray.splice(0, 9);
							
							let uArray = userArray.filter(function(v){return v!==''});
							//let kioskTypes = uArray.toString();
							//kioskTypes = kioskTypes.replaceAll(',','|');
							
							let kioskT = [];
							uArray.map((val,i)=>{
								let fieldk = 'delegate'+parseInt(i+1);
								let fd = {
									[fieldk]: val.trim(),
								}
								kioskT.push(fd);
							});

							/* let strArray = oldP.split(',');
							strArray.splice(9, 15); */

							/* let configStr = strArray.toString();
							configStr = configStr+",["+kioskTypes+"]"; */
							
							field[91] = '';
							field[129] = '';
							field[130] = '';
							field[131] = '';
							
							let rqFields = [];
							let requiredFields = '';
							if(field[142] && field[5] == 'launchpad'){
								let rfStr = field[142].split(',');
								rfStr.map((rfield,i)=>{
									rfield = rfield.split('|');
									rqFields.push(rfield[0]+','+rfield[1]);
								});
								requiredFields = rqFields.join('|');
								
							}
							
							let opFields = [];
							let optionalFields = '';
							if(field[143] && field[5] == 'launchpad'){
								let opStr = field[143].split(',');
								opStr.map((ofield,i)=>{
									ofield = ofield.split('|');
									opFields.push(ofield[0]+','+ofield[1]);
								});
								optionalFields = opFields.join('|');
								
							}
							let createdDate = new Date(field[97]).toLocaleString("en-US").replace(',','');
							let lastDate = new Date(field[98]).toLocaleString("en-US").replace(',','');
							
							let options = [];
							if(field[6]){
								options = field[6].split(',');
							}
							
							/* let dateValidation = [];
							if(field[48] == 'V9' || field[48] == 'V10' || field[48] == 'V11'){
								dateValidation = ["Any", "Past", "Today", "Future"];
							} */
							
							let dateValidation = '';
							if(field[48] == 'V9'){
								dateValidation = 'past'
							}else if(field[48] == 'V10'){
								dateValidation = 'today'
							}else if(field[48] == 'V11'){
								dateValidation = 'future'
							}
							
							let gridLabel = [];
							if(field[5] == 'basicgrid' || field[5] == 'grid5point' || field[5] == 'grid-multicheck' || field[5] == 'grid-singlecheck'){
								let str = field[41] ? field[41].split('_') : [];
								
								if(str.length > 0){
									
									let columns = str[0].split(',');
									let clabel = [];
									columns.map((val,i)=>{
										let fieldN = 'columnlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										clabel.push(fd);
									});
									
									let rows = str[1].split(',');
									let crow = [];
									rows.map((val,i)=>{
										let fieldN = 'rowlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										crow.push(fd);
									});
									gridLabel = {
										"ColumnLabelValues": clabel,
										"RowLabelValues": crow,
									}
								}
							}
							
							if(field[5] == 'basicgrid2'){
								let str = field[41] ? field[41].split('_') : [];
								
								if(str.length > 0){
									let columns = str[0].split('|');
									let rows = str[1].split(',');
									let crow = [];
									rows.map((val,i)=>{
										let fieldN = 'rowlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										crow.push(fd);
									});
									
									let columnslable = [];
									let columnstd = [];
									columns.map((val,i)=>{
										let cm = val.split(':');
										let fieldN = 'columnlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: cm[0].trim(),
										}
										
										columnslable.push(fd);
										columnstd.push(cm[1]);
									});
									let columnstd2 = [];
									columnstd.map((val,i)=>{
										let fieldm = 'column'+parseInt(i+1);
										let fds = {
											[fieldm]: val.trim(),
										}
										
										columnstd2.push(fds);
									});
									
									gridLabel = {
										"ColumnLabelValues": columnslable,
										"RowLabelValues": crow,
										"ColumnDataType": columnstd2,
									}
								}
							}
							let optionValue = [];
							if(field[5] == 'weighted'){
								let str = field[41] ? field[41].split(',') : [];
								str.map((val,i)=>{
									let op = val.split(':');
									options.push(op[0]);
									optionValue.push(op[1]);
								});
							}
							
							if(field[5] == 'grid-dropdown'){
								let str = field[41] ? field[41].split('_') : [];
								
								if(str.length > 0){
									let columns = str[0].split(',');
									let clabel = [];
									columns.map((val,i)=>{
										let fieldN = 'columnlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										clabel.push(fd);
									});
									let rows = str[1].split('|');
									
									//let columnslable = [];
									let rowValue = [];
									let coption = [];
									rows.map((val,i)=>{
										let cm = val.split(':');
										//columnslable.push(cm[0]);
										//rowValue.push(cm[0]);
										let fieldN = 'rowlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: cm[0].trim(),
										}
										rowValue.push(fd);
										
										cm.map((val,j)=>{
											if(j > 0){
												let fieldN = 'row'+parseInt(i+1)+'_columnoption'+parseInt(j);
												let fd = {
													[fieldN]: val.split(','),
												}
												coption.push(fd);
											}
											
										});
										
									});
									
									gridLabel = {
										"ColumnLabelValues": clabel,
										"RowLabelValues": rowValue,
										"ColumnOption": coption,
										//"ColumnDataType": columnstd,
									}
								}
							}
							
							let ctype = field[114];
							let ctypeVal = [];
							let calculationType = [];
							if(field[5] == 'calculation'){
								if(ctype.indexOf(',') > -1) {
									let ctypearr = ctype.split(',');
									ctypearr.map((val,i)=>{
										let fieldN = 'field'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										ctypeVal.push(fd);
									});
									
								}
								
								calculationType = ["Sum", "Average", "Subtract", "Highest Number", "Lowest Number", "Longest Time", "Shortest Time"]
							}
							
							let opVal = [];
							if(options.length > 0){
								options.map((val,i)=>{
									let fieldN = 'option'+parseInt(i+1);
									let fd = {
										[fieldN]: val.trim(),
									}
									opVal.push(fd);
								});
							}
							
							let TrackingCode = {'trackingcodelabel1':field[125]};
							
							if(field[125].indexOf(',') > -1) {
								TrackingCode = [];
								let arr = field[125].split(',');
								arr.map((val,i)=>{
									let fieldN = 'trackingcodelabel'+parseInt(i+1);
									let fd = {
										[fieldN]: val.trim(),
									}
									TrackingCode.push(fd);
								});
							}
							
							let FieldTypeOptions = {
								"Options": opVal.length > 0 ? opVal : '',
								//"OptionsValue": optionValue.length > 0 ? optionValue : '',
								"GridColumns": field[136],
								"GridRows": field[137],
								"GridLabelValue": Object.keys(gridLabel).length !== 0 ? gridLabel : '',
								"CalculationType": field[113],
								//"CalculationType": calculationType.length > 0 ? calculationType : '',
								"CalculationFields": ctypeVal.length > 0 ? ctypeVal : '',
								"WebsiteLink": field[78],
								//"TrackingCode": field[125] ? field[125].split(',') : '',
								"TrackingCode": TrackingCode.length > 0 ? TrackingCode : '',
								//"DateValidation": dateValidation.length > 0 ? dateValidation : '',
								"DateValidation": dateValidation,
							}
							
							const filteredObj2 = (obj) =>
							  Object.entries(obj)
								.filter(([_, value]) => !!value || typeof value === "boolean")
								.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
							
							FieldTypeOptions = filteredObj2(FieldTypeOptions);
							
							let DataLowLimit = '';
							let DataHighLimit = '';
							if(field[5] == 'Q6' && field[50] && field[51]){
								DataLowLimit = {
									'number':field[50],
								}
								
								DataHighLimit = {
									'number':field[51],
								}
							}
							
							if(field[5] == 'timer' && field[50] && field[51]){
								DataLowLimit = {
									'time':field[50],
								}
								
								DataHighLimit = {
									'time':field[51],
								}
							}
							let fieldData = {
								"FieldGUID": field[117],
								"MasterDataSetGUID": mdsGuid,
								"FieldName": field[0].replaceAll("<<", "").replaceAll(">>", "").trim(),
								"FieldNameIdentifier_STATIC": field[61],
								"VisibleText": field[4],
								"FieldType": field[5],
								"FieldTypeOptions": Object.keys(FieldTypeOptions).length !== 0 ? FieldTypeOptions : '',
								"PrefilteredDelegates": kioskT.length > 0 ? kioskT : '',
								"ButtonLabel": field[40],
								"ImageLocationReferenceInfo": field[99],
								"OutputText": field[9],
								"Notes": notes,
								"InternalDataPersistenceLabel": field[110],
								"Tags": field[13],
								"DataAgg": field[3] == 'Y' ? true:false,
								"AutoTags": field[106] ? field[106].replaceAll("<<", "").replaceAll(">>", "").trim() : '',
								"ValidationType": field[48],
								"DataLowLimit": field[5] != 'Q8' ? DataLowLimit : '',
								"DataHighLimit":field[5] != 'Q8' ? DataHighLimit : '',
								"CreatedDate": field[97] ? moment(createdDate).format('YYYY-MM-DD HH:mm:ss') : moment(lastDate).format('YYYY-MM-DD HH:mm:ss'),
								"ModifiedDate": moment(lastDate).format('YYYY-MM-DD HH:mm:ss'),
								"IsActive": field[60] == 'Y' || field[60] == '' ? true:false,
								"Translations": false,
							}
							
							const filteredObj = (obj) =>
							  Object.entries(obj)
								.filter(([_, value]) => !!value || typeof value === "boolean")
								.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
							
							fieldData = filteredObj(fieldData);
							fields.push(fieldData);
							
						}
					})

					let maximumDate = new Date(Math.max.apply(null, dates));
					let minimumDate = new Date(Math.min.apply(null, dates));
					let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
					let minDate = new Date(minimumDate).toLocaleString("en-US").replace(',','');
					//let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
					
					parentMds = parentMds.filter(function(x) {
						 return x !== undefined;
					});
					let parentMdsGuid = this.uniqueArray2(parentMds);
					let itemString = parentMdsGuid.toString();
					
					let layout ={
						"MDSGuid": mdsGuid,
						"MDSName": sheetName,
						"CreatedDate": moment(minDate).format('YYYY-MM-DD HH:mm:ss'),
						"ModifiedDate": moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
						'Version': version,
						'Fields': fields,
					}
					this.insertMdsJsonMongo(JSON.stringify(layout));
				}

			},function(reason) {
				alert(reason.result.error.message);
			} 
		);
	}
	
	insertMdsJsonMongo(data){
		let mongoApiUrl = $('#mongoApiUrl').val();
		let url = mongoApiUrl+'insert-mds-v2';
		axios({
			method: 'POST',
			url: url,
			data: data,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			let that = this;
			setTimeout(function(){
				that.getMdsJsonFileVersion();
			}, 1000);
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	uniqueArray2(arr) {
		var a = [];
		for (var i=0, l=arr.length; i<l; i++)
			if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
				a.push(arr[i]);
		return a;
	}

	getMdsJsonFileVersion=()=>{
		let mdsSheetGuids = [];
		
		this.props.mdsSheetNames.map((sheet,i)=>{
			let sheetGuids = this.props.sheetGuids;
			let sheetGuid = sheetGuids.get(sheet.name);
			mdsSheetGuids.push(sheetGuid);
		});
		
		let mongoApiUrl = $('#mongoApiUrl').val();
		let url = mongoApiUrl+'mds-json-version-v2';
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(mdsSheetGuids),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			let files = response.data;
			if(files.length > 0){
				files.map((file,i)=>{
					if(file.Version){
						let mdsJsonFilesVersion = this.state.mdsJsonFilesVersion;
						mdsJsonFilesVersion.set(file.MDSGuid,file.Version);
						this.setState({mdsJsonFilesVersion});
					}
				});
			}
			
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	render() {
		//const {mdsSpreadsheetId} = this.state;
		const {mdsSpreadsheetId,mdsSpreadSheetData,sheetApiName,mdsSheetNames,sheetVersion,sheetGuids} = this.props;
		const {mdsJsonFilesVersion,layoutFriendlyName,selectedFiles} = this.state;
		let MdsFiles =[];
		//console.log('mdsSpreadsheetId->',mdsSpreadsheetId);
		let totalFieldCountM = 0;
		let totalFieldCountC = 0;
		let optionHtmlsheets = '';
		if(mdsSpreadSheetData){
			optionHtmlsheets = mdsSpreadSheetData.map(function(val,i) {
				return (
					<option value={val["spreadsheetId"]} key={i}>{val["title"]}</option>
				);
			})
		}
		
		return (
			<div className="mds-manager-mongo container">
				
				<div className="mongo-header2">
					<div className="mg-mds-page-title"><h4>MongoDB MDS Manager</h4></div>
				</div>
				
				<div className="mg-child-sheets">
					<div className="mg-top-action-icon2">
						<div className="mg-top-action-span">
							<div className="mg-mongo-name">MDS Name</div>
							<div className="mg-mongo-update">Last Updated</div>
						</div>
						<div className="mg-top-action-span3">
							<div className="mg-mongo-lable2">Send to Mongo</div>
							<div className="mg-mongo-lable2">MDS files in DB</div>
							<div className="mg-mongo-lable2">Ver.#</div>
						</div>
					</div>
					<div className="mds-sheet-div">
						{mdsSheetNames ?
						<div className="mds-file-list">
						<ul className="list-group manage-form">
							{mdsSheetNames.map((sheet, i) => {
								let apiName = sheetApiName.get(sheet.name);
								
								let totalRows = '';
								let lastUpdate = '';
								if(apiName){
									apiName = apiName.split('_');
									lastUpdate = apiName[2];
									totalRows = apiName[3];
									
									if(Number(totalRows)){
										totalFieldCountC = parseInt(totalFieldCountC) + parseInt(totalRows);
									}
								}
								
								let version = sheetVersion.get(sheet.name);
								let sheetGuid = sheetGuids.get(sheet.name);
								let mongoVersion = mdsJsonFilesVersion.get(sheetGuid);
								//let friendlyName = layoutFriendlyName.get(sheetGuid);
								
								let classn = 'not-in-mongo';
								if(mongoVersion && (mongoVersion == version || version == '')){
									classn = 'has-in-mongo';
								}
								let mdsClass = 'not-in-mongo';
								
								/* if(selectedFiles.get(sheet.sheetId) != undefined){
									alert(selectedFiles.get(sheet.sheetId));
									classn = 'has-in-mongo';
								} */
								
								let selectedLanguage = this.state.selectedLanguage;
								let language = 'eng';
								if(selectedLanguage){
									let str = selectedLanguage.split('_');
									if(str.length > 1){
										language = str[0] == sheetGuid ? str[1]: 'eng'
									}
								}
								//console.log('sheet->',sheet);
									
								return(<li id={'mds-'+sheet.sheetId} key={i} className={"mg-list-group-item show"}>
								<div className="mg-sheet-name-i ">{sheet.name} </div>
								<div className="mg-sheet-last-update">{lastUpdate} </div>
								<div className="mg-mds-download-icon2">
									<div className="mg-mongo-val2 pointer">
									{ classn == 'has-in-mongo' ?
									 <i className="fa fa-floppy-o" aria-hidden="true" onClick={() => { this.uploadMdsJsonMongoDb(mdsSpreadsheetId,sheet.name) } }></i>
									:
									 <i className="fa fa-floppy-o" aria-hidden="true" onClick={() => { this.uploadMdsJsonMongoDb(mdsSpreadsheetId,sheet.name) } }></i>
									}
									</div>
									<div className="mg-mongo-val2"><i id={'guid-'+sheetGuid} className={"fa fa-circle "+classn} aria-hidden="true"></i></div>
									<div className="mg-mongo-val2"><i>{version && version != 1 ? version : '1.01' }</i></div>
									
								</div>
								</li>)
							})}
							</ul>
						</div>
						:null}
					</div>
				</div>
			</div>
		);	
	}	
}
