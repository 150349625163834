import React,{ Component } from 'react';
import $ from 'jquery';
import { Form,Button } from 'react-bootstrap';

class MainHeader extends Component {
	constructor(props) {
        super(props);
	 	this.state ={

	 	}
    }
   	handleChange(event){
    	this.setState({renderComponent:event.target.value});
    	this.props.getModule(event.target.value);
    }
	
	logout = () => {
		let subdirectory = $('#subdirectory').val();
        //localStorage.removeItem('id');
        localStorage.removeItem('username');
		if(subdirectory){
			window.location = '/'+subdirectory+'/';
		}else{
			window.location = '/';
		}
    }
	
    render(){
        const { builderOption, dataMode} =this.props;
		let date = new Date();
		let newDate = ("0" + (date.getMonth() + 1)).slice(-2)+''+date.getDate()+''+date.getFullYear();
		let role = localStorage.getItem('role');
    	return (
        <div className="card-header top-header">
            <div className="row">
				<div className="col-md-6">
                    <h4>MDS Builder Tool</h4>
					<div className="instance-version">{'V1.08082023.a'}</div>
                </div>
				{dataMode == 'TestForm' ?
				<div className="col-md-2">
					<select id="select-view-option" className="form-control" onChange={builderOption} >
						<option value="status_tracker">Status Tracker</option>
					</select>
				</div>
				:
				<div className="col-md-2">
				
					{role == 'Administrator' ? 
						<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
							<option value="instance">Instance Manager</option>
							<option value="builder">Builder Mode</option>
							{/* <option value="mds_view">View Fields mode</option> */}
							<option value="layout_mongo">MongoDB Layout Manager</option>
							<option value="translation">Translation Manager</option>
							<option value="mds_builder">Zip Manager</option>
							<option value="mds_mongo">MongoDB MDS Manager</option>
							<option disabled>--------------------------------------</option>
							<option value="trackerStatus">Status Tracker Manager</option>
							<option value="taskManager">Task List Manager</option>
							<option value="workFlow">Workflow Builder</option>
							<option value="workFlowDetails">Workflow Details</option>
							<option value="workFlowManger">Workflow Manager</option>
							<option value="workFlowProgress">Workflows in Progress</option>
							<option value="scheduledworkFlow">Scheduled Workflows</option>
						</select>
					:null}
					
					{role == 'Builder' ? 
						<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
							<option value="builder">Builder</option>
							<option value="mds_builder">MDS Manager</option>
						</select>
					:null}
					
					{role == 'Editor' ?
						<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
							<option value="builder">Builder</option>
						</select>
					:null}
					
					{role == 'Fab' ?
						<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
							<option value="trackerStatus">Status Tracker Manager</option>
						</select>
					:null}
					
					{role == 'OA' ?
						<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
							<option value="workFlow">Workflow Builder</option>
							<option value="workFlowDetails">Workflow Details</option>
							<option value="workFlowManger">Workflow Manager</option>
							<option value="workFlowProgress">Workflows in Progress</option>
							<option value="scheduledworkFlow">Scheduled Workflows</option>
						</select>
					:null}
					
				</div>
				}
            
                <div className="col-md-4 user-top-outer">
                <div className="login-user-top">{'Logged in as: '+localStorage.getItem('username')}</div> <a className="nav-link btn btn-outline-info sign-out-btn" onClick={this.logout}>Sign Out</a>
                </div>
            </div>
        </div>            
    )}
	
}

export default MainHeader;