import React, { Component } from 'react';
import $ from 'jquery';
import config from "../config";
import { gapi } from 'gapi-script';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'
import arrayMove from "./arrayMove";
import Recurring from "./Recurring";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

export default class MongoDBManager extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			layoutFilesVersion:new Map(),
			layoutMdsFiles:new Map(),
			layoutFriendlyName:new Map(),
			selectedFiles:new Map(),
		}

    }
	
	componentDidMount() {
		this.getLayoutJsonFileVersion();
	}
	
	uploadLayoutInMongo=()=>{
		let selectedFiles = this.state.selectedFiles;
		let files = Object.fromEntries(selectedFiles.entries());
		let layouts = [];
		for (let prop in files) {
			layouts.push(files[prop]);
		}
		
		layouts.map((sheetName,i)=>{
			this.uploadLayoutJsonMongoDb(sheetName);
		});
		
	}
	
	uploadMdsJsonMongoDb =(spreadsheetId,sheetName)=> {					
		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:sheetName, 
		})
		.then(
			response => {
				const allData = response.result.values;
				let currentDate = new Date().toLocaleString("en-US").replace(',','');
				let parentMds = [];
				if(allData.length > 0){
					let mdsGuid = allData[1][91];
					let version = allData[1][59] ? allData[1][59] : '1.01';
					
					let dates = [];
					let fields = [];
					allData.map((field,i)=>{
						if(i > 0){
							
							let date = new Date(field[98]);
							if(date != 'Invalid Date'){
								dates.push(date);
							}
							
							let notes = field[12] = field[12].replaceAll('\n','');
							notes = notes.replaceAll('<p></p>','');
							let customNotes = field[116] = field[116].replaceAll('\n','');
							customNotes = customNotes.replaceAll('<p></p>','');
							
							let preId = '';
							let parentGuid = '';
							allData.map((pre_field,i)=>{
								if(field[7] && field[7] == pre_field[0]){
									preId = pre_field[117];
								}
								
								if(pre_field[1] == field[2]){
									parentGuid = pre_field[117];
								}
							})

							let oldP = field[16];
							let userArray = oldP.split(',');
							userArray.splice(0, 9);
							
							let uArray = userArray.filter(function(v){return v!==''});
							//let kioskTypes = uArray.toString();
							//kioskTypes = kioskTypes.replaceAll(',','|');
							
							let kioskT = [];
							uArray.map((val,i)=>{
								let fieldk = 'delegate'+parseInt(i+1);
								let fd = {
									[fieldk]: val.trim(),
								}
								kioskT.push(fd);
							});

							/* let strArray = oldP.split(',');
							strArray.splice(9, 15); */

							/* let configStr = strArray.toString();
							configStr = configStr+",["+kioskTypes+"]"; */
							
							field[91] = '';
							field[129] = '';
							field[130] = '';
							field[131] = '';
							
							let rqFields = [];
							let requiredFields = '';
							if(field[142] && field[5] == 'launchpad'){
								let rfStr = field[142].split(',');
								rfStr.map((rfield,i)=>{
									rfield = rfield.split('|');
									rqFields.push(rfield[0]+','+rfield[1]);
								});
								requiredFields = rqFields.join('|');
								
							}
							
							let opFields = [];
							let optionalFields = '';
							if(field[143] && field[5] == 'launchpad'){
								let opStr = field[143].split(',');
								opStr.map((ofield,i)=>{
									ofield = ofield.split('|');
									opFields.push(ofield[0]+','+ofield[1]);
								});
								optionalFields = opFields.join('|');
								
							}
							let createdDate = new Date(field[97]).toLocaleString("en-US").replace(',','');
							let lastDate = new Date(field[98]).toLocaleString("en-US").replace(',','');
							
							let options = [];
							if(field[6]){
								options = field[6].split(',');
							}
							
							/* let dateValidation = [];
							if(field[48] == 'V9' || field[48] == 'V10' || field[48] == 'V11'){
								dateValidation = ["Any", "Past", "Today", "Future"];
							} */
							
							let dateValidation = '';
							if(field[48] == 'V9'){
								dateValidation = 'past'
							}else if(field[48] == 'V10'){
								dateValidation = 'today'
							}else if(field[48] == 'V11'){
								dateValidation = 'future'
							}
							
							let gridLabel = [];
							if(field[5] == 'basicgrid' || field[5] == 'grid5point' || field[5] == 'grid-multicheck' || field[5] == 'grid-singlecheck'){
								let str = field[41] ? field[41].split('_') : [];
								
								if(str.length > 0){
									
									let columns = str[0].split(',');
									let clabel = [];
									columns.map((val,i)=>{
										let fieldN = 'columnlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										clabel.push(fd);
									});
									
									let rows = str[1].split(',');
									let crow = [];
									rows.map((val,i)=>{
										let fieldN = 'rowlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										crow.push(fd);
									});
									gridLabel = {
										"ColumnLabelValues": clabel,
										"RowLabelValues": crow,
									}
								}
							}
							
							if(field[5] == 'basicgrid2'){
								let str = field[41] ? field[41].split('_') : [];
								
								if(str.length > 0){
									let columns = str[0].split('|');
									let rows = str[1].split(',');
									let crow = [];
									rows.map((val,i)=>{
										let fieldN = 'rowlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										crow.push(fd);
									});
									
									let columnslable = [];
									let columnstd = [];
									columns.map((val,i)=>{
										let cm = val.split(':');
										let fieldN = 'columnlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: cm[0].trim(),
										}
										
										columnslable.push(fd);
										columnstd.push(cm[1]);
									});
									let columnstd2 = [];
									columnstd.map((val,i)=>{
										let fieldm = 'column'+parseInt(i+1);
										let fds = {
											[fieldm]: val.trim(),
										}
										
										columnstd2.push(fds);
									});
									
									gridLabel = {
										"ColumnLabelValues": columnslable,
										"RowLabelValues": crow,
										"ColumnDataType": columnstd2,
									}
								}
							}
							let optionValue = [];
							if(field[5] == 'weighted'){
								let str = field[41] ? field[41].split(',') : [];
								str.map((val,i)=>{
									let op = val.split(':');
									options.push(op[0]);
									optionValue.push(op[1]);
								});
							}
							
							if(field[5] == 'grid-dropdown'){
								let str = field[41] ? field[41].split('_') : [];
								
								if(str.length > 0){
									let columns = str[0].split(',');
									let clabel = [];
									columns.map((val,i)=>{
										let fieldN = 'columnlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										clabel.push(fd);
									});
									let rows = str[1].split('|');
									
									//let columnslable = [];
									let rowValue = [];
									let coption = [];
									rows.map((val,i)=>{
										let cm = val.split(':');
										//columnslable.push(cm[0]);
										//rowValue.push(cm[0]);
										let fieldN = 'rowlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: cm[0].trim(),
										}
										rowValue.push(fd);
										
										cm.map((val,j)=>{
											if(j > 0){
												let fieldN = 'row'+parseInt(i+1)+'_columnoption'+parseInt(j);
												let fd = {
													[fieldN]: val.split(','),
												}
												coption.push(fd);
											}
											
										});
										
									});
									
									gridLabel = {
										"ColumnLabelValues": clabel,
										"RowLabelValues": rowValue,
										"ColumnOption": coption,
										//"ColumnDataType": columnstd,
									}
								}
							}
							
							let ctype = field[114];
							let ctypeVal = [];
							let calculationType = [];
							if(field[5] == 'calculation'){
								if(ctype.indexOf(',') > -1) {
									let ctypearr = ctype.split(',');
									ctypearr.map((val,i)=>{
										let fieldN = 'field'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										ctypeVal.push(fd);
									});
									
								}
								
								calculationType = ["Sum", "Average", "Subtract", "Highest Number", "Lowest Number", "Longest Time", "Shortest Time"]
							}
							
							let opVal = [];
							if(options.length > 0){
								options.map((val,i)=>{
									let fieldN = 'option'+parseInt(i+1);
									let fd = {
										[fieldN]: val.trim(),
									}
									opVal.push(fd);
								});
							}
							
							let TrackingCode = {'trackingcodelabel1':field[125]};
							
							if(field[125].indexOf(',') > -1) {
								TrackingCode = [];
								let arr = field[125].split(',');
								arr.map((val,i)=>{
									let fieldN = 'trackingcodelabel'+parseInt(i+1);
									let fd = {
										[fieldN]: val.trim(),
									}
									TrackingCode.push(fd);
								});
							}
							
							let FieldTypeOptions = {
								"Options": opVal.length > 0 ? opVal : '',
								//"OptionsValue": optionValue.length > 0 ? optionValue : '',
								"GridColumns": field[136],
								"GridRows": field[137],
								"GridLabelValue": Object.keys(gridLabel).length !== 0 ? gridLabel : '',
								"CalculationType": field[113],
								//"CalculationType": calculationType.length > 0 ? calculationType : '',
								"CalculationFields": ctypeVal.length > 0 ? ctypeVal : '',
								"WebsiteLink": field[78],
								//"TrackingCode": field[125] ? field[125].split(',') : '',
								"TrackingCode": TrackingCode.length > 0 ? TrackingCode : '',
								//"DateValidation": dateValidation.length > 0 ? dateValidation : '',
								"DateValidation": dateValidation,
							}
							
							const filteredObj2 = (obj) =>
							  Object.entries(obj)
								.filter(([_, value]) => !!value || typeof value === "boolean")
								.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
							
							FieldTypeOptions = filteredObj2(FieldTypeOptions);
							
							let DataLowLimit = '';
							let DataHighLimit = '';
							if(field[5] == 'Q6'){
								DataLowLimit = {
									'number':field[50],
								}
								
								DataHighLimit = {
									'number':field[51],
								}
							}
							
							if(field[5] == 'timer'){
								DataLowLimit = {
									'time':field[50],
								}
								
								DataHighLimit = {
									'time':field[51],
								}
							}
							
							let fieldData = {
								"FieldGUID": field[117],
								"MasterDataSetGUID": mdsGuid,
								"FieldName": field[0].replaceAll("<<", "").replaceAll(">>", "").trim(),
								"FieldNameIdentifier_STATIC": field[61],
								"VisibleText": field[4],
								"FieldType": field[5],
								"FieldTypeOptions": Object.keys(FieldTypeOptions).length !== 0 ? FieldTypeOptions : '',
								"PrefilteredDelegates": kioskT.length > 0 ? kioskT : '',
								"ButtonLabel": field[40],
								"ImageLocationReferenceInfo": field[99],
								"OutputText": field[9],
								"Notes": notes,
								"InternalDataPersistenceLabel": field[110],
								"Tags": field[13],
								"DataAgg": field[3] == 'Y' ? true:false,
								"AutoTags": field[106] ? field[106].replaceAll("<<", "").replaceAll(">>", "").trim() : '',
								"ValidationType": field[48],
								"DataLowLimit": field[5] != 'Q8' ? DataLowLimit : '',
								"DataHighLimit":field[5] != 'Q8' ? DataHighLimit : '',
								"CreatedDate": field[97] ? moment(createdDate).format('YYYY-MM-DD HH:mm:ss') : moment(lastDate).format('YYYY-MM-DD HH:mm:ss'),
								"ModifiedDate": moment(lastDate).format('YYYY-MM-DD HH:mm:ss'),
								"IsActive": field[60] == 'Y' || field[60] == '' ? true:false,
								"Translations": false,
							}
							
							const filteredObj = (obj) =>
							  Object.entries(obj)
								.filter(([_, value]) => !!value || typeof value === "boolean")
								.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
							
							fieldData = filteredObj(fieldData);
							fields.push(fieldData);
							
						}
					})

					let maximumDate = new Date(Math.max.apply(null, dates));
					let minimumDate = new Date(Math.min.apply(null, dates));
					let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
					let minDate = new Date(minimumDate).toLocaleString("en-US").replace(',','');
					//let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
					
					parentMds = parentMds.filter(function(x) {
						 return x !== undefined;
					});
					let parentMdsGuid = this.uniqueArray2(parentMds);
					let itemString = parentMdsGuid.toString();
					
					let layout ={
						"MDSGuid": mdsGuid,
						"MDSName": sheetName,
						"CreatedDate": moment(minDate).format('YYYY-MM-DD HH:mm:ss'),
						"ModifiedDate": moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
						'Version': version,
						'Fields': fields,
					}
					
					this.insertMdsJsonMongo(JSON.stringify(layout));
				}

			},function(reason) {
				alert(reason.result.error.message);
			} 
		);
	}
	
	uploadLayoutJsonMongoDb = (sheetName) => {
		
		let spreadsheetId = this.props.childSpreadsheetId;
		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:sheetName, 
		})
		.then(
			response => {
				const allData = response.result.values;
				
				let currentDate = new Date().toLocaleString("en-US").replace(',','');
				let parentMds = [];
				
				if(allData.length > 0){
					let layoutGuid = allData[1][91];
					let associatedType = allData[1][92];
					let associatedForm = allData[1][93];
					let associatedDevice = allData[1][94];
					let associatedVisit = allData[1][95];
					let version = allData[1][59] ? allData[1][59] : '1.01';
					let friendly_name = allData[1][62] ? allData[1][62] : '';
					let Public = allData[1][111] ? allData[1][111] : 'Yes';
					let PrivateLayoutOwner = allData[1][115] ? allData[1][115] : '';
					let AssignedTrack = allData[1][125] ? allData[1][125].split(',') : '';
					
					let dates = [];
					let fields = [];
					let mdsFiles = [];
					allData.map((field,i)=>{
						if(i > 0){
							let date = new Date(field[98]);
							if(date != 'Invalid Date'){
								dates.push(date);
							}
							
							parentMds.push(field[129]);
							
							let mdsSheetWithSid = field[58] ? field[58]+','+field[118]+','+field[129] : '';
							mdsFiles.push(mdsSheetWithSid);
									
							let preId = '';
							let parentGuid = '';
							allData.map((pre_field,i)=>{
								if(field[7] && field[7] == pre_field[0]){
									preId = pre_field[117];
								}
								
								if(pre_field[1] == field[2]){
									parentGuid = pre_field[117];
								}
							})

							let oldP = field[16];
							let userArray = oldP.split(',');
							userArray.splice(0, 9);
							
							let uArray = userArray.filter(function(v){return v!==''});
							let kioskTypes = uArray.toString();
							kioskTypes = kioskTypes.replaceAll(',','|');

							
							let fieldData = {
								"FieldGUID": field[131] ? field[131] : field[117],
								"Position#": field[10],
								"Include": field[14] == 'Y' ? true:false,
								"Required": field[15] == 'Y' ? true:false,
								"DelegateTypes": "["+kioskTypes+"]",
								"DefaultLayoutGuid": layoutGuid,
								"ParentGUID": parentGuid,
								//"Public": field[111] == 'Y' ? true:false,
							}
							
							const filteredObj = (obj) =>
							  Object.entries(obj)
								.filter(([_, value]) => !!value || typeof value === "boolean")
								.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
							
							fieldData = filteredObj(fieldData);
							fields.push(fieldData);
							
						}
					})

					let minimumDate = new Date(Math.min.apply(null, dates));
					let maximumDate = new Date(Math.max.apply(null, dates));
					let minDate = new Date(minimumDate).toLocaleString("en-US").replace(',','');
					let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
					//let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
					
					parentMds = parentMds.filter(function(x) {
						 return x !== undefined;
					});
					
					let parentMdsGuid = this.uniqueArray2(parentMds);
					
					let mdsFilesArray = this.uniqueArray2(mdsFiles);
					
					//console.log('mdsFilesArray->',mdsFilesArray);
					
					if(mdsFilesArray.length > 0){
						mdsFilesArray.map((fls,i)=>{
							let fileStr = fls.split(',');
							this.uploadMdsJsonMongoDb(fileStr[0],fileStr[1]);
						});
						
					}
					
					let itemString = parentMdsGuid.toString();
					
					let layout ={
						"DefaultLayoutName": sheetName,
						"DefaultLayoutGuid": layoutGuid,
						"FriendlyName": friendly_name,
						"Public": Public,
						"PrivateLayoutOwner": PrivateLayoutOwner,
						"IncludedMDSGUIDs": itemString,
						"AssociatedType": associatedType,
						"AssociatedDeviceTypes": associatedDevice,
						"AssociatedVisitTypes": associatedVisit,
						"AssignedTrack": AssignedTrack,
						//"AssociatedTrack": [],
						"Version": version,
						"CreatedDate": moment(minDate).format('YYYY-MM-DD HH:mm:ss'),
						"ModifiedDate": moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
						"Fields": fields,
					}
					//console.log('layout->',layout);
					this.insertLayoutJsonMongo(JSON.stringify(layout));
				}

			},function(reason) {
				alert(reason.result.error.message);
			}
		);
	}
	
	insertMdsJsonMongo(data){
		let mongoApiUrl = $('#mongoApiUrl').val();
		let url = mongoApiUrl+'insert-mds-v2';
		axios({
			method: 'POST',
			url: url,
			data: data,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			console.log('response::', response.data);
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	insertLayoutJsonMongo(data){
		
		let mongoApiUrl = $('#mongoApiUrl').val();
		let url = mongoApiUrl+'insert-layout-v2';
		axios({
			method: 'POST',
			url: url,
			data: data,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			//console.log('response::', response.data);
			let that = this;
			setTimeout(function(){
				that.getLayoutJsonFileVersion();
			}, 1000);
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	uniqueArray2(arr) {
		var a = [];
		for (var i=0, l=arr.length; i<l; i++)
			if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
				a.push(arr[i]);
		return a;
	}
	
	setUploadToMongo=(event)=>{
		let sheet = event.target.name;
		let type = event.target.id;
		let selectedFiles = this.state.selectedFiles;
		
		if($("#"+type).prop("checked") == true){
			selectedFiles.set(type,sheet);
			this.setState({[type]:true});
		}else{
			selectedFiles.delete(type);
			this.setState({[type]:false});
		}
		
		this.setState({selectedFiles});
	}
	
	getLayoutJsonFileVersion=()=>{
		let layoutSheetGuids = [];
		this.props.childSheetNames.map((sheet,i)=>{
			let sheetGuids = this.props.sheetGuids;
			let sheetGuid = sheetGuids.get(sheet.name);
			layoutSheetGuids.push(sheetGuid);	
		});
		
		let mongoApiUrl = $('#mongoApiUrl').val();
		let url = mongoApiUrl+'layout-json-version-v2';
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(layoutSheetGuids),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			
			let files = response.data;
			files.map((file,i)=>{
				if(file.Version){
					let layoutFilesVersion = this.state.layoutFilesVersion;
					layoutFilesVersion.set(file.DefaultLayoutGuid,file.Version);
					
					let layoutMdsFiles = this.state.layoutMdsFiles;
					layoutMdsFiles.set(file.DefaultLayoutGuid,file.mdsFiles);
					
					let layoutFriendlyName = this.state.layoutFriendlyName;
					layoutFriendlyName.set(file.DefaultLayoutGuid,file.FriendlyName);
					
					this.setState({layoutFilesVersion,layoutMdsFiles,layoutFriendlyName});
				}
			});
			
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	render() {
		const {sheetApiName,childSheetNames,sheetVersion,sheetGuids} = this.props;
		const {layoutFilesVersion,layoutFriendlyName,layoutMdsFiles,selectedFiles} = this.state;
		let MdsFiles =[];
		//console.log('layoutMdsFiles->',layoutMdsFiles);
		let totalFieldCountM = 0;
		let totalFieldCountC = 0;
		
		return (
			<div className="mds-manager-mongo container">
				
				<div className="mongo-header">
					<div className="mg-page-title"><h4>MongoDB Layout Manager</h4></div>
					<div className="mg-upload-all"><button type="button" className="btn btn-info" onClick={this.uploadLayoutInMongo} >Upload selected files</button></div>
				</div>
				
				<div className="mg-child-sheets">
					<div className="mg-top-action-icon">
						<div className="mg-top-action-span">
							<div className="mg-mongo-name">Layout Name</div>
							<div className="mg-mongo-update">Last Updated</div>
						</div>
						<div className="mg-top-action-span2">
							<div className="mg-mongo-lable">Select to process</div>
							<div className="mg-mongo-lable">Send to Mongo</div>
							<div className="mg-mongo-lable">Layout files in DB</div>
							<div className="mg-mongo-lable">Ver.#</div>
							<div className="mg-mongo-lable">MDS files in DB</div>
						</div>
					</div>
					<div className="mds-sheet-div">
						{childSheetNames ?
						<div className="mds-file-list">
						<ul className="list-group manage-form">
							{childSheetNames.map((sheet, i) => {
								let apiName = sheetApiName.get(sheet.name);
								
								let totalRows = '';
								let lastUpdate = '';
								if(apiName){
									apiName = apiName.split('_');
									lastUpdate = apiName[2];
									totalRows = apiName[3];
									
									if(Number(totalRows)){
										totalFieldCountC = parseInt(totalFieldCountC) + parseInt(totalRows);
									}
								}
								
								let version = sheetVersion.get(sheet.name);
								let sheetGuid = sheetGuids.get(sheet.name);
								let mongoVersion = layoutFilesVersion.get(sheetGuid);
								let friendlyName = layoutFriendlyName.get(sheetGuid);
								
								let classn = 'not-in-mongo';
								if(mongoVersion && (mongoVersion == version || version == '')){
									classn = 'has-in-mongo';
								}
								let mdsClass = 'not-in-mongo';
								
								let mdsFilestr = '';
								if(layoutMdsFiles.get(sheetGuid) != undefined){
									let MdsFiles = layoutMdsFiles.get(sheetGuid);
									mdsClass = 'has-in-mongo';
									MdsFiles.map((msheet, i) => {
										if(msheet.has == false){
											mdsClass = 'not-in-mongo';
										}
									});
									
									mdsFilestr = MdsFiles.map((msheet, i) => {
										return (
											<tr><td>{msheet.MDSName}</td><td>{msheet.MDSGuid}</td><td>{msheet.Version}</td></tr>
										);
									})
								}
								
								/* if(selectedFiles.get(sheet.sheetId) != undefined){
									alert(selectedFiles.get(sheet.sheetId));
									classn = 'has-in-mongo';
								} */
								
								let selectedLanguage = this.state.selectedLanguage;
								let language = 'eng';
								if(selectedLanguage){
									let str = selectedLanguage.split('_');
									if(str.length > 1){
										language = str[0] == sheetGuid ? str[1]: 'eng'
									}
								}
								//console.log('sheet->',sheet);
									
								return(<li id={'mds-'+sheet.sheetId} key={i} className={"mg-list-group-item show"}>
								<div className="mg-sheet-name-i ">{sheet.name} </div>
								<div className="mg-sheet-last-update">{lastUpdate} </div>
								<div className="mg-mds-download-icon">
									<div className="mg-mongo-val pointer"><input type="checkbox" name={sheet.name} id={sheet.sheetId} checked={classn == 'has-in-mongo' || this.state[sheet.sheetId] ? 'checked' : ''} onChange={this.setUploadToMongo} disabled={classn == 'has-in-mongo' ? 'disabled' : ''} /></div>
									<div className="mg-mongo-val pointer">
									{/*  classn == 'has-in-mongo' ?
									 <i className="fa fa-floppy-o" aria-hidden="true"></i>
									:
									 <i className="fa fa-floppy-o" aria-hidden="true" onClick={() => { this.uploadLayoutJsonMongoDb(sheet.name) } }></i>
									 */}
									 <i className="fa fa-floppy-o" aria-hidden="true" onClick={() => { this.uploadLayoutJsonMongoDb(sheet.name) } }></i>
									</div>
									<div className="mg-mongo-val"><i id={'guid-'+sheetGuid} className={"fa fa-circle "+classn} aria-hidden="true"></i></div>
									<div className="mg-mongo-val"><i>{version && version != 1 ? version : '1.01' }</i></div>
									{mdsClass == 'has-in-mongo' ? 
										<div className="mg-mongo-val pointer" data-target={"#layoutDataModal"+sheetGuid} data-toggle="modal"><i id={'guid-'+sheetGuid} className={"fa fa-circle has-in-mongo"} aria-hidden="true"></i></div>
									:
										<div className="mg-mongo-val" data-toggle="modal"><i id={'guid-'+sheetGuid} className={"fa fa-circle not-in-mongo"} aria-hidden="true"></i></div>
									}
									
									<div className="modal" id={"layoutDataModal"+sheetGuid} role="dialog">
										<div className="modal-dialog modal-lg">
											<div className="modal-content">
											  <div className="modal-header">
												<h5> Layout Name: {sheet.name} </h5>
												<button type="button" className="close" data-dismiss="modal">&times;</button>
											  </div>
											  <div className="modal-body">
													<table className="layout-tbl table-bordered">
													  <tr>
														<th>Layout Friendly Name</th>
														<th>Layout GUID</th>
														<th>Version</th>
													  </tr>
													  <tr>
														<td>{friendlyName ? friendlyName : sheet.name}</td>
														<td>{sheetGuid}</td>
														<td>{version}</td>
													  </tr>
													  <tr>&nbsp;</tr>
													  <tr>
														<th>MDS Name</th>
														<th>MDS GUID</th>
														<th>Version</th>
													  </tr>
													  {mdsFilestr}
													</table>
											  </div>
											</div>
										</div>
									</div>
									
								</div>
								</li>)
							})}
							</ul>
						</div>
						:null}
					</div>
				</div>
			</div>
		);	
	}	
}
