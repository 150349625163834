import React, { Component } from 'react';
import $ from 'jquery';
import config from "../config";
import { gapi } from 'gapi-script';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'
import arrayMove from "./arrayMove";
import Recurring from "./Recurring";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

export default class InstanceManager extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			instanceSetting:[],
			instanceId:'',
		}

    }
	
	componentDidMount() {
		$("body").on("click", ".btn-delete-mds-sheet", function () {
			let rowId = $(this).attr('id');
			$('#appendRow'+rowId).remove();
			var count = 1;
			$('#mdsSheetSec .append-sec').each(function() {
				$(this).find(".mds-input-sheet").attr('name', 'mdsSpreadsheetId' + count);
				$(this).find(".mds-input-sheet").attr('placeholder', 'Spreadsheet ' + count);
				$(this).find(".mds-input-temp").attr('name', 'mdsTempSheetId' + count);
				$(this).find(".mds-input-temp").attr('placeholder', 'Temp SheetId for Spreadsheet ' + count);
				count++;
			});
		})
	}
	
	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		alert(name);
		this.setState({[event.target.name]:event.target.value});

	}
	
	deleteInstance=(id)=>{

		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'instance/delete_instance.php';
		let formData = new FormData();
		formData.append('id', id);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			alert(response.data);
			this.props.getAllInstances();
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	selectInstance=(event)=>{
		let value = event.target.value;
		let instances = this.props.defaultProps.instances;
		
		instances.map((val,i)=>{
			if(val.id == value){
				this.setState({instanceSetting:val,instanceId:val.id});
			}
		});	
    }
	
	addMdsSheets(){
		
		var count = 1;
		$('#mdsSheetSec .append-sec').each(function() {
			count++;
		});
		
		let newRowAdd = '<div id="appendRow'+count+'" class="row align-items-end append-sec">'+
			'<div class="col-md-6">'+
				'<input class="form-control mds-input mds-input-sheet" placeholder="Spreadsheet '+count+'" type="text" name="mdsSpreadsheetId'+count+'" />'+
			'</div>'+
			'<div class="col-md-6">'+
				'<input class="form-control temp-left-side temp-input mds-input-temp" placeholder="Temp SheetId for Spreadsheet '+count+'" type="text" name="mdsTempSheetId'+count+'" /> <button id="'+count+'" class="btn btn-delete-mds-sheet"><i class="fa fa-trash"></i></button>'+
			'</div>'+
		'</div>';
		$('#mdsSheetSec').append(newRowAdd);
    }
	
	
	storeMdsInMongoB=()=>{
		let spreadsheetId = this.props.mdsSpreadsheetId;
		let mdsSheets = this.props.mdsSheets;
		mdsSheets.map((sheetName,i)=>{
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: spreadsheetId,
				range:sheetName, 
			})
			.then(
				response => {
					const allData = response.result.values;
					if(allData.length > 0){
						let mdsGuid = allData[1][91];
						let version = allData[1][59] ? allData[1][59] : '1.01';
						let layout ={
							"MDSGuid": mdsGuid,
							"MDSName": sheetName,
							'Version': version,
							'Fields': allData,
						}
						
						this.storeMdsJsonMongo(JSON.stringify(layout));
					}
					
				},function(reason) {
					console.log(reason.result.error.message);
				}
			);
			return false;
		});		
    }
	
	storeMdsInMongo=(instanceName, instanceId, mdsSheets)=>{
		let spreadsheetId = instanceId;
		
		this.storeInstanceMongo(instanceId,instanceName);
		
		mdsSheets.map((sheetName,i)=>{
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: spreadsheetId,
				range:sheetName, 
			})
			.then(
				response => {
					const allData = response.result.values;
					if(allData.length > 0){
						let mdsGuid = allData[1][91];
						let version = allData[1][59] ? allData[1][59] : '1.01';
						let dates = [];
						allData.map((field,i)=>{
							if(i > 0){
								
								let date = new Date(field[98]);
								if(date != 'Invalid Date'){
									dates.push(date);
								}
							}
						});
						
						let maximumDate = new Date(Math.max.apply(null, dates));
						let minimumDate = new Date(Math.min.apply(null, dates));
						let modifiedDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
						let createdDate = new Date(minimumDate).toLocaleString("en-US").replace(',','');
					
						
						let layout ={
							"MDSGuid": mdsGuid,
							"MDSName": sheetName,
							"InstanceName": instanceName,
							"InstanceGuild": instanceId,
							"CreatedDate": moment(createdDate).format('YYYY-MM-DD HH:mm:ss'),
							"ModifiedDate": moment(modifiedDate).format('YYYY-MM-DD HH:mm:ss'),
							'Version': version,
							'Fields': allData,
						}
						
						let that = this;
						that.storeMdsJsonMongo(JSON.stringify(layout));
						
					}
					
				},function(reason) {
					console.log(reason.result.error.message);
				}
			);
		});
		
		alert('Instance & MDS files Inserted Successfully!');
		
    }
	
	storeInstanceMongo(instanceId,instanceName){
		let mongoApiUrl = $('#mongoApiUrl').val();
		let url = mongoApiUrl+'store-instance';
		let data ={
			"InstanceName": instanceName,
			"InstanceGuild": instanceId
		}
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			//alert(response.data);
			console.log('response::', response.data);
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	storeMdsJsonMongo(data){
		let mongoApiUrl = $('#mongoApiUrl').val();
		let url = mongoApiUrl+'store-mds-files';
		axios({
			method: 'POST',
			url: url,
			data: data,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			//alert(response.data);
			console.log('response::', response.data);
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	/* storeMdsJsonMongoV2(data){
		console.log('data', data);
		let mongoApiUrl = $('#mongoApiUrl').val();
		let url = mongoApiUrl+'store-mds-files-v2';
		axios({
			method: 'POST',
			url: url,
			data: data,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			console.log('response::', response.data);
		}).catch(error => {
			console.log('error::', error);
		}) 
	} */
	
	mdsToMongo=(mdsSpreadsheetId) => {
		console.log('mdsSpreadsheetId->',mdsSpreadsheetId);
		gapi.client.sheets.spreadsheets
		.get({
			spreadsheetId: mdsSpreadsheetId, 
		})
		.then(
			response => {
				const instanceName = response.result.properties.title;
				const instanceId = response.result.spreadsheetId;
				const sheets = response.result.sheets;
				let mdsSheets = sheets.map((sheet) =>{
					let sheetTitle = sheet.properties.title;
					if(sheet.properties.gridProperties.columnCount > 120 && sheet.properties.gridProperties.columnCount < 150 && sheetTitle != 'Temp File' && sheetTitle.indexOf('Temp-') == -1){
						return sheet.properties.title
					}
					
				});
				
				mdsSheets = mdsSheets.filter(function(x) {
					 return x !== undefined;
				});
				if(mdsSheets){
					this.storeMdsInMongo(instanceName, instanceId, mdsSheets);
				}
			},function(reason) {
				alert(reason.result.error.message);
			}
		);
	}
	
	render() {
		const {defaultProps} = this.props;
		
		let optionHtml = '';
		
		if(defaultProps.instances){
			let ins = defaultProps.instances;
			
			optionHtml = ins.map(function(val,i) {
				if(defaultProps.instanceName != val.instance_name){
					return (
						<option value={val.id} key={i}>{val.instance_name}</option>
					);
				}
			})
		}
		
		let optionHtmlDes = '';
		
		if(defaultProps.destinations){
			let destinations = defaultProps.destinations;
			
			optionHtmlDes = destinations.map(function(val,i) {
				return (
					<option value={val.id} key={i}>{val.name}</option>
				);
			})
		}
		let mdsSheetRows = '';
		
		if(defaultProps.mdsSpreadsheets){
			let mdsIds = defaultProps.mdsSpreadsheets;
			let that = this;
			mdsSheetRows = mdsIds.map(function(val,i) {
				
				let count = i+2;
				if(val["mdsSpreadsheetId"+count]){
					return (<div id={"appendRow"+count} class="row align-items-end append-sec">
						<div class="col-md-6">
							<input class="form-control mds-input mds-input-sheet mds-sid" placeholder={"Spreadsheet "+count} type="text" value={val["mdsSpreadsheetId"+count][0]} name={"mdsSpreadsheetId"+count} />
							<button onClick = {()=>that.mdsToMongo(val["mdsSpreadsheetId"+count][0])} className="btn plus-send"><i className="fa fa-send"></i></button>
						</div>
						<div class="col-md-6">
						<input class="form-control temp-left-side temp-input mds-input-temp" value={val["mdsSpreadsheetId"+count][1]} placeholder={"Temp SheetId for Spreadsheet "+count} type="text" name={"mdsTempSheetId"+count} /> <button id={count} class="btn btn-delete-mds-sheet"><i class="fa fa-trash"></i></button>
						</div>
					</div>);
				}
			})
		}

		return (
			<div className="instance-manager">
				<div className="col-md-12 show-output-txt">
					<div className="row">
					{/* <div className="col-md-9">
						<button type="button" className="btn btn-primary" onClick={this.storeMdsInMongo}>MDS files store in MongoDB</button>
					</div> */}
					<div className="col-md-8"></div>
					<div className="col-md-4">
						<label className="showAllSwitch">
							<input id="showOutputText" type="checkbox" name="showAddNewInput" onClick={this.props.showAddNewSection}/>
							<span className="slider round showAllSlide"></span>
						</label> Add New Instance
					</div>
					</div>
				</div>
				
				{ defaultProps.showAddNew ? 
				<div className="row add-ins-section">
					<div className="col-md-12">
						<div className="inss-title"> Add Instances</div>
					</div>
					
					<div className="col-md-12 instance-sec">
						<div className="row align-items-end">
							<div className="col-md-6">
								<label> Instance Name: </label>
								<input className="form-control" type="text" name='addInstanceName' onChange={this.props.getValue} />
							</div>
							<div className="col-md-6">
								<label> Layout Spreadsheet Id: </label>
								<input className="form-control" type="text" name='addLayoutSpreadsheetId' onChange={this.props.getValue} />
							</div>
						</div>
					</div>
					
					<div className="col-md-12 instance-sec">
						<div className="row align-items-end">
							<div className="col-md-6">
								<label> MDS Spreadsheet Id: </label>
								<input className="form-control" type="text" name='addMdsSpreadsheetId' onChange={this.props.getValue} />
							</div>
							<div className="col-md-6">
								<label> MDS Temp SheetId: </label>
								<input className="form-control" type="text" name='addMdsTempSheetId' onChange={this.props.getValue}/>
							</div>
						</div>
					</div>
					
					<div className="col-md-12 instance-sec">
						<div className="row align-items-end">
							<div className="col-md-6">
								<label> Default Layout Spreadsheet Id: </label>
								<input className="form-control" type="text" name='addChildSpreadsheetId' onChange={this.props.getValue} />
							</div>
							<div className="col-md-6">
								<label> Default Layout Temp SheetId: </label>
								<input className="form-control" type="text" name='addChildTempSheetId' onChange={this.props.getValue}/>
							</div>
						</div>
					</div>
					
					<div className="col-md-12 instance-sec">
						<div className="row align-items-end">
							<div className="col-md-6">
								<label> Test Form Data Spreadsheet Id: </label>
								<input className="form-control" type="text" name='addTestFormSpreadsheetId' onChange={this.props.getValue} />
							</div>
							<div className="col-md-6">
								<label> Test Form Data SheetId: </label>
								<input className="form-control" type="text" name='addformDataSheetId' onChange={this.props.getValue} />
							</div>
						</div>
					</div>

					<div className="col-md-12">
						<div className="row align-items-end save-inst">
							<button type="button" className="btn btn-primary float-left instance-update" onClick={this.props.addInstance}>Save</button>
						</div>
						<div className="row new-destination">
							<label className="showAllSwitch">
								<input id="showOutputText" type="checkbox" name="showAddNewDes" onClick={this.props.showAddNewDestination}/>
								<span className="slider round showAllSlide"></span>
							</label> Add  New Copy To Destinations
						</div>
					</div>
				</div>
				:null}
				
				{ defaultProps.showAddNewDes ?
				<div className="row add-ins-destination">
					<div className="col-md-12">
						<div className="inss-title"> Add Copy To Destinations</div>
					</div>
					<div className="col-md-12 instance-sec">
						<div className="row align-items-end">
							<div className="col-md-6">
								<label> Copy To Destinations Name: </label>
								<input className="form-control" type="text" name='addDestinationName' onChange={this.props.getValue} />
							</div>
						</div>
					</div>
					<div className="col-md-12 instance-sec">
						<div className="row align-items-end">
							<div className="col-md-6">
								<label>MDS Field Copy destination Spreadsheet Id: </label>
								<input className="form-control" type="text" name='addCopyFieldSpreadsheetId' onChange={this.props.getValue} />
							</div>
							<div className="col-md-6">
								<label>MDS Field Copy destination Sheet Id: </label>
								<input className="form-control" type="text" name='addCopyFieldSheetId' onChange={this.props.getValue} />
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<div className="row align-items-end save-inst">
							<button type="button" className="btn btn-primary float-left instance-update" onClick={this.props.addDestination}>Save</button>
						</div>
					</div>
						
				</div>
				:null}
				
				{ !defaultProps.showAddNew ?
				
				<div className="setting-ins-section">
					
					<div className="col-md-12">
						<div className="inss-title"> Instance Settings for "{defaultProps.instanceName}"</div>
					</div>
					
					<div className="col-md-12 instance-sec main-ins-sce">
						<div className="row align-items-end">
							<div className="col-md-6">
								<label> Instance Name: </label>
								<input className="form-control" type="text" name='addInstanceName' onChange={this.props.getValue} value={defaultProps.instanceName} disabled/>
							</div>
							
							<div className="col-md-6">
								<div className="instnace-select">
								<label className="label-control"> Instances </label>
								<select className="form-control custom-select-ins" name="selectInstance" onChange={this.selectInstance}>
									<option value="">Please Select</option>
									{optionHtml}
								</select>
								</div>
								<div className="instnace-btn">
									<i onClick={() => { if (window.confirm('Are you sure you want to delete this Instance?')) this.deleteInstance(this.state.instanceId)} } className="fa fa-trash"></i>
									
									<button type="button" className="btn btn-primary float-left instance-update btn-sm" onClick = {()=>this.props.changeInstance(this.state.instanceId)}>Select</button>
								</div>
							</div>
						</div>
					</div>
					
					<div id="mdsSheetSec" className="col-md-12 instance-sec mds-sheet-sec">
						<div className="row align-items-end append-sec">
							<div className="col-md-6">
								<label> MDS Spreadsheet Id: </label>
								<input className="form-control mds-sid" type="text" name='mdsSpreadsheetId' onChange={this.props.getValue} value={defaultProps.mdsSpreadsheetId} />
								<button onClick = {()=>this.mdsToMongo(defaultProps.mdsSpreadsheetId)} className="btn plus-send"><i className="fa fa-send"></i></button>
								
							</div>
							<div className="col-md-6">
								<label> MDS Temp SheetId: </label> <button onClick={this.addMdsSheets} className="btn plus-right-side"><i className="fa fa-plus"></i></button>
								<input className="form-control temp-left-side " type="text" name='mdsTempSheetId' onChange={this.props.getValue} value={defaultProps.mdsTempSheetId} /> 
								
							</div>
						</div>
						{mdsSheetRows}
					</div>
					
					<div className="col-md-12 instance-sec">
						<div className="row align-items-end">
							<div className="col-md-6">
								<label> Default Layout Spreadsheet Id: </label>
								<input className="form-control" type="text" name='childSpreadsheetId' onChange={this.props.getValue} value={defaultProps.childSpreadsheetId} />
								
							</div>
							<div className="col-md-6">
								<label> Default Layout Temp SheetId: </label>
								<input className="form-control" type="text" name='childTempSheetId' onChange={this.props.getValue} value={defaultProps.childTempSheetId} />
							</div>
						</div>
					</div>
					
					<div className="col-md-12 instance-sec">
						<div className="row align-items-end">
							<div className="col-md-6">
								<label> Test Form Data Spreadsheet Id: </label>
								<input className="form-control" type="text" name='formDataSpreadsheetId' onChange={this.props.getValue} value={defaultProps.formDataSpreadsheetId} />
							</div>
							<div className="col-md-6">
								<label> Test Form Data SheetId: </label>
								<input className="form-control" type="text" name='formDataSheetId' onChange={this.props.getValue} value={defaultProps.formDataSheetId} />
							</div>
						</div>
					</div>
					
					<div className="col-md-12 instance-sec">
						<div className="row align-items-end">
							<div className="col-md-6">
								<label> Layout Spreadsheet Id: </label>
								<input className="form-control" type="text" name='layoutSpreadsheetId' onChange={this.props.getValue} value={defaultProps.layoutSpreadsheetId} />
							</div>
							<div className="col-md-6">
								<label className="label-control"> Copy MDS Destination </label>
								<select className="form-control custom-select-ins" name="selectDestination" value={defaultProps.selectDestination} onChange={this.props.getValue}>
									<option value="">Please Select</option>
									{optionHtmlDes}
								</select>
							</div>
						</div>
					</div>
					
					<div className="col-md-12 instance-sec">
						<div className="row align-items-end">
							<div className="col-md-6">
								<button type="button" className="btn btn-primary float-right instance-update" onClick={this.props.instanceUpdate}>Update</button>
							</div>
						</div>
					</div>
	
				</div>
				:null}
			</div>
		);	
	}	
}
