import React, { Fragment, useState } from 'react';
import $ from 'jquery';
import { makeStyles }   from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import STLViewer from 'stl-viewer'
import config from "../../config";
import axios  from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: '10px 0px'
    },
    fileGrid: {
        border: '1px solid #c5c5c5',
        margin: '10px -10px',
    },
    inputFile: {
        marginTop: '20px',
    },
    uploadButton: {
        borderRadius: '20px',
    },
    spinner: {
        marginLeft: '15px'
    }
}));

export default function FieldFile(props) {
	const fieldId = props.data.fieldId;
	const classes = useStyles();
    const [title, setTitle]             = useState('');
    const [description, setDescription] = useState('');
    const [fileName, setFileName]       = useState('');
    const [file, setFile]     = useState('');
    const [embedCode, setEmbedCode]         = useState('');
    const [showSpinner, setShowSpinner]     = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(true);
    const [showUploadButton, setShowUploadButton] = useState(false);
	const [showEmbedCode, setShowEmbedCode] = useState(false);
	const [dataField, setDataField] = useState(props.dataField);
	
	if(!dataField && props.dataField){
		setDataField(props.dataField);
		let str = props.dataField.split(',');
		let url = str[0];
		let ftype = str[0].split('.').pop();
		let emCode='<div class="spinner-border text-dark"></div>';
		if(ftype == 'stl'){
			emCode = '<iframe width="100%" height="420px" src="https://www.viewstl.com/?embedded&url='+url+'&edges=yes&noborder=yes"></iframe>';
		}else if(ftype.toLowerCase() === 'jpeg' || ftype.toLowerCase() === 'jpg' || ftype.toLowerCase() === 'png' || ftype == 'gif'){
			emCode = '<img class="file-upload-image" src="'+url+'"/>'
		}else if(ftype == 'pdf'){
			emCode = '<embed width="100%" height="420px" src='+url+' />'
		}else{
			emCode = '<iframe width="100%" height="420px" src='+url+'></iframe>'
		}
		
		let strData = emCode+'<br><b>Name:</b> '+str[1]+'<br><b>Description:</b> '+str[2]+'<br><b>Date:</b> '+str[3];
		$("#ans"+fieldId).html(strData);	

	}
	
	const handleChange = (files) => {
        setFileName(files[0].name);
        setFile(files[0]);
    }

	const showUpload = () => {
		setUploadSuccess(false);
		setShowUploadButton(true);
	}
	
	const deleteUpload = () => {
		$( "#ans"+fieldId ).html('');
		setDataField('');
	}
	
	const uploadSubmit = () => {
		let ApiUrl = $('#ApiUrl').val();
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
		//let blobURL = URL.createObjectURL(file);
		let fileType = fileName.split('.').pop();
		let embedCode='<div class="spinner-border text-dark"></div>';
		//console.log('blobURL->',blobURL);
		
		if(file){
			
			let formData = new FormData();
			formData.append('file', file);
			formData.append('name', title);
			formData.append('description', description);
			
			let url = ApiUrl+'uploadfile/file_upload1.php';
	
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				let data = response.data;
				let path =data[2];
				
				if(fileType == 'stl'){
					embedCode = '<iframe width="100%" height="420px" src="https://www.viewstl.com/?embedded&url='+path+'&edges=yes&noborder=yes"></iframe>';
				}else if(fileType.toLowerCase() === 'jpeg' || fileType.toLowerCase() === 'jpg' || fileType.toLowerCase() === 'png' || fileType == 'gif'){
					embedCode = '<img class="file-upload-image" src="'+path+'"/>'
				}else if(fileType == 'pdf'){
					embedCode = '<embed width="100%" height="420px" src='+path+' />'
				}else{
					embedCode = '<iframe width="100%" height="420px" src='+path+'></iframe>'
				}
				
				let str = embedCode+'<br><b>Name:</b> '+title+'<br><b>Description:</b> '+description+'<br><b>Date:</b> '+date;
				$( "#ans"+fieldId ).html(str);
				
				setUploadSuccess(true);
				setShowEmbedCode(true);
				setShowUploadButton(false);
				setShowSpinner(false);
				setTitle('');
				setDescription('');
				setFileName('');
				setFile('');
				setDataField(embedCode);

				let res = path+','+title+','+description+','+date;
				props.func(fieldId,res);
			})
			.catch(error => {
				console.log('error::', error);
			}) 
		}
    }
	
	return (
	<Fragment>
		<div id={'ans'+fieldId}></div>
		<form className={classes.root +' upload-btn-type'} noValidate autoComplete="off">
			{ !showUploadButton && <div><Button id={"uploadbtn-"+fieldId} variant="contained" color="primary" onClick={() =>showUpload()}>{dataField ? 'Replace File' : 'Upload File'}</Button>&nbsp;{dataField ? <button id={"deletebtn-"+fieldId} type="button" style={{color:'red'}} className="btn code-dialog btn-delete " onClick={() =>deleteUpload()}><i className="fa fa-trash"></i></button> : null}</div>
			}
			{ !uploadSuccess && <Grid container spacing={3} className={classes.fileGrid}>
				<Grid item xs={12} className={classes.inputFile}>
					<Button
						variant="contained"
						component="label"
						color="secondary"
						className={classes.uploadButton}
						>
						<AddIcon /> Select a File
						<input
							type="file"
							name="file" 
							style={{ display: "none" }}
							onChange={ (e) => handleChange(e.target.files) }
						/>
					</Button>
					{ fileName && (<Box component="span" display="block">{fileName}</Box>) }
				</Grid>
				<Grid item xs={12}>
					<TextField id="title" label="Title" variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)} />
				</Grid>
				<Grid item xs={12}>
					<TextField id="description" label="Description" variant="outlined" value={description} onChange={(e) => setDescription(e.target.value)} />
				</Grid>
				<Grid item xs={12}>
					<Button variant="contained" onClick={() =>uploadSubmit()}>Upload { showSpinner && <CircularProgress disableShrink size="20px" className={classes.spinner} /> }</Button>
				</Grid>
			</Grid> }
		</form>
	</Fragment>
	);
}
