import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from "@material-ui/core/TextField";
import Checkbox from '@mui/material/Checkbox';

export function GridSingleCheckTable(rowData) {
	
	let id = rowData.fieldId;
	let options = rowData.labels;
	let headers = [];
	let rows = [];
	if(options){    
		let str = options.split('_');
		headers = str[0].split(',');
		rows = str[1].split(',');
	}
	let headerHtml = '';
	if(headers){
		headerHtml = headers.map(function(headerText,i) {
			return (	
			<TableCell align="center">
				{headerText}
			</TableCell>	
			);
		})
	}
	let optionsHtml = '';
	if(rows){
		optionsHtml = rows.map(function(rowText,i) {
			let r = i+1;
			return (	
				<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCell component="th" scope="row">
					{rowText}
					</TableCell>
					{
						headers.map(function(headerText,j) {
							let c = j+1;
							return(	
							<TableCell align="center">
								<input className="cus-radio-btn" type="radio" value={headerText}  name={id+''+rowText}/>
							</TableCell>);
						})
					}
				</TableRow>
			);
		}) 
	}

	return (
		<TableContainer component={Paper}>
		  <Table sx={{ minWidth: 566 }} size="small" aria-label="a dense table">
			<TableHead>
			  <TableRow>
				<TableCell></TableCell>
				{headerHtml}
			  </TableRow>
			</TableHead>
			<TableBody>
				{optionsHtml}
			</TableBody>
		  </Table>
		</TableContainer>
	);
}
