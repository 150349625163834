import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from "@material-ui/core/TextField";

export function GridDropdownTable(rowData) {
	
	let id = rowData.fieldId;
	let options = rowData.labels;
	let headers = [];
	let rows = [];
	if(options){    
		let str = options.split('_');
		headers = str[0].split(',');
		rows = str[1].split('|');
	}
	let headerHtml = '';
	if(headers){
		headerHtml = headers.map(function(headerText,i) {
			return (	
			<TableCell>
				{headerText}
			</TableCell>	
			);
		})
	}
	let optionsHtml = '';
	if(rows){
		optionsHtml = rows.map(function(rowText,i) {
			let r = i+1;
			let row = rowText.split(':');
			
			let col1Html = '';
			let col2Html = '';
			
			if(row[1] !== undefined){
				let options = row[1].split(',');
						
				col1Html = options.map(function(opt,i) {
					return (
						<option value={opt} key={i}>{opt}</option>
					);
				})
			}
			
			if(row[2] !== undefined){
				let options = row[2].split(',');
						
				col2Html = options.map(function(opt,i) {
					return (
						<option value={opt} key={i}>{opt}</option>
					);
				})
			}
			
			return (	
				<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCell component="th" scope="row">
					{row[0]}
					</TableCell>
					{col1Html ?
					<TableCell>
						<select className="form-control" name={'R'+r+'-C2'}>
							<option value=""> Please Select</option> 
							{col1Html}							
						</select>
					
					</TableCell>
					:null}
					{col2Html ?
					<TableCell>
						<select className="form-control" name={'R'+r+'-C3'}>
							<option value=""> Please Select</option> 
							{col2Html}							
						</select>
					</TableCell>
					:null}
				</TableRow>
			);
		}) 
	}

	return (
		<TableContainer component={Paper}>
		  <Table sx={{ minWidth: 566 }} size="small" aria-label="a dense table">
			<TableHead>
			  <TableRow>
				{headerHtml}
			  </TableRow>
			</TableHead>
			<TableBody>
				{optionsHtml}
			</TableBody>
		  </Table>
		</TableContainer>
	);
}
