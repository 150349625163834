import React, { Component } from 'react';
import Box from '@mui/material/Box';
import $ from 'jquery';
import config from "../config";
import { gapi } from 'gapi-script';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import arrayMove from "./arrayMove";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';


export default class StatusManager extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			statusData:[],
			statusList:[],
		}
    }
	
	componentDidMount() {
		let that = this;
		that.getStatusList();
		setTimeout(function(){
			that.getTrackerData();
		}, 2000);
		
	}
	
	addFabricationLab=()=>{
		let spreadsheetId = config.lookUpSpreadsheetId;
		let statusText = $('#addStatusText').val();
		let UID = ulid();
		var params = {
			spreadsheetId:spreadsheetId,
			range:'Tracker Data!A:B', 
			valueInputOption: "RAW", 
			insertDataOption: "INSERT_ROWS",
			resource:{
				"values":[[statusText,UID]],
				"majorDimension": "ROWS"
			}
		};
		if(statusText){
			let that = this;
			var request = gapi.client.sheets.spreadsheets.values.append(params);
			request.then(function(response) {
				$('#addStatusText').val('');
				that.props.getLookUpData();
				alert('The Fabrication Lab added successfully!');
			}, function(reason) {
				console.error('error: ' + reason.result.error.message);
			});
		}
	}
	
	getStatusList=()=>{
		let spreadsheetId = config.lookUpSpreadsheetId;
		gapi.client.sheets.spreadsheets.values.get({
			spreadsheetId: spreadsheetId,
			range:'Fab Status list', 
		})
		.then(
			response => {
				const tags = response.result.values;
				let status = [];
				tags.map(function(val,i) {
					if(i > 0){
						status.push(val[0]);
					}
				})
				this.setState({statusList:status});
			},
		);
	}
		
	getTrackerData=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let facilityId = $('#fabStatusFacility').val();
		
		let url = ApiUrl+'tracker/get_status.php?fId='+facilityId;
		let data = [];
		let con = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        axios.get(url,con)
        .then(response => {
            data = response.data;
			this.setState({statusData:data});
        })
	}
	
	addTrackerStatus=()=>{
		let val = $('#statusText').val();
		let facilityId = $('#fabStatusFacility').val();
		
		let ApiUrl = $('#ApiUrl').val();
		let pos = this.state.statusData.length
		if(val && facilityId){
			let url = ApiUrl+'tracker/add_status.php';
			let formData = new FormData();
			formData.append('status', val);
			formData.append('pos', pos+1);
			formData.append('facility', facilityId);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getTrackerData();
					alert(response.data);
					$('#statusText').val('');
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert("Please select the facility and insert the status value.");
		}
	}
	
	changeFacility=(event)=>{
		let id = event.target.value;
		let fabType = $( "#fabStatusFacility option:selected" ).attr('data-type');
		if(fabType == 'custom'){
			$('.btn-delete-fab').show();
		}else{
			$('.btn-delete-fab').hide()
		}
		this.getTrackerData();
	}
	
	updateTrackerStatus=(event)=>{
		
        let name = event.target.name;
        let id = event.target.value;
		let visible = 'N';
		if($("input[name='"+name+"']").prop("checked") == true){
			visible = 'Y';
		}
		this.setState({[name]:visible});
		let that = this;
		if(id && visible){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'tracker/update_status.php';
			let formData = new FormData();
			formData.append('id', id);
			formData.append('visible', visible);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					//alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	updateTrackerStatusMail=(event)=>{
		
        let name = event.target.name;
        let id = event.target.value;
		let mail = 'N';
		if($("input[name='"+name+"']").prop("checked") == true){
			mail = 'Y';
		}
		this.setState({[name]:mail});
		let that = this;
		if(id && mail){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'tracker/update_mail.php';
			let formData = new FormData();
			formData.append('id', id);
			formData.append('mail', mail);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					//alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	updateStatusPractitioner=(event)=>{
		
        let name = event.target.name;
        let id = event.target.value;
		let mail = 'N';
		if($("input[name='"+name+"']").prop("checked") == true){
			mail = 'Y';
		}
		this.setState({[name]:mail});
		let that = this;
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'tracker/update_mail.php';
			let formData = new FormData();
			formData.append('id', id);
			formData.append('send_practitioner', mail);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					//alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	deleteStatus=(id)=>{
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'tracker/delete_status.php';
			let formData = new FormData();
			formData.append('id', id);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				this.getTrackerData();
				if(response.data){
					//alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	deleteFabStatus=(id)=>{
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'tracker/delete_fab.php';
			let formData = new FormData();
			formData.append('facility_id', id);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				this.getTrackerData();
				if(response.data){
					//alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	deleteFab=()=>{
		let value = $("#fabStatusFacility option:selected" ).val();
		let fabType = $("#fabStatusFacility option:selected" ).attr('data-type');

		if(fabType == 'custom'){
			let trackerData = this.props.trackerData;
			let startIndex = '';
			trackerData.map((val, i) => {
				if(val[1] == value){
					startIndex = i;
				}
			});
			this.deleteFabRow(startIndex,value);
		}else{
			alert("You can't delete the original Fabrication Lab.");
		}
	}
	
	deleteFabRow=(startIndex,facility_id)=>{
		let spreadsheetId = config.lookUpSpreadsheetId;
		let fabLabsheetId = config.fabLabsheetId;
		let params =  {
            spreadsheetId: spreadsheetId,
        };
		let that = this;
		let endIndex = startIndex+1;
        let deleteRow = {
            "requests": [
            {
              "deleteDimension": {
                "range": {
                  "sheetId": fabLabsheetId,
                  "dimension": "ROWS",
                  "startIndex": startIndex,
                  "endIndex": endIndex
                }
              }
            }]
        }
		
		let request = gapi.client.sheets.spreadsheets.batchUpdate(params, deleteRow);
        request.then(function(response) {
			that.props.getLookUpData();
			that.deleteFabStatus(facility_id);
			setTimeout(function(){
				that.getTrackerData();
			},4000);
			alert('Fabrication Lab Deleted Successfully!');
		}, function(reason) {
            alert(reason.result.error.message);
        });
	}
	
	onSortEnd = ({oldIndex, newIndex}) => {
		
		this.setState(({statusData}) => ({
		    statusData: arrayMove(statusData, oldIndex, newIndex),
		}));

		let newArray = [];
		this.state.statusData.map((val, i) => {
			let j = i+1;
			newArray[i] = {'id':val.id,'pos':j};
		})
		
		let that = this;
		if(newArray.length > 0){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'tracker/update_pos.php';
			axios({
				method: 'POST',
				url: url,
				data: newArray,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					//alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	};
	
	render() {
		
		const {statusData,statusList} = this.state;
		
		let statusOption = statusList.map(function(val,i) {
			return (
				<option value={val} key={i}>{val}</option>
			);
		})
		
		let facilityOption = this.props.facilityIds.map(function(val,i) {
			let facility = val.split('|');
			return (
				<option value={facility[1]} data-type={facility[2]} key={i}>{facility[0]}</option>
			);
		})

		let that = this;
		const DragHandle = sortableHandle(() => <span className="showcase"></span>);
		const SortableItem = sortableElement(({value}) => {
			
			let str = 'status-'+value.id;
			let checked = value.visible == 'Y' ? true:false;
			if(that.state[str]){
				checked = that.state[str] == 'Y' ? true:false;
			}
			
			let strMail = 'mail-'+value.id;
			let mail = value.mail == 'Y' ? true:false;
			if(that.state[strMail]){
				mail = that.state[strMail] == 'Y' ? true:false;
			}
			
			let strPractitioner = 'practitioner-'+value.id;
			let practitioner = value.send_practitioner == 'Y' ? true:false;
			if(that.state[strPractitioner]){
				practitioner = that.state[strPractitioner] == 'Y' ? true:false;
			}
			
			return (<div id={value.id} className={'field-div'}><DragHandle /><span className="input-title">{value.status}</span>
			<div className="edit-btns-status"><input name={'status-'+value.id} type="checkbox" value={value.id} checked={checked ? 'checked' : ''} onChange={that.updateTrackerStatus} /><input name={'mail-'+value.id} type="checkbox" value={value.id} checked={mail ? 'checked' : ''} onChange={that.updateTrackerStatusMail} /><input name={'practitioner-'+value.id} type="checkbox" value={value.id} checked={practitioner ? 'checked' : ''} onChange={that.updateStatusPractitioner} /><button type="button" style={{color:'red'}} className="btn code-dialog btn-delete" onClick={() => { if (window.confirm('Are you sure you want to Delete this status?')) that.deleteStatus(value.id)}}><i className="fa fa-trash"></i></button></div>
			</div>);
		});
		
		const SortableContainer = sortableContainer(({children}) => {
		  return <div className="status-inner">{children}</div>;
		});
		
		return (
			<div className="status-list">
				<div className="sataus-header-top">
					<div className="row">
						<div className="col-md-12 mb-4">
							<h4>Status List</h4>
						</div>
					</div>
					
					<div className="row">
						<div className="col-md-6">
							<label>Add New Fabrication Lab: </label>
							<div className="input-group">
								<input id="addStatusText" className="form-control" name="addStatusText" type="text" />
								<div className="input-group-append">
									<button className="btn btn-primary" type="button" onClick={this.addFabricationLab}>Add</button>
								</div>
							</div>	
						</div>
						<div className="col-md-6">
						</div>	
					</div>
					
					<div className="row">
						<div className="col-md-6 mt-2">
							<label>Fabrication Lab: </label>
							<div className="input-group">
								<select id='fabStatusFacility' className="form-control" name="fabFacility" onChange={this.changeFacility}>
								{facilityOption}
								</select>
								<button type="button" style={{color:'red',display:'none'}} className="btn btn-delete-fab" onClick={() => { if (window.confirm('Are you sure you want to Delete selected Fabrication Lab?')) this.deleteFab()}} ><i className="fa fa-trash"></i></button>
							</div>	
						</div>
						<div className="col-md-6 mt-2">
							<label> Select Status: </label>
							<div className="input-group">
								<select id='statusText' className="form-control orignal-status" name="orignalStatus">
									<option value="">Please Select</option>
									{statusOption}
								</select>
								<div className="input-group-append">
									<button className="btn btn-primary btn-save-status" type="button" onClick={this.addTrackerStatus}>Add</button>
								</div>
							</div>
						</div>	
					</div>
				</div>
				
				{/*<div className="add-status-tracker-top">
					<label> Add New Fabrication Lab: </label>
					
					<div className="status-select-box">
						<input id="addStatusText" className="form-control" name="addStatusText" type="text" />
						<button type="button" className="btn btn-info btn-save-status" onClick={this.addFabricationLab}>Add</button>
					</div>
				</div>
				<div className="facility-top">
					<label>Fabrication Lab: </label>
					<select id='fabStatusFacility' className="form-control" name="fabFacility" onChange={this.changeFacility}>
						
					{facilityOption}
					</select>
				</div>
				<div className="status-tracker-top">
					<label> Select Status: </label>
					
					<div className="status-select-box">
						<select id='statusText' className="form-control orignal-status" name="orignalStatus">
							<option value="">Please Select</option>
							{statusOption}
						</select>
						<button type="button" className="btn btn-info btn-save-status" onClick={this.addTrackerStatus}>Add</button>
						<button type="button" style={{color:'red',display:'none'}} className="btn btn-delete-fab" onClick={() => { if (window.confirm('Are you sure you want to Delete selected Fabrication Lab?')) this.deleteFab()}} ><i className="fa fa-trash"></i></button>
					</div>
				</div>*/}
				
				{ statusData.length > 0 ?
				<div className="status-table-div mt-3">
				<div className="table-action-icon"><i className="fa fa-eye"></i><i className="fa fa-envelope"></i><i className="fa fa-user status-user"></i></div>
				<SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
					{statusData.map((value, index) => (
					  <SortableItem key={index} index={index} value={value} /> 
					))}
				</SortableContainer>
				</div>
				:null}
			</div>	
		);
	}
}
