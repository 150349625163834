import React from 'react'
import $ from 'jquery';
import '../containers/App.css';
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import config from "../config";
import { gapi } from 'gapi-script';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            username:'',
            password:'',
            errors : ''
        }
        this.btn_submit = React.createRef();

		if( localStorage.getItem('username')){
			this.props.history.push('/');
        }
    }
    validateForm = () => {
        return this.state.username.length > 0 && this.state.password.length > 0;
    }
    setUsername = (event) => {
        this.setState({username:event.target.value})
    }
    setPassword = (event) => {
        this.setState({password:event.target.value})
    }
	
	getUrlParameter(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
	
    handleSubmit = async (event) => {
		let mod = '';
		if(typeof(this.props.location.state) != "undefined" && this.props.location.state !== null) {
			mod = this.props.location.state.moduleName;
		}
		let apiKey = $('#apiKey').val();
		let instance = $('#instance').val();
		let subdirectory = $('#subdirectory').val();
		
        event.preventDefault();
        const btn_submit = this.btn_submit.current;
        btn_submit.setAttribute("disabled", true);
		
		gapi.client.init({
		   apiKey: apiKey,
		   discoveryDocs: config.discoveryDocs,
		})
		.then(() => {
			gapi.client.load("sheets", "v4", () => {
				gapi.client.sheets.spreadsheets.values
				.get({
					spreadsheetId: config.lookUpSpreadsheetId,
					range:instance,
				})
				.then(
					response => {
						let users = response.result.values;
						
						let that = this;
						let login = false;
						
						let uid = this.getUrlParameter('uid');
						let wid = this.getUrlParameter('wid');
						let substring = '';
						if(uid){
							substring = '?uid='+uid;
						}
						
						if(wid){
							substring = '?wid='+wid;
						}
			
						users.forEach(function(user,i){
							//console.log('users->',users);
							if(i >0){
								if(user[0] == that.state.username && user[1] == that.state.password){
									localStorage.setItem('role', user[2]);
									localStorage.setItem('user_id', user[3]);
									localStorage.setItem('user_facility', user[4]);
									localStorage.setItem('user_facility_id', user[5]);
									login = true;
								}
							}  
						});
						
						if(login){
							localStorage.setItem('username', this.state.username);
							
							if(subdirectory){
								this.props.history.push('/'+subdirectory+substring);
							}else{
								this.props.history.push('/'+substring);
							}
						}else{
							
							btn_submit.removeAttribute("disabled");
							this.setState({errors:'Invalid username or password.'})
							
						}
						
					},
					response => {
						alert(response.result.error.message);
						console.log('error',response.result.error);
					}
				);
			});
		
		});

    }
	
    render() {
        return (<div className="container">
            <div className="row">
                <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div className="card card-signin my-5">
                            <div className="card-body">
                                <h5 className="card-title text-center">Sign In</h5>

                                { this.state.errors &&
                                <div className="alert alert-danger"> { this.state.errors } </div> }

                                <form onSubmit={this.handleSubmit}>
                                    <FormGroup controlId="username">
                                      <FormLabel>Username</FormLabel>
                                      <FormControl
                                        autoFocus
                                        type="text"
                                        onChange={this.setUsername}
                                      />
                                    </FormGroup>
                                    <FormGroup controlId="password">
                                      <FormLabel>Password</FormLabel>
                                      <FormControl
                                        type="password"
                                        onChange={this.setPassword}
                                      />
                                    </FormGroup>
                                    <Button block disabled={!this.validateForm()} type="submit" ref={this.btn_submit}>
                                      Login
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        )
    }

}

export default Login