import React, { Component } from 'react';
import $ from 'jquery';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

export default class FieldQ13 extends Component {
	
	constructor(props) {
    super(props);
        this.state = { itemArray:[],ItemsDes: new Map(),newItemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
		let fieldId = this.props.data.fieldId;
        const value = event.target.value;
		let itemArray = this.state.itemArray
		
		let atr = event.target.getAttribute('data_atr');
		let attr = event.target.getAttribute('data_attr');
		
		if(atr){
			$('#'+atr).css('display','block');
        }else{
			$('#'+attr).css('display','none');
        }
		
		if(atr != 2){
			this.setState({itemArray:value});
		}
		
		let that = this;
		
		if(atr == 2){
			setTimeout(function(){
				that.props.func(fieldId,that.state.itemArray+':'+value);
			}, 1000);
		}else{
			that.props.func(fieldId,value);
		}
    }
	
    render(){
        const {data} = this.props;
        let id = data.fieldId;
        let question = data.inputText;
        let required = data.inputText;
		let options = data.options;
		let buttonLabel = data.buttonLabel.split(',');
		let kioskResponse = data.kioskResponse;
        let response = '';
        if(kioskResponse.size > 0){
            let resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                response = resAns.split(':');
            }
        }

		return (
			<div className="one-row-radio"><div>{question} {required == 'Y' ? <span className="required">*</span> : ''}</div>
			<TableContainer component={Paper} onChange={this.shoot}>
				<Table sx={{ minWidth: 566 }} size="small" aria-label="a dense table">
					<TableBody>
						<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row" width='220px'>
							{options}
							</TableCell>
							<TableCell align="center">
								<input data_attr={id+'-text'} checked={response[0] == buttonLabel[0] ? true:false } className="cus-radio-btn" type="radio" value={buttonLabel[0]}  name={id+'-text'}/> {buttonLabel[0]}
							</TableCell>
							<TableCell align="center">
								<input data_atr={id+'-text'} checked={response[0] == buttonLabel[1] ? true:false } className="cus-radio-btn" type="radio" value={buttonLabel[1]}  name={id+'-text'}/> {buttonLabel[1]}
							</TableCell>
							<TableCell align="center" width='320px'>
								{response[1] || response[0] == buttonLabel[1] ?
									<div id={id+'-text'}>
									{buttonLabel[2]} <input data_atr={2} text="type" name={buttonLabel[1]} className="form-control" value={response[1]}/>
									</div>
								:
									<div id={id+'-text'} style={{display:'none'}} >
									{buttonLabel[2]} <input data_atr={2} text="type" name={buttonLabel[1]} className="form-control" />
									</div>
								}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer></div>
		);
    }
	
	/* let id = props.fieldId;
	let question = props.question;
	let required = props.required;
	let buttonLabel = props.buttonLabel.split(',');
	
	const handleChange = (type,name) => {
		if(type == 'right'){
			$('#'+name+"-text").toggle();
		}else{
			$('#'+name+"-text").hide();
		}
	};
	

	return (
		<TableContainer component={Paper}>
		  <Table sx={{ minWidth: 566 }} size="small" aria-label="a dense table">
			<TableBody>
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCell component="th" scope="row">
					{question} {required == 'Y' ? <span className="required">*</span> : ''}
					</TableCell>
					<TableCell align="center">
						<input className="cus-radio-btn" onChange={() =>handleChange('left',id)} type="radio" value={buttonLabel[0]}  name={id}/> {buttonLabel[0]}
					</TableCell>
					<TableCell align="center">
						<input onChange={() =>handleChange('right',id)} className="cus-radio-btn" type="radio" value={buttonLabel[1]}  name={id}/> {buttonLabel[1]}
					</TableCell>
					<TableCell align="center" width='320px'>
						<div id={id+'-text'} style={{display:'none'}} >
						{buttonLabel[2]} <TextField name={id+'-text'} focused={false} hiddenLabel id="filled-hidden-label-small" label="" variant="outlined" size="small" />
						</div>
					</TableCell>
				</TableRow>
			</TableBody>
		  </Table>
		</TableContainer>
	); */
}

