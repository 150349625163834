import React, { Component } from "react";
import $ from 'jquery';
import { saveAs } from 'file-saver';
import '../containers/App.css';
import 'bootstrap';
import config from "../config";
import Login from "./Login";
import MainHeader from "../component/Header/MainHeader";
import LeftPanel from "../component/LeftPanel/Fields";
import BuildDetails from "../component/RightPanel/BuildDetails";
import CopyFieldDetails from "../component/RightPanel/CopyFieldDetails";
import MainData from "../component/MainData/MainData";
import CopyMainData from "../component/MainData/CopyMainData";
import PdfData from "../component/MainData/PdfData";
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {stateFromHTML} from 'draft-js-import-html';
import {convertToRaw, EditorState, convertFromHTML, convertFromRaw,ContentState} from 'draft-js';
import axios,{post,get} from 'axios';
import { getConfigResponse } from '../Helpers/Common';
import guid from "../Helpers/GUID";
import { ulid } from 'ulid'
import { gapi } from 'gapi-script';
import { Form,Button } from 'react-bootstrap';
import moment from 'moment';
//import Moment from 'react-moment';
import arrayMove from "./arrayMove";
import StatusTracker from "./StatusTracker";
import StatusManager from "./StatusManager";
import TaskManager from "./TaskManager";
import WorkFlowBuilder from "./WorkFlowBuilder";
import WorkFlowDetails from "./WorkFlowDetails";
import WorkFlowManger from "./WorkFlowManger";
import WorkFlowProgress from "./WorkFlowProgress";
import ScheduledworkFlow from "./ScheduledworkFlow";
import TranslationManager from "./TranslationManager";
import MongoDBManager from "./MongoDBManager";
import MongoDBMdsManager from "./MongoDBMdsManager";
import InstanceManager from "./InstanceManager";
//import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {DebounceInput} from 'react-debounce-input';
import { generatehtmlforFiled } from "../Helpers/FieldPreview";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import emailjs from 'emailjs-com';

class MdsBuilder extends Component {

    constructor(props) {
        super(props);
        this.state = {
			checkedInc:new Map(),
            checkedReq:new Map(),
			checkedKiosk:new Map(),
			delegateUsers:new Map(),
			delegateTypes:[],
			types:[],
			oDeviceTypes:[],
			pDeviceTypes:[],
			visitTypes:[],
			allData:[],
			sheetData:[],
			parentDataSets:[],
			mdsSheets:[],
			mdsSheetNames:[],
			childSheetNames:[],
			layoutSheetNames:[],
			textfields:[],
			basicfields:[],
			functionfields:[],
			gridfields:[],
			formattingfields:[],
			companies:[],
			//trackingCode:[],
			groupId:[],
			facilityIds:[],
			responsibleRole:[],
			responsiblePerson:[],
			personEmail:'',
			statusType:[],
			statusByfacility:[],
			statusWithMail:[],
			statusWithPos:[],
			sendToPract:[],
			statusList:[],
			allfields:[],
			fileArray:[],
			savedResponse:[],
			savedResponseForm:[],
			selectedRForm:[],
			ogTags:[],
			persons:[],
			facilityData:[],
			uploadedImages:[],
			showMdsList:false,
			showFieldEdit:false,
			showBuildEdit:false,
			changeQueType:false,
			showCompany:false,
			hasParentHeader:false,
			showImageUpload:false,
			showUpdate:false,
			showPrefix:false,
			instanceManager:true,
			trackerStatusManager:false,
			taskManager:false,
			workFlow:false,
			workFlowDetails:false,
			workFlowManger:false,
			workFlowProgress:false,
			scheduledworkFlow:false,
			showInlineVideo:true,
			showView:false,
			showFieldCopyView:false,
			showResponseForm:false,
			showResponseData:false,
			hasMdsChanged:false,
			//showBuilder:true,
			//showMdsManager:false,
			calFieldsId:new Map(),
			requiredFields:new Map(),
			optionalFields:new Map(),
			wcalFieldsId:'',
			sheetVisibility:new Map(),
			sheetVersion:new Map(),
			sheetApiName:new Map(),
			sheetLayoutType:new Map(),
			mdsSheetGuidName:new Map(),
			mdsJsonFilesVersion:new Map(),
			layoutJsonFilesVersion:new Map(),
			layoutTranslation:new Map(),
			sheetGuids:new Map(),
			sheetGuidName:new Map(),
			reasonNotes:new Map(),
			mdsHasFieldSet:new Map(),
			editorSourceHTML:'',
			suffix:'(Copy)',
			IncludeCopy:'Yes',
			asGroup:'',
			dueDate:'',
			imagePath:'',
			sliderMinText:'',
			sliderMaxText:'',
			validationTypefilter:'',
			validation1:'V1',
			validation2:'V1',
			validation3:'V1',
			arraignment:'provided',
			currentStatus:'',
			fieldId:'',
			loadDataSet:'',
			addToParentMds:true,
			hasNotCopy:true,
			selectedSheet:'',
			outputText:false,
			fieldView:false,
			dataMode:'',
			fieldPos:'',
			copyValue:[],
			apiPdfs:[],
			ogAutoTagsArray:[],
			trackerTableData:[],
			saveAs:'',
			fieldPrefix:'',
			MDGroups:0,
			spreadsheetId:'',
			allDeviceTypesArray:[],
			deviceTypes:[],
			trackerRow:[],
			practitioners:[],
			statusLogs:[],
			trackerData:[],
			viewMode:'builder',
			delegateFilter:'',
			cFormUID:'',
			kioskResponse:new Map(),
			userClassResponse:new Map(),
			userResponseDate:new Map(),
			checkedNeeds:new Map(),
			checkedCopyFields:new Map(),
			needsNote:new Map(),
			showTrackerTable:false,
			showTrackerDetails:true,
			showEditHeaders:false,
			trackerMode:false,
			redoOption:'',
			redoInformation:'',
			redoInfo:'',
			fieldDeactivated:'N',
			Public:'Yes',
			autoPopulate:'',
			autoPopulateTags:'',
			formPriority:2,
			formRank:0,
			trackerTableUpdate:false,
			currentStatusChanged:false,
			statusDueDate:'No',
			fabPractitioner:'',
			fabPatientId:'',
			fabJobNumber:'',
			fabPractitionerEmail:'',
			patientFirstName:'',
			patientLastName:'',
			createdBy:localStorage.getItem('username'),
			role:localStorage.getItem('role'),
			showAddNew:false,
			showMdsManageSection:false,
			showMongoMdsSection:false,
			showTranslationManager:false,
			showMongoSection:false,
			instances:[],
			addInstanceName:'',
			addMdsSpreadsheetId:'',
			addChildSpreadsheetId:'',
			addLayoutSpreadsheetId:'',
			addMdsTempSheetId:'',
			addMdsTempSheetId:'',
			addChildTempSheetId:'',
			addTestFormSpreadsheetId:'',
			addCopyFieldSpreadsheetId:'',
			addCopyFieldSheetId:'',
			addformDataSheetId:'',
			copyMdsSheets:[],
			copyMdsSheetNames:[],
			destinations:[],
			copyAllData:[],
			copySheetData:[],
			copySelectedSheet:'',
			copyHeaderFiled:'single',
			copyFieldsNumber:'',
			mdsSprdSheets:[],
			mdsSpreadsheets:[],
			mdsSpreadSheetData:[],
			oldMdsSpreadsheetId:'',
			oldMdsTempSheetId:'',
			instanceId:'',
			layoutFriendlyName:'',
			layoutLanguageData:[],
			combineArray:[],
			layoutData:[],
			assignTracks:[],
			defaultLayoutFacility:'A2130BEB-D932-4587-B8F3-57A667EA814C',

		}
		
		this.selectMode = this.selectMode.bind(this);
		
    }
	
	loadScript() {
		$(".fadeInLeft").trigger("click");
		var trigger = $('.fadeInLeft'),
			overlay = $('.overlay'),
		   isClosed = true; 
		   
		var trigger2 = $('.fadeInRight'),
			overlay = $('.overlay'),
		   isClosed2 = false;
		
		function buttonSwitch() {
			if (isClosed === true) {
				overlay.hide();
				trigger.removeClass('is-open');
				trigger.addClass('is-closed');
				isClosed = false;
			} else {
				overlay.show();
				trigger.removeClass('is-closed');
				trigger.addClass('is-open');
				isClosed = true;
			}
		}
		
		function buttonSwitch2() {
			if (isClosed2 === true) {
				overlay.hide();
				trigger2.removeClass('is-open');
				trigger2.addClass('is-closed');
				isClosed2 = false;
			} else {
				overlay.show();
				trigger2.removeClass('is-closed');
				trigger2.addClass('is-open');
				isClosed2 = true;
			}
		}

		trigger.click(function () {
			buttonSwitch();
		});
		
		trigger2.click(function () {
			buttonSwitch2();
		});

		$('[data-toggle="offcanvas"]').click(function () {
			$('#wrapper').toggleClass('toggled');
		});
		$('[data-toggle="offcanvas-2"]').click(function () {
			$('#wrapper').toggleClass('toggled-2');
		});

	}
    
    componentDidMount() {
        this.loadScript();
		let ApiLibraryUrl = $('#ApiLibraryUrl').val();
		let ApiUsername = $('#ApiUsername').val();
		let ApiPassword = $('#ApiPassword').val();
		this.setState({ApiLibraryUrl,ApiUsername,ApiPassword});
		let that = this;
		/* let url = ApiLibraryUrl+'login';
		let formData = new FormData();
		formData.append('username', ApiUsername);
		formData.append('password', ApiPassword);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			let token = response.data.token;
			this.setState({ token });
		}).catch(error => {
			console.log('error::', error);
		}) 
		
		
		this.getPdfFromApi().then(function(response) {
			that.setState({apiPdfs:response});
		}, function(error) {
		  console.error("Failed!", error);
		})
		*/
		
		this.getInstance();
		
		let uid = this.getUrlParameter('uid');
		let wid = this.getUrlParameter('wid');
		let formName = this.getUrlParameter('form');
		
		if(uid){
			$('.loader-box').show();
			this.getTrackerData();
			setTimeout(function(){
				$('#selectMode').val('TestForm').trigger('change');
				that.selectMode(null,'TestForm');
			}, 3000);
			
		}
		
		if(wid){
			//$('.loader-box').show();
			this.getTrackerData();
			setTimeout(function(){
				$('#select-view-option').val('workFlowProgress').trigger('change');
				that.setState({workFlowProgress:true});
				$('#wrapper').hide();
				$('.mds-manager').hide();
				$('.mds-view').hide();
				//that.selectMode(null,'TestForm');select-view-option
			}, 1000);
			
			setTimeout(function(){
				$('#view_'+wid).trigger('click');
			}, 3000);
		}
		
		this.getStatusData();

		if(this.state.role == 'OA'){
			this.setState({workFlow:true});
			$('#wrapper').hide();
			$('.mds-manager').hide();
			$('.mds-view').hide();
		}
		
		//setTimeout(function(){
			that.builderOption(null,'instance');
			that.getTrackMongo();
		//}, 1000);
		
    }
	
	initClient = () => {
		let apiKey = $('#apiKey').val();
		let clientId = $('#clientId').val();
		let that =this;
        gapi.client.init({
           'apiKey': apiKey,
           'clientId': clientId,
		   'plugin_name': "chat",
           'scope': 'https://www.googleapis.com/auth/spreadsheets',
           discoveryDocs: config.discoveryDocs,
        })
        .then(() => {
            gapi.auth.authorize({client_id:clientId, scope: 'https://www.googleapis.com/auth/spreadsheets', immediate: false});
			setTimeout(function(){
				that.getLookUpData();
				that.getMdsSheets();
				that.getSpreadSheetName();
				that.getAllInstances();
			}, 2000);
        }).catch(error => {
			alert('error::'+ error.details);
		});
		
    };
	
	getSpreadSheetName=()=>{
		let mdsSprdSheets = this.state.mdsSprdSheets;
		let mdsSpreadSheetData = [];
		if(mdsSprdSheets.length > 1){
			mdsSprdSheets.map((val, i) => {
				let str = val.split(',');
				gapi.client.sheets.spreadsheets
				.get({
					spreadsheetId: str[0], 
				})
				.then(
					response => {
						const sheets = response.result;
						let sheetData = { 
							"title": sheets['properties']['title'],
							"spreadsheetId": sheets['spreadsheetId'],
							"mdsTempSheetId": str[1],
							"sheets": sheets['sheets'],
							"spreadsheetUrl": sheets['spreadsheetUrl'],
						};
						mdsSpreadSheetData.push(sheetData);
						this.setState({mdsSpreadSheetData:mdsSpreadSheetData});
					},function(reason) {
						alert(reason.result.error.message);
					}
						
				);
				
			});
		}
	}
	
	loadMdsSpreadSheet2=(event)=>{
		let dataMode = this.state.dataMode;
		let name = event.target.name;
        let id = event.target.value;
		let mdsSpreadSheetData = this.state.mdsSpreadSheetData;
		let tempSheetId = '';
		mdsSpreadSheetData.map((val, i) => {
			if(val["spreadsheetId"] == id){
				tempSheetId = val["mdsTempSheetId"];
			}
		});
		if(id){
			//this.setState({mdsSpreadsheetId:id,spreadsheetId:id,mdsTempSheetId:tempSheetId});
			this.setState({spreadsheetId:id,selectedSheetId:tempSheetId,hasMdsChanged:true,mdsSpreadsheetId:id,mdsTempSheetId:tempSheetId,parentDataSets:[],tempSheetId});
			let that = this;
			setTimeout(function(){
				that.getMdsSheets();
				that.getMdsData();
			}, 2000);
		}
	}
	
	loadMdsSpreadSheet=(event)=>{
		let dataMode = this.state.dataMode;
		let name = event.target.name;
        let id = event.target.value;
		let mdsSpreadSheetData = this.state.mdsSpreadSheetData;
		let tempSheetId = '';
		mdsSpreadSheetData.map((val, i) => {
			if(val["spreadsheetId"] == id){
				tempSheetId = val["mdsTempSheetId"];
			}
		});
		if(id){
			//this.setState({mdsSpreadsheetId:id,spreadsheetId:id,mdsTempSheetId:tempSheetId});
			if(dataMode == 'MDS'){
				this.setState({spreadsheetId:id,selectedSheetId:tempSheetId,hasMdsChanged:true,mdsSpreadsheetId:id,mdsTempSheetId:tempSheetId,parentDataSets:[],tempSheetId});
				let that = this;
				setTimeout(function(){
					that.getMdsSheets();
					that.getMdsData();
				}, 2000);
			}else if(dataMode == 'Child' || dataMode == 'mdsFieldCopy'){
				this.setState({mdsSpreadsheetId:id,parentDataSets:[]});
				let that = this;
				setTimeout(function(){
					that.getMdsSheets();
				}, 2000);
			}
		}
		
	}
	
	appendDimensionRequest=()=>{
		//let spreadsheetId = config.saveOutputspreadsheetId;
		let spreadsheetId = this.state.formDataSpreadsheetId;
		let sheetId = this.state.formDataSheetId;
		let params =  {
            spreadsheetId: spreadsheetId,
        };
        let append = {
            "requests": [
            {
			  "appendDimension": {
				"sheetId": sheetId,
				"dimension": "COLUMNS",
				"length": 8
			  }
			}]
        }
		
		let request = gapi.client.sheets.spreadsheets.batchUpdate(params,append);
        request.then(function(response) {
			//alert('The columns added Successfully!');
		}, function(reason) {
            alert(reason.result.error.message);
        });
	}
	
	getStatusData=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'tracker/get_status.php';
		let data = [];
		let statusType = [];
		let statusWithMail = [];
		let statusWithPos = [];
		let sendToPract = [];
		let con = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        axios.get(url,con)
        .then(response => {
            data = response.data;
			this.setState({statusData:data});
			if(data){
				
				data.map((val,i)=>{
					if(val.visible == 'Y'){
						statusType.push(val.status);
						statusWithPos.push(val.facility_id+':'+val.status+':'+val.pos+':'+val.mail+':'+val.send_practitioner);
					}
					if(val.visible == 'Y' && val.mail == 'Y'){
						statusWithMail.push(val.status);
					}
					if(val.visible == 'Y' && val.send_practitioner == 'Y'){
						sendToPract.push(val.status);
					}
				});
			}
			this.setState({statusType,statusWithMail,statusWithPos,sendToPract});
        })
	}
	
	getUrlParameter(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
	
	getInstance=()=>{
		//let instanceName = $('#instance').val();
		let ApiUrl = $('#ApiUrl').val();
		//let url = ApiUrl+'instance/get_instance.php?instance='+instanceName;
		let url = ApiUrl+'instance/get_instance.php';
		let con = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        axios.get(url,con)
        .then(response => {
            let data = response.data;
			let sheetObj = $.parseJSON(data.mds_spreadsheets) ? $.parseJSON(data.mds_spreadsheets) : [];
			let mdsSprdSheets = [data.mds_spreadsheet+','+data.mds_temp];
			if(sheetObj){
				sheetObj.map((val, i) => {
					let count = i+2;
					if(val["mdsSpreadsheetId"+count]){
						mdsSprdSheets.push(val["mdsSpreadsheetId"+count][0]+','+val["mdsSpreadsheetId"+count][1]);
					}
					
				});
			}
			
			this.setState({instanceId:data.id,instanceName:data.instance_name,mdsSpreadsheetId:data.mds_spreadsheet,oldMdsSpreadsheetId:data.mds_spreadsheet,mdsTempSheetId:data.mds_temp,oldMdsTempSheetId:data.mds_temp,childSpreadsheetId:data.child_spreadsheet,layoutSpreadsheetId:data.layout_spreadsheet,childTempSheetId:data.field_set_temp,formDataSpreadsheetId:data.form_data_spreadsheet,formDataSheetId:data.form_data_sheet,mdsCopyFieldSpreadsheetId:data.copySpreadsheet_id,mdsCopyFieldSheetId:data.copySheet_id,selectDestination:data.destination_id,ApiUrl,mdsSpreadsheets:sheetObj,mdsSprdSheets});
        })
		let that = this;
		setTimeout(function(){
			gapi.load("client", that.initClient);
		}, 1000);

	}
	
	getAllInstances=()=>{
		let instanceName = $('#instance').val();
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'instance/get_all_instances.php';
		let con = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        axios.get(url,con)
        .then(response => {
            let data = response.data;
			this.setState({instances:data});
        })
	}
	
	getDestination=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'instance/get_destination.php';
		let con = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        axios.get(url,con)
        .then(response => {
            let data = response.data;
			this.setState({destinations:data});
        })
	}
	
	getTrackerData=()=>{
		//let instanceName = $('#instance').val();
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'tracker/get_form.php';
		let data = [];
		let con = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        axios.get(url,con)
        .then(response => {
            data = response.data;
			this.setState({trackerTableData:data});
        })
	}
	
	showAddNewSection=()=>{
		let showAddNew = this.state.showAddNew;
		if($("input[name='showAddNewInput']").prop("checked") == true){
			this.setState({showAddNew:true});
		}else{
			this.setState({showAddNew:false,showAddNewDes:false});
		}
	}
	
	showAddNewDestination=()=>{
		let showAddNewDes = this.state.showAddNewDes;
		if($("input[name='showAddNewDes']").prop("checked") == true){
			this.setState({showAddNewDes:true});
		}else{
			this.setState({showAddNewDes:false});
		}
	}
	
	addInstance=()=>{
		
		let instanceName = this.state.addInstanceName;
		let mdsSpreadsheetId = this.state.addMdsSpreadsheetId;
		let childSpreadsheetId = this.state.addChildSpreadsheetId;
		let layoutSpreadsheetId = this.state.addLayoutSpreadsheetId;
		let mdsTempSheetId = this.state.addMdsTempSheetId;
		
		let childTempSheetId = this.state.addChildTempSheetId;
		let formDataSpreadsheetId = this.state.addTestFormSpreadsheetId;
		let formDataSheetId = this.state.addformDataSheetId;
		
		let url = this.state.ApiUrl+'instance/add_instance.php';
		let formData = new FormData();
		formData.append('instance', instanceName);

		formData.append('mdsSpreadsheetId', mdsSpreadsheetId);
		formData.append('childSpreadsheetId', childSpreadsheetId);
		formData.append('layoutSpreadsheetId', layoutSpreadsheetId);
		formData.append('mdsTempSheetId', mdsTempSheetId);
		formData.append('childTempSheetId', childTempSheetId);
		formData.append('formDataSpreadsheetId', formDataSpreadsheetId);
		formData.append('formDataSheetId', formDataSheetId);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			if(response.data == 1){
				alert('The Instance inserted successufuly');
				let that = this;
				setTimeout(function(){
					that.setState({showAddNew:false});
					$("input[name='showAddNewInput']").prop('checked', false);
				}, 1000);
			}else if(response.data == 2){
				alert('The Instance name already exist.');
			}
			
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	addDestination=()=>{
		
		let destinationName = this.state.addDestinationName;
		let mdsCopyFieldSpreadsheetId = this.state.addCopyFieldSpreadsheetId;
		let copyFieldSheetId = this.state.addCopyFieldSheetId;
		
		let url = this.state.ApiUrl+'instance/add_destination.php';
		let formData = new FormData();
		formData.append('destination', destinationName);
		formData.append('mdsCopyFieldSpreadsheetId', mdsCopyFieldSpreadsheetId);
		formData.append('copyFieldSheetId', copyFieldSheetId);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			
			if(response.data == 1){
				alert('The Copy destination inserted successufuly');
				let that = this;
				setTimeout(function(){
					that.setState({showAddNewDes:false});
					$("input[name='showAddNewDes']").prop('checked', false);
					that.getDestination();
				}, 1000);
			}else if(response.data == 2){
				alert('The Copy destination name already exist.');
			}
			
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	instanceUpdate=()=>{
		
		let instanceName = $('#instance').val();
		
		if(this.state.instanceName){
			instanceName = this.state.instanceName;
		}
		let mdsSpreadsheetIds = [];
		let mdsTempSheetIds = [];
		$('#mdsSheetSec .append-sec').each(function() {
			let name = $(this).find(".mds-input").attr('name');
			let tempName = $(this).find(".temp-input").attr('name');
			let val = $(this).find(".mds-input").val();
			let tempVal = $(this).find(".temp-input").val();
			
			if(name && val){
				let mdsSpreads = {
					[name]: [val,tempVal]
				}
				mdsSpreadsheetIds.push(mdsSpreads)
			}
		});
		
		
		let mdsSpreadsheets = JSON.stringify(mdsSpreadsheetIds);
		
		let mdsSpreadsheetId = this.state.mdsSpreadsheetId;
		let childSpreadsheetId = this.state.childSpreadsheetId;
		let layoutSpreadsheetId = this.state.layoutSpreadsheetId;
		let mdsTempSheetId = this.state.mdsTempSheetId;
		let childTempSheetId = this.state.childTempSheetId;
		
		let formDataSpreadsheetId = this.state.formDataSpreadsheetId;
		let formDataSheetId = this.state.formDataSheetId;
		let selectDestination = this.state.selectDestination;
		
		let url = this.state.ApiUrl+'instance/save_instance.php';
		let formData = new FormData();
		formData.append('instance', instanceName);
		formData.append('mdsSpreadsheetId', mdsSpreadsheetId);
		formData.append('mdsSpreadsheets', mdsSpreadsheets);
		formData.append('childSpreadsheetId', childSpreadsheetId);
		formData.append('layoutSpreadsheetId', layoutSpreadsheetId);
		formData.append('mdsTempSheetId', mdsTempSheetId);
		formData.append('childTempSheetId', childTempSheetId);
		formData.append('formDataSpreadsheetId', formDataSpreadsheetId);
		formData.append('formDataSheetId', formDataSheetId);
		formData.append('destinationId', selectDestination);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			if(response.data){
				this.getInstance();
				this.setState({sheetData:[],allData:[],copyFieldsNumber:''});
				setTimeout(function(){
					$('#selectMode').val('');
					$('#loadSelectMds').val('');
				}, 1000);
				alert(response.data);
			}
			
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	changeInstance = (instanceId) => {
		
		let url = this.state.ApiUrl+'instance/change_instance.php';
		let formData = new FormData();
		formData.append('instanceId', instanceId);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			if(response.data){
				this.getInstance();
				this.setState({sheetData:[],allData:[]});
				setTimeout(function(){
					$('#selectMode').val('');
					$('#loadSelectMds').val('');
				}, 1000);
			}
			
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	selectMode=(event,mode) => {
		$("#wrapper").removeClass('toggled-2');
		if(event){
			mode = event.target.value;
		}
		let that = this;
		let mdsSpreadsheetId = this.state.mdsSpreadsheetId;
		let mdsTempSheetId = this.state.mdsTempSheetId;
		
		if(mode != 'Child' && this.state.hasMdsChanged){
			
			mdsSpreadsheetId = this.state.oldMdsSpreadsheetId;
			mdsTempSheetId = this.state.oldMdsTempSheetId;
			this.setState({mdsSpreadsheetId,mdsTempSheetId});
			setTimeout(function(){
				that.getMdsSheets();
			}, 2000);
		}
		
		this.setState({sheetData:[],allData:[],copySheetData:[],copyAllData:[],copySelectedSheet:'',showTrackerDetails:false,showFieldEdit:false,copyFieldsNumber:'',showFieldCopyView:false,showView:false,mdsSprdSheets:[],hasMdsChanged:false,showCompany:false});
		$("#loadLayout option:selected").prop("selected", false);
		$("#loadSelectMds").val('');
		this.emptyState();
		
		let childSpreadsheetId = this.state.childSpreadsheetId;
		let layoutSpreadsheetId = this.state.layoutSpreadsheetId;
		let childTempSheetId = this.state.childTempSheetId;
		
		$("#loadFeildSet option:selected").prop("selected", false);
		
		if(mode != 'TestForm'){
			setTimeout(function(){
				that.builderOption(null,'builder');
			}, 1000);
			$('#select-view-option').val('builder').trigger('change');
		}
		
		if(mode=='MDS'){
			this.setState({saveAs:'parentMds',showPrefix:true,spreadsheetId:mdsSpreadsheetId,selectedSheetId:mdsTempSheetId,tempSheetId:mdsTempSheetId});
		}else if(mode=='mdsFieldCopy'){
			this.setState({spreadsheetId:mdsSpreadsheetId,showFieldCopyView:true});
			this.builderOption(null,'mds_view');
			this.getCopyMdsFile();
		}else if(mode=='Child'){
			this.setState({spreadsheetId:childSpreadsheetId,selectedSheetId:childTempSheetId,tempSheetId:childTempSheetId});
		}else if(mode=='Layout'){
			this.setState({spreadsheetId:childSpreadsheetId,layoutSpreadsheetId:layoutSpreadsheetId,sheetData:[],allData:[]});
			
		}else if(mode=='TestForm'){
			this.setState({layoutSpreadsheetId:layoutSpreadsheetId,sheetData:[],allData:[]});
			
			this.builderOption(null,'mds_view');
			getConfigResponse(this.state.formDataSpreadsheetId).then(function(response) {
				that.setState({savedResponse:response});
				if(response){
					let rForms = [];
					response.map((frm,i)=>{
						rForms.push(frm[3]);
					})
					
					let rsforms = that.uniqueArray2(rForms);
					that.setState({savedResponseForm:rsforms});
					
					let uid = that.getUrlParameter('uid');
					if(uid){
						setTimeout(function(){
							let trackerData = that.state.trackerTableData;
							if(trackerData){
								trackerData.map((val,i)=>{
									if(val.form_id == uid){
										let name = val.form_name;
										that.showTrackerView(name,uid,val);
										$('.loader-box').hide();
										$(".fadeInLeft").trigger("click");
									}
								})
							}
							
						}, 1000);
					}
				}
			}, function(error){
			  console.error("Failed!", error);
			})
			
		}
		
		if(mode!='TestForm'){
			let uri = window.location.toString();
            if (uri.indexOf("?") > 0) {
                let clean_uri = uri.substring(0, uri.indexOf("?"));
                window.history.replaceState({}, document.title, clean_uri);
            }
		}
		
		this.setState({dataMode:mode,loadDataSet:'',selectedSheet:'',showTrackerDetails:false});
		setTimeout(function(){
			if(mode!='Layout' && mode!='TestForm'){
				that.getMdsData();
			}
		}, 2000);
	}
	
	loadMdsSheet=(event)=>{
		let name = event.target.name;
		let sheet = event.target.value;
		let sheetId = this.state.tempSheetId;
		let dataMode = this.state.dataMode;
		this.setState({allData:[],sheetData:[],copyAllData:[],copySelectedSheet:'',copyFieldsNumber:'',showResponseForm:false,showResponseData:false});
		$('#loadCopySelectMds').val('');
		this.emptyState();
		
		if(name == 'loadMds' && sheet != ''){
			let mdsSheetNames = this.state.mdsSheetNames;
			mdsSheetNames.map((val, i) => {
				if(val.name == sheet){
					sheetId = val.sheetId;
				}
			});
			$('.load-select-child').val('');
			
		}else if(name == 'loadChild' && sheet != ''){
			let childSpreadsheetId = this.state.childSpreadsheetId;
			let childSheetNames = this.state.childSheetNames;
			childSheetNames.map((val, i) => {
				if(val.name == sheet){
					sheetId = val.sheetId;
				}
			});
			$("#loadSelectMds option:selected").prop("selected", false);
			$("#loadLayout option:selected").prop("selected", false);
			this.setState({spreadsheetId:childSpreadsheetId});
		}else if((name == 'loadLayout' || name == 'loadCform') && sheet != ''){
			let layoutSheetNames = this.state.layoutSheetNames;
			let layoutSpreadsheetId = this.state.layoutSpreadsheetId;
			layoutSheetNames.map((val, i) => {
				if(val.name == sheet){
					sheetId = val.sheetId;
				}
			});
			this.setState({spreadsheetId:layoutSpreadsheetId,layoutSpreadsheetId:layoutSpreadsheetId,sheetData:[],allData:[]});
			$("#loadSelectMds option:selected").prop("selected", false);
			$("#loadFeildSet option:selected").prop("selected", false);
			
			if(name == 'loadCform'){
				this.setState({showResponseForm:true});
				let savedResponse = this.state.savedResponse;
				
				if(savedResponse.length > 0){
					let selectedRForm = [];
					savedResponse.map((val,i)=>{
						if(val[3] == sheet){
							selectedRForm.push(val)
						}
					})
					
					this.setState({selectedRForm});
				}
				$("#loadLayout option:selected").prop("selected", false);
			}
			this.setState({showTrackerTable:false,showView:true});
			$('#select-view-option').val('mds_view').trigger('change');
			
		}else{
			name = '';
			sheet = '';
			this.setState({sheetData:[],allData:[]});
		}
		
		
		
		this.setState({loadDataSet:name,selectedSheet:sheet,selectedSheetId:sheetId,launchpadMds:sheet,requiredFields:new Map(),optionalFields:new Map(),outputText:false,showTrackerDetails:false,trackerMode:false});
		let that = this;
		setTimeout(function(){
			that.getMdsData();
			if(dataMode == 'MDS'){
				that.getFieldsSets(sheet);
			}
			if(name == 'loadMds' && that.state.dataMode == 'mdsFieldCopy'){
				$(".fadeInRight").trigger("click");
				$("#wrapper").addClass('toggled-2');
				$(".fadeInRight").addClass('is-open');
				$(".fadeInRight").removeClass('is-closed');
			}
		}, 2000);
		$("input[name='showOutputText']").prop('checked', false);
	}
	
	loadformResponse=(event)=>{
		let name = event.target.name;
		let fromGuid = event.target.value;
		let kioskResponse = this.state.kioskResponse;
		let needsNote = this.state.needsNote;
		$("h3, h4, h5, h6").trigger('click');
		this.setState({kioskResponse:new Map(),needsNote:new Map()});
		
		let savedResponse = this.state.savedResponse;
		$(".question").addClass('not-response');
		savedResponse.map((val,i)=>{
			if(val[1] == fromGuid){
				val[4].map((res,i)=>{
					if(res.response){
						kioskResponse.set(res.FieldID, res.response);
						$("#"+res.FieldID).removeClass('not-response');
					}else{
						kioskResponse.delete(res.FieldID); 
					}
					
					if(res.feedNote){
						needsNote.set(res.FieldID, res.feedNote);
					}else{
						needsNote.delete(res.FieldID); 
					}	
				})
			}
		})
		let that = this;
		setTimeout(function(){
			$(".header-m").addClass('collapsed');
			$(".need-attention-footer, .answer .MuiSvgIcon-root, .upload-btn-type").hide();
			$(".childMain").addClass('show');
			$(".header-m i").removeClass('fa-plus-square-o');
			$(".header-m i").addClass('fa-minus-square-o');
			$(".answer ").removeClass('offset-1');
			$(".answer ").addClass('col-md-12');
			$('.comments input, .comments select, .comments textarea, .react-timer button, .answer button').attr('disabled', 'disabled');$("input[name='showOutputText']").prop('checked', true);
			$(".filter-attention").val("all");
			that.setState({kioskResponse:kioskResponse,needsNote,outputText:true,showResponseData:true,trackerMode:false});
		}, 2000);
	}
	
	getApiFiles = (token) => {
		let ApiLibraryUrl = this.state.ApiLibraryUrl;
		return new Promise((resolve, reject) => {
		let url = ApiLibraryUrl+'files?type_id=10';
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Authorization': 'Bearer '+token
			}
		}).then(response => {
				let files = response.data.files;
				resolve(files);
			}).catch(error => {
				console.log('error::', error);

			})
		});
	}
	
	getPdfFromApi = () => {
		let ApiLibraryUrl = $('#ApiLibraryUrl').val();
		let ApiUsername = $('#ApiUsername').val();
		let ApiPassword = $('#ApiPassword').val();
		return new Promise((resolve, reject) => {
			let url = ApiLibraryUrl+'login';
			let formData = new FormData();
			formData.append('username', 'SOOGAP');
			formData.append('password', 'U?G}6!HU9~z}F');
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				let token = response.data.token;
				let url2 = ApiLibraryUrl+'pdfs';
				axios({
					method: 'GET',
					url: url2,
					headers: {
						'Authorization': 'Bearer '+token
					}
				})
				.then(response => {
					let pdfs = response.data;
					resolve(pdfs.data.pdfs);
				}).catch(error => {
					console.log('error::', error);

				})
			}).catch(error => {
				console.log('error::', error);

			})
		});
	}
	
	getLookUpData=() => {

		let spreadsheetId = config.lookUpSpreadsheetId;
		//let spreadsheetId = config.mdsSpreadsheetId;
        var  singleSheetName = 'Data LookUp';
		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:singleSheetName, 
		})
		.then(
			response => {
				const data = response.result.values;
				//console.log('data',data);
				let delegateTypes = [];
				let types = [];
				let oDeviceTypes = [];
				let pDeviceTypes = [];
				let visitTypes = [];
				let textfields = [];
				let basicfields = [];
				let functionfields = [];
				let gridfields = [];
				let formattingfields = [];
				let companies = [];
				let groupId = [];
				//let facilityId = [];
				//let trackingCode = [];
				let allfields = [];
				let statusList = [];
				
				data.map(function(val,i) {
					
					if(i > 0){
						if(val[0]){
							delegateTypes.push(val[0]);
						}
						if(val[1]){
							types.push(val[1]);
						}
						if(val[2]){
							oDeviceTypes.push(val[2]);
						}
						if(val[3]){
							pDeviceTypes.push(val[3]);
						}
						if(val[4]){
							visitTypes.push(val[4]);
						}
						if(val[5]){
							textfields.push(val[5]);
							allfields.push(val[5]);
						}
						if(val[6]){
							basicfields.push(val[6]);
							allfields.push(val[6]);
						}
						if(val[7]){
							functionfields.push(val[7]);
							allfields.push(val[7]);
						}
						if(val[8]){
							gridfields.push(val[8]);
							allfields.push(val[8]);
						}
						if(val[9]){
							formattingfields.push(val[9]);
							allfields.push(val[9]);
						}
						if(val[10]){
							companies.push(val[10]);
						}
						
						if(val[11]){
							//trackingCode.push(val[11]);
						}
						
						if(val[12]){
							groupId.push(val[12]);
						}
						
						if(val[13]){
							statusList.push(val[13]);
						}
					}

				})

				this.setState({delegateTypes,types,oDeviceTypes,pDeviceTypes,visitTypes,textfields,basicfields,functionfields,gridfields,formattingfields,allfields,companies,groupId,statusList});
			
			},function(reason) {
				//alert(reason.result.error.message);
			}
		);
		
		gapi.client.sheets.spreadsheets.values.get({
			spreadsheetId: spreadsheetId,
			range:'<<Tag>> OG Auto tag', 
		})
		.then(
			response => {
				const tags = response.result.values;
				let ogTags = [];
				tags.map(function(val,i) {
					if(i > 0){
						//console.log('val->',val);
						ogTags.push(val[1]);
					}
					
				})
				
				this.setState({ogTags});
				
			},
		);
		
		gapi.client.sheets.spreadsheets.values.get({
			spreadsheetId: spreadsheetId,
			range:'Tracker Data', 
		})
		.then(
			response => {
				const trackerData = response.result.values;
				//console.log('trackerData->',trackerData);
				let facilityIds = [];
				let facilityUid = [];
				let responsibleRole = [];
				let trackerPerson = [];
				let leadTechnician = [];
				let prostheticTechnicians = [];
				let plasticTechnicians = [];
				let orthoticTechnicians = [];
				let authorizationAgents = [];
				let schedulingAgents = [];
				let practitioners = [];
				let facilityData = [];
				trackerData[0].map(function(role,i) {
					if(i > 1){
						responsibleRole.push(role);
					}
				})
				//console.log('trackerData-',trackerData);
				
				trackerData.map(function(val,i) {
					if(i > 0){
						if(val[1]){
							let ids = val[0]+'|'+val[1]+'|original';
							if(!val[2]){
								ids = val[0]+'|'+val[1]+'|custom';
							}
							facilityIds.push(ids);
							facilityUid.push(val[1]);
						}
						if(val[2]){
							leadTechnician.push(val[2]);
						}
						
						if(val[3]){
							prostheticTechnicians.push(val[3]);
						}
						
						if(val[4]){
							plasticTechnicians.push(val[4]);
						}
						
						if(val[5]){
							orthoticTechnicians.push(val[5]);
						}
						
						if(val[6]){
							authorizationAgents.push(val[6]);
						}
						
						if(val[7]){
							schedulingAgents.push(val[7]);
						}
						
						if(val[8]){
							practitioners.push(val[8]);
						}
					}
				})
				
				facilityUid.map(function(uid,i) {
					facilityData[uid] = [facilityIds[i]]
					facilityData[uid]['Lead technician'] = leadTechnician[i] ? leadTechnician[i].split(',') : [];
					facilityData[uid]['Prosthetic technicians'] = prostheticTechnicians[i] ? prostheticTechnicians[i].split(',') : [];
					facilityData[uid]['Plastic technicians'] = plasticTechnicians[i] ? plasticTechnicians[i].split(',') : [];
					facilityData[uid]['Orthotic Technicians'] = orthoticTechnicians[i] ? orthoticTechnicians[i].split(',') : [];
					facilityData[uid]['Authorization agents'] = authorizationAgents[i] ? authorizationAgents[i].split(',') : [];
					facilityData[uid]['Scheduling agents'] = schedulingAgents[i] ? schedulingAgents[i].split(',') : [];
					facilityData[uid]['Practitioners'] = practitioners[i] ? practitioners[i].split(',') : [];
				})
				//console.log('facilityData->',facilityData);
				this.setState({trackerData,facilityData,facilityIds,responsibleRole});
			},
		);
    }
	
	getPlaceholder=(val) => {
		let allfields = this.state.allfields;
		let pls = '';
		allfields.map((field,i) =>{
			let str = field.split(':');
			if(val.toLowerCase() == str[0].toLowerCase()){
				pls = str[1];
			}
			
		});
		return pls;
	}
	
	getMdsData=() => {
		
		let selectedSheet = this.state.selectedSheet;
		let spreadsheetId = this.state.spreadsheetId;
        var  sheetName = 'Temp File';
        if(selectedSheet != '' && selectedSheet !== undefined){
			sheetName = selectedSheet;
		}
		//console.log('sheetName->',sheetName);
		if(this.state.dataMode == 'mdsFieldCopy' && sheetName == 'Temp File'){
			return false;
		}

		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:sheetName, 
		})
		.then(
			response => {
				const data = response.result.values;
				//console.log('data->',data);
				let allData = [];
				let checkedInc = this.state.checkedInc;
				let checkedReq = this.state.checkedReq;
				let checkedKiosk = this.state.checkedKiosk;
				let delegateUsers = this.state.delegateUsers;
				data.map((val,i)=>{
					if(i > 0){
						allData.push(val);
						checkedInc.set(val[0], val[14]);
						checkedReq.set(val[0], val[15]);
						checkedKiosk.set(val[0], val[37]);
						let posData = val[16];
						if(posData && val[37] == 'Y'){
							posData = posData.split(',');
							let array = posData.slice(-10);
							array = array.filter(function(x) {
								 return x !== '';
							});
							delegateUsers.set(val[0], array);
						}else{
							delegateUsers.set(val[0], ['CPO'])
						}
						
						if(this.state.loadDataSet == 'loadLayout' && (val[5] == 'image' || val[5] == 'inlinevideo')){
							this.getResponseValue(val[0],val[99]);
						}
					}
				});
				
				if(this.state.loadDataSet == 'loadMds' || this.state.loadDataSet == 'loadLayout'){
					
					if(allData.length > 0){
						let fieldPrefix = allData[0][118];
						let mdsDataName = allData[0][118];
						let mdsCompany = allData[0][115];
						let mdsCompanyArray = allData[0][115] ? allData[0][115].split(',') : []; 
						let mdsFacility = allData[0][120] ? allData[1][120] : this.state.defaultLayoutFacility;
						let mdsGroupId = allData[0][121];
						let mdsGroupIdArray = allData[0][121] ? allData[0][121].split(',') : []; 
						let allDelegate = allData[0][119]; 
						let allDelegateArray = allData[0][119] ? allData[0][119].split(',') : []; 
						let allTypes = allData[0][92]; 
						let allTypesArray = allData[0][92] ? allData[0][92].split(',') : [];  
						let allDeviceTypes = allData[0][94]; 
						let allDeviceTypesArray = allData[0][94] ? allData[0][94].split(',') : []; 
						let allVisitTypes = allData[0][95]; 
						let allVisitTypesArray = allData[0][95] ? allData[0][95].split(',') : [];
						let layoutType = allData[0][138] ? allData[0][138] : '';
						
						let chooseTracking = data[125] ? data[125] : '';
						let trackingName = data[125] ? data[125].split(',') : [];
						
						if(allTypes == 'Orthotic'){
							this.setState({deviceTypes:this.state.oDeviceTypes});
						}else if(allTypes=='Prosthetic'){
							this.setState({deviceTypes:this.state.pDeviceTypes});
						}
						
						if(mdsDataName == ''){
							this.setState({hasNotCopy:false});
						}
						
						this.setState({fieldPrefix,addToParentMds:false,mdsDataName,mdsCompany,mdsFacility,mdsGroupId,allDelegate,allTypes,allDeviceTypes,allVisitTypes,mdsCompanyArray,mdsGroupIdArray,allDelegateArray,allDeviceTypesArray,allTypesArray,allVisitTypesArray,layoutType,chooseTracking,chooseTrackingArray:trackingName});
					}

				}else{
					
					if(allData.length > 0){
						let s = allData[0][0];
						let lastIndex = s.lastIndexOf("-")
						let fieldPrefix = s.substring(0, lastIndex);
						this.setState({fieldPrefix});
					}
					
					this.setState({addToParentMds:true,mdsDataName:'',mdsCompany:'',mdsFacility:'',mdsGroupId:'',allDelegate:'',allTypes:'',allDeviceTypes:'',allVisitTypes:'',mdsCompanyArray:[],mdsGroupIdArray:[],allTypesArray:[],allDelegateArray:[],allDeviceTypesArray:[],allVisitTypesArray:[],layoutType:'',showTrackerDetails:false});
				}
				
				let ids =[];
				let mainData =[];
				let copiedFields = 0;
				{allData.map((data,i)=>{
					var key = data[10];
					mainData[key] = data; 
					
					if(this.state.loadDataSet == 'loadLayout'){
						let needsNote = this.state.needsNote;
						if(data[27]){
							needsNote.set(data[0], data[27]);
						}else{
							needsNote.delete(data[0]); 
						}
						this.setState({needsNote});
					}
					
					if(data[134]){
						copiedFields = copiedFields+1;
					}
					
				})}
				
				let dbArray = this.checkKey2(mainData, '', ids, 2, 1);
				
				let MDGroups = this.state.MDGroups;
				
				if(this.state.fieldPos && MDGroups > 0){
					let dataLenth = allData.length - MDGroups;
					for(let i=0; i < MDGroups; i++ ){
						allData = arrayMove(allData, dataLenth+i, parseInt(this.state.fieldPos)+parseInt(i));
					}
				}else if(this.state.fieldPos && MDGroups == 0){
					allData = arrayMove(allData, allData.length-1, this.state.fieldPos);
				}
				
				let mdsDataLen = allData.length;
				let mdsDataLeft = mdsDataLen-copiedFields;
				let copyFieldsNumber = mdsDataLeft+'/'+mdsDataLen;
				
				this.setState({sheetData:dbArray,allData,showUpdate:true,checkedInc:checkedInc,checkedReq:checkedReq,checkedKiosk:checkedKiosk,delegateUsers:delegateUsers,delegateFilter:'',MDGroups:0,fieldPos:'',copyFieldsNumber});
				setTimeout(function(){
					$(".select-pos").prop('checked', false);
				}, 2000);
			},function(reason) {
				//alert(reason.result.error.message);
			}
		);
		this.setState({trackerRow:'',showTrackerDetails:false,cFormUID:'',trackingFacility:'',personRole:'',personName:'',currentStatus:'',dueDate:'',markCompleted:'',fabCompleted:'',allParts:'',editorState:'',editorSourceHTML:''});
    }
	
	getCopyMdsData=(sheetName) => {
		
		if(sheetName == ''){
			this.setState({copySelectedSheet:'',copySheetData:[],copyAllData:[]});
			return false
		}
		
		let spreadsheetId = this.state.mdsCopyFieldSpreadsheetId;
        gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:sheetName, 
		})
		.then(
			response => {
				const data = response.result.values;
				
				let copyAllData = [];
				
				data.map((val,i)=>{
					if(i > 0){
						copyAllData.push(val);
					}
				});
				
				let ids =[];
				let mainData =[];
				
				let dbArray = this.checkKey2(mainData, '', ids, 2, 1);
				let MDGroups = this.state.MDGroups;
				if(this.state.fieldPos && MDGroups > 0){
					let dataLenth = copyAllData.length - MDGroups;
					for(let i=0; i < MDGroups; i++ ){
						copyAllData = arrayMove(copyAllData, dataLenth+i, parseInt(this.state.fieldPos)+parseInt(i));
					}
				}else if(this.state.fieldPos && MDGroups == 0){
					copyAllData = arrayMove(copyAllData, copyAllData.length-1, this.state.fieldPos);
				}
				
				this.setState({showEditHeaders:false,copySelectedSheet:sheetName,copySheetData:dbArray,copyAllData});
				
			},function(reason) {
				//alert(reason.result.error.message);
			}
		);
    }
	
	checkKey2(elements, parentId = '',ids ,pId, cId) {
        var branch = [];
        { elements.map((element,i) => {
            if(element[5] == 'Parent Header'){
                element[pId] = '';
            }  
            if (element[pId].toLowerCase() == parentId) {
                if(element[cId] == ''){
                    element[cId]='a';
                }
                var children = this.checkKey2(elements, element[cId].toLowerCase(), ids, pId, cId);
                if (children) {
                    element['sub'] = children; 
                }
                if(!ids.includes(element['0'])){
                    branch.push(element);  
                }
                ids.push(element['0']);
            }
        })};
        return branch;    
    }
	
	getCopyMdsFile=() => {
		let spreadsheetId = this.state.mdsCopyFieldSpreadsheetId;
		//console.log('spreadsheetId->',spreadsheetId);
        gapi.client.sheets.spreadsheets
		.get({
			spreadsheetId: spreadsheetId, 
		})
		.then(
			response => {
				const sheets = response.result.sheets;
				//console.log('sheets->',sheets);
				let copyMdsSheets = sheets.map((sheet) =>{
					let sheetTitle = sheet.properties.title;
					if(sheet.properties.gridProperties.columnCount > 120 && sheet.properties.gridProperties.columnCount < 150 && sheetTitle != 'Temp File' && sheetTitle.indexOf('Temp-') == -1){
						return sheet.properties.title
					}
					
				});
				
				//console.log('mdsSheets->',mdsSheets);
				copyMdsSheets = copyMdsSheets.filter(function(x) {
					 return x !== undefined;
				});
				
				let copyMdsSheetNames = [];
				
				sheets.map((sheet, i) => {
					if(copyMdsSheets.indexOf(sheet.properties.title) != -1 && i <= 120){
						let sht = {sheetId:sheet.properties.sheetId, name:sheet.properties.title};
						copyMdsSheetNames.push(sht);
					}
				});
				this.setState({copyMdsSheets,copyMdsSheetNames});
			},function(reason) {
				alert(reason.result.error.message);
			}
		);
	}
	
	getMdsSheets=() => {
		let spreadsheetId = this.state.mdsSpreadsheetId;
        gapi.client.sheets.spreadsheets
		.get({
			spreadsheetId: spreadsheetId, 
		})
		.then(
			response => {
				const sheets = response.result.sheets;
				let mdsSheets = sheets.map((sheet) =>{
					let sheetTitle = sheet.properties.title;
					if(sheet.properties.gridProperties.columnCount > 120 && sheet.properties.gridProperties.columnCount < 150 && sheetTitle != 'Temp File' && sheetTitle.indexOf('Temp-') == -1){
						return sheet.properties.title
					}
					
				});
				
				mdsSheets = mdsSheets.filter(function(x) {
					 return x !== undefined;
				});
				
				let mdsSheetNames = [];
				
				sheets.map((sheet, i) => {
					if(mdsSheets.indexOf(sheet.properties.title) != -1 && i <= 120){
						let sht = {sheetId:sheet.properties.sheetId, name:sheet.properties.title};
						mdsSheetNames.push(sht);
					}
				});
				this.getSheetdDataName(mdsSheetNames,'MDS');
				this.getMdsSheetdDataName(mdsSheetNames);
				this.setState({mdsSheets,mdsSheetNames});
				let that = this;
				setTimeout(function(){
					//that.getMdsJsonFileVersion();
				}, 1000);
			},function(reason) {
				alert(reason.result.error.message);
			}
		);

		gapi.client.sheets.spreadsheets
		.get({
			spreadsheetId: this.state.childSpreadsheetId,
		})
		.then(
			response => {
				const sheets = response.result.sheets;
				let childSheets = sheets.map((sheet) =>{
					let sheetTitle = sheet.properties.title;
					if(sheet.properties.gridProperties.columnCount > 120 && sheet.properties.gridProperties.columnCount < 150 && sheetTitle != 'Temp File' && sheetTitle.indexOf('Temp-') == -1){
						return sheet.properties.title
					}
				});
				childSheets = childSheets.filter(function(x) {
					 return x !== undefined;
				});
				let childSheetNames = [];
				sheets.map((sheet, i) => {
					if(childSheets.indexOf(sheet.properties.title) != -1 && i <= 125){
						let sht = {sheetId:sheet.properties.sheetId, name:sheet.properties.title};
						childSheetNames.push(sht);
					}
				});
				this.getSheetdDataName(childSheetNames,'child');
				this.setState({childSheetNames});
				let that = this;
				setTimeout(function(){
					that.getLayoutJsonFileVersion();
				}, 1000);
			},function(reason) {
				alert(reason.result.error.message);
			}
		);
		if(this.state.layoutSpreadsheetId){
			gapi.client.sheets.spreadsheets
			.get({
				spreadsheetId: this.state.layoutSpreadsheetId,
			})
			.then(
				response => {
					const sheets = response.result.sheets;
					let layoutSheets = sheets.map((sheet) =>{
						let sheetTitle = sheet.properties.title;
						if(sheet.properties.gridProperties.columnCount > 120 && sheet.properties.gridProperties.columnCount < 150 && sheetTitle != 'Temp File' && sheetTitle.indexOf('Temp-') == -1){
							return sheet.properties.title
						}
					});
					layoutSheets = layoutSheets.filter(function(x) {
						 return x !== undefined;
					});
					let layoutSheetNames = [];
					sheets.map((sheet, i) => {
						if(layoutSheets.indexOf(sheet.properties.title) != -1 && i <= 125){
							let sht = {sheetId:sheet.properties.sheetId, name:sheet.properties.title};
							layoutSheetNames.push(sht);
						}
					});
					this.getSheetdDataName(layoutSheetNames,'layout');
					this.setState({layoutSheetNames});
				},function(reason) {
					alert(reason.result.error.message);
				}
			);
		}
	
	}
	
	addNewField=(optionType,value) =>{
		this.setState({	MDGroups:0});
		let allData = this.state.allData;
		let spreadsheetId = this.state.spreadsheetId;
		let selectedSheet = this.state.selectedSheet;
        var  sheetName = 'Temp File';
        if(selectedSheet != '' && selectedSheet !== undefined){
			sheetName = selectedSheet;
		}
		let fieldPrefix = this.state.fieldPrefix;
		let id = allData.length+1
		let ttevalId = fieldPrefix+'-'+id;
		let fieldID = '';
		let parentId = '';
		let position = id;
		let PosId = '';
		let guids = '';
		let guids_dn = ulid();
		let tagId = '';
		let mds_guids = '';
		let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
		var params = {
			spreadsheetId:spreadsheetId,
			range:sheetName+'!A:EB', 
			valueInputOption: "RAW", 
			insertDataOption: "INSERT_ROWS",
			resource:{
				"values": 
				[
					[ ttevalId,fieldID,parentId,'','',optionType,'','','','',position,'','','','Y','N',PosId,'','','','','','','','','','','','','','','','Y','N','',guids,'','N','',tagId,'','','','','','','','','V1','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','',mds_guids,'','','','','',currentDate,currentDate,'','','','','','','','','','','','','Y','','','','','',guids_dn,'','','','','','','','' ]
				],
				"majorDimension": "ROWS"
			  }
		};
		let that = this;
		var request = gapi.client.sheets.spreadsheets.values.append(params);
		request.then(function(response) {
			that.getMdsData();
			if(!that.state.fieldPos){
				setTimeout(function(){
					that.updateData();
					$(".page-builder").animate({ scrollTop: $('.page-builder').prop("scrollHeight")}, 1000);
				}, 2000);
			}else{
				setTimeout(function(){
					that.clearUpdateData('pos');
				}, 2000);
			}
		}, function(reason) {
			console.error('error: ' + reason.result.error.message);
		});
	}
	
	addNewPreField=(value) =>{
		this.setState({	MDGroups:0});
		let mdsSpreadsheetId = this.state.mdsSpreadsheetId;
		let spreadsheetId = this.state.spreadsheetId;
		let selectedSheet = this.state.selectedSheet;
        var  sheetName = 'Temp File';
        if(selectedSheet != '' && selectedSheet !== undefined){
			sheetName = selectedSheet;
		}
		let uld = ulid();
		let row = Object.assign([], value);
		let allData = this.state.allData;
		let exist = false;
		{allData.map((data,i)=>{
			if(data[130] == value[0] && data[131] == value[117]){
				exist = true;
			}
		})}
	
		if(exist){
			alert('The field already exist in the Default Layout!');
			return false;
		}
				
		let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
		row[129] = value[91];
		row[130] = value[0];
		row[131] = value[117];
		row[7] = '';
		row[8] = '';
		//row[39] = '';
		row[71] = '';
		//row[91] = '';
		row[92] = '';
		row[93] = '';
		row[94] = '';
		row[95] = '';
		//row[117] = uld;
		row[97] = currentDate;
		row[98] = currentDate;
		row[58] = mdsSpreadsheetId;
		var params = {
			spreadsheetId:spreadsheetId,
			range:sheetName+'!A:EB', 
			valueInputOption: "RAW", 
			insertDataOption: "INSERT_ROWS",
			resource:{
				"values": [row],
				"majorDimension": "ROWS"
			  }
		};
		let that = this;
		var request = gapi.client.sheets.spreadsheets.values.append(params);
		request.then(function(response) {
			that.getMdsData();
			if(!that.state.fieldPos){
				setTimeout(function(){
					that.updateData();
					$(".page-builder").animate({ scrollTop: $('.page-builder').prop("scrollHeight")}, 1000);
				}, 2000);
			}else{
				setTimeout(function(){
					that.clearUpdateData('pos');
				}, 2000);
			}
			
		}, function(reason) {
			console.error('error: ' + reason.result.error.message);
		});
	}
	
	addNewGroup=(fname,type,pos) =>{
		let parentdata = this.state.parentDataSets;
		let mdsSpreadsheetId = this.state.mdsSpreadsheetId;
		let spreadsheetId = this.state.spreadsheetId;
		let selectedSheet = this.state.selectedSheet;
		let allData = this.state.allData;
        let sheetName = 'Temp File';
        if(selectedSheet != '' && selectedSheet !== undefined){
			sheetName = selectedSheet;
		}
		
		parentdata.sort(function(a, b) {
			return a[10] - b[10];
		});
		
		let newSets = [];
		let end = 0;
		for (var i = pos; i < parentdata.length; i++) {
			if(i > pos && (parentdata[i][5] == type || (type.toLowerCase() == 'child header 1' && parentdata[i][5].toLowerCase() == 'parent header') || (type.toLowerCase() == 'child header 2' && (parentdata[i][5].toLowerCase() == 'child header 1' || parentdata[i][5].toLowerCase() == 'parent header')) || (type.toLowerCase() == 'child header 3' && (parentdata[i][5].toLowerCase() == 'child header 2' || parentdata[i][5].toLowerCase() == 'child header 1' || parentdata[i][5].toLowerCase() == 'parent header'))) ){
				break;
			}
			
			if(type.toLowerCase() == 'boxbegin' && end > 0){
				break;
			}
			
			if(i > pos && (parentdata[i][5] == type || (type.toLowerCase() == 'boxbegin' && parentdata[i][5].toLowerCase() == 'boxend')) ){
				end = end+1;
			}
			
			let exist = false;
			allData.map((data,j)=>{
				if(data[130] == parentdata[i][0]){
					exist = true;
				}
			})
			
			if(exist){
				break;
			}
			
			let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
			parentdata[i][129] = parentdata[i][91];
			parentdata[i][130] = parentdata[i][0];
			parentdata[i][131] = parentdata[i][117];
			parentdata[i][7] = '';
			parentdata[i][8] = '';
			//parentdata[i][39] = '';
			parentdata[i][71] = '';
			//parentdata[i][91] = '';
			parentdata[i][92] = '';
			parentdata[i][93] = '';
			parentdata[i][94] = '';
			parentdata[i][95] = '';
			parentdata[i][97] = currentDate;
			parentdata[i][98] = currentDate;
			parentdata[i][58] = mdsSpreadsheetId;
			newSets.push(parentdata[i]);  
		}
		
		this.setState({	MDGroups:newSets.length});
		
		if(newSets.length > 0){
			let length = allData.length+1
			this.setState({dataLength:length});
			var params = {
				spreadsheetId:spreadsheetId,
				range:sheetName+'!A:EB', 
				valueInputOption: "RAW", 
				insertDataOption: "INSERT_ROWS",
				resource:{
					"values":newSets,
					"majorDimension": "ROWS"
				}
			};
			let that = this;
			var request = gapi.client.sheets.spreadsheets.values.append(params);
			request.then(function(response) {
				that.getMdsData();
				if(!that.state.fieldPos){
					setTimeout(function(){
						that.updateData();
						$(".page-builder").animate({ scrollTop: $('.page-builder').prop("scrollHeight")}, 1000);
						$('.btn-undo-data').show();
					}, 3000);
				}else{
					$('.btn-undo-data').show();
				}
			}, function(reason) {
				console.error('error: ' + reason.result.error.message);
			});
		}else{
			alert('The Group fields already exist in the Default Layout!');
		}

	}
	
	updateData=(msg=false) =>{
		let allData = this.state.allData;
		let spreadsheetId = this.state.spreadsheetId;
		let selectedSheet = this.state.selectedSheet;
		var sheetName = 'Temp File';
        if(selectedSheet != '' && selectedSheet !== undefined){
			sheetName = selectedSheet;
		}
		
		//console.log('allData->',allData);
		let fieldID = '';
		let j = 0;
		let n = 0;
		let s = 0;
		let q = 0;
		let parent1 = '';
		let parent2 = '';
		let parent3 = '';
		let parentID = '';
		let parentGuid = '';
		let parentGuid1 = '';
		let parentGuid2 = '';
		let parentGuid3 = '';
		
		let characters = "abcdefghijklmnopqrstuvwxyz";
		let length = 3;
		let randomStr = "";

		for (let i = 0; i < length; i++) {
		  const randomNum = Math.floor(Math.random() * characters.length);
		  randomStr += characters[randomNum];
		}
		//console.log('allData->',allData);
		let fieldPrefix = this.state.fieldPrefix;
		let mdsGuid = allData[0][91];
		let mdsName = allData[0][118];
		let chtr = 0;
		allData.map((field,i)=>{
			let id = i+1;
			allData[i][0] = fieldPrefix+'-'+id;	
			allData[i][10] = id;
			
			if(field[5].toLowerCase() == 'parent header'){
				
				if(field[4]){
					fieldID = field[4].substring(0, 3)+characters[chtr];
				}else if(field[1] == ''){
					fieldID = randomStr
				}
				
				allData[i][1] = fieldID;
				allData[i][2] = 'MDS';
				parentID = fieldID;
				parent1 = fieldID;
				parentGuid = field[117];
				parentGuid1 = field[117];
				j = 0;
				n = 0;
				s = 0;
				chtr++;
			}else if(field[5].toLowerCase() == 'child header 1'){
				j = j+1
				let child = 'c'+j;
				allData[i][1] = fieldID+child;
				allData[i][2] = parent1;
				allData[i][11] = parentGuid1;
				parentID = fieldID+child
				parent2 = fieldID+child
				parentGuid = field[117];
				parentGuid2 = field[117];
				//n = 0;
			}else if(field[5].toLowerCase() == 'child header 2'){
				n = n+1
				let child = 'cc'+n;
				allData[i][1] = fieldID+child;
				allData[i][2] = parent2 ? parent2 : parentID;
				allData[i][11] = parentGuid2 ? parentGuid2 : parentGuid;
				parentID = fieldID+child
				parent3 = fieldID+child
				parentGuid = field[117];
				parentGuid3 = field[117];
			}else if(field[5].toLowerCase() == 'child header 3'){
				s = s+1
				let child = 'ccc'+s;
				allData[i][1] = fieldID+child;
				allData[i][2] = parent3 ? parent3 : parentID;
				allData[i][11] = parentGuid3 ? parentGuid3 : parentGuid;
				parentID = fieldID+child
				parentGuid = field[117];
			}else{
				q = q+1
				allData[i][1] = 'q'+q;
				allData[i][2] = parentID;
				allData[i][11] = parentGuid;
			}

		});
		
		let updateRows=[];
		let dates=[];
		let rowId='';
		
		let checkedInc = this.state.checkedInc;
		let checkedReq = this.state.checkedReq;
		let checkedKiosk = this.state.checkedKiosk;
		
		allData.map((rowDatas, i) => {
			rowId = (i+2);
			let include = checkedInc.get(rowDatas[0]);
			if(include == undefined || msg == 'pos' || msg == 'del'){
				include = rowDatas[14];
			}
			let required = checkedReq.get(rowDatas[0]);
			if(required == undefined || msg == 'pos' || msg == 'del'){
				required = rowDatas[15];
			}
			let delegate = checkedKiosk.get(rowDatas[0]);
			if(delegate == undefined || msg == 'pos' || msg == 'del'){
				delegate = rowDatas[37];
			}
			
			let uArray = ['CPO'];
			
			if(this.state.delegateUsers.get(rowDatas[0]) && msg != 'pos' && msg == 'kiosk'){
				uArray = this.state.delegateUsers.get(rowDatas[0]);
			}else{
				let posData = rowDatas[16];
				if(posData){
					posData = posData.split(',');
					uArray = posData.slice(-10);
					uArray = uArray.filter(function(x) {
						 return x !== '';
					});
				}
			}
			
			if(msg == 'pos' || msg == 'del' || msg == 'kiosk'){
				checkedInc.set(rowDatas[0], include);
				checkedReq.set(rowDatas[0], required);
				checkedKiosk.set(rowDatas[0], delegate);
				this.setState({checkedInc,checkedReq,checkedKiosk});
			}
			
			this.setState({[rowDatas[0]]: ''});
			
			let uType = [];
			let kioskType = this.state.delegateTypes;
			
			if(kioskType){
				kioskType.map(function(type,i) {
					type = type.split(':');
					uType.push(type[1]);
				})
			}else{
				uType = ['CPO']
			}
			
			let final_arr = uArray;
			let final_arr1 = uType;

			let array=[];
			if(final_arr){
				final_arr1.map((item,i)=>{
					if(final_arr.includes(item) == true){
						array.push(item)
					}else if(item == 'CPO'){
						array.push('CPO')
					}else{
						array.push('')
					}
				})
			}
			let userTypes = array.toString();
			if(userTypes == undefined){
				userTypes = ',,,,,,,CPO,,';
			}
			
			let delegateUsers = uArray.toString();
			//console.log('delegateUsers->',delegateUsers);
			delegateUsers = delegateUsers.replaceAll(',','|');
			
			if(delegate == 'N'){
				userTypes = ',,,,,,,CPO,,';
				delegateUsers = ['CPO']
			}
			
			let parentID = rowDatas[2];
			if(rowDatas[5].toLowerCase() == 'parent header'){
				parentID ='';
			}
			let positionConfig = rowDatas[0]+',,'+parentID+','+rowDatas[7]+','+rowDatas[8]+','+include+','+required+','+rowDatas[10]+','+delegate+','+userTypes;
			
			let row1 = {
				"range": sheetName+'!A'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[rowDatas[0]]
				]
			};
			let row2 = {
				"range": sheetName+'!B'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[rowDatas[1]]
				]
			};
			let row3 = {
				"range": sheetName+'!C'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[rowDatas[2]]
				]
			};
			
			let row4 = {
				"range": sheetName+'!K'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[rowDatas[10]]
				]
			};
			
			let row5 = {
				"range": sheetName+'!L'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[rowDatas[11]]
				]
			};
			
			let row6 = {
				"range": sheetName+'!Q'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[positionConfig]
				]
			};
			
			
			let row7 = {
				"range": sheetName+'!O'+rowId+':P'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[include,required]
				]
			};
			
			let row8 = {
				"range": sheetName+'!AL'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[delegate]
				]
			};
			//console.log('delegateUsers->>',delegateUsers);
			
			let row9 = {
				"range": sheetName+'!EK'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					["["+delegateUsers+"]"]
				]
			};
			
			let row10 = {
				"range": sheetName+'!CN'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[mdsGuid]
				]
			};
			
			updateRows.push(row1)
			updateRows.push(row2)
			updateRows.push(row3)
			updateRows.push(row4)
			updateRows.push(row5)
			updateRows.push(row6)
			updateRows.push(row7)
			updateRows.push(row8)
			updateRows.push(row9)
			updateRows.push(row10)
			
			if(this.state.dataMode == 'MDS'){
				let row12 = {
					"range": sheetName+'!DO'+rowId,
					"majorDimension": "ROWS",
					"values": 
					[
						[mdsName]
					]
				};
				updateRows.push(row12)
			}
			
			let date = new Date(rowDatas[98]);
			if(date != 'Invalid Date'){
				dates.push(date);
			}
		});
		
		let maximumDate = new Date(Math.max.apply(null, dates));
		let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
		let totalFields = allData.length;
		
		let apiName = 'MDS_'+ sheetName +'_'+lastUpDate+'_'+totalFields;
		if(this.state.dataMode == 'Child'){
			apiName = 'DefaultLayout_'+ sheetName +'_'+lastUpDate+'_'+totalFields;
		}
		
		let row11 = {
			"range": sheetName+'!DT2',
			"majorDimension": "ROWS",
			"values": 
			[
				[apiName]
			]
		};
		updateRows.push(row11);
		
		if(msg && msg != 'pos' && msg != 'del' && msg != 'kiosk'){
			
			let version = allData[0][59];
			
			if(version != ''){
				version = parseFloat(version) + parseFloat(0.01);
			}else{
				version = 1.01;
			}
			
			let row12 = {
				"range": sheetName+'!BH2',
				"majorDimension": "ROWS",
				"values": 
				[
					[version]
				]
			};
			updateRows.push(row12);
			
		}
		
		var params = {
			spreadsheetId: spreadsheetId,
		}
		let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateRows, 
        };
		let that = this;
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
			that.setState({fieldPos:''});
			if(msg && msg != 'pos' && msg != 'del' && msg != 'kiosk'){
				alert('Update Data Successfully!');
				setTimeout(function(){
					if(that.state.dataMode == 'MDS' && sheetName != 'Temp File'){
						that.saveMdsJsonDataMongodb(sheetName, 'mongo', 'mds');
					}else if(that.state.dataMode == 'Child' && sheetName != 'Temp File'){
						that.saveMdsJsonDataMongodb(sheetName, 'mongo', 'child');
					}
				}, 2000);
			}else if(msg == 'pos'){
				alert('Field added Successfully in the chosen position!');
			}
            that.getMdsData();
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        });
	}
	
	updateLayout=(spreadsheetId,sheetName,type) =>{
		let allData = this.state.allData;
		let fieldID = '';
		let j = 0;
		let n = 0;
		let s = 0;
		let q = 0;
		let parent1 = '';
		let parent2 = '';
		let parent3 = '';
		let parentID = '';
		let parentGuid = '';
		let parentGuid1 = '';
		let parentGuid2 = '';
		let parentGuid3 = '';
		let characters = "abcdefghijklmnopqrstuvwxyz";
		let length = 3;
		let randomStr = "";

		for (let i = 0; i < length; i++) {
		  const randomNum = Math.floor(Math.random() * characters.length);
		  randomStr += characters[randomNum];
		}
		
		let mdsCompany = this.state.mdsCompany;
		let mdsFacility = this.state.mdsFacility;
		let layoutFriendlyName = this.state.layoutFriendlyName;
		let mdsGroupId = this.state.mdsGroupId;
		let Public = this.state.Public;
		let privateOwner = this.state.privateOwner;
		let allDelegate = this.state.allDelegate;
		let allTypes = this.state.allTypes;
		let allDeviceTypes = this.state.allDeviceTypes;
		let allVisitTypes = this.state.allVisitTypes;
		let layoutType = this.state.layoutType;
		
		let chooseTracking = this.state.chooseTracking;
		let fieldPrefix = this.state.fieldPrefix;
		let mdsGuid = ulid();
		//let mdsGuid = allData[0][91];
		//let mdsName = allData[0][118];
		let mdsName = this.state.mdsDataName;
		let chtr = 0;
		allData.map((field,i)=>{
			let id = i+1;
			//allData[i][0] = fieldPrefix+'-'+id;	
			allData[i][10] = id;
			
			if(field[5].toLowerCase() == 'parent header'){
				if(field[4]){
					fieldID = field[4].substring(0, 3)+characters[chtr];
				}else if(field[1] == ''){
					fieldID = randomStr
				}
				
				allData[i][1] = fieldID;
				allData[i][2] = 'MDS';
				parentID = fieldID;
				parent1 = fieldID;
				parentGuid = field[117];
				parentGuid1 = field[117];
				j = 0;
				n = 0;
				s = 0;
				chtr++;
			}else if(field[5].toLowerCase() == 'child header 1'){
				j = j+1
				let child = 'c'+j;
				allData[i][1] = fieldID+child;
				allData[i][2] = parent1;
				allData[i][11] = parentGuid1;
				parentID = fieldID+child
				parent2 = fieldID+child
				parentGuid = field[117];
				parentGuid2 = field[117];
				//n = 0;
			}else if(field[5].toLowerCase() == 'child header 2'){
				n = n+1
				let child = 'cc'+n;
				allData[i][1] = fieldID+child;
				allData[i][2] = parent2 ? parent2 : parentID;
				allData[i][11] = parentGuid2 ? parentGuid2 : parentGuid;
				parentID = fieldID+child
				parent3 = fieldID+child
				parentGuid = field[117];
				parentGuid3 = field[117];
			}else if(field[5].toLowerCase() == 'child header 3'){
				s = s+1
				let child = 'ccc'+s;
				allData[i][1] = fieldID+child;
				allData[i][2] = parent3 ? parent3 : parentID;
				allData[i][11] = parentGuid3 ? parentGuid3 : parentGuid;
				parentID = fieldID+child
				parentGuid = field[117];
			}else{
				q = q+1
				allData[i][1] = 'q'+q;
				allData[i][2] = parentID;
				allData[i][11] = parentGuid;
			}
		});
		
		let updateRows=[];
		let dates=[];
		let rowId='';

		allData.map((rowDatas, i) => {
			let id = i+1;
			rowId = (i+2);
			let include = this.state.checkedInc.get(rowDatas[0]);
			let required = this.state.checkedReq.get(rowDatas[0]);
			let delegate = this.state.checkedKiosk.get(rowDatas[0]);
			let uArray = ['CPO'];
			if(this.state.delegateUsers.get(rowDatas[0])){
				uArray = this.state.delegateUsers.get(rowDatas[0]);
			}
			this.setState({[rowDatas[0]]: ''});
			let uType = [];
			let kioskType = this.state.delegateTypes;
			
			if(kioskType){
				kioskType.map(function(type,i) {
					type = type.split(':');
					uType.push(type[1]);
				})
			}else{
				uType = ['CPO']
			}
			
			var final_arr = uArray;
			var final_arr1 = uType;
			
			
			var array=[];
			if(final_arr){
				final_arr1.map((item,i)=>{
					if(final_arr.includes(item) == true){
						array.push(item)
					}else if(item == 'CPO'){
						array.push('CPO')
					}else{
						array.push('')
					}
				})
			}
			let userTypes = array.toString();
			if(userTypes == undefined){
				userTypes = ',,,,,,,CPO,,';
			}
			
			let delegateUsers = uArray.toString();
			delegateUsers = delegateUsers.replaceAll(',','|');
			
			let parentID = rowDatas[2];
			if(rowDatas[5].toLowerCase() == 'parent header'){
				parentID ='';
			}
			
			let positionConfig = rowDatas[0]+',,'+parentID+','+rowDatas[7]+','+rowDatas[8]+','+include+','+required+','+rowDatas[10]+','+delegate+','+userTypes;
			
			if(type == 'default'){
				positionConfig = fieldPrefix+'-'+id+',,'+parentID+','+rowDatas[7]+','+rowDatas[8]+','+include+','+required+','+rowDatas[10]+','+delegate+','+userTypes;
			}
			
			let row1 = {
				"range": sheetName+'!A'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[fieldPrefix+'-'+id]
				]
			};
			let row2 = {
				"range": sheetName+'!B'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[rowDatas[1]]
				]
			};
			let row3 = {
				"range": sheetName+'!C'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[rowDatas[2]]
				]
			};
			
			let row4 = {
				"range": sheetName+'!K'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[rowDatas[10]]
				]
			};
			
			let row5 = {
				"range": sheetName+'!L'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[rowDatas[11]]
				]
			};
			
			let row6 = {
				"range": sheetName+'!Q'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[positionConfig]
				]
			};
			
			
			let row7 = {
				"range": sheetName+'!O'+rowId+':P'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[include,required]
				]
			};
			
			let row8 = {
				"range": sheetName+'!AL'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[delegate]
				]
			};
			//console.log('delegateUsers->>',delegateUsers);
			
			let row9 = {
				"range": sheetName+'!EK'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					["["+delegateUsers+"]"]
				]
			};
			
			let row10 = {
				"range": sheetName+'!CN'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[mdsGuid]
				]
			};
			
			updateRows.push(row1)
			updateRows.push(row2)
			updateRows.push(row3)
			updateRows.push(row4)
			updateRows.push(row5)
			updateRows.push(row6)
			updateRows.push(row7)
			updateRows.push(row8)
			updateRows.push(row9)
			updateRows.push(row10)
			
			if(type == 'layout'){
				let row12 = {
					"range": sheetName+'!DO'+rowId,
					"majorDimension": "ROWS",
					"values": 
					[
						[mdsName]
					]
				};
				updateRows.push(row12)
			}
			
			let date = new Date(rowDatas[98]);
			if(date != 'Invalid Date'){
				dates.push(date);
			}
		});
		
		let row13 = {
			"range": sheetName+'!DL2',
			"majorDimension": "ROWS",
			"values": 
			[
				[mdsCompany]
			]
		};
		
		let row14 = {
			"range": sheetName+'!DQ2',
			"majorDimension": "ROWS",
			"values": 
			[
				[mdsFacility]
			]
		};
		
		let row15 = {
			"range": sheetName+'!DR2',
			"majorDimension": "ROWS",
			"values": 
			[
				[mdsGroupId]
			]
		};
		
		let row16 = {
			"range": sheetName+'!DP2',
			"majorDimension": "ROWS",
			"values": 
			[
				[allDelegate]
			]
		};
		
		let row17 = {
			"range": sheetName+'!CO2:CR2',
			"majorDimension": "ROWS",
			"values": 
			[
				[allTypes,'',allDeviceTypes,allVisitTypes]
			]
		};
		
		let row18 = {
			"range": sheetName+'!EI2',
			"majorDimension": "ROWS",
			"values": 
			[
				[layoutType]
			]
		};
		
		updateRows.push(row13);
		updateRows.push(row14);
		updateRows.push(row15);
		updateRows.push(row16);
		updateRows.push(row17);
		updateRows.push(row18);
		
		
		let maximumDate = new Date(Math.max.apply(null, dates));
		let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
		let totalFields = allData.length;
		
		let apiName = 'Layout_'+ sheetName +'_'+lastUpDate+'_'+totalFields;
		
		
		let row11 = {
			"range": sheetName+'!DT2',
			"majorDimension": "ROWS",
			"values": 
			[
				[apiName]
			]
		};
		updateRows.push(row11);
		let version = 1.01;
		let row19 = {
			"range": sheetName+'!BH2',
			"majorDimension": "ROWS",
			"values": 
			[
				[version]
			]
		};
		updateRows.push(row19);
		
		let row20 = {
			"range": sheetName+'!BK2',
			"majorDimension": "ROWS",
			"values": 
			[
				[layoutFriendlyName]
			]
		};
		updateRows.push(row20);
		
		let row21 = {
			"range": sheetName+'!DH2',
			"majorDimension": "ROWS",
			"values": 
			[
				[Public]
			]
		};
		updateRows.push(row21);
		
		let row22 = {
			"range": sheetName+'!DL2',
			"majorDimension": "ROWS",
			"values": 
			[
				[privateOwner]
			]
		};
		
		updateRows.push(row22);
		
		let row23 = {
			"range": sheetName+'!DV2',
			"majorDimension": "ROWS",
			"values": 
			[
				[chooseTracking]
			]
		}; 
		
		updateRows.push(row23);
		
		var params = {
			spreadsheetId: spreadsheetId,
		}
		
		let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateRows, 
        };
		let that = this;
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
			if(type == 'default'){
				setTimeout(function(){
					that.saveMdsJsonDataMongodb(sheetName, 'mongo', 'child');
					that.getMdsSheets();
				}, 1000);
				alert('Default Layout created Successfully!');
			}else{
				setTimeout(function(){
					that.getMdsSheets();
				}, 1000);
				alert('New Layout created Successfully!');
			}
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        });
	}
	
	addParentSets=(sheet,i) =>{

		$('.set-btn').removeClass('active');
		$('.set-'+i).addClass('active');
		let spreadsheetId = this.state.mdsSpreadsheetId;
        let singleSheetName = sheet;
        
		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:singleSheetName, 
		})
		.then(
			response => {
				const parentDataSets = response.result.values;
				this.setState({parentDataSets});
				
			},function(reason) {
				//alert(reason.result.error.message);
			}
		);
	}
	
	buildDetails=()=>{
		$(".fadeInRight").trigger("click");
		this.setState({showFieldEdit:false,showBuildEdit:true});
		$('.build-select').val('');
	}
	
	getDueDate=(date)=>{
       this.setState({dueDate:date});
	}
	
	getValue=(event)=>{
		
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
		
		if(name == 'autoPopulate'){
			if($("input[name='autoPopulate']").prop("checked") == false){
				this.setState({autoPopulate:'',autoPopulateTags:''});
			}
		}
		
		if(name == 'fieldDeactivated'){
			if($("input[name='fieldDeactivated']").prop("checked") == false){
				this.setState({fieldDeactivated:'N'});
			}
		}
		
		if(name == 'mdsDataName'){
			res = res.replaceAll(" ", "-");
			this.setState({[name]:res,fieldPrefix:res});
		}
		
		if(name == 'saveAs' && res == 'parentMds'){
			this.setState({showPrefix:true});
		}else if(name == 'saveAs' && res != 'parentMds'){
			this.setState({showPrefix:false});
		}
		//console.log('ogAutoTags->',Array.from(event.target.selectedOptions, (item) => item.value));
		if(name == 'mdsCompany' || name == 'mdsGroupId' || name == 'allTypes' || name == 'allDelegate' || name == 'allDeviceTypes' || name == 'allVisitTypes' || name == 'ogAutoTags' || name == 'chooseTracking'){
			res = Array.from(event.target.selectedOptions, (item) => item.value).join();
			let newArray = name+'Array';
			this.setState({[event.target.name]:res,[newArray]:Array.from(event.target.selectedOptions, (item) => item.value)});
		}
		
		/* if(name == 'requiredFields' || name == 'optionalFields'){
			
			let requiredFields = this.state.requiredFieldsArray;
			let newSelected = Array.from(event.target.selectedOptions, (item) => item.value);
			newSelected.map((val,i)=>{
				console.log('val->', val);
				requiredFields.push(val);
			});
			
			let unique = requiredFields.filter(function(itm, i, requiredFields) {
				return i == requiredFields.indexOf(itm);
			});
		} */
		
		if(name == 'inlineVideoLink'){
			let path = '<iframe src="'+res+'" width="480" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Inline Video"></iframe>';
			this.setState({inlineVideo:path});
			$("#inlineVideo").html(path);
		}
		
		if($("input[name='noneAbove']").prop("checked") == false){
			this.setState({noneAbove:''});
		}
		
		if($("input[name='dataAgg']").prop("checked") == false){
			this.setState({dataAgg:''});
		}

		if(name == 'Public' && res == 'Yes'){
			this.setState({showCompany:false});
		}else if(name == 'Public' && res == 'No'){
			this.setState({showCompany:true});
		}
		
		let that = this;
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).split(',')[0];
		if(name == 'validationType' && (res == 'V3' || res == 'V4' || res == 'V5' || res == 'V6' || res == 'V9' || res == 'V11'  || res == 'V12' || res == 'V13')){
			
			that.setState({showMinMax:true});
			setTimeout(function(){
				if(res == 'V9'){
					$('.min-val').val('01/01/1920');
					$('.max-val').val(date);
					that.setState({minValue:'01/01/1920',maxValue:date});
				}else if(res == 'V11'){
					$('.max-val').val('01/01/2050');
					$('.min-val').val(date);
					that.setState({maxValue:'01/01/2050',minValue:date});
				}else if(res == 'V12' || res == 'V13'){
					$('.max-val').val('00:00:00');
					$('.min-val').val('00:00:00');
					that.setState({maxValue:'00:00:00',minValue:'00:00:00'});
				}else{
					$('.max-val').val('');
					$('.min-val').val('');
				}
			}, 2000);
			
		}else if(name == 'validationType' && (res == 'V1' || res == 'V2' || res == 'V7' || res == 'V8' || res == 'V10')){
			this.setState({showMinMax:false});
			that.setState({maxValue:'',minValue:''});
		}
		
		if(name == 'validation1' && res == 'V9'){
			$('.vmin1').val('01/01/1920');
			$('.vmax1').val(date);
			that.setState({vmin1:'01/01/1920',vmax1:date});
		}
		
		for (let i = 1; i <= 5; i++) {
			if(name == 'validation'+i && res == 'V9'){
				$('.vmin'+i).val('01/01/1920');
				$('.vmax'+i).val(date);
				that.setState({['vmin'+i]:'01/01/1920',['vmax'+i]:date});
			}else if(name == 'validation'+i && res == 'V11'){
				$('.vmin'+i).val(date);
				$('.vmax'+i).val('01/01/2050');
				that.setState({['vmax'+i]:'01/01/2050',['vmin'+i]:date});
			}else if(name == 'validation'+i && res == 'V12'){
				$('.vmin'+i).val('00:00:00');
				$('.vmax'+i).val('00:00:00');
				that.setState({['vmax'+i]:'00:00:00',['vmin'+i]:'00:00:00'});
			}else if(name == 'validation'+i && (res != 'V9' || res != 'V11' || res != 'V12')){
				that.setState({['vmin'+i]:'',['vmax'+i]:''});
			}
			
			/* if(name == 'validation'+i && (res == 'V1' || res == 'V2' || res == 'V10')){
				$('.vmin'+i).hide();
				$('.vmax'+i).hide();
			}else if(name == 'validation'+i && (res != 'V1' || res != 'V2' || res != 'V10')){
				$('.vmin'+i).show();
				$('.vmax'+i).show();
			} */
			
		}
		
		for (let i = 1; i <= 5; i++) {
			if(name == 'columnOption'+i && res == 'limited'){
				that.setState({['limited'+i]:true});
			}else if(name == 'columnOption'+i && res == 'free'){
				that.setState({['limited'+i]:false});
			}
		}
		
		
		if(name == 'numberOfColumn'){
			$('.column-input').css('display','none');
			$('.dropdown-col3').css('display','none');
			for(let i = 1; i <= res; i++){
				$('.column-'+i).css('display','block');
			}
			if(res == 3){
				$('.dropdown-col3').css('display','block');
			}
		}
		if(name == 'numberRows'){
			$('.row-input').css('display','none');
			for(let i = 1; i <= res; i++){
				$('.row-'+i).css('display','block');
			}
		}
		
		if(name == 'sameAllC2'){
			let c2 = $('.r1c2').val();
			let numberRows = this.state.numberRows;
			for(let i=2; i <= numberRows; i++ ){
				this.setState({['R'+i+'C2']:c2});
			}
			//this.setState({R2C2:c2,R3C2:c2});
			if($("input[name='sameAllC2']").prop("checked") == false){
				for(let i=2; i <= numberRows; i++ ){
					this.setState({['R'+i+'C2']:''});
				}
			}
		}
		
		if(name == 'sameAllC3' && this.state.numberOfColumn > 2){
			let c3 = $('.r1c3').val();
			let numberRows = this.state.numberRows;
			for(let i=2; i <= numberRows; i++ ){
				this.setState({['R'+i+'C3']:c3});
			}
			//this.setState({R2C3:c3,R3C3:c3});
			if($("input[name='sameAllC3']").prop("checked") == false){
				for(let i=2; i <= numberRows; i++ ){
					this.setState({['R'+i+'C3']:''});
				}
			}
		}
		
		
		if(name == 'personRole'){
			let trackingFacility = this.state.trackingFacility;
			let roleName = this.state.responsibleRole;
			let facilityData = this.state.facilityData;
			roleName.map((val, i) => {
				if(val === res){
					let persons = facilityData[trackingFacility][val];
					let personName = [];
					persons.map((name, i) => {
						name = name.split('|');
						personName.push(name[0]);
					})
					
					this.setState({persons,responsiblePerson:personName});
				}
			})
		}
		
		
		if(name == 'currentStatus'){
			this.setState({currentStatusChanged:true});
		}
		
		if(name == 'personName'){
			let persons = this.state.persons;
			let email = '';
			persons.map((name, i) => {
				name = name.split('|');
				if(name[0] == res){
					email = name[1];
				}
			})
			this.setState({personEmail:email});
		}
		
		if($("input[name='redoInfo']").prop("checked") == false){
			this.setState({redoInfo:'N'});
		}
		
		if($("input[name='markCompleted']").prop("checked") == false){
			this.setState({markCompleted:'N'});
		}
		
		if($("input[name='fabCompleted']").prop("checked") == false){
			this.setState({fabCompleted:'N'});
		}
		
		if($("input[name='allParts']").prop("checked") == false){
			this.setState({allParts:'N'});
		}
		
		
		if(name == 'redoOption'){
			if(res){
				$('#redoInfo').show();
			}else{
				$('#redoInfo').hide();
			}
		}
		
		if(name == 'fabFacility'){
			let facilityData = this.state.facilityData;
			let practitioners = [];
			if(res){
				practitioners = facilityData[res]['Practitioners'];
			}
			this.setState({practitioners:practitioners});
		}
    }
	
	buildMds=(mode)=>{
		let mdsDataName = this.state.mdsDataName;
		let mdsCompany = this.state.mdsCompany;
		let mdsFacility = this.state.mdsFacility;
		
		if(!mdsFacility && this.state.dataMode == 'Child'){
			mdsFacility = this.state.defaultLayoutFacility;
		}
		let mdsGroupId = this.state.mdsGroupId;
		//let Public = this.state.Public;
		//let chooseCompany = this.state.chooseCompany;
		let chooseTracking = this.state.chooseTracking;
		let allDelegate = this.state.allDelegate;
		let allTypes = this.state.allTypes;
		let allDeviceTypes = this.state.allDeviceTypes;
		let allVisitTypes = this.state.allVisitTypes;
		let fieldPrefix = this.state.fieldPrefix;
		
		if(mode != 'Update' && !fieldPrefix){
			fieldPrefix = mdsDataName;
		}
		
		let selectedSheet = this.state.selectedSheet;
        var  sheetName = 'Temp File';
        if(selectedSheet != '' && selectedSheet !== undefined){
			sheetName = selectedSheet;
		}
		
		let allData = this.state.allData;
		let existMds = false;
		let txt = '';
		if(this.state.dataMode == 'Child' && mode != 'Update'){
			
			allData.map((rows, i) => {
				if(existMds){
					return false;
				}
				if(!rows[129] || !rows[130] || !rows[131]){
					txt = rows[4] ? rows[4] : rows[5];
					$("#fId-"+rows[0]).trigger("click");
					existMds = true;
				}
			});
			
		}
		
		if(existMds){
			$('#createBuild').modal('hide');
			setTimeout(function(){
				alert('The field "'+txt+'" required to add to Parent MDS!');
				$('#sidebar-wrapper-right').animate({ scrollTop: 0}, 1000);
			}, 1000);
			return false;
		}
		
		
		let updateRows = [];
		
		let row2 = {
			"range": sheetName+'!DL2',
			"majorDimension": "ROWS",
			"values": 
			[
				[mdsCompany]
			]
		};
		
		let row3 = {
			"range": sheetName+'!DQ2',
			"majorDimension": "ROWS",
			"values": 
			[
				[mdsFacility]
			]
		};
		
		let row4 = {
			"range": sheetName+'!DR2',
			"majorDimension": "ROWS",
			"values": 
			[
				[mdsGroupId]
			]
		};
		
		/* let row13 = {
			"range": sheetName+'!DH2',
			"majorDimension": "ROWS",
			"values": 
			[
				[Public]
			]
		};
		
		let row14 = {
			"range": sheetName+'!DL2',
			"majorDimension": "ROWS",
			"values": 
			[
				[chooseTracking]
			]
		}; */

		let row5 = {
			"range": sheetName+'!DP2',
			"majorDimension": "ROWS",
			"values": 
			[
				[allDelegate]
			]
		};
		
		let row6 = {
			"range": sheetName+'!CO2:CR2',
			"majorDimension": "ROWS",
			"values": 
			[
				[allTypes,'',allDeviceTypes,allVisitTypes]
			]
		};
		
		let version = allData[0][59];
			
		if(sheetName != 'Temp File' && version != '' && mode != 'Update'){
			version = parseFloat(version) + parseFloat(0.01);
		}else{
			version = 1.01;
		}
		
		let row12 = {
			"range": sheetName+'!BH2',
			"majorDimension": "ROWS",
			"values": 
			[
				[version]
			]
		};
		
		updateRows.push(row2);
		updateRows.push(row3);
		updateRows.push(row4);
		updateRows.push(row5);
		updateRows.push(row6);
		updateRows.push(row12);
		//updateRows.push(row13);
		//updateRows.push(row14);
		//updateRows.push(row15);
		let dates=[];
		if(mode != 'Update'){
			let uld = ulid();
			let rowId='';
			let prefixId='';
			allData.map((rowDatas, i) => {
				rowId = (i+2);
				prefixId = (i+1);
				let prefix = fieldPrefix+'-'+prefixId;
				let row7 = {
					"range": sheetName+'!A'+rowId,
					"majorDimension": "ROWS",
					"values": 
					[
						[prefix]
					]
				};
				let newPos = rowDatas[16].replace(rowDatas[0],prefix);
				
				let row8 = {
					"range": sheetName+'!Q'+rowId,
					"majorDimension": "ROWS",
					"values": 
					[
						[newPos]
					]
				};
				
				let row9 = {
					"range": sheetName+'!CN'+rowId,
					"majorDimension": "ROWS",
					"values": 
					[
						[uld]
					]
				};
				updateRows.push(row9);
				
				if(this.state.dataMode == 'MDS'){
					/* let row9 = {
						"range": sheetName+'!CN'+rowId,
						"majorDimension": "ROWS",
						"values": 
						[
							[uld]
						]
					};
					updateRows.push(row9); */
				
					let row1 = {
						"range": sheetName+'!DO'+rowId,
						"majorDimension": "ROWS",
						"values": 
						[
							[mdsDataName]
						]
					};
					updateRows.push(row1);
				}
				
				updateRows.push(row7)
				updateRows.push(row8)
				
				
				let date = new Date(rowDatas[98]);
				if(date != 'Invalid Date'){
					dates.push(date);
				}
				
			});
			
		}else{
			let rowId='';
			let prefixId='';
			
			allData.map((rowDatas, i) => {
				rowId = (i+2);
				prefixId = (i+1);
				let prefix = fieldPrefix+'-'+prefixId;
				let row7 = {
					"range": sheetName+'!A'+rowId,
					"majorDimension": "ROWS",
					"values": 
					[
						[prefix]
					]
				};
				let newPos = rowDatas[16].replace(rowDatas[0],prefix);
				
				let row8 = {
					"range": sheetName+'!Q'+rowId,
					"majorDimension": "ROWS",
					"values": 
					[
						[newPos]
					]
				};
				
				if(this.state.dataMode == 'MDS'){
					let row1 = {
						"range": sheetName+'!DO'+rowId,
						"majorDimension": "ROWS",
						"values": 
						[
							[mdsDataName]
						]
					};
					updateRows.push(row1);
				}
				
				updateRows.push(row7)
				updateRows.push(row8)
				
				let date = new Date(rowDatas[98]);
				if(date != 'Invalid Date'){
					dates.push(date);
				}
			
			});
		}
		
		let maximumDate = new Date(Math.max.apply(null, dates));
		let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
		let totalFields = allData.length;
		
		let apiName = 'MDS_'+ mdsDataName +'_'+lastUpDate+'_'+totalFields;
		if(this.state.dataMode == 'Child'){
			apiName = 'DefaultLayout_'+ mdsDataName +'_'+lastUpDate+'_'+totalFields;
		}
		
		let row11 = {
			"range": sheetName+'!DT2',
			"majorDimension": "ROWS",
			"values": 
			[
				[apiName]
			]
		};
		
		updateRows.push(row11);
		
		var params = {
			spreadsheetId:  this.state.spreadsheetId,
		}
		let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateRows, 
        };
		let that = this;
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
			//alert('Update Data Successfully!');
			if(mode == 'Update'){
				that.newMdsRename();
				alert('Update Data Successfully!');
			}else{
				$('#createBuild').modal('hide');
				that.newMdsCreation();
			}
            that.getMdsData();
			that.setState({fieldPrefix:''});
			
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        });
	}
	
	buildLayout=(mode)=>{
		let spreadsheetId = this.state.spreadsheetId;
		let selectedSheetId = this.state.selectedSheetId;
		let selectedSheet = this.state.selectedSheet;
		let layoutSpreadsheetId = this.state.layoutSpreadsheetId;
		let mdsDataName = this.state.mdsDataName;
		
		let params = {
            spreadsheetId: spreadsheetId,
            sheetId: selectedSheetId,
        };

		let copySheetToAnotherSpreadsheetRequestBody = {
            destinationSpreadsheetId:layoutSpreadsheetId,
        };
		
        let that=this;
        let request = gapi.client.sheets.spreadsheets.sheets.copyTo(params, copySheetToAnotherSpreadsheetRequestBody);
		request.then(
			response => {
				let params1 =  {
					spreadsheetId:layoutSpreadsheetId,
				};
				let getRequest = gapi.client.sheets.spreadsheets.get(params1);
				getRequest.then(function(response) {
					let length = response.result.sheets.length;
					let lastSheetId = response.result.sheets[length-1].properties.sheetId;
					
					let requests ={
						"requests": [
							{
							  "updateSheetProperties": {
								"properties": {
								  "sheetId": lastSheetId,
								  "title": mdsDataName,
								},
								"fields": "title"
							  }
							}
						]
					}
					let renameRequest = gapi.client.sheets.spreadsheets.batchUpdate(params1,requests);
					renameRequest.then(function(response) {
						$('#createBuild').modal('hide');
						that.updateLayout(layoutSpreadsheetId,mdsDataName,'layout');
					}, function(reason) {
						alert(reason.result.error.message);
					});
				}, function(reason) {
					alert(reason.result.error.message);
				});
			}, function(reason) {
				alert(reason.result.error.message);
		});
	}
	
	buildDefautLayout=()=>{
		let spreadsheetId = this.state.spreadsheetId;
		let selectedSheetId = this.state.selectedSheetId;
		let selectedSheet = this.state.selectedSheet;
		let mdsDataName = this.state.mdsDataName;
		let Public = this.state.Public;
		let privateOwner = this.state.privateOwner;
		
		if(Public == 'No' && ( privateOwner == '' || privateOwner == undefined )){
			$('#createBuild').modal('hide');
			
			setTimeout(function(){
				alert('Please select Private Layout Owner!');
			}, 1000);
			
			return false;
		}
		
		let params = {
            spreadsheetId: spreadsheetId,
            sheetId: selectedSheetId,
        };

		let copySheetToAnotherSpreadsheetRequestBody = {
            destinationSpreadsheetId:spreadsheetId,
        };
		
        let that=this;
        let request = gapi.client.sheets.spreadsheets.sheets.copyTo(params, copySheetToAnotherSpreadsheetRequestBody);
		request.then(
			response => {
				let params1 =  {
					spreadsheetId:spreadsheetId,
				};
				let getRequest = gapi.client.sheets.spreadsheets.get(params1);
				getRequest.then(function(response) {
					let length = response.result.sheets.length;
					let lastSheetId = response.result.sheets[length-1].properties.sheetId;
					
					let requests ={
						"requests": [
							{
							  "updateSheetProperties": {
								"properties": {
								  "sheetId": lastSheetId,
								  "title": mdsDataName,
								},
								"fields": "title"
							  }
							}
						]
					}
					let renameRequest = gapi.client.sheets.spreadsheets.batchUpdate(params1,requests);
					renameRequest.then(function(response) {
						$('#createBuild').modal('hide');
						that.updateLayout(spreadsheetId,mdsDataName,'default');
					}, function(reason) {
						alert(reason.result.error.message);
					});
				}, function(reason) {
					alert(reason.result.error.message);
				});
			}, function(reason) {
				alert(reason.result.error.message);
		});
	}
	
	newMdsRename=()=>{
		let mdsDataName = this.state.mdsDataName;
		let selectedSheetId = this.state.selectedSheetId;
		let dataMode = this.state.dataMode;
		let params =  {
			spreadsheetId: this.state.spreadsheetId,
		};
		let requests ={
			"requests": [
				{
				  "updateSheetProperties": {
					"properties": {
					  "sheetId": selectedSheetId,
					  "title": mdsDataName,
					},
					"fields": "title"
				  }
				}
			]
		}
		let that = this;
		let renameRequest = gapi.client.sheets.spreadsheets.batchUpdate(params,requests);
		renameRequest.then(function(response) {
			that.setState({selectedSheet:mdsDataName});
			setTimeout(function(){
				if(dataMode == 'MDS'){
					that.saveMdsJsonDataMongodb(mdsDataName, 'mongo', 'mds');
				}else if(dataMode == 'Child'){
					that.saveMdsJsonDataMongodb(mdsDataName, 'mongo', 'child');
				}
				that.getMdsSheets();
				//window.location.reload();
			}, 2000);
		}, function(reason) {
			alert(reason.result.error.message);
		});
	}
	
	newMdsCreation=()=>{
		let mdsDataName = this.state.mdsDataName;
		let dataMode = this.state.dataMode;
		let spreadsheetId = this.state.spreadsheetId;
		let selectedSheetId = this.state.selectedSheetId;
		let params = {
            spreadsheetId: spreadsheetId,
            sheetId:selectedSheetId,
        };
		
		let mdsFiles = [];
		
		if(dataMode == 'MDS'){
			spreadsheetId = this.state.mdsSpreadsheetId;
		}else{
			spreadsheetId = this.state.childSpreadsheetId;
			
			let allData = this.state.allData;
			
			//console.log('allData->',allData);
			
			allData.map((rows, i) => {
				mdsFiles.push(rows[129]); 
			}); 
		}
		var mdsGuid = mdsFiles.filter(function(elem, index, self) {
			  return index === self.indexOf(elem);
		});
		//console.log('mdsFiles->',mdsFiles);
		
		this.updateMdsHasFieldSet(mdsGuid);

        let copySheetToAnotherSpreadsheetRequestBody = {
            destinationSpreadsheetId: spreadsheetId,
        };
		
        let that=this;
        let request = gapi.client.sheets.spreadsheets.sheets.copyTo(params, copySheetToAnotherSpreadsheetRequestBody);
		request.then(
			response => {
				
				let params1 =  {
					spreadsheetId: spreadsheetId,
				};
				let getRequest = gapi.client.sheets.spreadsheets.get(params1);
				getRequest.then(function(response) {
					
					let length = response.result.sheets.length;
					let lastSheetId = response.result.sheets[length-1].properties.sheetId;
					let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
					let requests ={
						"requests": [
							{
							  "updateSheetProperties": {
								"properties": {
								  "sheetId": lastSheetId,
								  "title": mdsDataName,
								},
								"fields": "title"
							  }
							}
						]
					}
					let renameRequest = gapi.client.sheets.spreadsheets.batchUpdate(params1,requests);
					renameRequest.then(function(response) {
						if(dataMode == 'MDS'){
							that.saveMdsJsonDataMongodb(mdsDataName, 'mongo', 'mds');
							alert('MDS File created successfully!');
						}else{
							that.saveMdsJsonDataMongodb(mdsDataName, 'mongo', 'child');
							alert('Default Layout created successfully!');
						}
						
						//$('#createBuild').modal('hide');
						$("#wrapper").removeClass('toggled-2');
						$(".save-as").val('childMds');
						that.getMdsSheets();
						
						/* if(dataMode == 'MDS'){
							spreadsheetId = config.mdsSpreadsheetId;
						}
						that.setState({selectedSheet:mdsDataName,spreadsheetId});
						setTimeout(function(){
							that.getMdsData();
						}, 2000); */

					}, function(reason) {
						alert(reason.result.error.message);
					});
				}, function(reason) {
					alert(reason.result.error.message);
				});

			}, function(reason) {
				alert(reason.result.error.message);
		});
	}
	
	updateMdsHasFieldSet=(mdsGuid)=>{

		let mdsSheetGuidName = this.state.mdsSheetGuidName;
		let spreadsheetId = this.state.mdsSpreadsheetId;
		let vl = 'Yes';
		if(mdsGuid){
			let updateRows = [];
			mdsGuid.map((guid,i)=>{
				let sheetName = mdsSheetGuidName.get(guid);
				if(sheetName){
					let row = {
						"range": sheetName+'!CT2',
						"majorDimension": "ROWS",
						"values": 
						[
							[vl]
						]
					};
					updateRows.push(row)
				}
			});
			
			var params = {
				spreadsheetId: spreadsheetId,
			}
			let batchUpdateValuesRequestBody = {
				valueInputOption: 'USER_ENTERED',
				data: updateRows, 
			};
			let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
			updateRequest.then(function(response) {

			}, function(reason) {
				console.error('error: ' + reason.result.error.message);
			});
		}
	}
	
	saveTempData=(clear)=>{
		let spreadsheetId = this.state.spreadsheetId;
		let selectedSheetId = this.state.selectedSheetId;
		let params = {
            spreadsheetId: spreadsheetId,
            sheetId: selectedSheetId,
        };
		
		let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
		
        let mdsDataName = this.state.mdsDataName;
		let tempName = "Temp-"+currentDate;
		if(mdsDataName != '' && mdsDataName !== undefined){
			tempName = mdsDataName+'-'+currentDate;
		}
		
        let copySheetToAnotherSpreadsheetRequestBody = {
            destinationSpreadsheetId:spreadsheetId,
        };
        let that=this;
        let request = gapi.client.sheets.spreadsheets.sheets.copyTo(params, copySheetToAnotherSpreadsheetRequestBody);
		request.then(
			response => {
				let params1 =  {
					spreadsheetId:spreadsheetId,
				};
				let getRequest = gapi.client.sheets.spreadsheets.get(params1);
				getRequest.then(function(response) {
					let length = response.result.sheets.length;
					let lastSheetId = response.result.sheets[length-1].properties.sheetId;
					
					let requests ={
						"requests": [
							{
							  "updateSheetProperties": {
								"properties": {
								  "sheetId": lastSheetId,
								  "title": tempName,
								},
								"fields": "title"
							  }
							}
						]
					}
					let renameRequest = gapi.client.sheets.spreadsheets.batchUpdate(params1,requests);
					renameRequest.then(function(response) {
						let msg = 'Data Save & Clear Successfully';
						if(clear == 'not'){
							alert('Data Save Successfully!');
						}else{
							that.clearTempData(msg);
						}
					}, function(reason) {
						alert(reason.result.error.message);
					});
				}, function(reason) {
					alert(reason.result.error.message);
				});

			}, function(reason) {
				alert(reason.result.error.message);
		});
	}
	
	clearTempData=(msg=null)=>{
		let spreadsheetId = this.state.spreadsheetId;
		let sheetId = this.state.tempSheetId;
		let params =  {
            spreadsheetId:spreadsheetId,
        };
		//let selectedSheetId = this.state.selectedSheetId;
		let that = this;
		
        let deleteRow = {
            "requests": [
            {
              "deleteDimension": {
                "range": {
                  "sheetId": sheetId,
                  "dimension": "ROWS",
                  "startIndex": 1,
                  "endIndex": 10000
                }
              }
            }]
        }
        let request = gapi.client.sheets.spreadsheets.batchUpdate(params, deleteRow);
        request.then(function(response) {
            if(msg){
				alert(msg);
			}else{
				alert('Data Clear Successfully!');
			}
			
			that.getMdsData();        
        }, function(reason) {
            //alert(reason.result.error.message);
        });
		
		$('.load-select-mds').val('');
		$('.load-select-child').val('');
		that.setState({allData:'',selectedSheet:''});
		//that.setState({allData:''});
	}
	
	clearLayoutData=()=>{
		$("#loadFeildSet option:selected").prop("selected", false);
		$("#loadLayout option:selected").prop("selected", false);
		this.setState({allData:'',selectedSheet:''});
	}
	
	clearTempDataOnLoad=()=>{
		let params =  {
            spreadsheetId: this.state.childSpreadsheetId,
        };
		let that = this;
		let deleteRow = {
            "requests": [
            {
              "deleteDimension": {
                "range": {
                  "sheetId": this.state.tempSheetId,
                  "dimension": "ROWS",
                  "startIndex": 1,
                  "endIndex": 10000
                }
              }
            }]
        }
        let request = gapi.client.sheets.spreadsheets.batchUpdate(params, deleteRow);
        request.then(function(response) {
           that.getMdsData();        
        }, function(reason) {
            //alert(reason.result.error.message);
        });
	}
	
	clearUpdateData=(msg=null)=>{
		let selectedSheet = this.state.selectedSheet;
		let allData = this.state.allData;
        var sheetName = 'Temp File';
        if(selectedSheet != '' && selectedSheet !== undefined){
			sheetName = selectedSheet;
		}
		let params =  {
            spreadsheetId: this.state.spreadsheetId,
        };
		
		let rowId ='';
		let updateData=[];
		allData.map((rowDatas,i)=>{
			rowId = (i+2);
			let row = {
				range: sheetName+'!A'+rowId+':EO'+rowId,
				values: [ rowDatas ]
			};
			updateData.push(row)
		});
		
		let that = this;
		let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
		
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
			if(msg == 'pos'){
				that.updateData(msg);
			}else{
				that.updateData(msg=true);
			}
        }, function(reason) {
            alert(reason.result.error.message);
        });
	}
	
	clearUpdateDataTemp=(msg=null)=>{
		let selectedSheetId = this.state.selectedSheetId;
		let selectedSheet = this.state.selectedSheet;
        var sheetName = 'Temp File';
        if(selectedSheet != '' && selectedSheet !== undefined){
			sheetName = selectedSheet;
		}
		let params =  {
            spreadsheetId: this.state.spreadsheetId,
        };
		let that = this;
		
        let deleteRow = {
            "requests": [
            {
              "deleteDimension": {
                "range": {
                  "sheetId": selectedSheetId,
                  "dimension": "ROWS",
                  "startIndex": 1,
                  "endIndex": 10000
                }
              }
            }]
        }
        let request = gapi.client.sheets.spreadsheets.batchUpdate(params, deleteRow);
        request.then(function(response) {
            let allData = that.state.allData;
			
			/* if(allData.length > 1){
				allData.map((field,i)=>{
					allData[i][128] = '';
					allData[i][141] = '';
				});
			} */
			
			var params = {
				spreadsheetId:  that.state.spreadsheetId,
				range:sheetName+'!A:EO', 
				valueInputOption: "RAW", 
				insertDataOption: "INSERT_ROWS",
				resource:{
					"values":allData,
					"majorDimension": "ROWS"
				  }
			};
			var request2 = gapi.client.sheets.spreadsheets.values.append(params);
			request2.then(function(response) {
				that.updateData(msg=true);
			}, function(reason) {
				console.error('error: ' + reason.result.error.message);
			});         
        }, function(reason) {
            alert(reason.result.error.message);
        });
	}
	
	clearLastData=()=>{
		let selectedSheetId = this.state.selectedSheetId;
		let selectedSheet = this.state.selectedSheet;
        var sheetName = 'Temp File';
        if(selectedSheet != '' && selectedSheet !== undefined){
			sheetName = selectedSheet;
		}
		let params =  {
            spreadsheetId: this.state.spreadsheetId,
        };
		let that = this;
		let dataLength = this.state.dataLength;
		let deleteRow = {
            "requests": [
            {
              "deleteDimension": {
                "range": {
                  "sheetId": selectedSheetId,
                  "dimension": "ROWS",
                  "startIndex": dataLength,
                  "endIndex": 10000
                }
              }
            }]
        }
		
		let request = gapi.client.sheets.spreadsheets.batchUpdate(params, deleteRow);
        request.then(function(response) {
            that.getMdsData();
			$('.btn-undo-data').hide();			
        }, function(reason) {
            alert(reason.result.error.message);
        });
	}
	
	onSortEnd = ({oldIndex, newIndex}) => {
		this.setState(({allData}) => ({
		    allData: arrayMove(allData, oldIndex, newIndex),
		}));
		//this.clearUpdateData();
	};
	
	onSortEndCopyMds = ({oldIndex, newIndex}) => {
		this.setState(({copyAllData}) => ({
		    copyAllData: arrayMove(copyAllData, oldIndex, newIndex),
		}));
		
		let that = this;
		setTimeout(function(){
			that.copyMdsUpdatePostion();
		}, 2000);
	};
	
	onSortEndMds = ({oldIndex, newIndex}) => {
		this.setState(({mdsSheetNames}) => ({
		    mdsSheetNames: arrayMove(mdsSheetNames, oldIndex, newIndex),
		}));
		
		let that = this;
		setTimeout(function(){
			let mdsSheetNames = that.state.mdsSheetNames;
			let sheetId = mdsSheetNames[newIndex].sheetId;
			let newPos = newIndex+1;
			if(newIndex > oldIndex){
				newPos = newIndex+2;
			}
			that.updateSheetproperty(that.state.mdsSpreadsheetId,sheetId,newPos);
		}, 2000);
		
	};
	
	onSortEndChild = ({oldIndex, newIndex}) => {
		this.setState(({childSheetNames}) => ({
		    childSheetNames: arrayMove(childSheetNames, oldIndex, newIndex),
		}));
		
		let that = this;
		setTimeout(function(){
			let childSheetNames = that.state.childSheetNames;
			let sheetId = childSheetNames[newIndex].sheetId;
			let newPos = newIndex+1;
			if(newIndex > oldIndex){
				newPos = newIndex+2;
			}
			that.updateSheetproperty(that.state.childSpreadsheetId,sheetId,newPos);
		}, 2000);
		
	};
	
	updateSheetproperty=(spreadsheetId,sheetId,newPos)=>{

		let params =  {
            spreadsheetId: spreadsheetId,
        };

        let reqs = {
            "requests": [
            {
			  "updateSheetProperties": {
				'properties': {
					'sheetId': sheetId,
					'index': newPos
				},
				'fields': 'index',
			  }
			}]
        }
		
		let request = gapi.client.sheets.spreadsheets.batchUpdate(params,reqs);
        request.then(function(response) {
			//alert('Index updated Successfully!');
		}, function(reason) {
            alert(reason.result.error.message);
        });
	}
	
	classTypes(comment){
        let className = 'question';
        if(comment[5].toLowerCase() == 'parent header'){
            className = 'parent-header';
        }else if(comment[5].toLowerCase() == 'child header 1'){
			className = 'builder-header-1 ';
		}else if(comment[5].toLowerCase() == 'child header 2'){
			className = 'builder-header-2 ';
		}else if(comment[5].toLowerCase() == 'child header 3'){
			className = 'builder-header-3 ';
		}
		return className;
    }
	
	copyField(data){
		let allData = this.state.allData;
		let selectedSheet = this.state.selectedSheet;
        var  sheetName = 'Temp File';
        if(selectedSheet != '' && selectedSheet !== undefined){
			sheetName = selectedSheet;
		}
		let fieldPrefix = this.state.fieldPrefix;
		let suffix = this.state.suffix;
		let IncludeCopy = this.state.IncludeCopy;
		let id = allData.length+1
		let ttevalId = fieldPrefix+'-'+id;
		let position = id;
		let optionType = data[5];
		let guids_dn = ulid();
		let inputText = data[4] ? data[4] : '';
		if(IncludeCopy == 'Yes'){
			inputText = data[4] ? data[4]+''+suffix : '';
		}
		let answers = data[6];
		let outputText = data[9];
		let tags = data[13];
		let note = data[12];
		let numberOfColumn = parseInt(data[136]);
		let numberOfRow = parseInt(data[137]);
		let buttonlabel = data[40];
		let label = data[41];
		let validation = data[48];
		let min = data[50];
		let max = data[51];
		let step = data[36];
		let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
		var params = {
			spreadsheetId:  this.state.spreadsheetId,
			range:sheetName+'!A:EI', 
			valueInputOption: "RAW", 
			insertDataOption: "INSERT_ROWS",
			resource:{
				"values": 
				[
					[ ttevalId,'','','',inputText,optionType,answers,'','',outputText,position,'',note,tags,'Y','N','','','','','','','','','','','','','','','','','Y','N','','',step,'N','','',buttonlabel,label,'','','','','','',validation,'',min,max,'','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','',currentDate,'','','','','','','','','','','','','Y','','','','','',guids_dn,'','','','','','','','','','','','','','','','','','',numberOfColumn,numberOfRow,'' ]
				],
				"majorDimension": "ROWS"
			  }
		};
		let that = this;
		var request = gapi.client.sheets.spreadsheets.values.append(params);
		request.then(function(response) {
			that.getMdsData(); 
			setTimeout(function(){
				that.updateData();
				$(".page-builder").animate({ scrollTop: $('.page-builder').prop("scrollHeight")}, 1000);
			}, 2000);
		}, function(reason) {
			console.error('error: ' + reason.result.error.message);
		});
	}
	
	copyGroupFields(data){
		let allData = this.state.allData;
		let selectedSheet = this.state.selectedSheet;
		let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
        var  sheetName = 'Temp File';
        if(selectedSheet != '' && selectedSheet !== undefined){
			sheetName = selectedSheet;
		}
		let suffix = this.state.suffix;
		let IncludeCopy = this.state.IncludeCopy;
		let row = Object.assign([], data);
		let guid = ulid();
		let pos = row[10];
		let type = row[5];
		row[117] = guid;
		row[98] = currentDate;
		if(row[4] != '' && IncludeCopy == 'Yes'){
			row[4] = row[4]+''+suffix;
		}
		
		let dataRows = JSON.parse(JSON.stringify(allData));
		let newSetsArr = [row];
		for (var i = pos; i < dataRows.length; i++) {
			let guids_dn = ulid();
			if(i > pos && (dataRows[i][5] == type || (type.toLowerCase() == 'child header 2' && dataRows[i][5].toLowerCase() == 'child header 1') || (type.toLowerCase() == 'child header 3' && (dataRows[i][5].toLowerCase() == 'child header 2' || dataRows[i][5].toLowerCase() == 'child header 1'))) ){
				break;
			}
			if(dataRows[i][4] != '' && IncludeCopy == 'Yes'){
				dataRows[i][4] = dataRows[i][4]+''+suffix;
			}
			
			dataRows[i][7] = '';
			dataRows[i][8] = '';
			//dataRows[i][39] = '';
			dataRows[i][71] = '';
			dataRows[i][91] = '';
			dataRows[i][92] = '';
			dataRows[i][93] = '';
			dataRows[i][94] = '';
			dataRows[i][95] = '';
			
			dataRows[i][98] = currentDate;
			dataRows[i][117] = guids_dn;
			
			dataRows[i][124] = '';
			dataRows[i][125] = '';
			dataRows[i][126] = '';
			dataRows[i][127] = '';
			dataRows[i][129] = '';
			dataRows[i][130] = '';
			dataRows[i][131] = '';
			
			newSetsArr.push(dataRows[i]);  
		}
		
		let length = allData.length+1
		
		this.setState({dataLength:length});
		var params = {
			spreadsheetId:this.state.spreadsheetId,
			range:sheetName+'!A:EB', 
			valueInputOption: "RAW", 
			insertDataOption: "INSERT_ROWS",
			resource:{
				"values":newSetsArr,
				"majorDimension": "ROWS"
			}
		};
		let that = this;
		var request = gapi.client.sheets.spreadsheets.values.append(params);
		request.then(function(response) {
			that.getMdsData();
			setTimeout(function(){
				that.updateData();
				$(".page-builder").animate({ scrollTop: $('.page-builder').prop("scrollHeight")}, 1000);
				$('.btn-undo-data').show();
			}, 3000);
			
		}, function(reason) {
			console.error('error: ' + reason.result.error.message);
		});

	}
	
	editField(filed,ftype,data){
		$("#wrapper").addClass('toggled-2');
		$(".fadeInRight").addClass('is-open');
		$(".fadeInRight").removeClass('is-closed');
		let showAddMds = true;
		//console.log('data->',data);
		let type = ftype.toLowerCase();
		let changeQueInpText = data[4];
		let changeAnsInpText = data[6];
		let changeOutInpText = data[9];
		let valType = data[48];
		let minValue = data[50];
		let maxValue = data[51];
		let path = data[99];
		let addTagsText = data[13];
		let fieldIdentifier = data[61];
		//let pfb = data[111] ? data[111] : 'Y';
		//let chooseTracking = data[125] ? data[125] : '';
		//let trackingName = data[125] ? data[125].split(',') : [];
		let companyName = data[115] ? data[115] : '';
		let dataPersistence = data[110] ? data[110] : '';
		let exPersistencekey = data[42] ? data[42] : '';
		let persistencekey = data[39] ? data[39] : '';
		let asGroup = data[127] ? data[127] : '';
		let websiteLink = data[78] ? data[78] : '';
		let responseAction = data[44] ? data[44] : '';
		let calculationType = data[113] ? data[113] : '';
		let labelValue = data[41] ? data[41] : '';
		let dataAgg = data[3] ? data[3] : '';
		let styleQ4 = data[138] ? data[138] : 'checkbox';
		let analysisVideo = data[59] ? data[59] : '';
		let reportPdf = data[60] ? data[60] : '';
		let autoPopulateTags = data[106] ? data[106] : '';
		let autoPopulate = '';
		if(autoPopulateTags){
			autoPopulate = 'Y';
		}
		
		let fieldDeactivated = data[60] ? data[60] : 'N';
		
		
		let that = this;
		
		//console.log('data->',data);
		if(data[129] && data[130] && data[131]){
			showAddMds = false;
		}
		
		this.getRequiredAndOptionalFields(filed);
		
		setTimeout(function(){
			$('#selectType option').hide();
		}, 1000);
		
		setTimeout(function(){
			if(type == 'q1' || type == 'q2' || type == 'q3' || type == 'q11' || type == 'q11b' || type == 'smart' || type == '12'){
				$('#selectType option[value="q1"],option[value="q2"],option[value="q3"],option[value="q11"],option[value="q11b"],option[value="smart"],option[value="q12"]').show();
			}
			
			if(type == 'q5' || type == 'q10'){
				$('#selectType option[value="q5"],option[value="q10"]').show();
			}
		}, 2000);

		this.setState({buttonLabel:'',leftButtonTxt:'',rightButtonTxt:'',sacale1:'',sacale2:'',sacale3:'',sacale4:'',sacale5:'',numberRows:'1',numberOfColumn:'',numberOfGrid:'',arraignment:'provided',lowestText:'',highestText:'',validation1:'V1',validation2:'V1',validation3:'V1',validation4:'V1',validation5:'V1',vmin1:'',vmin2:'',vmin3:'',vmin4:'',vmin5:'',vmax1:'',vmax2:'',vmax3:'',vmax4:'',vmax5:'',ogAutoTagsArray:[],requiredFields:new Map(),optionalFields:new Map()});
		
		for(let i = 1; i <= 20; i++){
			let variable = 'row'+i;
			let variable2 = 'C'+i;
			let variable3 = 'R'+i;
			let variable4 = 'weight'+i;
			
			for(let j = 1; j <= 5; j++){
				that.setState({['R'+i+'C'+j]:''});
			}
			
			that.setState({[variable]:'',[variable2]:'',[variable3]:'',[variable4]:''});
		}
		
		for(let i = 1; i <= 5; i++){
			this.setState({['columnOption'+i]:'free',['Option'+i]:'',['limited'+i]:false});
		}
		
		setTimeout(function(){
			$('.column-input').css('display','none');
			$('.row-input').css('display','none');
			$('.row-1').css('display','block');
			$('.column-1').css('display','block');
			if(type == 'weighted'){
				$('.row-2').css('display','block');
			}
			if(type == 'grid-dropdown'){
				$('.column-2').css('display','block');
			}
		}, 1000);
		
		if(type == 'parent header'){
			asGroup = data[126];
		}
		
		if(type == 'slider' && labelValue){
			let str = labelValue.split(',');
			let sliderMinValue = data[50];
			let sliderMaxValue = data[51];
			let sliderStepValue = data[36];
			this.setState({sliderMinText:str[0],sliderMaxText:str[1],sliderMinValue,sliderMaxValue,sliderStepValue});
		}
		
		if(type == 'smart' && changeAnsInpText){
			let str = changeAnsInpText.split(',');
			let noneAbove = ''
			if(str.indexOf('None of the Above') > -1){
				noneAbove = 'None of the Above'
			}
			this.setState({noneAbove});
			changeAnsInpText = changeAnsInpText.replace(',None of the Above', '');
		}
		
		if(typeof data[12] !== 'undefined' && data[12] !== ''){
			let addNoteText = data[12];
			let imgUrl  = addNoteText;
			const html = `<p>`+imgUrl+`</p>`;
			const contentBlock = htmlToDraft(html);

			if (contentBlock) {
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
				const editorState = EditorState.createWithContent(contentState);
				this.setState({
				  editorState,editorSourceHTML:addNoteText
				});
			}
			//this.setState({noteColor:'4px solid #9900ff',noteText:'Original Note',addNoteText1:imgUrl,addNoteText:addNoteText,});
		}else{
			this.setState({editorState:'',editorSourceHTML:''});
		}
		
		let changeQueType = false;
		let changeAnsOpt = false;
		let changeRadioPlus = false;
		let changeOneRadioPlus = false;
		let changeOutOpt = false;
		let apiVideo = false;
		let changeWebsite = false;
		let changeSliderText = false;
		let changeLaunchpad = false;
		let changeCalculation = false;
		let changeBasicGrid = false;
		let changeBasicGrid2 = false;
		let changeGridOption = false;
		let changeGridDropdown = false;
		let changeGrid5 = false;
		let changeLikert = false;
		let changeSmart = false;
		let changeGridCheck = false;
		let changeWeighted = false;
		let changeWcalculation = false;
		let changebuttonLabel = false;
		let changeinlineVideoLink = false;
		//let showCompany = true;
		let showStyleQ4 = false;
		
		let showImageUpload = false;
		
		let hasPH = true;
		/* if(pfb == 'Y'){
			showCompany = false;
		} */
		if(type != 'parent header' && type != 'child header 1' && type != 'child header 2' && type != 'child header 3' && type != 'boxbegin'){
			changeQueType = true;
			changeOutOpt = true;
			hasPH = false;
		}
		
		if(type == 'q12'){
			changeRadioPlus = true;
		}
		
		if(type == 'apivideo'){
			apiVideo = true;
		}
		
		if(type == 'q4'){
			showStyleQ4 = true;
		}
		
		if(type == 'q13'){
			changeOneRadioPlus = true;
		}
		
		if(type == 'q1' || type == 'q2' || type == 'q3' || type == 'q4' || type == 'q11' || type == 'q11b' || type == 'q11w' || type == 'q11f' || type == 'gridcheck' || type == 'smart'){
			changeAnsOpt = true;
		}
		
		if(valType == 'V3' || valType == 'V4' || valType == 'V5' || valType == 'V6' || valType == 'V9' || valType == 'V11'  || valType == 'V12' || valType == 'V13'){
			
			let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).split(',')[0];
			this.setState({showMinMax:true,minValue:minValue,maxValue:maxValue});
			
			if(!minValue && !maxValue){
				if(valType == 'V9'){
					this.setState({minValue:'01/01/1920',maxValue:date});
				}else if(valType == 'V11'){
					this.setState({maxValue:'01/01/2050',minValue:date});
				}else if(valType == 'V12' || valType == 'V13'){
					this.setState({maxValue:'00:00:00',minValue:'00:00:00'});
				}
			}

		}else if(valType == 'V1' || valType == 'V2' || valType == 'V7' || valType == 'V8' || valType == 'V10'){
			this.setState({showMinMax:false,maxValue:'',minValue:''});
		}
		
		let showInlineVideo = false;
		
		if(type == 'image'){
			showImageUpload = true;
			//showInlineVideo = false;
		}
		if(type == 'link'){
			changeWebsite = true;
		}
		
		if(type == 'slider'){
			changeSliderText = true;
		}
		
		if(type == 'q12' || type == 'q13'){
			let buttontxt = data[40].split(',');
			if(buttontxt[0]){
				this.setState({leftButtonTxt:buttontxt[0]});
			}
			if(buttontxt[1]){
				this.setState({rightButtonTxt:buttontxt[1]});
			}
			if(buttontxt[2]){
				this.setState({buttonLabel:buttontxt[2]});
			}
		}
		
		if(type == 'launchpad'){
			changeLaunchpad = true;
			let launchpadType = data[138];
			let ogAutoTagsArray = data[144] ? data[144].split(',') : [];
			let buttonLabel = data[40];
			let that = this;
			setTimeout(function(){
				let rqFields = that.state.requiredFields;
				let opFields = that.state.optionalFields;
				
				if(data[142] && data[142].indexOf(',') > -1){
					let rqFieldsId = data[142].split(',');
					rqFieldsId.map((item, i) => {
						rqFields.set(item, item);
					});
				}else if(data[142]){
					rqFields.set(data[142], data[142]);
				}
				
				if(data[143] && data[143].indexOf(',') > -1){
					let opFieldsId = data[143].split(',');
					opFieldsId.map((item, i) => {
						opFields.set(item, item);
					});
				}else if(data[143]){
					opFields.set(data[143], data[143]);
				}
				
				that.setState({buttonLabel,launchpadType,requiredFields:rqFields,optionalFields:opFields,ogAutoTagsArray});
			}, 1000);
		}else{
			this.setState({requiredFields:new Map(),optionalFields:new Map()});
		}
		
		if(type == 'calculation'){
			changeCalculation = true;
			let calFieldsId = this.state.calFieldsId;
			if(data[114] && data[114].indexOf(',') > -1){
				let FieldsId = data[114].split(',');
				FieldsId.map((item, i) => {
					calFieldsId.set(item, item);
				});
			}else{
				calFieldsId.set(data[114], data[114]);
			}
			this.setState({calFieldsId:calFieldsId});
		}
		
		if(type == 'smart'){
			changeSmart = true;
			//let numberRows = data[49];
			//this.setState({numberRows});
		}
		
		if(type == 'file' || type == 'video'){
			changebuttonLabel = true;
			let buttonLabel = data[40];
			this.setState({buttonLabel});
		}
		
		if(type == 'gridcheck'){
			changeGridCheck = true;
			let numberOfGrid = data[136];
			this.setState({numberOfGrid});
		}
		
		if(type == 'likert'){
			changeLikert = true;
			if(labelValue && labelValue.indexOf('_') > -1){
				let string = labelValue.split('_');
				if(string[0] && string[0].indexOf(',') > -1){
					let str1 = string[0].split(',');
					this.setState({lowestText:str1[0],highestText:str1[1]});
				}
				
				if(string[1] && string[1].indexOf(',') > -1){
					let str2 = string[1].split(',');
					let col2 = str2.length;
					this.setState({numberRows:col2});
					setTimeout(function(){
						$('.row-input').css('display','none');
						for(let i = 0; i < col2	; i++){
							let j = i+1;
							$('.row-'+j).css('display','block');
							let variable = 'row'+j;
							if(str2[i]){
								that.setState({[variable]:str2[i]});
							}
						}
					}, 1000);
				}
			}
		}
		
		if(type == 'wcalculation'){
			changeWcalculation = true;
			this.setState({wcalFieldsId:data[114]});
		}
		
		if(type == 'weighted'){
			changeWeighted = true;
			let numberOfGrid = data[136];
			let numberRows = data[137] ? data[137] : 2;
			let arraignment = data[138];
			this.setState({numberOfGrid,numberRows,arraignment});
			
			if(labelValue && labelValue.indexOf(',') > -1){
				let str1 = labelValue.split(',');
				setTimeout(function(){
					str1.map((item,i)=>{
						let str = item.split(':');
						let j = i+1;
						$('.row-'+j).css('display','block');
						let variable = 'row'+j;
						let variable2 = 'weight'+j;
						that.setState({[variable]:str[0],[variable2]:str[1]});
					})
				}, 1000);
			}
		}
		
		if(type == 'grid5point'){
			changeGrid5 = true;
			if(labelValue && labelValue.indexOf('_') > -1){
				let string = labelValue.split('_');
				if(string[0] && string[0].indexOf(',') > -1){
					let str1 = string[0].split(',');
					str1.map((item,i)=>{
						let j = i+1;
						let variable = 'sacale'+j;
						this.setState({[variable]:item});
						
					})
				}else{
					let sacale1 = string[0] ? string[0] : '';
					this.setState({sacale1});
				}
				
				if(string[1] && string[1].indexOf(',') > -1){
					let str2 = string[1].split(',');
					let col2 = str2.length;
					this.setState({numberRows:col2});
					setTimeout(function(){
						$('.row-input').css('display','none');
						for(let i = 0; i < col2	; i++){
							let j = i+1;
							$('.row-'+j).css('display','block');
							let variable = 'row'+j;
							if(str2[i]){
								that.setState({[variable]:str2[i]});
							}
						}
					}, 1000);
				}
			}
		}
		
		if(type == 'grid-dropdown'){
			changeGridDropdown = true;
			
			if(labelValue && labelValue.indexOf('_') > -1){
				let string = labelValue.split('_');
				if(string[0] && string[0].indexOf(',') > -1){
					let str1 = string[0].split(',');
					let col = str1.length;
					this.setState({numberOfColumn:col});
					setTimeout(function(){
						$('.column-input').css('display','none');
						for(let i = 0; i < col	; i++){
							let j = i+1;
							$('.column-'+j).css('display','block');
							let variable = 'C'+j;
							that.setState({[variable]:str1[i]});
							
						}
						
						if(col == 3){
							$('.dropdown-col3').css('display','block');
						}
					
					}, 2000);
					
				}else{
					let C1 = string[0] ? string[0] : '';
					this.setState({numberOfColumn:1,C1});
				}
				
				
		
				if(string[1] && string[1].indexOf('|') > -1){
					
					let str2 = string[1].split('|');
					
					let col2 = str2.length;
					this.setState({numberRows:col2});
					
					setTimeout(function(){
						$('.row-input').css('display','none');
						for(let i = 0; i < col2	; i++){
							let j = i+1;
							$('.row-'+j).css('display','block');
							
							if(str2[i]){
								let str3 = str2[i].split(':');
								let col3 = str3.length;
								for(let n = 0; n < col3; n++){
									let s = n+1;
									let variable = 'R'+j+'C'+s;
									that.setState({[variable]:str3[n]});
								}
								
							}
						}
					}, 2000);
				}else{
					setTimeout(function(){
						if(string[1]){
							let str3 = string[1].split(':');
							let col3 = str3.length;
							for(let n = 0; n < col3; n++){
								let s = n+1;
								let variable = 'R1C'+s;
								that.setState({[variable]:str3[n]});
							}
						}
					}, 2000);
				}
			}	
		}
		
		if(type == 'grid-multicheck' || type == 'grid-singlecheck'){
			changeGridOption = true;
			if(labelValue && labelValue.indexOf('_') > -1){
				let string = labelValue.split('_');
				
				if(string[0] && string[0].indexOf(',') > -1){
					let str1 = string[0].split(',');
					let col = str1.length;
					this.setState({numberOfColumn:col});
					setTimeout(function(){
						$('.column-input').css('display','none');
						for(let i = 0; i < col	; i++){
							let j = i+1;
							$('.column-'+j).css('display','block');
							let variable = 'C'+j;
							that.setState({[variable]:str1[i]});
							
						}
					}, 2000);
				}else{
					let C1 = string[0] ? string[0] : '';
					this.setState({numberOfColumn:1,C1});
				}
				
				if(string[1] && string[1].indexOf(',') > -1){
					let str2 = string[1].split(',');
					let col2 = str2.length;
					this.setState({numberRows:col2});
					setTimeout(function(){
						$('.row-input').css('display','none');
						for(let i = 0; i < col2	; i++){
							let j = i+1;
							$('.row-'+j).css('display','block');
							let variable = 'R'+j;
							if(str2[i]){
								that.setState({[variable]:str2[i]});
							}
						}
					}, 2000);
				}
			}
		}
		
		if(type == 'basicgrid'){
			changeBasicGrid = true;
			let validation = data[48];
			let fVli = [];
			let vmin = [];
			let vmax = [];
			if(validation){
				if(validation.indexOf(',') > -1){
					let vli = validation.split(',');
					for(let i = 0; i < vli.length; i++){
						let srt = vli[i].split(':');
						fVli.push(srt[1]);
						vmin.push(srt[2]);
						vmax.push(srt[3]);
					}
				}else{
					let srt = validation.split(':');
					fVli.push(srt[1]);
					vmin.push(srt[2]);
					vmax.push(srt[3]);
				}
			}
			
			if(labelValue && labelValue.indexOf('_') > -1){
				let string = labelValue.split('_');
				
				if(string[0] && string[0].indexOf(',') > -1){
					let str1 = string[0].split(',');
					let col = str1.length;
					this.setState({numberOfColumn:col});
					setTimeout(function(){
						$('.column-input').css('display','none');
						for(let i = 0; i < col	; i++){
							let j = i+1;
							$('.column-'+j).css('display','block');
							let variable = 'C'+j;
							let validationVar = 'validation'+j;
							let vVar1 = 'vmin'+j;
							let vVar2 = 'vmax'+j;
							that.setState({[variable]:str1[i]});
							if(fVli[i] !== undefined){
								that.setState({[validationVar]:fVli[i],[vVar1]:vmin[i],[vVar2]:vmax[i]});
							}
						}
					}, 2000);
				}else{
					let C1 = string[0] ? string[0] : '';
					this.setState({numberOfColumn:1,C1});
				}
				
				if(string[1] && string[1].indexOf(',') > -1){
					let str2 = string[1].split(',');
					let col2 = str2.length;
					this.setState({numberRows:col2});
					setTimeout(function(){
						$('.row-input').css('display','none');
						for(let i = 0; i < col2	; i++){
							let j = i+1;
							$('.row-'+j).css('display','block');
							let variable = 'R'+j;
							if(str2[i]){
								that.setState({[variable]:str2[i]});
							}
						}
					}, 2000);
				}
			}
		}
		
		if(type == 'basicgrid2'){
			changeBasicGrid = true;
			changeBasicGrid2 = true;
			let validation = data[48];
			let fVli = [];
			let vmin = [];
			let vmax = [];
			if(validation){
				if(validation.indexOf(',') > -1){
					let vli = validation.split(',');
					for(let i = 0; i < vli.length; i++){
						let srt = vli[i].split(':');
						fVli.push(srt[1]);
						vmin.push(srt[2]);
						vmax.push(srt[3]);
					}
				}else{
					let srt = validation.split(':');
					fVli.push(srt[1]);
					vmin.push(srt[2]);
					vmax.push(srt[3]);
				}
			}
			
			if(labelValue && labelValue.indexOf('_') > -1){
				let string = labelValue.split('_');
				
				if(string[0] && string[0].indexOf('|') > -1){
					
					let str1 = string[0].split('|');
					
					let col = str1.length;
					this.setState({numberOfColumn:col});
					
					setTimeout(function(){
						$('.column-input').css('display','none');
						for(let i = 0; i < col	; i++){
							let str2 = str1[i].split(':');
							let j = i+1;
							$('.column-'+j).css('display','block');
							let variable = 'C'+j;
							let validationVar = 'validation'+j;
							let vVar1 = 'vmin'+j;
							let vVar2 = 'vmax'+j;
							that.setState({[variable]:str2[0]});
							if(str2[1] == 'limited'){
								that.setState({['columnOption'+j]:'limited',['Option'+j]:str2[2],['limited'+j]:true});
							}
							if(fVli[i] !== undefined){
								that.setState({[validationVar]:fVli[i],[vVar1]:vmin[i],[vVar2]:vmax[i]});
							}
						}
					}, 2000);
				}else{
					let strC1 = string[0] ? string[0] : '';
					strC1 = strC1.split(':');
					this.setState({numberOfColumn:1,C1:strC1[0]});
					if(strC1[1] == 'limited'){
						that.setState({['columnOption1']:'limited',['Option1']:strC1[2],['limited1']:true});
					}
				}
				
				if(string[1] && string[1].indexOf(',') > -1){
					let str2 = string[1].split(',');
					let col2 = str2.length;
					this.setState({numberRows:col2});
					setTimeout(function(){
						$('.row-input').css('display','none');
						for(let i = 0; i < col2	; i++){
							let j = i+1;
							$('.row-'+j).css('display','block');
							let variable = 'R'+j;
							if(str2[i]){
								that.setState({[variable]:str2[i]});
							}
						}
					}, 2000);
				}
			}
			
		}
		
		if(type == 'inlinevideo'){
			showInlineVideo = true;
			setTimeout(function(){
				$("#inlineVideo").html(path);
				var iframeSrc = $('#inlineVideo iframe').attr('src');
				that.setState({inlineVideoLink:iframeSrc});
			}, 1000);
		}
		
		this.setState({fieldId:filed,showFieldEdit:true,showBuildEdit:false,chooseAnsType:ftype,changeQueInpText,changeAnsInpText,changeOutInpText,changeQueType,validationType:valType,inlineVideo:path,imagePath:path,changeAnsOpt,changeRadioPlus,changeOneRadioPlus,changeOutOpt,hasParentHeader:hasPH,asGroup,chooseCompany:companyName,dataPersistence,persistencekey,exPersistencekey,showImageUpload,showInlineVideo,changeWebsite,websiteLink,responseAction,changeSliderText,addTagsText,fieldIdentifier,changeCalculation,calculationType,changeBasicGrid,changeBasicGrid2,changeGridOption,changeGridDropdown,changeGrid5,changeGridCheck,changeLikert,changeSmart,changeWeighted,changeWcalculation,changebuttonLabel,dataAgg,changeLaunchpad,showAddMds,showStyleQ4,styleQ4,apiVideo,analysisVideo,reportPdf,autoPopulate,autoPopulateTags,fieldDeactivated});
	}
	
	getRqAndOpFields=(event)=>{
		let sheet = event.target.value;
		if(sheet){
			this.getFieldsSets(sheet);
		}
	}
	
	getRequiredAndOptionalFields=(fieldId)=>{
		let allData = this.state.allData;
		let rqFields = [];
		let opFields = [];
		if(allData.length > 1){
			
			allData.map((field,i)=>{
				
				if(fieldId != field[0] && field[15] == 'Y'){
					let array = [field[0],field[4],field[91],field[117]];
					rqFields.push(array);
				}
				
				if(fieldId != field[0] && field[15] == 'N'){
					let arrayOp = [field[0],field[4],field[91],field[117]];
					opFields.push(arrayOp);
				}
			});
			
			this.setState({rqFields,opFields});
		} 
	}
	
	getFieldsSets=(sheet) =>{
		let spreadsheetId = this.state.mdsSpreadsheetId;
        let singleSheetName = sheet;
        let that = this;
		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:singleSheetName, 
		})
		.then(
			response => {
				const mdsFields = response.result.values;
				let rqFields = [];
				let opFields = [];
				mdsFields.map((field,i)=>{
				
					if(field[15] == 'Y'){
						let array = [field[0],field[4],field[91],field[117]];
						rqFields.push(array);
					}
					
					if(field[15] == 'N'){
						let arrayOp = [field[0],field[4],field[91],field[117]];
						opFields.push(arrayOp);
					}
				});
				
				that.setState({rqFields,opFields,launchpadMds:sheet});
				
			},function(reason) {
				alert(reason.result.error.message);
			}
		);
	}
	
	setAsGroup=(event)=>{
		
		let name = event.target.name;
		let res = event.target.value;
		let isChecked = event.target.checked;
		if(isChecked){
			this.setState({[event.target.name]:event.target.value});
		}else{
			this.setState({[event.target.name]:''});
		}
		
	}
	
	onEditorStateChange=(editorState)=>{
        var convertedData = editorState.getCurrentContent()
        let d =  convertToRaw(editorState.getCurrentContent()) 
        let editorSourceHTML = draftToHtml(d)
        this.setState({
            editorState,editorSourceHTML
        });
    }
	
    _uploadImageCallBack=(file)=>{
        let uploadedImages = this.state.uploadedImages;

        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        }
        uploadedImages.push(imageObject);
        this.setState({uploadedImages: uploadedImages})
        let con = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'content-type': 'multipart/form-data'
			}
		}
		
		let url = this.state.ApiUrl+'notefile/file_upload.php';
		let ApiUrl = this.state.ApiUrl;
		return new Promise(
		(resolve, reject) => {
			var form_data = new FormData();
			form_data.append('file', file);
			post(url, form_data, con)
			.then(response => {
				let rlink = ApiUrl+response.data
				resolve({ data: { link: rlink } });
			})
		});
    }
		
	updateField=()=>{
		let fieldId = this.state.fieldId;
		let allData = this.state.allData;
		let selectedSheet = this.state.selectedSheet;
        var  sheetName = 'Temp File';
        if(selectedSheet != '' && selectedSheet !== undefined){
			sheetName = selectedSheet;
		}
		let hasPH = '';
		let question = this.state.changeQueInpText;
		let answerType = this.state.chooseAnsType;
		let options = this.state.changeAnsInpText;
		let outputText = this.state.changeOutInpText;
		let noteHtml = this.state.editorSourceHTML;
		let validationType = this.state.validationType;
		let minValue = this.state.minValue;
		let maxValue = this.state.maxValue;
		let inlineVideo = this.state.inlineVideo;
		let asGroup = this.state.asGroup;
		//let PFB = this.state.PFB;
		//let chooseCompany = this.state.chooseCompany;
		let dataPersistence = this.state.dataPersistence;
		let persistencekey = this.state.persistencekey;
		let exPersistencekey = this.state.exPersistencekey;
		//let chooseTracking = this.state.chooseTracking;
		let websiteLink = this.state.websiteLink;
		let responseAction = this.state.responseAction;
		let addTagsText = this.state.addTagsText;
		let fieldIdentifier = this.state.fieldIdentifier;
		let buttonLabel = this.state.buttonLabel;
		let addParentMds = this.state.addParentMds;
		let addGroupMds = this.state.addGroupMds;
		let dataAgg = this.state.dataAgg
		let styleQ4 = this.state.styleQ4
		let analysisVideo = this.state.analysisVideo
		let reportPdf = this.state.reportPdf
		let autoPopulateTags = this.state.autoPopulateTags
		let fieldDeactivated = this.state.fieldDeactivated
		let unit = '';
		let labelValue = '';
		let mod = '';
		
		let calculationType = this.state.calculationType;
		let calFieldsId = this.state.calFieldsId;
		let wcalFieldsId = this.state.wcalFieldsId;
		if(calFieldsId){
			let calFields = Object.fromEntries(calFieldsId.entries());
			
			let calArr = [];
			for (let prop in calFields) {
				calArr.push(calFields[prop]);
			}
			calArr = calArr.filter(function(x) {
				 return x !== '';
			});
			calFieldsId = calArr.join();
		}
		let rowId ='';
		let rowVal = [];
		let perantFeildGuid = '';
		//console.log('allData->',allData);
		allData.map((valu, i) => { 
			if(valu[0] == fieldId){
				rowId = (i+2);
				rowVal = valu;
				perantFeildGuid = valu[131];
			}
		});
		
		if(this.state.dataMode == 'Child' && !addParentMds && !perantFeildGuid){
			alert('Please select the option to Add to MDS!');
			return false;
		}
		//console.log('rowVal->',rowVal);
		let optionType = rowVal[5].toLowerCase();
		let path = '';
		let numberOfColumn = '';
		let numberOfRow = '';
		
		if (optionType == 'image') {
			path = this.state.imagePath;
		}
		if(optionType == 'file'){
			path = this.state.filePath;
		}
		if(optionType == 'inlinevideo'){
			path = this.state.inlineVideo;
		}
		
		let column = 'DX';
		
		if(optionType == 'Parent Header'){
			column = 'DW';
		}
		
		if(optionType == 'q12' || optionType == 'q13'){
			buttonLabel = this.state.leftButtonTxt+','+this.state.rightButtonTxt+','+this.state.buttonLabel
		}
		
		if(optionType == 'grid-dropdown'){
			numberOfColumn = 2;
			let optionColumns = [this.state.C1,this.state.C2];
			if(this.state.numberOfColumn){
				numberOfColumn = this.state.numberOfColumn;
			}
			if(this.state.C3 && numberOfColumn == 3){
				optionColumns.push(this.state.C3);
			}
			optionColumns = optionColumns.filter(function(x) {
				 return x !== 'undefined';
			});
			let columnStr = optionColumns.join();
			

			numberOfRow = this.state.numberRows;
			let optionRows = [];
			for(let i=1; i <= numberOfRow; i++ ){
				if(numberOfColumn == 2 && this.state['R'+i+'C1']){
					optionRows.push(this.state['R'+i+'C1']+':'+this.state['R'+i+'C2']);
				}else if(numberOfColumn == 3 && this.state['R'+i+'C1']){
					optionRows.push(this.state['R'+i+'C1']+':'+this.state['R'+i+'C2']+':'+this.state['R'+i+'C3']);
				}
			}
			/* let optionRows = [this.state.R1C1+':'+this.state.R1C2,this.state.R2C1+':'+this.state.R2C2,this.state.R3C1+':'+this.state.R3C2];
			
			if(numberOfColumn == 3){
				optionRows = [this.state.R1C1+':'+this.state.R1C2+':'+this.state.R1C3,this.state.R2C1+':'+this.state.R2C2+':'+this.state.R2C3,this.state.R3C1+':'+this.state.R3C2+':'+this.state.R3C3];
			} 
			

			optionRows = optionRows.filter(function(x) {
				return x !== 'undefined:undefined:undefined';
			});*/
			
			
			let rows = [];
			optionRows.map((row, i) => { 
				if(i < numberOfRow){
					rows.push(row);
				}
			});
			let rowaStr = rows.join('|');
			
			
			labelValue = columnStr+'_'+rowaStr
			
		}
		
		if(optionType == 'basicgrid'){
			numberOfColumn = 1;
			let optionRows = [this.state.R1,this.state.R2,this.state.R3,this.state.R4,this.state.R5,this.state.R6,this.state.R7,this.state.R8,this.state.R9,this.state.R10,this.state.R11,this.state.R12,this.state.R13,this.state.R14,this.state.R15,this.state.R16,this.state.R17,this.state.R18,this.state.R19,this.state.R20];
			
			optionRows = optionRows.filter(function(x) {
				 return x !== undefined && x !== '';
			});
			
			numberOfColumn = this.state.numberOfColumn;
			numberOfRow = this.state.numberRows;
			let rows = [];
			optionRows.map((row, i) => { 
				if(i < numberOfRow){
					rows.push(row);
				}
			});
			let rowaStr = rows.join();
			let optionColumns = [this.state.C1];
			let columnsVali = ['C1:'+this.state.validation1+':'+this.state.vmin1+':'+this.state.vmax1];
			if(this.state.C2 && numberOfColumn >= 2){
				optionColumns.push(this.state.C2);
				columnsVali.push('C2:'+this.state.validation2+':'+this.state.vmin2+':'+this.state.vmax2);
			}
			if(this.state.C3 && numberOfColumn >= 3){
				optionColumns.push(this.state.C3);
				columnsVali.push('C3:'+this.state.validation3+':'+this.state.vmin3+':'+this.state.vmax3);
			}
			if(this.state.C4 && numberOfColumn >= 4){
				optionColumns.push(this.state.C4);
				columnsVali.push('C4:'+this.state.validation4+':'+this.state.vmin4+':'+this.state.vmax4);
			}
			if(this.state.C5 && numberOfColumn >= 5){
				optionColumns.push(this.state.C5);
				columnsVali.push('C5:'+this.state.validation5+':'+this.state.vmin5+':'+this.state.vmax5);
			}
			validationType = columnsVali.join();
			optionColumns = optionColumns.filter(function(x) {
				 return x !== 'undefined:undefined';
			});
			
			//console.log('optionColumns->',optionColumns);
			
			let columnStr = optionColumns.join();                 
			labelValue = columnStr+'_'+rowaStr
		}
		
		if(optionType == 'basicgrid2'){
			numberOfColumn = 1;
			let optionRows = [this.state.R1,this.state.R2,this.state.R3,this.state.R4,this.state.R5,this.state.R6,this.state.R7,this.state.R8,this.state.R9,this.state.R10,this.state.R11,this.state.R12,this.state.R13,this.state.R14,this.state.R15,this.state.R16,this.state.R17,this.state.R18,this.state.R19,this.state.R20];
			
			optionRows = optionRows.filter(function(x) {
				 return x !== undefined && x !== '';
			});
			
			numberOfColumn = this.state.numberOfColumn;
			numberOfRow = this.state.numberRows;
			let rows = [];
			optionRows.map((row, i) => { 
				if(i < numberOfRow){
					rows.push(row);
				}
			});
			
			let rowaStr = rows.join();
			
			let optionColumns = [this.state.C1+':'+this.state.columnOption1];
			if(this.state.columnOption1 == 'limited' && this.state.Option1){
				optionColumns = [this.state.C1+':'+this.state.columnOption1+':'+this.state.Option1];
			}
			
			let columnsVali = ['C1:'+this.state.validation1+':'+this.state.vmin1+':'+this.state.vmax1];
			
			if(this.state.C2 && numberOfColumn >= 2){
				
				if(this.state.columnOption2 == 'limited' && this.state.Option2){
					optionColumns.push(this.state.C2+':'+this.state.columnOption2+':'+this.state.Option2);
				}else{
					optionColumns.push(this.state.C2+':'+this.state.columnOption2);
				}
			
				columnsVali.push('C2:'+this.state.validation2+':'+this.state.vmin2+':'+this.state.vmax2);
			}
			if(this.state.C3 && numberOfColumn >= 3){
				
				if(this.state.columnOption3 == 'limited' && this.state.Option3){
					optionColumns.push(this.state.C3+':'+this.state.columnOption3+':'+this.state.Option3);
				}else{
					optionColumns.push(this.state.C3+':'+this.state.columnOption3);
				}
				columnsVali.push('C3:'+this.state.validation3+':'+this.state.vmin3+':'+this.state.vmax3);
			}
			if(this.state.C4 && numberOfColumn >= 4){
				
				if(this.state.columnOption4 == 'limited' && this.state.Option4){
					optionColumns.push(this.state.C4+':'+this.state.columnOption4+':'+this.state.Option4);
				}else{
					optionColumns.push(this.state.C4+':'+this.state.columnOption4);
				}
				columnsVali.push('C4:'+this.state.validation4+':'+this.state.vmin4+':'+this.state.vmax4);
			}
			if(this.state.C5 && numberOfColumn >= 5){
				
				if(this.state.columnOption5 == 'limited' && this.state.Option5){
					optionColumns.push(this.state.C5+':'+this.state.columnOption5+':'+this.state.Option5);
				}else{
					optionColumns.push(this.state.C5+':'+this.state.columnOption5);
				}
				columnsVali.push('C5:'+this.state.validation5+':'+this.state.vmin5+':'+this.state.vmax5);
			}
			validationType = columnsVali.join();
			/* optionColumns = optionColumns.filter(function(x) {
				 return x !== 'undefined:undefined';
			}); */
			
			//console.log('optionColumns->',optionColumns);
			
			let columnStr = optionColumns.join('|');
			labelValue = columnStr+'_'+rowaStr
		}
		
		
		if(optionType == 'grid-multicheck' || optionType == 'grid-singlecheck'){
			numberOfColumn = 1;
			let optionRows = [this.state.R1,this.state.R2,this.state.R3,this.state.R4,this.state.R5,this.state.R6,this.state.R7,this.state.R8,this.state.R9,this.state.R10,this.state.R11,this.state.R12,this.state.R13,this.state.R14,this.state.R15,this.state.R16,this.state.R17,this.state.R18,this.state.R19,this.state.R20];
			
			optionRows = optionRows.filter(function(x) {
				 return x !== undefined && x !== '';
			});
			
			numberOfColumn = this.state.numberOfColumn;
			numberOfRow = this.state.numberRows;
			let rows = [];
			optionRows.map((row, i) => { 
				if(i < numberOfRow){
					rows.push(row);
				}
			});
			let rowaStr = rows.join();
			let optionColumns = [this.state.C1];
			if(this.state.C2 && numberOfColumn >= 2){
				optionColumns.push(this.state.C2);
			}
			if(this.state.C3 && numberOfColumn >= 3){
				optionColumns.push(this.state.C3);
			}
			if(this.state.C4 && numberOfColumn >= 4){
				optionColumns.push(this.state.C4);
			}
			if(this.state.C5 && numberOfColumn >= 5){
				optionColumns.push(this.state.C5);
			}
			optionColumns = optionColumns.filter(function(x) {
				 return x !== 'undefined:undefined';
			});
			let columnStr = optionColumns.join();
			labelValue = columnStr+'_'+rowaStr
		}
		
		
		if(optionType == 'grid5point'){
			numberOfColumn = 5;
			let sacale = [this.state.sacale1,this.state.sacale2,this.state.sacale3,this.state.sacale4,this.state.sacale5];
			let gridRows = [this.state.row1,this.state.row2,this.state.row3,this.state.row4,this.state.row5];
			sacale = sacale.filter(function(x) {
				 return x !== undefined && x !== '';
			});
			gridRows = gridRows.filter(function(x) {
				 return x !== undefined && x !== '';
			});
			numberOfRow = this.state.numberRows;
			let rows = [];
			gridRows.map((row, i) => { 
				if(i < numberOfRow){
					rows.push(row);
				}
			});
			let sacaleStr = sacale.join();
			let rowaStr = rows.join();
			labelValue = sacaleStr+'_'+rowaStr;
		}
		
		if(optionType == 'gridcheck'){
			numberOfColumn = this.state.numberOfGrid;
			
		}
		
		if(optionType == 'likert'){
			let sacale = [this.state.lowestText,this.state.highestText];
			let gridRows = [this.state.row1,this.state.row2,this.state.row3,this.state.row4,this.state.row5];
			sacale = sacale.filter(function(x) {
				 return x !== undefined && x !== '';
			});
			gridRows = gridRows.filter(function(x) {
				 return x !== undefined && x !== '';
			});
			numberOfRow = this.state.numberRows;
			let rows = [];
			gridRows.map((row, i) => { 
				if(i < numberOfRow){
					rows.push(row);
				}
			});
			let sacaleStr = sacale.join();
			let rowaStr = rows.join();
			labelValue = sacaleStr+'_'+rowaStr;
		}
		
		if(optionType == 'weighted'){
			let state = this.state;
			numberOfColumn = 1;
			let optionRows = [state.row1+':'+state.weight1 , state.row2+':'+state.weight2,state.row3+':'+state.weight3 , state.row4+':'+state.weight4,state.row5+':'+state.weight5 , state.row6+':'+state.weight6,state.row7+':'+state.weight7 , state.row8+':'+state.weight8,state.row9+':'+state.weight9 , state.row10+':'+state.weight10];
			
			optionRows = optionRows.filter(function(x) {
				 return x !== 'undefined:undefined' && x !== ':';
			});
			
			numberOfRow = this.state.numberRows;
			let rows = [];
			optionRows.map((row, i) => { 
				if(i < numberOfRow){
					rows.push(row);
				}
			});
			labelValue = rows.join();
			numberOfColumn = this.state.numberOfGrid;
		}
				
		
		if(optionType == 'smart'){
			if(this.state.noneAbove != ''){
				options = this.state.changeAnsInpText+','+this.state.noneAbove;
			}
		}
		
		let data = [];
		
		let row = {
			range: sheetName+'!D'+rowId,   
			values: [
				[ dataAgg ]
			]
		}
		
		let row1 = {
			range: sheetName+'!E'+rowId+':G'+rowId,   
			values: [
				[ question,answerType,options ]
			]
		}
		
		let row2 = {
			range: sheetName+'!J'+rowId,   
			values: [
				[ outputText ]
			]
		};
		
		let row3 = {
			range: sheetName+'!M'+rowId+':N'+rowId,   
			values: [
				[ noteHtml,addTagsText ]
			]
		};
		
		let row4 = {
			range: sheetName+'!AW'+rowId+':AZ'+rowId,   
			values: [
				[ validationType,'',minValue,maxValue]
			]
		};
		
		let row5 = {
			range: sheetName+'!CV'+rowId,   
			values: [
				[ path ]
			]
		};
		
		let row6 = {
			range: sheetName+'!'+column+''+rowId,   
			values: [
				[ asGroup ]
			]
		};
		
		/* let row7 = {
			range: sheetName+'!DH'+rowId,   
			values: [
				[ PFB ]
			]
		};
		
		let row8 = {
			range: sheetName+'!DL'+rowId,   
			values: [
				[ chooseCompany ]
			]
		}; 
		
		let row9 = {
			range: sheetName+'!DV'+rowId,   
			values: [
				[ chooseTracking ]
			]
		};
		*/
		let row20 = {
			range: sheetName+'!DG'+rowId,   
			values: [
				[ dataPersistence ]
			]
		};
		
		let row22 = {
			range: sheetName+'!DC'+rowId,   
			values: [
				[ autoPopulateTags ]
			]
		};
		
		let row23 = {
			range: sheetName+'!AN'+rowId,   
			values: [
				[ persistencekey ]
			]
		};
		
		let row24 = {
			range: sheetName+'!AQ'+rowId,   
			values: [
				[ exPersistencekey ]
			]
		};
		
		let row26 = {
			range: sheetName+'!BJ'+rowId,   
			values: [
				[ fieldIdentifier ]
			]
		};
		
		let row27 = {
			range: sheetName+'!BI'+rowId,   
			values: [
				[ fieldDeactivated ]
			]
		};
		
		data.push(row);
		data.push(row1);
		data.push(row2);
		data.push(row3);
		data.push(row4);
		data.push(row5);
		data.push(row6);
		//data.push(row7);
		//data.push(row8);
		//data.push(row9);
		data.push(row22);
		data.push(row23);
		data.push(row24);
		data.push(row26);
		data.push(row27);
		
		if (optionType == 'link' || optionType == 'launchpad') {
			let row10 = {
				range: sheetName+'!CA'+rowId,   
				values: [
					[ websiteLink ]
				]
			};
			data.push(row10);
		}
		
		if (optionType == 'launchpad') {
			mod = 'LP1';
			if(this.state.launchpadType != ''){
				mod = this.state.launchpadType;
			}
			
			let ogAutoTags = this.state.ogAutoTags;
			let requiredFields = this.state.requiredFields;
			let optionalFields = this.state.optionalFields;
			if(requiredFields){
				let rqFields = Object.fromEntries(requiredFields.entries());
				let rqcalArr = [];
				for (let prop in rqFields) {
					rqcalArr.push(rqFields[prop]);
				}
				rqcalArr = rqcalArr.filter(function(x) {
					 return x !== '';
				});
				requiredFields = rqcalArr.join();
			}
			
			if(optionalFields){
				let opFields = Object.fromEntries(optionalFields.entries());
				let opcalArr = [];
				for (let prop in opFields) {
					opcalArr.push(opFields[prop]);
				}
				opcalArr = opcalArr.filter(function(x) {
					 return x !== '';
				});
				optionalFields = opcalArr.join();
			}
			
			let row15 = {
				range: sheetName+'!EM'+rowId,   
				values: [
					[ requiredFields ]
				]
			};
			let row16 = {
				range: sheetName+'!EN'+rowId,   
				values: [
					[ optionalFields ]
				]
			};
			
			let row17 = {
				range: sheetName+'!EO'+rowId,   
				values: [
					[ ogAutoTags ]
				]
			};
			
			let row25 = {
				range: sheetName+'!AS'+rowId,   
				values: [
					[ responseAction ]
				]
			};
			
			
			data.push(row15);
			data.push(row16);
			data.push(row17);
			data.push(row25);
		}
		
		if (optionType == 'calculation') {
			let row11 = {
				range: sheetName+'!DJ'+rowId+':DK'+rowId,   
				values: [
					[ calculationType,calFieldsId ]
				]
			};
			data.push(row11);
		}
		
		if (optionType == 'wcalculation') {
			let row11 = {
				range: sheetName+'!DJ'+rowId+':DK'+rowId,   
				values: [
					[ calculationType,wcalFieldsId ]
				]
			};
			data.push(row11);
		}
		
		let row12 = {
			range: sheetName+'!AX'+rowId,   
			values: [
				[ unit ]
			]
		};
		
		let row13 = {
			range: sheetName+'!AO'+rowId,   
			values: [
				[ buttonLabel ]
			]
		};

		data.push(row12);
		data.push(row13);
		
		if(optionType == 'slider'){
			let sliderMinValue = this.state.sliderMinValue
			let sliderMaxValue = this.state.sliderMaxValue
			let sliderStepValue = this.state.sliderStepValue
			labelValue = this.state.sliderMinText+', '+this.state.sliderMaxText;
			
			let row14 = {
				range: sheetName+'!AY'+rowId+':AZ'+rowId,   
				values: [
					[ sliderMinValue,sliderMaxValue ]
				]
			};
			
			let row15 = {
				range: sheetName+'!AK'+rowId,   
				values: [
					[ sliderStepValue ]
				]
			};
			
			data.push(row14);
			data.push(row15);
		}
		
		let row16 = {
			range: sheetName+'!AP'+rowId,   
			values: [
				[ labelValue ]
			]
		};
		
		let row17 = {
			range: sheetName+'!EG'+rowId,   
			values: [
				[ numberOfColumn ]
			]
		};
		
		let row18 = {
			range: sheetName+'!EH'+rowId,   
			values: [
				[ numberOfRow ]
			]
		};
		
		data.push(row16);
		data.push(row17);
		data.push(row18);
		
		if(optionType == 'weighted'){
			mod = this.state.arraignment;
		}
		
		if(optionType == 'q4'){
			mod = this.state.styleQ4;
		}
		
		let row19 = {
			range: sheetName+'!EI'+rowId,   
			values: [
				[ mod ]
			]
		};
		data.push(row19);
		data.push(row20);
		
		if (optionType == 'apivideo') {
			let row21 = {
				range: sheetName+'!BH'+rowId+':BI'+rowId,   
				values: [
					[ analysisVideo,reportPdf ]
				]
			};
			data.push(row21);
		}
		
		let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
		
		let row25 = {
			range: sheetName+'!CU'+rowId,
			values: [
				[currentDate]
			]
		};
		data.push(row25);
		
		let spreadsheetId = this.state.spreadsheetId;
		var params = {
			spreadsheetId: spreadsheetId,
		}
		let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: data, 
        };
		let that = this; 
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
            alert('Field updated Successfully!');
			that.getMdsData();
			setTimeout(function(){
				/* if(that.state.dataMode == 'MDS'){
					that.saveMdsJsonDataMongodb(sheetName, 'mongo', 'mds');
				}else if(that.state.dataMode == 'Child'){
					that.saveMdsJsonDataMongodb(sheetName, 'mongo', 'child');
				} */
			}, 2000);
			
			
			
			/* if(addParentMds != '' && addParentMds !== undefined){
				setTimeout(function(){
					that.addInParentMds(addParentMds,addGroupMds,fieldId);
				}, 3000);	
			}
			
			if(rowVal[130] != '' && rowVal[130] !== undefined){
				setTimeout(function(){
					that.updateMdsField(rowVal[130],rowVal[118],data,sheetName,rowId);
				}, 3000);	
			} */
			
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        });
	}
	
	/* updateMdsField=(parentFieldId,mdsName,fieldData,sheetName,oldRowId)=>{
		
		let spreadsheetId = this.state.mdsSpreadsheetId;
		
		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:mdsName, 
		})
		.then(
			response => {
				const data = response.result.values;
				let rowId ='';
				data.map((value,i)=>{
					if(i > 0 && value[0] == parentFieldId){
						rowId = (i+1);
					}
				});
				
				let updateData = [];
				fieldData.map((val,i)=>{
					var sheetN = val.range.split("!");
					let str = sheetN[0].replace(sheetName, mdsName);
					let str2 = sheetN[1].replaceAll(oldRowId, rowId);
					
					let newRang = str+'!'+str2;
					let newValue = val['values'][0];
					let row = {
						range: newRang,   
						values: [newValue]
					};
					updateData.push(row);
				});
				//console.log('updateData->',updateData);
				var params = {
					spreadsheetId: spreadsheetId,
				}
				let batchUpdateValuesRequestBody = {
					valueInputOption: 'USER_ENTERED',
					data: updateData, 
				};
				let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
				updateRequest.then(function(response) {
				}, function(reason) {
					console.error('error: ' + reason.result.error.message);
				});
				
			},function(reason) {
				console.error('error: ' + reason.result.error.message);
			}
		);
	}
	
	addInParentMds=(addParentMds,addGroupMds,fieldId)=>{
		let parentDataSets = this.state.parentDataSets;
		let allData2 = this.state.allData;
		let value = [];
		let newObj = JSON.parse(JSON.stringify(parentDataSets));
		let data = newObj.reverse();
		let id = data[0][0].split('-');
		let newId = Number(id[1]) + 1;
		let ttevalId = id[0]+'-'+newId;
		let position = parentDataSets.length;
		let parentID = data[0][2];
		if(addGroupMds != '' && addGroupMds !== undefined){
			position = Number(addGroupMds)+1;
		}
		parentDataSets.map((val, i) => {
			if(val[10] == addGroupMds){
				parentID = val[1];
			}
		});	
		
		let positionConfig = ttevalId+',,'+parentID+',,,Y,N,'+position+',N,,,,,,,,CPO,,';
		let uld = ulid();
		allData2.map((val, i) => { 
			if(val[0] == fieldId){
				//console.log('val->',val);
				value = val;
				value[0] = ttevalId;
				value[2] = parentID;
				value[7] = '';
				value[8] = '';
				value[10] = position;
				value[11] = '';
				value[16] = positionConfig;
				value[117] = uld;
				value[118] = parentDataSets[1][118];
				value[91] = parentDataSets[1][91];
				value[129] = '';
				value[130] = '';
				value[131] = '';
			}
		});
		var params = {
			spreadsheetId:  this.state.mdsSpreadsheetId,
			range:addParentMds+'!A:DY', 
			valueInputOption: "RAW", 
			insertDataOption: "INSERT_ROWS",
			resource:{
				"values": [value],
				"majorDimension": "ROWS"
			  }
		};
		let that = this;
		var request = gapi.client.sheets.spreadsheets.values.append(params);
		request.then(function(response) {
			if(addGroupMds != '' && addGroupMds !== undefined){
				let rowId = '';
				let updateData = [];
				{ parentDataSets.map((val, i) => {
					if(i > 0){
						rowId = (i+1);
						if(val[10] >= position ){
							let position2 = Number(val[10])+1;
							
							let positionConfig = val[16].split(',');
							
							positionConfig[7] = position2;
							let newPos = positionConfig.join();

							let row1 = {
							  range: addParentMds+'!K'+rowId,
							  values: [
								[position2]
							  ]
							};
							
							let row2 = {
							  range: addParentMds+'!Q'+rowId,
							  values: [
								[newPos]
							  ]
							};
							
							updateData.push(row1);
							updateData.push(row2);
						}
					}
				})};
				
				let params = {
					spreadsheetId: this.state.mdsSpreadsheetId,
				};
				let batchUpdateValuesRequestBody = {
					valueInputOption: 'USER_ENTERED',
					data: updateData
				};
				let request = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
				request.then(function(response) {
					that.addParentSets(addParentMds);
					
				}, function(reason) {
					alert(reason.result.error.message);
				});
			}else{
				that.addParentSets(addParentMds);
			}
			that.getMdsData();
			setTimeout(function(){
				that.updateChildMds(fieldId,value);
			}, 2000);
			
			that.setState({addParentMds:'',addGroupMds:''});
			$('.add-select').val('');

		}, function(reason) {
			console.error('error: ' + reason.result.error.message);
		});
	}
	
	updateChildMds=(fieldId,value)=>{
		let allData = this.state.allData;
		let spreadsheetId = this.state.spreadsheetId;
		let selectedSheet = this.state.selectedSheet;
        var  sheetName = 'Temp File';
        if(selectedSheet != '' && selectedSheet !== undefined){
			sheetName = selectedSheet;
		}
		let rowId = '';
		let updateData = [];
		{ allData.map((val, i) => {
			rowId = (i+2);
			if(val[0] == fieldId){
				
				let row1 = {
				  range: sheetName+'!DO'+rowId,
				  values: [
					[value[118]]
				  ]
				};
				
				let row2 = {
				  range: sheetName+'!DZ'+rowId,
				  values: [
					[value[91]]
				  ]
				};
				
				let row3 = {
				  range: sheetName+'!EA'+rowId,
				  values: [
					[value[0]]
				  ]
				};
				
				let row4 = {
				  range: sheetName+'!EB'+rowId,
				  values: [
					[value[117]]
				  ]
				};
				
				updateData.push(row1);
				updateData.push(row2);
				updateData.push(row3);
				updateData.push(row4);
			}

		})};
		
		let params = {
			spreadsheetId: spreadsheetId,
		};
		let batchUpdateValuesRequestBody = {
			valueInputOption: 'USER_ENTERED',
			data: updateData
		};
		let that = this;
		let request = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
		request.then(function(response) {
			that.setState({showAddMds:false});
			that.getMdsData();
		}, function(reason) {
			alert(reason.result.error.message);
		});
		
	}*/
	
	deleteField(filed){
		let allData = this.state.allData;
		let newData = [];
		let startIndex = '';
		allData.map((rowDatas, i) => {
			if(rowDatas[0] != filed){
				newData.push(rowDatas);
			}
			if(rowDatas[0] == filed){
				startIndex = i+1;
			}
		});
		//console.log('startIndex->',startIndex);
		this.setState({allData:newData});
		$('.btn-delete').prop('disabled', true);
		let that = this;
		setTimeout(function(){
			that.clearUpdateData2(startIndex);
		}, 2000);
	}
	
	clearUpdateData2=(startIndex)=>{
		let spreadsheetId = this.state.spreadsheetId;
		let selectedSheetId = this.state.selectedSheetId;
		let selectedSheet = this.state.selectedSheet;
        var sheetName = 'Temp File';
        if(selectedSheet != '' && selectedSheet !== undefined){
			sheetName = selectedSheet;
		}
		let params =  {
            spreadsheetId: spreadsheetId,
        };
		let that = this;
		let endIndex = startIndex+1;
        let deleteRow = {
            "requests": [
            {
              "deleteDimension": {
                "range": {
                  "sheetId": selectedSheetId,
                  "dimension": "ROWS",
                  "startIndex": startIndex,
                  "endIndex": endIndex
                }
              }
            }]
        }
		
		let request = gapi.client.sheets.spreadsheets.batchUpdate(params, deleteRow);
        request.then(function(response) {
			that.updateData('del');
			alert('Field Deleted Successfully!');
		}, function(reason) {
            alert(reason.result.error.message);
        });
			
        /* let request = gapi.client.sheets.spreadsheets.batchUpdate(params, deleteRow);
        request.then(function(response) {
            let allData = that.state.allData;
			if(allData.length > 1){
				allData.map((field,i)=>{
					allData[i][128] = '';
					allData[i][141] = '';
				});
			}
			var params = {
				spreadsheetId:spreadsheetId,
				range:sheetName+'!A:EO', 
				valueInputOption: "RAW", 
				insertDataOption: "INSERT_ROWS",
				resource:{
					"values":allData,
					"majorDimension": "ROWS"
				  }
			};
			var request2 = gapi.client.sheets.spreadsheets.values.append(params);
			request2.then(function(response) {
				that.updateData();
			}, function(reason) {
				console.error('error: ' + reason.result.error.message);
			});         
        }, function(reason) {
            alert(reason.result.error.message);
        }); */
	}
	
	uploadVideoPath=(embedCode, errorMessage)=>{
		if(!errorMessage && embedCode){
			$( "#inlineVideo").html(embedCode);
			this.setState({inlineVideo:embedCode});
		}else{
			alert(errorMessage);
		} 
    }
	
	getDirectoryImages=()=>{
        let con = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        let url = this.state.ApiUrl+'uploadfile/get_images.php';
        axios.get(url,con)
        .then(response => {
            var fileArray=response.data;
            this.setState({fileArray});
        })
    }
	
	uploadImagePath=()=>{
        var path  =$( "#imageId" ).val();
        $('#directory').modal('hide');
        this.setState({imagePath:path});
    }
	
	getImagePath=(path,id)=>{
		$( ".view-image" ).removeClass( "selected" );
		$( "#image_"+id ).addClass( "selected" );
		$( "#imageId" ).val( path );
	}
	
	calculationFields=()=>{
		
		let validationTypefilter = this.state.validationTypefilter;
		
		return (<div>
			<table className="table table-bordered">
			{this.state.allData.map((comment,i) =>{
				if((validationTypefilter && comment[48] == validationTypefilter) || (!validationTypefilter && (comment[48]== 'V3' || comment[48]== 'V4' || comment[48]== 'V5' || comment[48]== 'V6' || comment[48]== 'V7' || comment[48]== 'V8' || comment[48]== 'V12' || comment[48]== 'V13')) ){
					let calFieldsId = this.state.calFieldsId;
					let hasId = calFieldsId.get(comment[117]);
					
					return (<tr >
						<td><input name={comment[117]} type="checkbox" onChange={this.handleCalItem} checked={hasId ? 'checked' : ''} /></td>
						<td>{comment[0]}</td>
						<td>{comment[4]}</td>
						<td>{comment[48]}</td>
					</tr>)
				}
			}   
			)}
			</table>
		</div>);
	}
	
	handleCalItem = (e) => {
        const item = e.target.name;
        let isChecked = e.target.checked;
		let calFieldsId = this.state.calFieldsId;
		if(isChecked == true){
			calFieldsId.set(item, item);
		}else{
			calFieldsId.delete(item);
		}
		this.setState({calFieldsId:calFieldsId});
    }
	
	handleLaunchPadItem = (e) => {
        let item = e.target.name;
        let type = e.target.value;
		let isChecked = e.target.checked;
		if(type == 'rq'){
			let rqFields = this.state.requiredFields;
			if(isChecked == true){
				rqFields.set(item, item);
			}else{
				rqFields.delete(item);
			}
			this.setState({requiredFields:rqFields});
		}
		
		if(type == 'op'){
			let opields = this.state.optionalFields;
			if(isChecked == true){
				opields.set(item, item);
			}else{
				opields.delete(item);
			}
			//console.log('opields->',opields);
			this.setState({optionalFields:opields});
		}
    }
	
	handleCalItemWeight = (e) => {
		const item = e.target.value;
        this.setState({wcalFieldsId:item});
    }
	
	wcalculationFields=()=>{
		
		return (<div>
			<table className="table table-bordered">
			{this.state.allData.map((comment,i) =>{
				if(comment[5] == 'weighted'){
					let hasId = this.state.wcalFieldsId;
					return (<tr >
						<td><input name={comment[5]} value={comment[117]} type="Radio" onChange={this.handleCalItemWeight} checked={hasId == comment[117] ? 'checked' : ''} /></td>
						<td>{comment[0]}</td>
						<td>{comment[4]}</td>
					</tr>)
				}
			}   
			)}
			</table>
		</div>);
	}
	
	preViewModeOn=()=>{
		this.builderOption(null,'mds_view');
	}
	
	preViewModeOff=()=>{
		this.builderOption(null,'builder');
	}
	
	builderOption=(event,value)=>{
		let val = '';
		if(value){
			val = value;
		}else{
			val = event.target.value;
		}

		this.setState({viewMode:val,showView:false,instanceManager:false,showTrackerTable:false,trackerStatusManager:false,taskManager:false,workFlow:false,workFlowDetails:false,workFlowManger:false,workFlowProgress:false,scheduledworkFlow:false,showMdsList:false,showMongoSection:false,showMongoMdsSection:false,showTranslationManager:false,showMdsManageSection:false});
		//$('.instance-manager').hide();
		
		if(this.state.dataMode == 'mdsFieldCopy'){
			this.setState({showView:true});
		}
		
		if(val == 'mds_view'){
			//$('.mds-view').show();
			this.setState({showView:true});
			$('#wrapper').show();
			$('.mds-manager').hide();
			let that = this;
			setTimeout(function(){
				that.showBoxBegin(that.state.allData);
			}, 3000);
			this.getStatusData();

		}else if(val == 'builder'){
			$('#wrapper').show();
			$('.mds-manager').hide();
			$('.mds-view').hide();
			this.getStatusData();
		}else if(val == 'instance'){
			this.setState({instanceManager:true});
			$('#wrapper').hide();
			$('.mds-manager').hide();
			$('.mds-view').hide();
			this.getDestination();
		}else if(val == 'trackerStatus'){
			this.setState({trackerStatusManager:true});
			$('#wrapper').hide();
			$('.mds-manager').hide();
			$('.mds-view').hide();
		}else if(val == 'taskManager'){
			this.setState({taskManager:true});
			$('#wrapper').hide();
			$('.mds-manager').hide();
			$('.mds-view').hide();
		}else if(val == 'workFlow'){
			this.setState({workFlow:true});
			$('#wrapper').hide();
			$('.mds-manager').hide();
			$('.mds-view').hide();
		}else if(val == 'workFlowDetails'){
			this.setState({workFlowDetails:true});
			$('#wrapper').hide();
			$('.mds-manager').hide();
			$('.mds-view').hide();
		}else if(val == 'workFlowManger'){
			this.setState({workFlowManger:true});
			$('#wrapper').hide();
			$('.mds-manager').hide();
			$('.mds-view').hide();
		}else if(val == 'workFlowProgress'){
			this.setState({workFlowProgress:true});
			$('#wrapper').hide();
			$('.mds-manager').hide();
			$('.mds-view').hide();
		}else if(val == 'scheduledworkFlow'){
			this.setState({scheduledworkFlow:true,workFlowProgress:false});
			$('#wrapper').hide();
			$('.mds-manager').hide();
			$('.mds-view').hide();	
		
		}else if(val == 'mds_builder'){
			this.setState({showMdsManageSection:true});
			$('#wrapper').hide();
			$('.mds-view').hide();
			let that = this;
			//that.getLayoutJsonFileVersion();
			setTimeout(function(){
				//$('.mds-manager').show();
				//that.getMdsJsonFileVersion();
				//that.getLayoutJsonFileVersion();
				
				//that.getLayoutLauguageJson();
				
				/* let token = that.state.token;
				that.getApiFiles(token).then(function(response) {
					
					let sheetApiName = that.state.sheetApiName;
					
					if(response){
						response.map((file,i)=>{
							let fileName = file.name;
							
							that.state.mdsSheetNames.map((sheet,i)=>{
								let apiName = sheetApiName.get(sheet.name);
								if(fileName == apiName){
									$('#'+sheet.sheetId+' img').attr("src","api-green.png");
								}
									
							});
							
							that.state.childSheetNames.map((sheet,i)=>{
								let apiName = sheetApiName.get(sheet.name);
								
								if(fileName == apiName){
									$('#'+sheet.sheetId+' img').attr("src","api-green.png");
								}
									
							});
						});
					}
				}, function(error) {
				  console.error("Failed!", error);
				}) */

			},2000);
			
			this.setState({showMdsList:true});
		}else if(val == 'status_tracker'){
			this.getTrackerData();
			this.setState({showTrackerTable:true});
			$("input[name='showViewForm']").prop('checked', false);
			
			let uri = window.location.toString();
            if (uri.indexOf("?") > 0) {
                let clean_uri = uri.substring(0, uri.indexOf("?"));
                window.history.replaceState({}, document.title, clean_uri);
            }
			
		}else if(val == 'layout_mongo'){
			this.setState({showMongoSection:true});
			
			$('#wrapper').hide();
			$('.mds-view').hide();
		}else if(val == 'mds_mongo'){
			this.setState({showMongoMdsSection:true});
			
			$('#wrapper').hide();
			$('.mds-view').hide();
		}else if(val == 'translation'){
			this.setState({showTranslationManager:true});
			$('#wrapper').hide();
			$('.mds-view').hide();
		}
		
		if(this.state.dataMode != 'Child'){
			$(".load-select-child option:selected").prop("selected", false);
		}
		
		this.setState({delegateFilter:''});
	}
	
	showBoxBegin=(mainData)=>{
		let classN = '';
		mainData.map((data,i)=>{
			if(data[5]=='boxend' || data[5].toLowerCase() == 'parent header' || data[5].toLowerCase() == 'child header 1' || data[5].toLowerCase() == 'child header 2' || data[5].toLowerCase() == 'child header 3'){
				classN = '';
			}
			
			$('#'+data[0]).addClass(classN);
			
			if(data[5]=='boxbegin' && !$('#'+data[0]).hasClass("not-include")){
				classN = 'in-box';
			}
		})
		
		mainData.map((data,i)=>{
			if(data[5].toLowerCase() == 'parent header' || data[5].toLowerCase() == 'child header 1' || data[5].toLowerCase() == 'child header 2' || data[5].toLowerCase() == 'child header 3'){
				let endlen = $( ".inner-"+data[0]+ ' .boxend-div').length;
				let len = $( ".inner-"+data[0]+ ' .in-box').length;
				let has = false;
				$( ".inner-"+data[0]+ ' .in-box').each(function( index ) {
					if (index === len - 1 && endlen == 0) {
						let lastChild = $( this ).attr('id');
						$('#'+lastChild).addClass('in-box-last');
					}
					
					if(!$(this).hasClass("not-include")){
						has = true;
					}
				  
				});
				
				if(has == false){
					$( ".inner-"+data[0]+ ' .boxbegin-div').addClass('hide');
					$( ".inner-"+data[0]+ ' .boxend-div').addClass('hide');
				}else{
					$( ".inner-"+data[0]+ ' .boxbegin-div').removeClass('hide');
					$( ".inner-"+data[0]+ ' .boxend-div').removeClass('hide');
				}
			}
		})
	}
	
	deleteSheet = (sheetId,type) => {
		let spreadsheetId = this.state.mdsSpreadsheetId;
		if(type == 'childMds'){
			spreadsheetId = this.state.childSpreadsheetId;
		}
		let params = {
			spreadsheetId: spreadsheetId,
		};
		let deleteRequest = {
			"requests": [
				{
				  "deleteSheet": {
					"sheetId": sheetId,
				  }
				}
			]
		}
					
		let request = gapi.client.sheets.spreadsheets.batchUpdate(params,deleteRequest);
		request.then(
		response => {
			$('#mds-'+sheetId).remove();
			alert('File Deleted Successfully');
			//this.getMdsSheets();
		}, function(reason) {
			alert(reason.result.error.message);
		});	
	}
	
	downloadMsdJson = (sheetName, type, fileSet) => {
		if(fileSet == 'mds'){
			$('#addMdsDescription').modal('hide');
			if(this.state.fileDescription == '' && type=='post'){
				$('#addMdsDescription').modal('show');
				return false;
			}
		}
		if(fileSet == 'child'){
			$('#addChildDescription').modal('hide');
			if(this.state.fileDescription == '' && type=='post'){
				$('#addChildDescription').modal('show');
				return false;
			}
		}
		
		let spreadsheetId = this.state.mdsSpreadsheetId;
		if(fileSet == 'child'){
			spreadsheetId = this.state.childSpreadsheetId;
		}
		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:sheetName, 
		})
		.then(
			response => {
				const allData = response.result.values;
				let currentDate = new Date().toLocaleString("en-US").replace(',','');
				//console.log('allData->',allData);
				let parentMds = [];
				if(allData.length > 0){
					let mdsName = allData[1][118];
					
					let mdsGuid = allData[1][91];
					let associatedType = allData[1][92];
					let associatedForm = allData[1][93];
					let associatedDevice = allData[1][94];
					let associatedVisit = allData[1][95];
					
					let Types = allData[1][100];
					let DeviceType = allData[1][101];
					let VisitType = allData[1][102];
					let totalFields = allData.length-1;
					let Industry = allData[1][80];
					let Profession = allData[1][81];
					let Subspecialty = allData[1][82];
					
					let delegateTypes = allData[1][119];
					let facilityId = allData[1][120] ? allData[1][120] : this.state.defaultLayoutFacility;
					let groupId = allData[1][121];
					let requiredGroups = allData[1][115];
					let version = allData[1][59] ? allData[1][59] : '1.01';
					let friendly_name = allData[1][62] ? allData[1][62] : '';
					let dates = [];
					let fields = [];
					allData.map((field,i)=>{
						if(i > 0){
							
							let date = new Date(field[98]);
							if(date != 'Invalid Date'){
								dates.push(date);
							}
							if(fileSet == 'child'){
								parentMds.push(field[129]);
							}
							let notes = field[12] = field[12].replaceAll('\n','');
							notes = notes.replaceAll('<p></p>','');
							let customNotes = field[116] = field[116].replaceAll('\n','');
							customNotes = customNotes.replaceAll('<p></p>','');
							
							let preId = '';
							let parentGuid = '';
							allData.map((pre_field,i)=>{
								if(field[7] && field[7] == pre_field[0]){
									preId = pre_field[117];
								}
								
								if(pre_field[1] == field[2]){
									parentGuid = pre_field[117];
								}
							})
							
							
							
							let oldP = field[16];
							let userArray = oldP.split(',');
							userArray.splice(0, 9);
							
							let uArray = userArray.filter(function(v){return v!==''});
							let kioskTypes = uArray.toString();
							kioskTypes = kioskTypes.replaceAll(',','|');

							let strArray = oldP.split(',');
							strArray.splice(9, 15);

							let configStr = strArray.toString();
							configStr = configStr+",["+kioskTypes+"]";
							
							if(fileSet == 'mds'){
								field[91] = '';
								field[129] = '';
								field[130] = '';
								field[131] = '';
							}
							
							let rqFields = [];
							let requiredFields = '';
							if(field[142] && field[5] == 'launchpad'){
								let rfStr = field[142].split(',');
								rfStr.map((rfield,i)=>{
									rfield = rfield.split('|');
									rqFields.push(rfield[0]+','+rfield[1]);
								});
								requiredFields = rqFields.join('|');
								
							}
							
							let opFields = [];
							let optionalFields = '';
							if(field[143] && field[5] == 'launchpad'){
								let opStr = field[143].split(',');
								opStr.map((ofield,i)=>{
									ofield = ofield.split('|');
									opFields.push(ofield[0]+','+ofield[1]);
								});
								optionalFields = opFields.join('|');
								
							}
							let options = [];
							
							let lastDate = new Date(field[98]).toLocaleString("en-US").replace(',','');
							let optionValue = [];
							let gridLabel = [];
							let SharedformID = "Shared form ID's";
							if(type == 'mongo'){
								if(field[6]){
									options = field[6].split(',');
								}
								if(field[5] == 'basicgrid' || field[5] == 'grid5point' || field[5] == 'grid-multicheck' || field[5] == 'grid-singlecheck'){
									let str = field[41] ? field[41].split('_') : [];
									
									if(str.length > 0){
										let columns = str[0].split(',');
										let rows = str[1].split(',');
										gridLabel = {
											"ColumnLabelValues": columns,
											"RowLabelValues": rows,
										}
									}
								}
								
								if(field[5] == 'basicgrid2'){
									let str = field[41] ? field[41].split('_') : [];
									
									if(str.length > 0){
										let columns = str[0].split('|');
										let rows = str[1].split(',');
										
										let columnslable = [];
										let columnstd = [];
										columns.map((val,i)=>{
											let cm = val.split(':');
											columnslable.push(cm[0]);
											columnstd.push(cm[1]);
										});
										gridLabel = {
											"ColumnLabelValues": columnslable,
											"RowLabelValues": rows,
											"ColumnDataType": columnstd,
										}
									}
								}
								
								if(field[5] == 'weighted'){
									let str = field[41] ? field[41].split(',') : [];
									
									str.map((val,i)=>{
										let op = val.split(':');
										options.push(op[0]);
										optionValue.push(op[1]);
									});
								}
								
								if(field[5] == 'grid-dropdown'){
									let str = field[41] ? field[41].split('_') : [];
									
									if(str.length > 0){
										let columns = str[0].split(',');
										let rows = str[1].split('|');
										
										//let columnslable = [];
										let rowValue = [];
										rows.map((val,i)=>{
											let cm = val.split(':');
											//columnslable.push(cm[0]);
											rowValue.push(cm[0]);
										});
										gridLabel = {
											"ColumnLabelValues": columns,
											"RowLabelValues": rowValue,
											//"ColumnDataType": columnstd,
										}
									}
								}
								
								if(field[5] == 'slider'){
									let str = field[41].split(',');
									if(str.length > 1){
										options = {
											"LabelLeft": str[0],
											"LabelRight": str[1],
										}
									}
								}
								
								SharedformID = 'LabelValues';
							}
							
							let fieldData = {
								"Field Name": field[0],
								"Parent #": field[2],
								"Data Agg": field[3],
								"Visible Text": field[4],
								"Field Type": field[5],
								"Options": field[6],
								"NewOptions": options.length > 0 ? options : '',
								"optionValue": optionValue.length > 0 ? optionValue : '',
								"GridLabelValue": gridLabel,
								//"Conditional Field Name": field[7],
								"Conditional Field Name": preId,
								"Conditional Option": field[8],
								
								"Output Text": field[9],
								"Position #": field[10],
								"Parent GUID": parentGuid,
								"Notes": notes,
								"Tags": field[13],
								"Include": field[14] == 'Y' ? true:false,
								"Required": field[15] == 'Y' ? true:false,
								
								"Position-Configuration": configStr,
								//"Position-Configuration": field[16],
								//"Custom Question-title-header GUID": field[35],
								"Delegate Types": "["+kioskTypes+"]",
								"Delegate Mode": field[37] == 'Y' ? true:false,
								"language": field[38],
								//"TAG-ID": field[39],
								"Button Label": field[40],
								[SharedformID]: field[41],
								"Value": field[48],
								"Unit": field[49],
								
								"data low limit": field[50],
								"data high limit": field[51],
								"Date Last Edited": moment(lastDate).format('YYYY-MM-DD HH:mm:ss'),
								"Image Location reference info": field[99],
								"Web Site Link": field[78],
								//"Public Field Bank": field[111] == 'Y' ? true:false,
								//"PFB=No Description": field[112],
								"Calculation Type": field[113],
								
								"Calculation Fields": field[114],
								"Company Name": field[115],
								"Custom Field Note": customNotes,
								"Field GUID": fileSet == 'child' ? field[131] : field[117],
								"Master Data Set GUID": field[129],
								//"Parent Form GUID": field[129],
								"Master Data Set Field Name": field[130],
								//"Parent Field GUID": field[131],
								"Selected Delegates": field[140],
								"Grid Columns": field[136],
								"Grid Rows": field[137],
								"RequiredFields": requiredFields,
								"OptionalFields": optionalFields,
								"OG Auto Tags": field[144],
								"Internal Data Persistence Level": field[110],
								"Tracking Code": field[125] ? field[125].split(',') : '',
								"Auto Tags": field[106] ? field[106] : '',
								"External Data Key": field[42] ? field[42] : '',
								"Internal Data Key": field[39] ? field[39] : '',
								"responseAction": field[44] ? field[44] : '',
							}
							
							const filteredObj = (obj) =>
							  Object.entries(obj)
								.filter(([_, value]) => !!value || typeof value === "boolean")
								.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
							
							fieldData = filteredObj(fieldData);
							fields.push(fieldData);
							
						}
						
						if(type == 'mongo'){
							fields = fields.map(item=>{
							  return Object
								.entries(item)
								.reduce((acc,[key,value])=>{
								  return {[key.replace(/\s/g, '')]:value, ...acc};
								}, {});
							});
						}	
					})
					
					let maximumDate = new Date(Math.max.apply(null, dates));
					let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
					let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
					
					parentMds = parentMds.filter(function(x) {
						 return x !== undefined;
					});
					let parentMdsGuid = this.uniqueArray2(parentMds);
					let itemString = parentMdsGuid.toString();
					
					let setGuid = 'Master Data Set GUID';
					let facilityID = 'MDS_Facility_ID';
					
					if(fileSet == 'child'){
						setGuid = 'Field Set GUID';
						facilityID = 'FieldSet_Facility_ID';
					}
					
					let layout ={
						"CreationDate": moment(currentDate).format('YYYY-MM-DD HH:mm:ss'),
						"ModifiedDate": moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
						"Form Name": sheetName,
						"Friendly Name": friendly_name,
						[setGuid]: mdsGuid,
						"Included MDS GUIDs": itemString,
						"Associated Type": associatedType,
						"Asociated Forms": associatedForm,
						"Associated Device Types": associatedDevice,
						"Associated Visit Types": associatedVisit,
						"Type Master List": Types.replaceAll('\n',''),
						"Device Type Master List": DeviceType.replaceAll('\n',''),
						"Visit Type Master List": VisitType.replaceAll('\n',''),
						"Industry": Industry,
						"Profession": Profession,
						"Subspecialty": Subspecialty,
						[facilityID]: facilityId,
						"Required Groups": requiredGroups ? requiredGroups.split(',') : '',
						"Filtering Groups": groupId ? groupId.split(',') : '',
						"All Delegate Types": delegateTypes,
						"Version": version,
						'Fields': fields,
					}
					
					if(type == 'mongo'){
						layout = this.fixKeys(layout);
					}
					
					//let dateCsv = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
					//this.download(JSON.stringify(layout), sheetName+'.json', "text/plain;charset=utf-8");
					let mdsJson = 'MDS_'+ sheetName +'_'+lastUpDate+'_'+ totalFields;
					if(fileSet == 'child'){
						mdsJson = 'FieldSet_'+ sheetName +'_'+lastUpDate+'_'+ totalFields;
					}
					
					if(type == 'post'){
						const file = new Blob([JSON.stringify(layout)], { type: "text/plain;charset=utf-8" });
						this.postMdsJson(this.state.sheetId,mdsJson, file, 'MDS');
						if(fileSet == 'child'){
							this.uploadParentMDS(parentMdsGuid);
						}
					}else if(type == 'mongo'){
						this.insertJsonMongo(fileSet,JSON.stringify(layout));
					}else{
						this.download(JSON.stringify(layout), mdsJson+'.json', "text/plain;charset=utf-8");
					}
				}
			},function(reason) {
				alert(reason.result.error.message);
			}
		);
	}
	
	
	fixKeys(obj) {
		if (Array.isArray(obj)) {
			obj.forEach(item => this.fixKeys(item));
		} else {
			Object.keys(obj).forEach(key => {
			  if (key.match(/\s+/g)) {
				let newKey = key.replace(/\s+/g, '');
				obj[newKey] = obj[key];
				delete obj[key];
			  }
			});
		}
		return obj;
	}
	
	downloadMsdJsonV2 = (sheetName, type, fileSet) => {
		if(fileSet == 'mds'){
			$('#addMdsDescription').modal('hide');
			if(this.state.fileDescription == '' && type=='post'){
				$('#addMdsDescription').modal('show');
				return false;
			}
		}
		
		let spreadsheetId = this.state.mdsSpreadsheetId;
		
		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:sheetName, 
		})
		.then(
			response => {
				const allData = response.result.values;
				let currentDate = new Date().toLocaleString("en-US").replace(',','');
				//console.log('allData->',allData);
				let parentMds = [];
				if(allData.length > 0){
					let mdsName = allData[1][118];
					
					let mdsGuid = allData[1][91];
					let associatedType = allData[1][92];
					let associatedForm = allData[1][93];
					let associatedDevice = allData[1][94];
					let associatedVisit = allData[1][95];
					
					let Types = allData[1][100];
					let DeviceType = allData[1][101];
					let VisitType = allData[1][102];
					let totalFields = allData.length-1;
					let Industry = allData[1][80];
					let Profession = allData[1][81];
					let Subspecialty = allData[1][82];
					
					let delegateTypes = allData[1][119];
					let facilityId = allData[1][120] ? allData[1][120] : this.state.defaultLayoutFacility;
					let groupId = allData[1][121];
					let requiredGroups = allData[1][115];
					let version = allData[1][59] ? allData[1][59] : '1.01';
					
					let dates = [];
					let fields = [];
					allData.map((field,i)=>{
						if(i > 0){
							let date = new Date(field[98]);
							if(date != 'Invalid Date'){
								dates.push(date);
							}
							/* if(fileSet == 'child'){
								parentMds.push(field[129]);
							} */
							let notes = field[12] = field[12].replaceAll('\n','');
							notes = notes.replaceAll('<p></p>','');
							let customNotes = field[116] = field[116].replaceAll('\n','');
							customNotes = customNotes.replaceAll('<p></p>','');
							
							let preId = '';
							let parentGuid = '';
							allData.map((pre_field,i)=>{
								if(field[7] && field[7] == pre_field[0]){
									preId = pre_field[117];
								}
								
								if(pre_field[1] == field[2]){
									parentGuid = pre_field[117];
								}
							})
							
							
							
							let oldP = field[16];
							let userArray = oldP.split(',');
							userArray.splice(0, 9);
							
							let uArray = userArray.filter(function(v){return v!==''});
							//let kioskTypes = uArray.toString();
							//kioskTypes = kioskTypes.replaceAll(',','|');
							
							let kioskT = [];
							uArray.map((val,i)=>{
								let fieldk = 'delegate'+parseInt(i+1);
								let fd = {
									[fieldk]: val.trim(),
								}
								kioskT.push(fd);
							});

							/* let strArray = oldP.split(',');
							strArray.splice(9, 15);

							let configStr = strArray.toString();
							configStr = configStr+",["+kioskTypes+"]"; */
							
							field[91] = '';
							field[129] = '';
							field[130] = '';
							field[131] = '';
							
							let rqFields = [];
							let requiredFields = '';
							if(field[142] && field[5] == 'launchpad'){
								let rfStr = field[142].split(',');
								rfStr.map((rfield,i)=>{
									rfield = rfield.split('|');
									rqFields.push(rfield[0]+','+rfield[1]);
								});
								requiredFields = rqFields.join('|');
								
							}
							
							let opFields = [];
							let optionalFields = '';
							if(field[143] && field[5] == 'launchpad'){
								let opStr = field[143].split(',');
								opStr.map((ofield,i)=>{
									ofield = ofield.split('|');
									opFields.push(ofield[0]+','+ofield[1]);
								});
								optionalFields = opFields.join('|');
								
							}
							
							let createdDate = new Date(field[97]).toLocaleString("en-US").replace(',','');
							let lastDate = new Date(field[98]).toLocaleString("en-US").replace(',','');
							
							let options = [];
							if(field[6]){
								options = field[6].split(',');
							}
							
							/* let dateValidation = [];
							if(field[48] == 'V9' || field[48] == 'V10' || field[48] == 'V11'){
								dateValidation = ["Any", "Past", "Today", "Future"];
							} */
							let dateValidation = '';
							if(field[48] == 'V9'){
								dateValidation = 'past'
							}else if(field[48] == 'V10'){
								dateValidation = 'today'
							}else if(field[48] == 'V11'){
								dateValidation = 'future'
							}
							
							let gridLabel = [];
							if(field[5] == 'basicgrid' || field[5] == 'grid5point' || field[5] == 'grid-multicheck' || field[5] == 'grid-singlecheck'){
								let str = field[41] ? field[41].split('_') : [];
								
								if(str.length > 0){
									
									let columns = str[0].split(',');
									let clabel = [];
									columns.map((val,i)=>{
										let fieldN = 'columnlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										clabel.push(fd);
									});
									
									let rows = str[1].split(',');
									let crow = [];
									rows.map((val,i)=>{
										let fieldN = 'rowlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										crow.push(fd);
									});
									gridLabel = {
										"ColumnLabelValues": clabel,
										"RowLabelValues": crow,
									}
								}
							}
							
							if(field[5] == 'basicgrid2'){
								let str = field[41] ? field[41].split('_') : [];
								
								if(str.length > 0){
									let columns = str[0].split('|');
									let rows = str[1].split(',');
									let crow = [];
									rows.map((val,i)=>{
										let fieldN = 'rowlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										crow.push(fd);
									});
									
									let columnslable = [];
									let columnstd = [];
									columns.map((val,i)=>{
										let cm = val.split(':');
										let fieldN = 'columnlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: cm[0].trim(),
										}
										
										columnslable.push(fd);
										columnstd.push(cm[1]);
									});
									
									let columnstd2 = [];
									columnstd.map((val,i)=>{
										let fieldm = 'column'+parseInt(i+1);
										let fds = {
											[fieldm]: val.trim(),
										}
										
										columnstd2.push(fds);
									});
									
									gridLabel = {
										"ColumnLabelValues": columnslable,
										"RowLabelValues": crow,
										"ColumnDataType": columnstd2,
									}
								}
							}
							let optionValue = [];
							if(field[5] == 'weighted'){
								let str = field[41] ? field[41].split(',') : [];
								str.map((val,i)=>{
									let op = val.split(':');
									options.push(op[0]);
									optionValue.push(op[1]);
								});
							}
							
							if(field[5] == 'grid-dropdown'){
								let str = field[41] ? field[41].split('_') : [];
								
								if(str.length > 0){
									let columns = str[0].split(',');
									let clabel = [];
									columns.map((val,i)=>{
										let fieldN = 'columnlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										clabel.push(fd);
									});
									let rows = str[1].split('|');
									
									//let columnslable = [];
									let rowValue = [];
									let coption = [];
									rows.map((val,i)=>{
										let cm = val.split(':');
										//columnslable.push(cm[0]);
										//rowValue.push(cm[0]);
										let fieldN = 'rowlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: cm[0].trim(),
										}
										rowValue.push(fd);
										
										cm.map((val,j)=>{
											if(j > 0){
												let fieldN = 'row'+parseInt(i+1)+'_columnoption'+parseInt(j);
												let fd = {
													[fieldN]: val.split(','),
												}
												coption.push(fd);
											}
											
										});
										
									});
									
									gridLabel = {
										"ColumnLabelValues": clabel,
										"RowLabelValues": rowValue,
										"ColumnOption": coption,
										//"ColumnDataType": columnstd,
									}
								}
							}
							
							let ctype = field[114];
							let ctypeVal = [];
							let calculationType = [];
							if(field[5] == 'calculation'){
								if(ctype.indexOf(',') > -1) {
									let ctypearr = ctype.split(',');
									ctypearr.map((val,i)=>{
										let fieldN = 'field'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										ctypeVal.push(fd);
									});
									
								}
								
								calculationType = ["Sum", "Average", "Subtract", "Highest Number", "Lowest Number", "Longest Time", "Shortest Time"]
							}
							
							let opVal = [];
							if(options.length > 0){
								options.map((val,i)=>{
									let fieldN = 'option'+parseInt(i+1);
									let fd = {
										[fieldN]: val.trim(),
									}
									opVal.push(fd);
								});
							}
							
							let TrackingCode = {'trackingcodelabel1':field[125]};
							
							if(field[125].indexOf(',') > -1) {
								TrackingCode = [];
								let arr = field[125].split(',');
								arr.map((val,i)=>{
									let fieldN = 'trackingcodelabel'+parseInt(i+1);
									let fd = {
										[fieldN]: val.trim(),
									}
									TrackingCode.push(fd);
								});
							}
							
							let FieldTypeOptions = {
								"Options": opVal.length > 0 ? opVal : '',
								//"OptionsValue": optionValue.length > 0 ? optionValue : '',
								"GridColumns": field[136],
								"GridRows": field[137],
								"GridLabelValue": Object.keys(gridLabel).length !== 0 ? gridLabel : '',
								"CalculationType": field[113],
								//"CalculationType": calculationType.length > 0 ? calculationType : '',
								"CalculationFields": ctypeVal.length > 0 ? ctypeVal : '',
								"WebsiteLink": field[78],
								//"TrackingCode": field[125] ? field[125].split(',') : '',
								"TrackingCode": TrackingCode.length > 0 ? TrackingCode : '',
								"DateValidation": dateValidation,
							}
							
							const filteredObj2 = (obj) =>
							  Object.entries(obj)
								.filter(([_, value]) => !!value || typeof value === "boolean")
								.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
							
							FieldTypeOptions = filteredObj2(FieldTypeOptions);
							
							let DataLowLimit = '';
							let DataHighLimit = '';
							if(field[48] != 'V2'){
								if(field[5] == 'Q6'){
									DataLowLimit = {
										'number':field[50],
									}
									
									DataHighLimit = {
										'number':field[51],
									}
								}
								
								if(field[5] == 'timer'){
									DataLowLimit = {
										'time':field[50],
									}
									
									DataHighLimit = {
										'time':field[51],
									}
								}
							}
							
							let fieldData = {
								"FieldGUID": field[117],
								"FieldName": field[0],
								"FieldNameIdentifier_STATIC": field[61],
								"VisibleText": field[4],
								"FieldType": field[5],
								"FieldTypeOptions": Object.keys(FieldTypeOptions).length !== 0 ? FieldTypeOptions : '',
								"PrefilteredDelegates": kioskT.length > 0 ? kioskT : '',
								"ButtonLabel": field[40],
								"ImageLocationReferenceInfo": field[99],
								"OutputText": field[9],
								"Notes": notes,
								"InternalDataPersistenceLabel": field[110],
								"Tags": field[13],
								"DataAgg": field[3] == 'Y' ? true:false,
								"AutoTags": field[106] ? field[106] : '',
								"ValidationType": field[48],
								"DataLowLimit": field[5] != 'Q8' ? DataLowLimit : '',
								"DataHighLimit":field[5] != 'Q8' ? DataHighLimit : '',
								"CreatedDate": field[97] ? moment(createdDate).format('YYYY-MM-DD HH:mm:ss') : moment(lastDate).format('YYYY-MM-DD HH:mm:ss'),
								"ModifiedDate": moment(lastDate).format('YYYY-MM-DD HH:mm:ss'),
								"IsActive": field[60] == 'Y' || field[60] == '' ? true:false,
								"Translations": false,
							}
							
							const filteredObj = (obj) =>
							  Object.entries(obj)
								.filter(([_, value]) => !!value || typeof value === "boolean")
								.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
							
							fieldData = filteredObj(fieldData);
							fields.push(fieldData);
							
						}
					})

					let maximumDate = new Date(Math.max.apply(null, dates));
					let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
					let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
					
					parentMds = parentMds.filter(function(x) {
						 return x !== undefined;
					});
					let parentMdsGuid = this.uniqueArray2(parentMds);
					let itemString = parentMdsGuid.toString();
					
					let setGuid = 'MDSGuid';
					let facilityID = 'MDS_Facility_ID';
					
					/* if(fileSet == 'child'){
						setGuid = 'Field Set GUID';
						facilityID = 'FieldSet_Facility_ID';
					} */
					
					let layout ={
						[setGuid]: mdsGuid,
						"MDSName": sheetName,
						"CreatedDate": moment(currentDate).format('YYYY-MM-DD HH:mm:ss'),
						"ModifiedDate": moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
						'Version': version,
						'Fields': fields,
					}
					
					//let dateCsv = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
					//this.download(JSON.stringify(layout), sheetName+'.json', "text/plain;charset=utf-8");
					let mdsJson = 'MDS_'+ sheetName +'_'+lastUpDate+'_'+ totalFields;
					if(fileSet == 'child'){
						mdsJson = 'FieldSet_'+ sheetName +'_'+lastUpDate+'_'+ totalFields;
					}
					
					if(type == 'post'){
						const file = new Blob([JSON.stringify(layout)], { type: "text/plain;charset=utf-8" });
						this.postMdsJson(this.state.sheetId,mdsJson, file, 'MDS');
						if(fileSet == 'child'){
							this.uploadParentMDS(parentMdsGuid);
						}
					}else if(type == 'mongo'){
						this.insertJsonMongo('mds',JSON.stringify(layout));
					}else{
						this.download(JSON.stringify(layout), mdsJson+'.json', "text/plain;charset=utf-8");
					}
					
					
				}

			},function(reason) {
				alert(reason.result.error.message);
			}
		);
	}
	
	defaultLayoutJsonV2 = (sheetName, type, fileSet) => {
		
		let spreadsheetId = this.state.childSpreadsheetId;
		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:sheetName, 
		})
		.then(
			response => {
				const allData = response.result.values;
				let currentDate = new Date().toLocaleString("en-US").replace(',','');
				//console.log('allData->',allData);
				let parentMds = [];
				let mdsFiles = [];
				if(allData.length > 0){
					let mdsName = allData[1][118];
					
					let mdsGuid = allData[1][91];
					let associatedType = allData[1][92];
					let associatedForm = allData[1][93];
					let associatedDevice = allData[1][94];
					let associatedVisit = allData[1][95];
					
					let Types = allData[1][100];
					let DeviceType = allData[1][101];
					let VisitType = allData[1][102];
					let totalFields = allData.length-1;
					let Industry = allData[1][80];
					let Profession = allData[1][81];
					let Subspecialty = allData[1][82];
					
					let delegateTypes = allData[1][119];
					let facilityId = allData[1][120] ? allData[1][120] : this.state.defaultLayoutFacility;
					let groupId = allData[1][121];
					let requiredGroups = allData[1][115];
					let version = allData[1][59] ? allData[1][59] : '1.01';
					let friendly_name = allData[1][62] ? allData[1][62] : '';
					
					let dates = [];
					let fields = [];
					allData.map((field,i)=>{
						if(i > 0){
							
							let date = new Date(field[98]);
							if(date != 'Invalid Date'){
								dates.push(date);
							}
							
							parentMds.push(field[129]);
							
							let mdsSheetWithSid = field[58] ? field[58]+','+field[118]+','+field[117] : '';
							mdsFiles.push(mdsSheetWithSid);
							
							let notes = field[12] = field[12].replaceAll('\n','');
							notes = notes.replaceAll('<p></p>','');
							let customNotes = field[116] = field[116].replaceAll('\n','');
							customNotes = customNotes.replaceAll('<p></p>','');
							
							let preId = '';
							let parentGuid = '';
							allData.map((pre_field,i)=>{
								if(field[7] && field[7] == pre_field[0]){
									preId = pre_field[117];
								}
								
								if(pre_field[1] == field[2]){
									parentGuid = pre_field[117];
								}
							})

							let oldP = field[16];
							let userArray = oldP.split(',');
							userArray.splice(0, 9);
							
							let uArray = userArray.filter(function(v){return v!==''});
							let kioskTypes = uArray.toString();
							kioskTypes = kioskTypes.replaceAll(',','|');

							let strArray = oldP.split(',');
							strArray.splice(9, 15);

							let configStr = strArray.toString();
							configStr = configStr+",["+kioskTypes+"]";
							
							let rqFields = [];
							let requiredFields = '';
							if(field[142] && field[5] == 'launchpad'){
								let rfStr = field[142].split(',');
								rfStr.map((rfield,i)=>{
									rfield = rfield.split('|');
									rqFields.push(rfield[0]+','+rfield[1]);
								});
								requiredFields = rqFields.join('|');
								
							}
							
							let opFields = [];
							let optionalFields = '';
							if(field[143] && field[5] == 'launchpad'){
								let opStr = field[143].split(',');
								opStr.map((ofield,i)=>{
									ofield = ofield.split('|');
									opFields.push(ofield[0]+','+ofield[1]);
								});
								optionalFields = opFields.join('|');
								
							}
							let lastDate = new Date(field[98]).toLocaleString("en-US").replace(',','');
							

							let fieldData = {
								"FieldGUID": field[131] ? field[131] : field[117],
								"Position#": field[10],
								"Include": field[14] == 'Y' ? true:false,
								"Required": field[15] == 'Y' ? true:false,
								"DelegateTypes": "["+kioskTypes+"]",
								"ParentGUID": parentGuid,
							}
							
							const filteredObj = (obj) =>
							  Object.entries(obj)
								.filter(([_, value]) => !!value || typeof value === "boolean")
								.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
							
							fieldData = filteredObj(fieldData);
							fields.push(fieldData);
							
						}
					})

					let maximumDate = new Date(Math.max.apply(null, dates));
					let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
					let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
					
					parentMds = parentMds.filter(function(x) {
						 return x !== undefined;
					});
					let parentMdsGuid = this.uniqueArray2(parentMds);
					let itemString = parentMdsGuid.toString();
					
					let mdsFilesArray = this.uniqueArray2(mdsFiles);
					
					if(mdsFilesArray.length > 0){
						
						mdsFilesArray.map((fls,i)=>{
							let fileStr = fls.split(',');
							this.uploadOldMdsJsonMongoDb(fileStr[0],fileStr[1]);
							//this.uploadMdsJsonMongoDb(fileStr[0],fileStr[1]);
						});
						
					}
					
					let layout ={
						"DefaultLayoutName": sheetName,
						"DefaultLayoutGuid": mdsGuid,
						"IncludedMDSGUIDs": itemString,
						"FriendlyName": friendly_name,
						"AssociatedType": associatedType,
						"AssociatedDeviceTypes": associatedDevice,
						"AssociatedVisitTypes": associatedVisit,
						"AssociatedTrack": [],
						"Version": version,
						"CreatedDate": moment(currentDate).format('YYYY-MM-DD HH:mm:ss'),
						"ModifiedDate": moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
						"Fields": fields,
					}
					
					/* let layout ={
						"DefaultLayoutHeaders": DefaultLayoutHeaders,
						"DefaultLayoutFields": fields,
					} */
					
					let mdsJson = 'FieldSet_'+ sheetName +'_'+lastUpDate+'_'+ totalFields;
					if(type == 'mongo'){
						this.insertJsonMongo('child',JSON.stringify(layout));
					}else{
						this.download(JSON.stringify(layout), mdsJson+'.json', "text/plain;charset=utf-8");
					}
					//this.insertJsonMongo('child',JSON.stringify(layout));
				}

			},function(reason) {
				alert(reason.result.error.message);
			}
		);
	}
	
	saveMdsJsonDataMongodb(sheetName, type, fileSet){
		this.downloadMsdJson(sheetName, type, fileSet);
		/* if(fileSet == 'mds'){
			this.downloadMsdJsonV2(sheetName, type, fileSet);
		} else if(fileSet == 'child'){
			this.defaultLayoutJsonV2(sheetName, type, fileSet);
		} */
		
		let that = this;
		setTimeout(function(){
			that.getMdsSheets();
			if(fileSet == 'mds'){
				that.getMdsJsonFileVersion();
			} else if(fileSet == 'child'){
				that.getLayoutJsonFileVersion();
			}
		}, 3000);
		
		this.setState({newMdsName: ''});
	}
	
	/* saveMdsJsonDataMongodb2(sheetName, type, fileSet,sheetGuid,version){
		this.downloadMsdJson(sheetName, type, fileSet);
		if(fileSet == 'mds'){
			this.downloadMsdJsonV2(sheetName, type, fileSet);
		} else if(fileSet == 'child'){
			this.defaultLayoutJsonV2(sheetName, type, fileSet);
		}
		
		let that = this;
		setTimeout(function(){
			
			if(fileSet == 'mds'){
				let mdsJsonFilesVersion = that.state.mdsJsonFilesVersion;
				mdsJsonFilesVersion.set(sheetGuid,version);
				that.setState({mdsJsonFilesVersion});
			} else if(fileSet == 'child'){
				let layoutJsonFilesVersion = that.state.layoutJsonFilesVersion;
				layoutJsonFilesVersion.set(sheetGuid,version);
				that.setState(layoutJsonFilesVersion);
			}
		}, 1000);
		
		this.setState({newMdsName: ''});
	} */
	
	insertJsonMongo(type,data){
		let mongoApiUrl = $('#mongoApiUrl').val();
		let url = mongoApiUrl+'insert-mds';
		if(type == 'child'){
			url = mongoApiUrl+'insert-layout';
		}
		axios({
			method: 'POST',
			url: url,
			data: data,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			//console.log('response::', response.data);
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	downloadZipJson = (sheetName,fileSet,spreadsheetId) => {
		return new Promise((resolve, reject) => {
			if(fileSet == 'child'){
				this.setState({layoutData:[]});
				spreadsheetId = this.state.childSpreadsheetId;
			}
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: spreadsheetId,
				range:sheetName, 
			})
			.then(
				response => {
					const allData = response.result.values;
					let currentDate = new Date().toLocaleString("en-US").replace(',','');
					let parentMds = [];
					let arry = [];
					
					if(fileSet == 'child'){
						this.setState({layoutData:allData});
					}
					
					if(allData.length > 0){
						let mdsName = allData[1][118];
						
						let mdsGuid = allData[1][91];
						let associatedType = allData[1][92];
						let associatedForm = allData[1][93];
						let associatedDevice = allData[1][94];
						let associatedVisit = allData[1][95];
						
						let Type = allData[1][100];
						let DeviceType = allData[1][101];
						let VisitType = allData[1][102];
						let totalFields = allData.length-1;
						let Industry = allData[1][80];
						let Profession = allData[1][81];
						let Subspecialty = allData[1][82];
						
						let delegateTypes = allData[1][119];
						let facilityId = allData[1][120] ? allData[1][120] : this.state.defaultLayoutFacility;
						let groupId = allData[1][121];
						let requiredGroups = allData[1][115];
						let version = allData[1][59];
						let friendly_name = allData[1][62];
						let dates = [];
						let fields = [];
						let mdsFiles = [];
						allData.map((field,i)=>{
							if(i > 0){
								
								let date = new Date(field[98]);
								if(date != 'Invalid Date'){
									dates.push(date);
								}
								if(fileSet == 'child'){
									parentMds.push(field[129]);
									let mdsSheetWithSid = field[58] ? field[58]+','+field[118]+','+field[117] : '';
									mdsFiles.push(mdsSheetWithSid);
								}
								let notes = field[12] = field[12].replaceAll('\n','');
								notes = notes.replaceAll('<p></p>','');
								let customNotes = field[116] = field[116].replaceAll('\n','');
								customNotes = customNotes.replaceAll('<p></p>','');
								
								let preId = '';
								let parentGuid = '';
								allData.map((pre_field,i)=>{
									if(field[7] && field[7] == pre_field[0]){
										preId = pre_field[117];
									}
									
									if(pre_field[1] == field[2]){
										parentGuid = pre_field[117];
									}
								})
								if(fileSet == 'mds'){
									let layoutData = this.state.layoutData;
									layoutData.map((lyout,i)=>{
										if(field[117] == lyout[117]){
											console.log(lyout[117],field[117]);
											field[14] = lyout[14];
											field[15] = lyout[15];
										}
									})
								}
								
								let oldP = field[16];
								let userArray = oldP.split(',');
								userArray.splice(0, 9);
								
								let uArray = userArray.filter(function(v){return v!==''});
								let kioskTypes = uArray.toString();
								kioskTypes = kioskTypes.replaceAll(',','|');

								let strArray = oldP.split(',');
								strArray.splice(9, 15);

								let configStr = strArray.toString();
								configStr = configStr+",["+kioskTypes+"]";
								
								if(fileSet == 'mds'){
									field[91] = '';
									field[129] = '';
									field[130] = '';
									field[131] = '';
								}
								
								let rqFields = [];
								let requiredFields = '';
								if(field[142] && field[5] == 'launchpad'){
									let rfStr = field[142].split(',');
									rfStr.map((rfield,i)=>{
										rfield = rfield.split('|');
										rqFields.push(rfield[0]+','+rfield[1]);
									});
									requiredFields = rqFields.join('|');
									
								}
								
								let opFields = [];
								let optionalFields = '';
								if(field[143] && field[5] == 'launchpad'){
									let opStr = field[143].split(',');
									opStr.map((ofield,i)=>{
										ofield = ofield.split('|');
										opFields.push(ofield[0]+','+ofield[1]);
									});
									optionalFields = opFields.join('|');
									
								}
								
								let lastDate = new Date(field[98]).toLocaleString("en-US").replace(',','');
								
								let fieldData = {
									"Field Name": field[0],
									"Parent #": field[2],
									"Data Agg": field[3],
									"Visible Text": field[4],
									"Field Type": field[5],
									"Options": field[6],
									//"Conditional Field Name": field[7],
									"Conditional Field Name": preId,
									"Conditional Option": field[8],
									
									"Output Text": field[9],
									"Position #": field[10],
									"Parent GUID": parentGuid,
									"Notes": notes,
									"Tags": field[13],
									"Include": field[14] == 'Y' ? true:false,
									"Required": field[15] == 'Y' ? true:false,
									
									"Position-Configuration": configStr,
									//"Position-Configuration": field[16],
									//"Custom Question-title-header GUID": field[35],
									"Delegate Types": "["+kioskTypes+"]",
									"Delegate Mode": field[37] == 'Y' ? true:false,
									"language": field[38],
									//"TAG-ID": field[39],
									"Button Label": field[40],
									"Shared form ID's": field[41],
									"Value": field[48],
									"Unit": field[49],
									
									"data low limit": field[50],
									"data high limit": field[51],
									"Date Last Edited": moment(lastDate).format('YYYY-MM-DD HH:mm:ss'),
									"Image Location reference info": field[99],
									"Web Site Link": field[78],
									//"Public Field Bank": field[111] == 'Y' ? true:false,
									//"PFB=No Description": field[112],
									"Calculation Type": field[113],
									
									"Calculation Fields": field[114],
									"Company Name": field[115],
									"Custom Field Note": customNotes,
									"Field GUID": fileSet == 'child' ? field[131] : field[117],
									"Master Data Set GUID": field[129],
									//"Parent Form GUID": field[129],
									"Master Data Set Field Name": field[130],
									//"Parent Field GUID": field[131],
									"Selected Delegates": field[140],
									"Grid Columns": field[136],
									"Grid Rows": field[137],
									"RequiredFields": requiredFields,
									"OptionalFields": optionalFields,
									"OG Auto Tags": field[144],
									"Internal Data Persistence Level": field[110],
									"Tracking Code": field[125] ? field[125].split(',') : '',
									"Auto Tags": field[106] ? field[106] : '',
									"External Data Key": field[42] ? field[42] : '',
									"Internal Data Key": field[39] ? field[39] : '',
									"responseAction": field[44] ? field[44] : '',
								}
								
								const filteredObj = (obj) =>
								  Object.entries(obj)
									.filter(([_, value]) => !!value || typeof value === "boolean")
									.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
								
								fieldData = filteredObj(fieldData);
								fields.push(fieldData);
							}	
						})

						let maximumDate = new Date(Math.max.apply(null, dates));
						let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
						let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
						
						parentMds = parentMds.filter(function(x) {
							 return x !== undefined;
						});
						let parentMdsGuid = this.uniqueArray2(parentMds);
						let mdsFilesArray = this.uniqueArray2(mdsFiles);
						let itemString = parentMdsGuid.toString();
						
						let setGuid = 'Master Data Set GUID';
						if(fileSet == 'child'){
							setGuid = 'Field Set GUID';
						}
						
						let layout ={
							"CreationDate": moment(currentDate).format('YYYY-MM-DD HH:mm:ss'),
							"ModifiedDate": moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
							"Form Name": sheetName,
							//"Friendly Name": friendly_name,
							[setGuid]: mdsGuid,
							"Included MDS GUID's": itemString,
							"Associated Type": associatedType,
							"Asociated Forms": associatedForm,
							"Associated Device Types": associatedDevice,
							"Associated Visit Types": associatedVisit,
							"Type Master List": Type.replaceAll('\n',''),
							"Device Type Master List": DeviceType.replaceAll('\n',''),
							"Visit Type Master List": VisitType.replaceAll('\n',''),
							"Industry": Industry,
							"Profession": Profession,
							"Subspecialty": Subspecialty,
							"Required Groups": requiredGroups ? requiredGroups.split(',') : '',
							"Filtering Groups": groupId ? groupId.split(',') : '',
							"All Delegate Types": delegateTypes,
						}
						
						if(fileSet == 'child'){
							let layoutFriendlyName = this.state.layoutFriendlyName;
							layout['FieldSet_Facility_ID'] = facilityId;
							
							if(friendly_name != ''){
								layout['Form Name'] = friendly_name;
							}
						}
						
						layout['Fields'] = fields;
						
						let mdsJson = 'MDS_'+ sheetName +'_'+lastUpDate+'_'+ totalFields;
						if(fileSet == 'child'){
							mdsJson = 'FieldSet_'+ sheetName +'_'+lastUpDate+'_'+ totalFields;
						}
						let fileName = mdsJson+'.json';
						let data = JSON.stringify(layout);
						
						//console.log('mdsFilesArray->',mdsFilesArray);
						arry = [fileName,data,mdsFilesArray];
					}
					
					resolve(arry);
					
				},function(reason) {
					let arry = [];
					//alert(reason.result.error.message);
					resolve(arry);
				}
			);
		});
	}
	
	newDownloadZipJson = (sheetName,fileSet,spreadsheetId) => {
		return new Promise((resolve, reject) => {
			//let spreadsheetId = this.state.mdsSpreadsheetId;
			if(fileSet == 'child'){
				spreadsheetId = this.state.childSpreadsheetId;
			}
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: spreadsheetId,
				range:sheetName, 
			})
			.then(
				response => {
					const allData = response.result.values;
					let currentDate = new Date().toLocaleString("en-US").replace(',','');
					//console.log('allData->',allData);
					let parentMds = [];
					let arry = [];
					if(allData.length > 0){
						let mdsName = allData[1][118];
						
						let mdsGuid = allData[1][91];
						let associatedType = allData[1][92];
						let associatedForm = allData[1][93];
						let associatedDevice = allData[1][94];
						let associatedVisit = allData[1][95];
						
						let Type = allData[1][100];
						let DeviceType = allData[1][101];
						let VisitType = allData[1][102];
						let totalFields = allData.length-1;
						let Industry = allData[1][80];
						let Profession = allData[1][81];
						let Subspecialty = allData[1][82];
						
						let delegateTypes = allData[1][119];
						let facilityId = allData[1][120] ? allData[1][120] : this.state.defaultLayoutFacility;
						let groupId = allData[1][121];
						let requiredGroups = allData[1][115];
						let version = allData[1][59];
						let friendly_name = allData[1][62];
						let dates = [];
						let fields = [];
						let mdsFiles = [];
						allData.map((field,i)=>{
							if(i > 0){
								
								let date = new Date(field[98]);
								if(date != 'Invalid Date'){
									dates.push(date);
								}
								if(fileSet == 'child'){
									parentMds.push(field[129]);
									let mdsSheetWithSid = field[58] ? field[58]+','+field[118]+','+field[117] : '';
									mdsFiles.push(mdsSheetWithSid);
								}
								let notes = field[12] = field[12].replaceAll('\n','');
								notes = notes.replaceAll('<p></p>','');
								let customNotes = field[116] = field[116].replaceAll('\n','');
								customNotes = customNotes.replaceAll('<p></p>','');
								
								let preId = '';
								let parentGuid = '';
								allData.map((pre_field,i)=>{
									if(field[7] && field[7] == pre_field[0]){
										preId = pre_field[117];
									}
									
									if(pre_field[1] == field[2]){
										parentGuid = pre_field[117];
									}
								})
								
								let oldP = field[16];
								let userArray = oldP.split(',');
								userArray.splice(0, 9);
								
								let uArray = userArray.filter(function(v){return v!==''});
								let kioskTypes = uArray.toString();
								kioskTypes = kioskTypes.replaceAll(',','|');
								
								let kioskT = [];
								uArray.map((val,i)=>{
									let fieldk = 'delegate'+parseInt(i+1);
									let fd = {
										[fieldk]: val.trim(),
									}
									kioskT.push(fd);
								});

								/* let strArray = oldP.split(',');
								strArray.splice(9, 15); */

								/* let configStr = strArray.toString();
								configStr = configStr+",["+kioskTypes+"]"; */
								
								field[91] = '';
								field[129] = '';
								field[130] = '';
								field[131] = '';
								
								let rqFields = [];
								let requiredFields = '';
								if(field[142] && field[5] == 'launchpad'){
									let rfStr = field[142].split(',');
									rfStr.map((rfield,i)=>{
										rfield = rfield.split('|');
										rqFields.push(rfield[0]+','+rfield[1]);
									});
									requiredFields = rqFields.join('|');
									
								}
								
								let opFields = [];
								let optionalFields = '';
								if(field[143] && field[5] == 'launchpad'){
									let opStr = field[143].split(',');
									opStr.map((ofield,i)=>{
										ofield = ofield.split('|');
										opFields.push(ofield[0]+','+ofield[1]);
									});
									optionalFields = opFields.join('|');
									
								}
								let createdDate = new Date(field[97]).toLocaleString("en-US").replace(',','');
								let lastDate = new Date(field[98]).toLocaleString("en-US").replace(',','');
								
								let options = [];
								if(field[6]){
									options = field[6].split(',');
								}
								/* let dateValidation = [];
								if(field[48] == 'V9' || field[48] == 'V10' || field[48] == 'V11'){
									dateValidation = ["Any", "Past", "Today", "Future"];
								} */
								
								let dateValidation = '';
								if(field[48] == 'V9'){
									dateValidation = 'past'
								}else if(field[48] == 'V10'){
									dateValidation = 'today'
								}else if(field[48] == 'V11'){
									dateValidation = 'future'
								}
								
								let gridLabel = [];
								if(field[5] == 'basicgrid' || field[5] == 'grid5point' || field[5] == 'grid-multicheck' || field[5] == 'grid-singlecheck'){
									let str = field[41] ? field[41].split('_') : [];
									
									if(str.length > 0){
										
										let columns = str[0].split(',');
										let clabel = [];
										columns.map((val,i)=>{
											let fieldN = 'columnlabel'+parseInt(i+1);
											let fd = {
												[fieldN]: val.trim(),
											}
											clabel.push(fd);
										});
										
										let rows = str[1].split(',');
										let crow = [];
										rows.map((val,i)=>{
											let fieldN = 'rowlabel'+parseInt(i+1);
											let fd = {
												[fieldN]: val.trim(),
											}
											crow.push(fd);
										});
										gridLabel = {
											"ColumnLabelValues": clabel,
											"RowLabelValues": crow,
										}
									}
								}
								
								if(field[5] == 'basicgrid2'){
								let str = field[41] ? field[41].split('_') : [];
								
								if(str.length > 0){
									let columns = str[0].split('|');
									let rows = str[1].split(',');
									let crow = [];
									rows.map((val,i)=>{
										let fieldN = 'rowlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										crow.push(fd);
									});
									
									let columnslable = [];
									let columnstd = [];
									columns.map((val,i)=>{
										let cm = val.split(':');
										let fieldN = 'columnlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: cm[0].trim(),
										}
										
										columnslable.push(fd);
										columnstd.push(cm[1]);
									});
									
									let columnstd2 = [];
									columnstd.map((val,i)=>{
										let fieldm = 'column'+parseInt(i+1);
										let fds = {
											[fieldm]: val.trim(),
										}
										
										columnstd2.push(fds);
									});
									
									gridLabel = {
										"ColumnLabelValues": columnslable,
										"RowLabelValues": crow,
										"ColumnDataType": columnstd2,
									}
								}
							}
								let optionValue = [];
								if(field[5] == 'weighted'){
									let str = field[41] ? field[41].split(',') : [];
									str.map((val,i)=>{
										let op = val.split(':');
										options.push(op[0]);
										optionValue.push(op[1]);
									});
								}
								
								if(field[5] == 'grid-dropdown'){
									let str = field[41] ? field[41].split('_') : [];
									
									if(str.length > 0){
										let columns = str[0].split(',');
										let clabel = [];
										columns.map((val,i)=>{
											let fieldN = 'columnlabel'+parseInt(i+1);
											let fd = {
												[fieldN]: val.trim(),
											}
											clabel.push(fd);
										});
										let rows = str[1].split('|');
										
										//let columnslable = [];
										let rowValue = [];
										let coption = [];
										rows.map((val,i)=>{
											let cm = val.split(':');
											//columnslable.push(cm[0]);
											//rowValue.push(cm[0]);
											let fieldN = 'rowlabel'+parseInt(i+1);
											let fd = {
												[fieldN]: cm[0].trim(),
											}
											rowValue.push(fd);
											
											cm.map((val,j)=>{
												if(j > 0){
													let fieldN = 'row'+parseInt(i+1)+'_columnoption'+parseInt(j);
													let fd = {
														[fieldN]: val.split(','),
													}
													coption.push(fd);
												}
												
											});
											
										});
										
										gridLabel = {
											"ColumnLabelValues": clabel,
											"RowLabelValues": rowValue,
											"ColumnOption": coption,
											//"ColumnDataType": columnstd,
										}
									}
								}
								
								let ctype = field[114];
								let ctypeVal = [];
								let calculationType = [];
								if(field[5] == 'calculation'){
									if(ctype.indexOf(',') > -1) {
										let ctypearr = ctype.split(',');
										ctypearr.map((val,i)=>{
											let fieldN = 'field'+parseInt(i+1);
											let fd = {
												[fieldN]: val.trim(),
											}
											ctypeVal.push(fd);
										});
										
									}
									
									calculationType = ["Sum", "Average", "Subtract", "Highest Number", "Lowest Number", "Longest Time", "Shortest Time"]
								}
								
								let opVal = [];
								if(options.length > 0){
									options.map((val,i)=>{
										let fieldN = 'option'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										opVal.push(fd);
									});
								}
								
								let TrackingCode = {'trackingcodelabel1':field[125]};
								
								if(field[125].indexOf(',') > -1) {
									TrackingCode = [];
									let arr = field[125].split(',');
									arr.map((val,i)=>{
										let fieldN = 'trackingcodelabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										TrackingCode.push(fd);
									});
								}
								
								let FieldTypeOptions = {
									"Options": opVal.length > 0 ? opVal : '',
									//"OptionsValue": optionValue.length > 0 ? optionValue : '',
									"GridColumns": field[136],
									"GridRows": field[137],
									"GridLabelValue": Object.keys(gridLabel).length !== 0 ? gridLabel : '',
									"CalculationType": field[113],
									//"CalculationType": calculationType.length > 0 ? calculationType : '',
									"CalculationFields": ctypeVal.length > 0 ? ctypeVal : '',
									"WebsiteLink": field[78],
									//"TrackingCode": field[125] ? field[125].split(',') : '',
									"TrackingCode": TrackingCode.length > 0 ? TrackingCode : '',
									"DateValidation": dateValidation,
								}
								
								/* const filteredObj2 = (obj) =>
								  Object.entries(obj)
									.filter(([_, value]) => !!value || typeof value === "boolean")
									.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
								
								FieldTypeOptions = filteredObj2(FieldTypeOptions); */
								
								let DataLowLimit = '';
								let DataHighLimit = '';
								if(field[48] != 'V2'){
									if(field[5] == 'Q6'){
										DataLowLimit = {
											'number':field[50],
										}
										
										DataHighLimit = {
											'number':field[51],
										}
									}
									
									if(field[5] == 'timer'){
										DataLowLimit = {
											'time':field[50],
										}
										
										DataHighLimit = {
											'time':field[51],
										}
									}
								}
								
								let fieldData = {
									"FieldGUID": field[117],
									"MasterDataSetGUID": mdsGuid,
									"FieldName": field[0],
									"FieldNameIdentifier_STATIC": field[61],
									"VisibleText": field[4],
									"FieldType": field[5],
									"FieldTypeOptions": Object.keys(FieldTypeOptions).length !== 0 ? FieldTypeOptions : '',
									"PrefilteredDelegates": kioskT.length > 0 ? kioskT : '',
									"ButtonLabel": field[40],
									"ImageLocationReferenceInfo": field[99],
									"OutputText": field[9],
									"Notes": notes,
									"InternalDataPersistenceLabel": field[110],
									"Tags": field[13],
									"DataAgg": field[3] == 'Y' ? true:false,
									"AutoTags": field[106] ? field[106] : '',
									"ValidationType": field[48],
									"DataLowLimit": field[5] != 'Q8' ? DataLowLimit : '',
									"DataHighLimit":field[5] != 'Q8' ? DataHighLimit : '',
									"CreatedDate": field[97] ? moment(createdDate).format('YYYY-MM-DD HH:mm:ss') : moment(lastDate).format('YYYY-MM-DD HH:mm:ss'),
									"ModifiedDate": moment(lastDate).format('YYYY-MM-DD HH:mm:ss'),
									"IsActive": field[60] == 'Y' || field[60] == '' ? true:false,
									"Translations": false,
								}
								
								if(fileSet == 'child'){
									fieldData = {
										"FieldGUID": field[131] ? field[131] : field[117],
										"Position#": field[10],
										"Include": field[14] == 'Y' ? true:false,
										"Required": field[15] == 'Y' ? true:false,
										"DelegateTypes": "["+kioskTypes+"]",
										"ParentGUID": parentGuid,
									}
								}
							
								/* const filteredObj = (obj) =>
								  Object.entries(obj)
									.filter(([_, value]) => !!value || typeof value === "boolean")
									.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
								
								fieldData = filteredObj(fieldData); */
								fields.push(fieldData);
							}	
						})

						let maximumDate = new Date(Math.max.apply(null, dates));
						let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
						let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
						
						parentMds = parentMds.filter(function(x) {
							 return x !== undefined;
						});
						let parentMdsGuid = this.uniqueArray2(parentMds);
						let mdsFilesArray = this.uniqueArray2(mdsFiles);
						let itemString = parentMdsGuid.toString();
						
						let setGuid = 'MDSGuid';
						let facilityID = 'MDS_Facility_ID';
						
						let layout ={
							[setGuid]: mdsGuid,
							"MDSName": sheetName,
							"CreatedDate": moment(currentDate).format('YYYY-MM-DD HH:mm:ss'),
							"ModifiedDate": moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
							'Fields': fields,
						}
						
						if(fileSet == 'child'){
							
							let DefaultLayoutHeaders ={
								"DefaultLayoutName": sheetName,
								"DefaultLayoutGuid": mdsGuid,
								"FriendlyName": friendly_name,
								"IncludedMDSGUIDs": itemString,
								"AssociatedType": associatedType,
								"AssociatedDeviceTypes": associatedDevice,
								"AssociatedVisitTypes": associatedVisit,
								"AssociatedTrack": [],
								"Version": version,
								"CreatedDate": moment(currentDate).format('YYYY-MM-DD HH:mm:ss'),
								"ModifiedDate": moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
							}
							
							layout ={
								"DefaultLayoutHeaders": DefaultLayoutHeaders,
								"DefaultLayoutFields": fields,
							}
							
						}
					
						
						let mdsJson = 'MDS_'+ sheetName +'_'+lastUpDate+'_'+ totalFields;
						if(fileSet == 'child'){
							mdsJson = 'DefaultLayout_'+ sheetName +'_'+lastUpDate+'_'+ totalFields;
						}
						let fileName = mdsJson+'.json';
						let data = JSON.stringify(layout);
						
						//console.log('mdsFilesArray->',mdsFilesArray);
						
						arry = [fileName,data,mdsFilesArray];
					}
					
					resolve(arry);
					
				},function(reason) {
					let arry = [];
					resolve(arry);
				}
			);
		});
	}
	
	GetZipFiles = (sheetName,fileSet,type) => {
		return new Promise((resolve, reject) => {
			let allFiles = [];
			let that = this;
			let spreadsheetId = this.state.mdsSpreadsheetId;
			if(type == 'Old'){
				this.downloadZipJson(sheetName,fileSet,spreadsheetId).then(function(response) {
					allFiles.push(response);
					let mdsFiles = response[2];
					//console.log('mdsFiles->',mdsFiles);
					if(mdsFiles.length > 0){
						mdsFiles.map((file,i)=>{
							let len = i+1
							let str = file.split(',');
							that.downloadZipJson(str[1],'mds',str[0]).then(function(res) {
								allFiles.push(res);
								//console.log('allFiles->',allFiles);
								if(mdsFiles.length == len){
									setTimeout(function(){
										resolve(allFiles);
									}, 3000);
								}
							}, function(error) {
							   console.error("Failed!", error);
							})
						})
					}else{
						resolve(allFiles);
					}
				}, function(error) {
				  console.error("Failed!", error);
				})
			}else if(type == 'New'){
				let spreadsheetId = this.state.mdsSpreadsheetId;
				this.newDownloadZipJson(sheetName,fileSet,spreadsheetId).then(function(response) {
					allFiles.push(response);
					let mdsFiles = response[2];
					//console.log('mdsFiles->',mdsFiles);
					if(mdsFiles.length > 0){
						mdsFiles.map((file,i)=>{
							let len = i+1
							let str = file.split(',');
							that.newDownloadZipJson(str[1],'MDS',str[0]).then(function(res) {
								allFiles.push(res);
								if(mdsFiles.length == len){
									setTimeout(function(){
										resolve(allFiles);
									}, 3000);
								}
							}, function(error) {
							   console.error("Failed!", error);
							})
						})
					}else{
						resolve(allFiles);
					}
				}, function(error) {
				  console.error("Failed!", error);
				})
			}
		})
	}
	
	downloadZip = (sheetName,fileSet) => {
		const zip = require('jszip')();
		let allFiles = [];
		let that = this;
		this.GetZipFiles(sheetName,fileSet,'Old').then(function(response) {
			response =  response.filter(e => e.length);
			if(response){
				let setime = 2000;
				if(response.length > 5){
					setime = 4000;
				}else if(response.length > 10){
					setime = 6000;
				}
				response.map((file,i)=>{
					zip.file(file[0], file[1]);
				})
				setTimeout(function(){
					zip.generateAsync({type:"blob"}).then(function(content) {
						saveAs(content, sheetName+".zip");
					});
				}, setime);
			}
			
		}, function(error) {
		  console.error("Failed!", error);
		})
	}
	
	downloadZipJsonLan = (sheetName,fileSet,spreadsheetId,layoutLanguageData) => {
		return new Promise((resolve, reject) => {
			if(fileSet == 'child'){
				spreadsheetId = this.state.childSpreadsheetId;
			}
			let defaultLayoutFacility = $('#defaultLayoutFacility').val();
			gapi.client.sheets.spreadsheets.values
			.get({
				spreadsheetId: spreadsheetId,
				range:sheetName, 
			})
			.then(
				response => {
					const allData = response.result.values;
					//console.log('allData->',allData);
					let currentDate = new Date().toLocaleString("en-US").replace(',','');
					let parentMds = [];
					let arry = [];
					let language = '';
					if(allData.length > 0){
						let mdsName = allData[1][118];
						
						let mdsGuid = allData[1][91];
						/* if(fileSet == 'child'){
							mdsGuid = ulid();
						} */
						let associatedType = allData[1][92];
						let associatedForm = allData[1][93];
						let associatedDevice = allData[1][94];
						let associatedVisit = allData[1][95];
						
						let Type = allData[1][100];
						let DeviceType = allData[1][101];
						let VisitType = allData[1][102];
						let totalFields = allData.length-1;
						let Industry = allData[1][80];
						let Profession = allData[1][81];
						let Subspecialty = allData[1][82];
						
						let delegateTypes = allData[1][119];
						//let facilityId = allData[1][120] ? allData[1][120] : this.state.defaultLayoutFacility;
						let groupId = allData[1][121];
						let requiredGroups = allData[1][115];
						let version = allData[1][59];
						let friendly_name = allData[1][62];
						let dates = [];
						let fields = [];
						let mdsFiles = [];
						allData.map((field,i)=>{
							if(i > 0){
								
								let date = new Date(field[98]);
								if(date != 'Invalid Date'){
									dates.push(date);
								}
								if(fileSet == 'child'){
									parentMds.push(field[129]);
									let mdsSheetWithSid = field[58] ? field[58]+','+field[118]+','+field[117] : '';
									mdsFiles.push(mdsSheetWithSid);
								}
								let notes = field[12] = field[12].replaceAll('\n','');
								notes = notes.replaceAll('<p></p>','');
								let customNotes = field[116] = field[116].replaceAll('\n','');
								customNotes = customNotes.replaceAll('<p></p>','');
								
								let preId = '';
								let parentGuid = '';
								allData.map((pre_field,i)=>{
									if(field[7] && field[7] == pre_field[0]){
										preId = pre_field[117];
									}
									
									if(pre_field[1] == field[2]){
										parentGuid = pre_field[117];
									}
								})
								
								let oldP = field[16];
								let userArray = oldP.split(',');
								userArray.splice(0, 9);
								
								let uArray = userArray.filter(function(v){return v!==''});
								let kioskTypes = uArray.toString();
								kioskTypes = kioskTypes.replaceAll(',','|');

								let strArray = oldP.split(',');
								strArray.splice(9, 15);

								let configStr = strArray.toString();
								configStr = configStr+",["+kioskTypes+"]";
								
								if(fileSet == 'mds'){
									field[91] = '';
									field[129] = '';
									field[130] = '';
									field[131] = '';
								}
								
								let rqFields = [];
								let requiredFields = '';
								if(field[142] && field[5] == 'launchpad'){
									let rfStr = field[142].split(',');
									rfStr.map((rfield,i)=>{
										rfield = rfield.split('|');
										rqFields.push(rfield[0]+','+rfield[1]);
									});
									requiredFields = rqFields.join('|');
									
								}
								
								let opFields = [];
								let optionalFields = '';
								if(field[143] && field[5] == 'launchpad'){
									let opStr = field[143].split(',');
									opStr.map((ofield,i)=>{
										ofield = ofield.split('|');
										opFields.push(ofield[0]+','+ofield[1]);
									});
									optionalFields = opFields.join('|');
									
								}
								
								let options = field[6];
								/* if(field[6].indexOf(',') > -1) {
									options = field[6].split(',');
								} */
								
								/* let dateValidation = 'all';
								if(field[48] == 'V9'){
									dateValidation = 'past'
								}else if(field[48] == 'V10'){
									dateValidation = 'today'
								}else if(field[48] == 'V11'){
									dateValidation = 'future'
								} */
								
								/* let gridLabel = '';
								if(field[5] == 'basicgrid' || field[5] == 'grid5point' || field[5] == 'grid-multicheck' || field[5] == 'grid-singlecheck'){
									let str = field[41] ? field[41].split('_') : [];
									
									if(str.length > 0){
										let columns = str[0].split(',');
										let rows = str[1].split(',');
										gridLabel = {
											"ColumnLabelValues": columns,
											"RowLabelValues": rows,
										}
									}
								}
								
								if(field[5] == 'basicgrid2'){
									let str = field[41] ? field[41].split('_') : [];
									
									if(str.length > 0){
										let columns = str[0].split('|');
										let rows = str[1].split(',');
										
										let columnslable = [];
										let columnstd = [];
										columns.map((val,i)=>{
											let cm = val.split(':');
											columnslable.push(cm[0]);
											columnstd.push(cm[1]);
										});
										gridLabel = {
											"ColumnLabelValues": columnslable,
											"RowLabelValues": rows,
											"ColumnDataType": columnstd,
										}
									}
								}
								
								if(field[5] == 'weighted'){
									let str = field[41] ? field[41].split(',') : [];
									let optionValue = [];
									options = [];
									str.map((val,i)=>{
										let op = val.split(':');
										options.push(op[0]);
										//optionValue.push(op[1]);
									});
									if(options.length > 1){
										options = options.join(",");
									}
								}
								
								if(field[5] == 'grid-dropdown'){
									let str = field[41] ? field[41].split('_') : [];
									
									if(str.length > 0){
										let columns = str[0].split(',');
										let rows = str[1].split('|');
										
										//let columnslable = [];
										let rowValue = [];
										rows.map((val,i)=>{
											let cm = val.split(':');
											//columnslable.push(cm[0]);
											rowValue.push(cm[0]);
										});
										gridLabel = {
											"ColumnLabelValues": columns,
											"RowLabelValues": rowValue,
											//"ColumnDataType": columnstd,
										}
									}
								} */
								
								let lastDate = new Date(field[98]).toLocaleString("en-US").replace(',','');
								
								let fieldData = {
									"Field Name": field[0],
									"Parent #": field[2],
									"Data Agg": field[3],
									"Visible Text": field[4],
									"Field Type": field[5],
									"Options": field[6],
									"Conditional Field Name": preId,
									"Conditional Option": field[8],
									"Output Text": field[9],
									"Position #": field[10],
									"Parent GUID": parentGuid,
									"Notes": notes,
									"Tags": field[13],
									"Include": field[14] == 'Y' ? true:false,
									"Required": field[15] == 'Y' ? true:false,
									"Position-Configuration": configStr,
									"Delegate Types": "["+kioskTypes+"]",
									"Delegate Mode": field[37] == 'Y' ? true:false,
									"language": field[38],
									"Button Label": field[40],
									"Shared form ID's": field[41],
									"Value": field[48],
									"Unit": field[49],
									"data low limit": field[50],
									"data high limit": field[51],
									"Date Last Edited": moment(lastDate).format('YYYY-MM-DD HH:mm:ss'),
									"Image Location reference info": field[99],
									"Web Site Link": field[78],
									//"Public Field Bank": field[111] == 'Y' ? true:false,
									//"PFB=No Description": field[112],
									"Calculation Type": field[113],
									"Calculation Fields": field[114],
									"Company Name": field[115],
									"Custom Field Note": customNotes,
									"Field GUID": field[117],
									"Master Data Set GUID": field[129],
									"Master Data Set Field Name": field[130],
									"Selected Delegates": field[140],
									"Grid Columns": field[136],
									"Grid Rows": field[137],
									"RequiredFields": requiredFields,
									"OptionalFields": optionalFields,
									"OG Auto Tags": field[144],
									"Internal Data Persistence Level": field[110],
									"Tracking Code": field[125] ? field[125].split(',') : '',
									"Auto Tags": field[106] ? field[106] : '',
									"External Data Key": field[42] ? field[42] : '',
									"Internal Data Key": field[39] ? field[39] : '',
									"responseAction": field[44] ? field[44] : '',
								}
								
								
								if(layoutLanguageData){
									layoutLanguageData.map((lan,i)=>{
										if(lan['FieldGUID'] == field[117]){
											language = lan['Language'].toUpperCase();
											fieldData['Visible Text'] = lan['VisibleText'] ? lan['VisibleText'] : field[4];
											fieldData['Output Text'] = lan['OutputText'] ? lan['OutputText'] : field[9];
											fieldData['Options'] = lan['Options'] ? lan['Options'] : field[6];
											
											/* let lanOptions = lan['FieldTypeOptions'] !== undefined ? lan['FieldTypeOptions']['Options'] : options;
											
											if(Array.isArray(lanOptions)){
												lanOptions = lanOptions.join(",");
											} 
											
											fieldData['Options'] = lanOptions;
											
											let lanGridLabel = lan['GridLabelValue'] !== undefined ? lan['GridLabelValue'] : gridLabel;
											
											let lanGridLabelstr = '';
											
											if(lanGridLabel){
												let ColumnLabelValues = [];
												if(lanGridLabel['ColumnLabelValues']){
													ColumnLabelValues = lanGridLabel['ColumnLabelValues'].join(",");
												}
												let RowLabelValues = [];
												if(lanGridLabel['RowLabelValues']){
													RowLabelValues = lanGridLabel['RowLabelValues'].join(",");
												}
												
												lanGridLabelstr = ColumnLabelValues+'_'+RowLabelValues;
											} */
											fieldData['Button Label'] = lan['ButtonLabel'] ? lan['ButtonLabel'] : field[40];
											fieldData["Shared form ID's"] = lan['LabelValues'] ? lan['LabelValues'] : field[41];
											
											fieldData['Notes'] = lan['Notes'] !== undefined ? lan['Notes'] : notes;
											fieldData['Tags'] = lan['Tags'] ? lan['Tags'] : field[13];
											
										}
									});

								}
								
								const filteredObj = (obj) =>
								  Object.entries(obj)
									.filter(([_, value]) => !!value || typeof value === "boolean")
									.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
								if(fieldData){
									fieldData = filteredObj(fieldData);
									fields.push(fieldData);
								}
							}	
						})
						
						let maximumDate = new Date(Math.max.apply(null, dates));
						let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
						let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
						
						parentMds = parentMds.filter(function(x) {
							 return x !== undefined;
						});
						let parentMdsGuid = this.uniqueArray2(parentMds);
						let mdsFilesArray = this.uniqueArray2(mdsFiles);
						
						
						let cArray = [];
						let newGuids = [];
						if(fileSet == 'child'){
							let i;
							for (i = 0; i < parentMdsGuid.length; ++i) {
								//let mGuid = ulid();
								//cArray.push(parentMdsGuid[i]+'_'+mGuid);
								//newGuids.push(mGuid+'_'+language);
								cArray.push(parentMdsGuid[i]+'|'+parentMdsGuid[i]+'_'+language);
								newGuids.push(parentMdsGuid[i]+'_'+language);
							}
							this.setState({combineArray:cArray});
							mdsGuid = mdsGuid+'_'+language;
						}
						
						let itemString = newGuids.toString();
						
						if(fileSet == 'MDS'){
							let combineArray = this.state.combineArray;
							
							let i;
							for (i = 0; i < combineArray.length; ++i) {
								let mGuid = ulid();
								let guid = combineArray[i].split('|');
								if(guid[0] == mdsGuid){
									mdsGuid = guid[1];
								}
							}
						}
						
						let setGuid = 'Master Data Set GUID';
						
						if(fileSet == 'child'){
							setGuid = 'Field Set GUID';
							fields.map((fld,i)=>{
								let oldPguid = fld['Master Data Set GUID'];
								let n;
								for(n = 0; n < cArray.length; ++n) {
									let mguid = cArray[n].split('|');
									if(mguid[0] == oldPguid){
										fld['Master Data Set GUID'] = mguid[1];
									}
								}
							});
						}
						
						let layout ={
							"CreationDate": moment(currentDate).format('YYYY-MM-DD HH:mm:ss'),
							"ModifiedDate": moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
							"Form Name": sheetName+'_'+language,
							//[setGuid]: mdsGuid+'_'+language,
							[setGuid]: mdsGuid,
							"Included MDS GUID's": itemString,
							"Associated Type": associatedType,
							"Asociated Forms": associatedForm,
							"Associated Device Types": associatedDevice,
							"Associated Visit Types": associatedVisit,
							"Type Master List": Type.replaceAll('\n',''),
							"Device Type Master List": DeviceType.replaceAll('\n',''),
							"Visit Type Master List": VisitType.replaceAll('\n',''),
							"Industry": Industry,
							"Profession": Profession,
							"Subspecialty": Subspecialty,
							"Required Groups": requiredGroups ? requiredGroups.split(',') : '',
							"Filtering Groups": groupId ? groupId.split(',') : '',
							"All Delegate Types": delegateTypes,
						}
						
						if(fileSet == 'child'){
							let layoutFriendlyName = this.state.layoutFriendlyName;
							layout['FieldSet_Facility_ID'] = defaultLayoutFacility;
							
							if(layoutFriendlyName != ''){
								layout['Form Name'] = layoutFriendlyName;
							}
						}
						
						layout['Fields'] = fields;
						
						console.log(layout);
						
						let mdsJson = sheetName +'_'+language;
						if(fileSet == 'child'){
							mdsJson = sheetName +'_'+language;
						}
						let fileName = mdsJson+'.json';
						let data = JSON.stringify(layout);
						arry = [fileName,data,mdsFilesArray];
					}
					
					resolve(arry);
					
				},function(reason) {
					let arry = [];
					alert(reason.result.error.message);
					resolve(arry);
				}
			);
		});
	}
	
	GetZipFilesLan = (sheetName,fileSet,layoutLanguageData) => {
		return new Promise((resolve, reject) => {
			let allFiles = [];
			let that = this;
			let spreadsheetId = this.state.mdsSpreadsheetId;
			this.downloadZipJsonLan(sheetName,fileSet,spreadsheetId,layoutLanguageData).then(function(response) {
				allFiles.push(response);
				let mdsFiles = response[2];
				//console.log('mdsFiles->',mdsFiles);
				if(mdsFiles.length > 0){
					mdsFiles.map((file,i)=>{
						let len = i+1
						let str = file.split(',');
						that.downloadZipJsonLan(str[1],'mds',str[0],layoutLanguageData).then(function(res) {
							allFiles.push(res);
							//console.log('allFiles->',allFiles);
							if(mdsFiles.length == len){
								setTimeout(function(){
									resolve(allFiles);
								}, 3000);
							}
						}, function(error) {
						   console.error("Failed!", error);
						})
					})
				}else{
					resolve(allFiles);
				}
			}, function(error) {
			  console.error("Failed!", error);
			})
		})
	}
	
	storeFriName = (sheetName) => {
		this.setState({layoutNameForlanguage:sheetName});
	}
	
	downloadZipWithLanguage = (sheetName) => {
		//let sheetName = this.state.layoutNameForlanguage;
		let fileSet = 'child';
		let layoutLanguageData = this.state.layoutLanguageData;
		const zip = require('jszip')();
		let allFiles = [];
		let that = this;
			
		if(layoutLanguageData.length > 0){
			let zipName = sheetName+'_'+layoutLanguageData[0]['Language'].toUpperCase();
			this.GetZipFilesLan(sheetName,fileSet,layoutLanguageData).then(function(response) {
				response =  response.filter(e => e.length);
				//console.log('response->',response);
				if(response){
					let setime = 2000;
					if(response.length > 5){
						setime = 4000;
					}else if(response.length > 10){
						setime = 6000;
					}
					response.map((file,i)=>{
						zip.file(file[0], file[1]);
					})
					setTimeout(function(){
						zip.generateAsync({type:"blob"}).then(function(content) {
							saveAs(content, zipName+".zip");
						});
					}, setime);
				}
				
			}, function(error) {
			  console.error("Failed!", error);
			})
			
			this.setState({combineArray:[]});
			
		}else{
			alert('Layout Data not found for the selected Language');
		}
	}
	
	newDownloadZip = (sheetName,fileSet) => {
		const zip = require('jszip')();
		let allFiles = [];
		let that = this;
		this.GetZipFiles(sheetName,fileSet,'New').then(function(response) {
			response =  response.filter(e => e.length);
			if(response){
				let setime = 2000;
				if(response.length > 5){
					setime = 4000;
				}else if(response.length > 10){
					setime = 6000;
				}
				response.map((file,i)=>{
					zip.file(file[0], file[1]);
				})
				setTimeout(function(){
					zip.generateAsync({type:"blob"}).then(function(content) {
						saveAs(content, sheetName+".zip");
					});
				}, setime);
			}
			
		}, function(error) {
		  console.error("Failed!", error);
		})
	}
	
	uniqueArray2(arr) {
		var a = [];
		for (var i=0, l=arr.length; i<l; i++)
			if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
				a.push(arr[i]);
		return a;
	}
	
	uploadParentMDS = (parentMdsGuid) => {
		let sheetGuidName = this.state.sheetGuidName;
		let mdsSheetNames = this.state.mdsSheetNames;
		parentMdsGuid.map((guid,i)=>{
			let sheetName = sheetGuidName.get(guid);
			mdsSheetNames.map((sheet,i)=>{
				if(sheet.name == sheetName){
					this.downloadMsdJson(sheetName, 'post', 'mds');
				}
			})

		});
	}
	
	postMdsJson = (sheetId,fileName, file, type) => {
		let token = this.state.token;
		let ApiLibraryUrl = this.state.ApiLibraryUrl;
		let url = ApiLibraryUrl+'jsons?type_id=10';
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Authorization': 'Bearer '+token
			}
		})
		.then(response => {
			let files = response.data.data.files;
			let exist = false;
			files.map((file,i)=>{
				if(fileName == file.name){
					exist = true;
				}
			})
			
			//if(exist){
				//alert('File already in the Library!');
				//$('#'+type+'-'+sheetId+' img').attr("src","api-green.png");
			//}else{
				let ApiLibraryUrl = this.state.ApiLibraryUrl;
				let url3 = ApiLibraryUrl+'jsons';
				let formData = new FormData();
				formData.append('json_file', file);
				formData.append('name', fileName);
				formData.append('description', this.state.fileDescription);
				formData.append('type_id', '10');
				formData.append('category_id', '45');
				/* if(type=='MDS'){
					formData.append('category_id', '45');
				}else{
					formData.append('category_id', '46');
				} */
				axios({
					method: 'POST',
					url: url3,
					data: formData,
					headers: {
						'Authorization': 'Bearer '+token,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(response => {
					//alert('File stored Successfully!');
					$('#'+sheetId+' img').attr("src","api-green.png");
					/* if(type=='MDS'){
						//mdsName = mdsName.replaceAll(" ", "");
						$('#'+sheetId+' img').attr("src","api-green.png");
					}else{
						//$('#'+type+'-'+configGuid+' img').attr("src","api-green.png");
					} */
					this.setState({sheetName:''})
				}).catch(error => {
					console.log('error::', error);
				})
			
			//}
		}).catch(error => {
			console.log('error::', error);
		})
		
	}
	
	download(content, fileName, contentType) {
		const a = document.createElement("a");
		const file = new Blob([content], { type: contentType });
		a.href = URL.createObjectURL(file);
		a.download = fileName;
		a.click();
	}
	
	setAttentionFilter=(event)=>{
		let val = event.target.value;
		//this.setState({delegateFilter: val});
		//let allData = this.state.allData;
		if(val == 'attention'){
			$('.question').css('display','none');
			$('.attention').css('display','block');
		}else{
			$('.question').css('display','block');
		}
	}
	
	setDelegateFilter=(event)=>{
		let val = event.target.value;
		this.setState({delegateFilter: val});
	}
	
	setFilter=(event)=>{
		let val = event.target.value;
		if(val == 'fieldSet'){
			$('.child-sheets').show();
			$('.mds-sheets').hide();
			$('.mds-ssheets').hide();
			/* this.state.childSheetNames.map((sheet,i)=>{
				this.getSheetdDataName(sheet.name,'child');
			}); */
		}else{
			$('.mds-ssheets').show();
			$('.mds-sheets').show();
			$('.child-sheets').hide();
		}
	}
	
	getSheetdDataNamebackup=(sheet,type)=>{
		let sheetGuid = '';
		let visibility = '';
		let spreadsheetId = this.state.mdsSpreadsheetId;
		if(type == 'child'){
			spreadsheetId = this.state.childSpreadsheetId;
		}
		let that = this;
		
		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:sheet, 
		})
		.then(
			response => {
				const allData = response.result.values;
				
				if(allData.length > 1){
					if(allData[1][141] !== undefined){
						visibility = allData[1][141];
					}
					let sheetVisibility = that.state.sheetVisibility;
					if(visibility == 'TRUE'){
						sheetVisibility.set(sheet, true);
					}else{
						sheetVisibility.delete(sheet);
					}
					this.setState({sheetVisibility:sheetVisibility});
					
					sheetGuid = allData[1][91];
					let dates = [];
					
					allData.map((field,i)=>{
						let date = new Date(field[98]);
						if(date != 'Invalid Date'){
							dates.push(date);
						}
					});
					
					var maximumDate = new Date(Math.max.apply(null, dates));
					let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
					let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
					let totalFields = allData.length-1;
					let sheetApiName = that.state.sheetApiName;
					
					let apiName = 'MDS_'+ sheet +'_'+lastUpDate+'_'+totalFields;
					if(type == 'child'){
						apiName = 'DefaultLayout_'+ sheet +'_'+lastUpDate+'_'+totalFields;
					}
					
					sheetApiName.set(sheet, apiName);
					
					let sheetGuidName = that.state.sheetGuidName;
					if(sheetGuid){
						sheetGuidName.set(sheetGuid,sheet);
					}
					this.setState({sheetApiName:sheetApiName,sheetGuidName:sheetGuidName});
					
				}
			},function(reason) {
				console.log(reason.result.error.message);
			}
		);
	}
	
	getSheetdDataName=(sheetNames,type)=>{

		let spreadsheetId = this.state.mdsSpreadsheetId;
		if(type == 'child'){
			spreadsheetId = this.state.childSpreadsheetId;
		}else if(type == 'layout'){
			spreadsheetId = this.state.layoutSpreadsheetId;
		}
		let that = this;
		
		var sheetType =[];
		let rangeArray = [];
		
		sheetNames.map((sheet,i)=>{
			
			let rArray = [sheet.name+'!EL2', sheet.name+'!CN2', sheet.name+'!DT2', sheet.name+'!BH2'];
			
			if(type == 'layout'){
				rArray = [sheet.name+'!EL2', sheet.name+'!CN2', sheet.name+'!DT2', sheet.name+'!EI2'];
			}
			rangeArray.push(rArray);
			
		})
		
		var myNewArray = [].concat.apply([], rangeArray);
		
		
		let params =  {
			spreadsheetId: spreadsheetId,
			majorDimension: "DIMENSION_UNSPECIFIED",
			ranges: myNewArray,
			valueRenderOption: "FORMATTED_VALUE"
		};

		let request = gapi.client.sheets.spreadsheets.values.batchGet(params);
		request.then(function(response) {
			let typeVal = [];
			let typeVal2 = [];
			//let reasonNote = [];

			sheetNames.map((sheet,i)=>{
				
				let response_val = response.result.valueRanges;
				let sheetName = sheet.name;
				
				typeVal = [];
				response_val.map((val,i)=>{
					
					let sheetN = val.range.split("!");
					let sheetN1 = sheetN[0].replaceAll("'", "");
					if(sheetN1 == sheetName){
						typeVal[sheetN[1]] = val;
						typeVal2[sheetName] = typeVal;
					} 
				})
			})

			sheetNames.map((sheet,i)=>{
				
				if(typeVal2[sheet.name]['CN2'].values){
					let sheetGuid = typeVal2[sheet.name]['CN2'].values[0][0];
					let sheetGuidName = that.state.sheetGuidName;
					let sheetGuids = that.state.sheetGuids;
					if(sheetGuid){
						sheetGuidName.set(sheetGuid,sheet.name);
						sheetGuids.set(sheet.name,sheetGuid);
					}
					that.setState({sheetGuidName,sheetGuids});
				}
				
				if(typeVal2[sheet.name]['DT2'].values){
					let apiName = typeVal2[sheet.name]['DT2'].values[0][0];
					let sheetApiName = that.state.sheetApiName;
					sheetApiName.set(sheet.name, apiName);
					that.setState({sheetApiName});
				}
				
				if(type != 'layout'){
					if(typeVal2[sheet.name]['BH2'].values){
						let version = typeVal2[sheet.name]['BH2'].values[0][0];
						let sheetVersion = that.state.sheetVersion;
						sheetVersion.set(sheet.name, version);
						that.setState({sheetVersion});
					}
				}
				
				if(type == 'layout'){
					if(typeVal2[sheet.name]['EI2'].values){
						let layoutType = typeVal2[sheet.name]['EI2'].values[0][0];
						let sheetLayoutType = that.state.sheetLayoutType;
						sheetLayoutType.set(sheet.name, layoutType);
						that.setState({sheetLayoutType});
					}
				}
				
				if(typeVal2[sheet.name]['EL2'].values){
					let visibility = typeVal2[sheet.name]['EL2'].values[0][0];
					
					let sheetVisibility = that.state.sheetVisibility;
					if(visibility == 'TRUE'){
						sheetVisibility.set(sheet.name, true);
					}else{
						sheetVisibility.delete(sheet.name);
					}
					that.setState({sheetVisibility});
				}
			})	

		}, function(reason) {
			console.error('error: ' + reason.result.error.message);
		});	
	}
	
	getMdsSheetdDataName=(sheetNames)=>{

		let spreadsheetId = this.state.mdsSpreadsheetId;
		
		let that = this;
		
		var sheetType =[];
		let rangeArray = [];
		
		sheetNames.map((sheet,i)=>{
			let rArray = [sheet.name+'!CN2', sheet.name+'!CS2', sheet.name+'!CT2'];
			rangeArray.push(rArray);
		})
		
		var myNewArray = [].concat.apply([], rangeArray);
		
		
		let params =  {
			spreadsheetId: spreadsheetId,
			majorDimension: "DIMENSION_UNSPECIFIED",
			ranges: myNewArray,
			valueRenderOption: "FORMATTED_VALUE"
		};

		let request = gapi.client.sheets.spreadsheets.values.batchGet(params);
		request.then(function(response) {
			let typeVal = [];
			let typeVal2 = [];
			//let reasonNote = [];

			sheetNames.map((sheet,i)=>{
				
				let response_val = response.result.valueRanges;
				let sheetName = sheet.name;
				
				typeVal = [];
				response_val.map((val,i)=>{
					
					let sheetN = val.range.split("!");
					let sheetN1 = sheetN[0].replaceAll("'", "");
					if(sheetN1 == sheetName){
						typeVal[sheetN[1]] = val;
						typeVal2[sheetName] = typeVal;
					} 
				})
			})
	
			sheetNames.map((sheet,i)=>{
				
				if(typeVal2[sheet.name]['CN2'].values){
					let sheetGuid = typeVal2[sheet.name]['CN2'].values[0][0];
					let mdsSheetGuidName = that.state.mdsSheetGuidName;
					if(sheetGuid){
						mdsSheetGuidName.set(sheetGuid,sheet.name);
					}
					that.setState({mdsSheetGuidName});
				}

				if(typeVal2[sheet.name]['CS2'].values){
					let str = typeVal2[sheet.name]['CS2'].values[0][0];
					let reasonNotes = that.state.reasonNotes;
					if(str){
						reasonNotes.set(sheet.name,str);
					}
					that.setState({reasonNotes});
				}
				
				if(typeVal2[sheet.name]['CT2'].values){
					let str = typeVal2[sheet.name]['CT2'].values[0][0];
					let mdsHasFieldSet = that.state.mdsHasFieldSet;
					if(str){
						mdsHasFieldSet.set(sheet.name,str);
					}
					that.setState({mdsHasFieldSet});
				}
			})	

		}, function(reason) {
			console.error('error: ' + reason.result.error.message);
		});	
	}
	
	setVisibility=(event)=>{
		
		let sheet = event.target.name;
		let type = event.target.id;
		
		let spreadsheetId = this.state.mdsSpreadsheetId;
		if(type == 'child'){
			spreadsheetId = this.state.childSpreadsheetId;
		}

		let visibility = '';
		let sheetVisibility = this.state.sheetVisibility;
		
		if(event.target.checked){
			visibility = 'TRUE';
			sheetVisibility.set(sheet, true);		
		}else{
			sheetVisibility.delete(sheet);
		}
		
		this.setState({sheetVisibility:sheetVisibility});
		var params = {
			spreadsheetId: spreadsheetId,
		};
		let data = [];
		let newRow = {
			range: sheet+'!EL2',
			values: 
			[
				[ visibility ]
			]
		};
		
		let newRow1 = {
			range: sheet+'!DY2',
			values: 
			[
				[ '' ]
			]
		};
		
		data.push(newRow)
		data.push(newRow1)
		let batchUpdateValuesRequestBody = {
			valueInputOption: 'USER_ENTERED',
			data: data,
		};
	
		let request1 = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
		request1.then(function(response) {
		}, function(reason) {
		console.error('error: ' + reason.result.error.message);
		});

	}
	
	addDescription=(event)=>{
        this.setState({fileDescription:event.target.value})
    }
	
	setMsdId = (sheetId,sheetName) => {
		this.setState({sheetId,sheetName,fileDescription:''})
	}
	
	handleInclude = (e) => {
		let item = e.target.name;
        let isChecked = e.target.checked;
        let checkedInc = this.state.checkedInc;
        
		if(isChecked == true){
			checkedInc.set(item, 'Y')
			
        }else{
            checkedInc.set(item, 'N')
        }
		
        this.setState({checkedInc: checkedInc});
    }
	
	handleRequire = (e) => {
		let item = e.target.name;
        let isChecked = e.target.checked;
        let checkedReq = this.state.checkedReq;
        
		if(isChecked == true){
			checkedReq.set(item, 'Y')
			
        }else{
            checkedReq.set(item, 'N')
        }
		
        this.setState({checkedReq: checkedReq});
    }
	
	handleKiosk = (e) => {
		let item = e.target.name;
        let isChecked = e.target.checked;
        let checkedKiosk = this.state.checkedKiosk;
        
		if(isChecked == true){
			checkedKiosk.set(item, 'Y')
        }else{
            checkedKiosk.set(item, 'N')
        }
		
        this.setState({checkedKiosk: checkedKiosk});
    }
	
	handleAddNewFiled = (e) => {
		
		let allData = this.state.allData;
		
		allData.map((field,i)=>{
			this.setState({[field[0]]: ''});
		});
		
		let id = e.target.name;
		let pos = e.target.value;
		let isChecked = e.target.checked;
		if(isChecked == true){
			this.setState({fieldPos: pos,[id]: pos});
        }else{
            this.setState({fieldPos: '',[id]: ''});
        }
	}
	
	handleCopyAddNewFiled = (e) => {
		
		let allData = this.state.copyAllData;
		
		allData.map((field,i)=>{
			this.setState({[field[0]]: ''});
		});
		
		let id = e.target.name;
		let pos = e.target.value;
		let isChecked = e.target.checked;
		if(isChecked == true){
			this.setState({fieldPos: pos,[id]: pos});
        }else{
            this.setState({fieldPos: '',[id]: ''});
        }
	}
	
	selectKioskUsers=(event)=>{
		let delegateUsers = this.state.delegateUsers;
		let item = event.target.name;
		delegateUsers.set(item, Array.from(event.target.selectedOptions, (item) => item.value))
        this.setState({delegateUsers:delegateUsers});
    }

	showAddUsersModal=(id)=>{
		this.setState({fieldId:id});
		$('#kioskUser').modal('show')
	}
	
	showPreviewModal=(value)=>{
		this.setState({previewData:value});
		$('#fieldPreview').modal('show')
	}
	
	showCopyModal=(val)=>{
		this.setState({copyValue:val});
		$('#copyModal').modal('show')
	}
	
	showOutputText=()=>{
		let outputText = this.state.outputText;
		if($("input[name='showOutputText']").prop("checked") == true){
			this.setState({outputText:true});
		}else{
			this.setState({outputText:false});
		}
	}
	
	saveAsCopyMds=()=>{
		
		let spreadsheetId = this.state.spreadsheetId;
		let selectedSheetId = this.state.selectedSheetId;
		let selectedSheet = this.state.selectedSheet;
		let newMdsName = this.state.newMdsName;
		let allData = this.state.allData;
		
		let originalMDSGuide = allData[0][91];
		
		if(newMdsName == ''){
			alert('Please insert New MDS file name!');
			return false;
		}
		
		let params = {
            spreadsheetId: spreadsheetId,
            sheetId: selectedSheetId,
        };
		
		let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
		
        let mdsDataName = this.state.mdsDataName;
		let tempName = "Copy of "+originalMDSGuide+"-"+newMdsName;
		
		let copySheetToAnotherSpreadsheetRequestBody = {
            destinationSpreadsheetId:spreadsheetId,
        };
		
        let that=this;
        let request = gapi.client.sheets.spreadsheets.sheets.copyTo(params, copySheetToAnotherSpreadsheetRequestBody);
		request.then(
			response => {
				let params1 =  {
					spreadsheetId:spreadsheetId,
				};
				let getRequest = gapi.client.sheets.spreadsheets.get(params1);
				getRequest.then(function(response) {
					let length = response.result.sheets.length;
					let lastSheetId = response.result.sheets[length-1].properties.sheetId;
					
					let requests ={
						"requests": [
							{
							  "updateSheetProperties": {
								"properties": {
								  "sheetId": lastSheetId,
								  "title": tempName,
								},
								"fields": "title"
							  }
							}
						]
					}
					let renameRequest = gapi.client.sheets.spreadsheets.batchUpdate(params1,requests);
					renameRequest.then(function(response) {
						
						let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
						let updateRows = [];
						let row1 = {
							"range": tempName+'!DO2',
							"majorDimension": "ROWS",
							"values": 
							[
								['']
							]
						};
						
						let row2 = {
							"range": tempName+'!DL2',
							"majorDimension": "ROWS",
							"values": 
							[
								['']
							]
						};
						
						let row3 = {
							"range": tempName+'!DQ2',
							"majorDimension": "ROWS",
							"values": 
							[
								['']
							]
						};
						
						let row4 = {
							"range": tempName+'!DR2',
							"majorDimension": "ROWS",
							"values": 
							[
								['']
							]
						};
						
						let row5 = {
							"range": tempName+'!DP2',
							"majorDimension": "ROWS",
							"values": 
							[
								['']
							]
						};
						
						let row6 = {
							"range": tempName+'!CO2:CR2',
							"majorDimension": "ROWS",
							"values": 
							[
								['','','','']
							]
						};
						let version = 1.01;
						let row12 = {
							"range": tempName+'!BH2',
							"majorDimension": "ROWS",
							"values": 
							[
								[version]
							]
						};
						
						updateRows.push(row1);
						updateRows.push(row2);
						updateRows.push(row3);
						updateRows.push(row4);
						updateRows.push(row5);
						updateRows.push(row6);
						updateRows.push(row12);
						let rowId = '';
						let mdsUld = ulid();
						allData.map((rowDatas, i) => {
							rowId = (i+2);
							let uld = ulid();
							let row12 = {
								"range": tempName+'!CT'+rowId,
								"majorDimension": "ROWS",
								"values": 
								[
									[currentDate]
								]
							};
							let row7 = {
								"range": tempName+'!CU'+rowId,
								"majorDimension": "ROWS",
								"values": 
								[
									[currentDate]
								]
							};
							let row8 = {
								"range": tempName+'!CN'+rowId,
								"majorDimension": "ROWS",
								"values": 
								[
									[mdsUld]
								]
							};
							let row9 = {
								"range": tempName+'!DN'+rowId,
								"majorDimension": "ROWS",
								"values": 
								[
									[uld]
								]
							};
							
							let row10 = {
								"range": tempName+'!L'+rowId,
								"majorDimension": "ROWS",
								"values": 
								[
									['']
								]
							};
							
							let row11 = {
								"range": tempName+'!DO'+rowId,
								"majorDimension": "ROWS",
								"values": 
								[
									[newMdsName]
								]
							};
							
							updateRows.push(row7)
							updateRows.push(row8)
							updateRows.push(row9)
							updateRows.push(row10)
							updateRows.push(row11)
							updateRows.push(row12)
						});
						
						var params = {
							spreadsheetId:spreadsheetId,
						}
						let batchUpdateValuesRequestBody = {
							valueInputOption: 'USER_ENTERED',
							data: updateRows, 
						};
						
						let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
						updateRequest.then(function(response) {
							setTimeout(function(){
								that.getMdsSheets();
								that.saveMdsJsonDataMongodb(tempName, 'mongo', 'mds')
							}, 2000);
							alert('MDS copy created Successfully!');
						}, function(reason) {
							console.error('error: ' + reason.result.error.message);
						});
						
					}, function(reason) {
						alert(reason.result.error.message);
					});
				}, function(reason) {
					alert(reason.result.error.message);
				});
			}, function(reason) {
				alert(reason.result.error.message);
		});
	}
	
	saveAsCopyLayout=()=>{
		
		let spreadsheetId = this.state.spreadsheetId;
		let selectedSheetId = this.state.selectedSheetId;
		let selectedSheet = this.state.selectedSheet;
		let layoutSpreadsheetId = this.state.layoutSpreadsheetId;
		
		let params = {
            spreadsheetId: spreadsheetId,
            sheetId: selectedSheetId,
        };
		
		let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}AMP]/g, '');
		currentDate = currentDate.replace(' ', '-')
        let mdsDataName = this.state.mdsDataName;
		let tempName = "Copy-of-"+selectedSheet+"-"+currentDate;
		
		let copySheetToAnotherSpreadsheetRequestBody = {
            destinationSpreadsheetId:layoutSpreadsheetId,
        };
		
        let that=this;
        let request = gapi.client.sheets.spreadsheets.sheets.copyTo(params, copySheetToAnotherSpreadsheetRequestBody);
		request.then(
			response => {
				let params1 =  {
					spreadsheetId:layoutSpreadsheetId,
				};
				let getRequest = gapi.client.sheets.spreadsheets.get(params1);
				getRequest.then(function(response) {
					let length = response.result.sheets.length;
					let lastSheetId = response.result.sheets[length-1].properties.sheetId;
					
					let requests ={
						"requests": [
							{
							  "updateSheetProperties": {
								"properties": {
								  "sheetId": lastSheetId,
								  "title": tempName,
								},
								"fields": "title"
							  }
							}
						]
					}
					let renameRequest = gapi.client.sheets.spreadsheets.batchUpdate(params1,requests);
					renameRequest.then(function(response) {
						that.updateLayout(layoutSpreadsheetId,tempName,'layout');
					}, function(reason) {
						alert(reason.result.error.message);
					});
				}, function(reason) {
					alert(reason.result.error.message);
				});
			}, function(reason) {
				alert(reason.result.error.message);
		});
	}
	
	filterMdsForms=(event)=>{
		let item = event.target.name;
		let value = event.target.value.toLowerCase();
		let mdsSheetNames = this.state.mdsSheetNames;
		if(value.length > 1){
			$("#loadSelectMds").children('option').hide();
			mdsSheetNames.map((sheet,i)=>{
				let sheetName = sheet.name.toLowerCase();
				sheetName = sheetName.substring(0, 10);
				if(sheetName.indexOf(value) > -1){
					let sheetValue = sheet.name.replaceAll(' ','-');
					$("#loadSelectMds").children("option[value^=" + sheetValue + "]").show();
				}
			})
		}else{
			$("#loadSelectMds").children('option').show();
		}
	}
	
	filterFieldSet=(event)=>{
		let item = event.target.name;
		let value = event.target.value.toLowerCase();
		let childSheetNames = this.state.childSheetNames;
		
		if(value.length > 1){
			$("#loadFeildSet").children('option').hide();
			childSheetNames.map((sheet,i)=>{
				let sheetName = sheet.name.toLowerCase();
				sheetName = sheetName.substring(0, 10);
				
				if(sheetName.indexOf(value) > -1){
					let sheetValue = sheet.name.replaceAll(' ','-');
					$("#loadFeildSet").children("option[value^=" + sheetValue + "]").show();
				}
			})
		}else{
			$("#loadFeildSet").children('option').show();
		}
	}
	
	filterLayout=(event)=>{
		let item = event.target.name;
		let value = event.target.value.toLowerCase();
		let layoutSheetNames = this.state.layoutSheetNames;
		
		if(value.length > 1){
			$("#loadLayout").children('option').hide();
			layoutSheetNames.map((sheet,i)=>{
				let sheetName = sheet.name.toLowerCase();
				sheetName = sheetName.substring(0, 10);
				
				if(sheetName.indexOf(value) > -1){
					//console.log('sheetName->',sheetName);
					let sheetValue = sheet.name.replaceAll(' ','-');
					$("#loadLayout").children("option[value^=" + sheetValue + "]").show();
				}
			})
		}else{
			$("#loadLayout").children('option').show();
		}
	}
	
	filterLayoutType=(event)=>{
		let item = event.target.name;
		let value = event.target.value;
		let layoutSheetNames = this.state.layoutSheetNames;
		if(value){
			$("#loadLayout").children('option').hide();
			layoutSheetNames.map((sheet,i)=>{
				let sheetLayoutType = this.state.sheetLayoutType;
				let layoutType = sheetLayoutType.get(sheet.name);
				if(value == layoutType){
					let sheetValue = sheet.name.replaceAll(' ','-');
					$("#loadLayout").children("option[value^=" + sheetValue + "]").show();
				}
			})
		}else{
			$("#loadLayout").children('option').show();
		}
	}
	
	setAllChecked=()=>{
		let checkedInc = this.state.checkedInc;
		$('.inc-btn').prop('checked', true);
		this.state.allData.map((valu, i) => {
			checkedInc.set(valu[0], 'Y');
		})
		this.setState({checkedInc:checkedInc});
	}
	
	setAllUnChecked=()=>{
		let checkedInc = this.state.checkedInc;
		$('.inc-btn').prop('checked', false);
		this.state.allData.map((valu, i) => {
			checkedInc.delete(valu[0]);
		})
		this.setState({checkedInc:checkedInc});
	}
	
	/* fieldButtons=(value)=>{
		if(value){
		let include = false;
		let require = false;
		let kiosk = false;
		
		if(this.state.checkedInc.get(value[0]) == 'Y'){
			include = true;
		}
		
		if(this.state.checkedReq.get(value[0]) == 'Y'){
			require = true;
		}
		
		if(this.state.checkedKiosk.get(value[0]) == 'Y'){
			kiosk = true;
		}
		
		return(<div>
		<input className="btn code-dialog inc-btn" name={value[0]} type="checkbox" checked={include} onChange={this.handleInclude}/>
		<input className="btn code-dialog" name={value[0]} type="checkbox" checked={require} onChange={this.handleRequire}/>
		<input className="btn code-dialog" name={value[0]} type="checkbox" checked={kiosk} onChange={this.handleKiosk} />
		</div>);
		}
	} */
	
	getResponseValue=(Id,Value)=>{
		let kioskResponse = this.state.kioskResponse;
		let userClassResponse = this.state.userClassResponse;
		let delegateFilter = this.state.delegateFilter;
		let userResponseDate = this.state.userResponseDate;
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
		if(Value){
            kioskResponse.set(Id, Value);
            userResponseDate.set(Id, date);
			if(delegateFilter){
				userClassResponse.set(Id, delegateFilter);
			}else{
				userClassResponse.set(Id, 'CPO');
			}
        }else{  
            kioskResponse.delete(Id);       
            userClassResponse.delete(Id);       
            userResponseDate.delete(Id);       
        }
        //console.log('userClassResponse->',userClassResponse);
        this.setState({kioskResponse:kioskResponse})   
	}
	
	idOf(i){
        return (i >= 26 ? this.idOf((i / 26 >> 0) - 1) : '') +  'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i % 26 >> 0];
    }
	
	handleSubmitSave=(msg)=>{
        let uld = ulid();
       // let userName = this.username;
        let kioskResponse = this.state.kioskResponse;
        let userClassResponse = this.state.userClassResponse;
        let userResponseDate = this.state.userResponseDate;
        //let feedsResponse = this.state.feedsResponse;
        let required = 0;
        
		let allData = this.state.allData;
		//console.log('allData->',allData);
		let layoutGuid = allData[0][91];
		let formName = allData[0][118];
		
        allData.map((rowDatas,i)=>{
            $('#'+rowDatas[0]).removeClass("error");
            let res = kioskResponse.get(rowDatas[0]);
            if(rowDatas[14] == 'Y' && rowDatas[15] == 'Y' && typeof res == "undefined"){
                required = 1;
                $('#'+rowDatas[0]).addClass("error");
                
            }
        })
        
        if(required > 0){
            alert('Please fill answer all required questions');
			let nav = $('.error');
			if(nav.length){
				$('html, body').animate({
					scrollTop: $('.error').offset().top - 50
				}, 2000);
			}
            return false;
        }
        
        let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
        //let spreadsheetID = config.saveOutputspreadsheetId; 
        let spreadsheetID = this.state.formDataSpreadsheetId; 
        let params1 = {
            spreadsheetId: spreadsheetID,
        };
        var sheetVal = '';
        let updateData=[];
        gapi.client.load("sheets", "v4", () => {
            gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: spreadsheetID,
                range:'Sheet1',
            })
            .then(response => {
                    sheetVal = response.result.values;
					this.appendDimensionRequest();
					//console.log('sheetVal->',sheetVal);
                    let length = 0;
                    if(sheetVal){
                        length = sheetVal[6].length;
                    }
                    let firstRow = layoutGuid;
                    let secondRow = uld;
                    let thirdRow = date;
                    let fourRow = formName;
                    //let fiveRow = date;
                    let columnName = this.idOf(length);
                    let row = {
                            "majorDimension": "ROWS",
                            "range": "Sheet1!"+columnName+1,
                            "values": [
                                [ firstRow ]
                            ]
                        }
                    let row1 = {
                        "range": "Sheet1!"+columnName+'2',
                        "majorDimension": "ROWS",
                        "values": [
                            [ secondRow ]
                        ]
                    }
                    let row2 = {
                        "range": "Sheet1!"+columnName+'3',
                        "majorDimension": "ROWS",
                        "values": [
                            [ thirdRow ]
                        ]
                    }
                    let row3 = {
                        "range": "Sheet1!"+columnName+'4',
                        "majorDimension": "ROWS",
                        "values": [
                            [ fourRow ]
                        ]
                    }
                    
                    /* let row4 = {
                        "range": "Sheet1!"+columnName+'5',
                        "majorDimension": "ROWS",
                        "values": [
                            [ fiveRow ]
                        ]
                    } */
                    
                    
                    updateData.push(row);
                    updateData.push(row1);
                    updateData.push(row2);
                    updateData.push(row3);
                    //updateData.push(row4);
                    
                    let st = length+7;
                    let headings = ["Field Name", "Field GUID", "Response", "Feedback Notes", "User ID", "User Class", 'Date'];
                    
                    let j=0;
                    
                    for (let i = length; i < st; i++) {
                        let nColumn = this.idOf(i);
                        let row5 = {
                            "range": "Sheet1!"+nColumn+'7',
                            "values": [
                                [ headings[j] ]
                            ]
                        }
                        j++;
                        updateData.push(row5);
                    }
                    
                    //let sheetData = this.state.sheetData;
					
                    let newArray = [];
					
					allData.map((rowDatas,i)=>{
						let userClass = userClassResponse.get(rowDatas[0]);
						let rDate = userResponseDate.get(rowDatas[0]);
						let row7 = [rowDatas[0],rowDatas[117],kioskResponse.get(rowDatas[0]),rowDatas[27],localStorage.getItem('username'),userClass,rDate];
                        newArray.push(row7);
                    })
					
                    let n = 8
                    newArray.map((rowDatas,i)=>{
                        let j=0;
                        for (let i = length; i < st; i++) {
                            let nColumn = this.idOf(i);
                            let row8 = {
                                "range": "Sheet1!"+nColumn+n,
                                "values": [
                                    [ rowDatas[j] ]
                                ]
                            }
                            j++;
                            updateData.push(row8);
                        }
                        
                        n++;
                    })
                   
                    let batchUpdateValuesRequestBody = {
                        "valueInputOption": 'USER_ENTERED',
                        data: updateData
                    };
					let that = this;
                    let request2 = gapi.client.sheets.spreadsheets.values.batchUpdate(params1,batchUpdateValuesRequestBody);
                    request2.then(function(response) {
						that.setState({cFormUID:secondRow});
						$('.smart-input').prop('disabled', false);
						if(!msg){
							that.emptyState();
							that.removeNeedNote();
							alert('Data Saved Successfully');
						}
						
						setTimeout(function(){
							getConfigResponse(this.state.formDataSpreadsheetId).then(function(response) {
								that.setState({savedResponse:response});
								if(response){
									let rForms = [];
									response.map((frm,i)=>{
										rForms.push(frm[3]);
									})
									
									let rsforms = that.uniqueArray2(rForms);
									that.setState({savedResponseForm:rsforms});
								}
							}, function(error){
							  console.error("Failed!", error);
							})
						}, 2000);
						//that.handleClearTempSave(that.state.mainSheet);
                    }, function(reason) {
                        alert(reason.result.error.message);
                    }); 
                },
            );
        }); 
    }
	
	removeNeedNote=()=>{
		let allData = this.state.allData;
		let spreadsheetID = this.state.layoutSpreadsheetId;
		let accessToken = gapi.auth.getToken().access_token;
		let params = {
			access_token:accessToken,
			spreadsheetId: spreadsheetID,
		};
		
		let rowId = '';
		let updateRows = [];
		{ allData.map((valu, i) => {
			rowId = (i+2);
			let row1 = {
				"range": this.state.selectedSheet+'!AB'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					['']
				]
			};
			
			updateRows.push(row1)
		})}
		
		let batchUpdateValuesRequestBody = {
			valueInputOption: 'USER_ENTERED',
			data: updateRows, 
		};
		let that = this;
		let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
		updateRequest.then(function(response) {
			setTimeout(function(){
				that.getMdsData();
			}, 2000);
		}, function(reason) {
			console.error('error: ' + reason.result.error.message);
		});
	}
	
	subQuestions = (id,value) => {
		this.getResponseValue(id,value);
	}
	
	emptyState=()=>{
		let kioskResponse = this.state.kioskResponse;
		let userClassResponse = this.state.userClassResponse;
		let checkedNeeds = this.state.checkedNeeds;
		let needsNote = this.state.needsNote;
		this.setState({kioskResponse:new Map(),checkedCopyFields:new Map(),userClassResponse:new Map(),checkedNeeds:new Map(),needsNote:new Map()});
	}
	
	handleNeeds = (e) => {
        const item = e.target.name;
        //this.setState({name:''});
        const isChecked = e.target.checked;
		if(isChecked){
			$('#needs'+item).modal('show');
		}
        this.setState(prevState => ({
            checkedNeeds: prevState.checkedNeeds.set(item, isChecked)
        }));
    }
	
	saveNeedsNote=(id,data)=>{
		let allData = this.state.allData;
		if(!data){
			let checkedNeeds = this.state.checkedNeeds;
			checkedNeeds.delete(id);
			this.setState({checkedNeeds});
		}else{
		
			let spreadsheetID = this.state.layoutSpreadsheetId;
			let accessToken = gapi.auth.getToken().access_token;
			let params = {
				access_token:accessToken,
				spreadsheetId: spreadsheetID,
			};
			let rowId ='';
			{ allData.map((valu, i) => {
				if(valu[0] === id){
					rowId = (i+2);
					valu[27] = data;
				}
			})}
			
			let batchUpdateValuesRequestBody = {
				valueInputOption: 'USER_ENTERED',
				data: [{
					"range":this.state.selectedSheet+'!AB'+rowId,
					"majorDimension": "ROWS",
					"values": 
					[
						[ data ]
					]
				}], 
			};
			let that = this;
			let request = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
			request.then(function(response) {
				that.getMdsData();
			}, function(reason) {
				alert(reason.result.error.message);
			});
			$('#needs'+id).modal('hide');
		}
    }
	
	removeNeedsNote=(id)=>{
		let allData = this.state.allData;
		let spreadsheetID = this.state.layoutSpreadsheetId;
        let rowId ='';
		{ allData.map((valu, i) => {
			if(valu[0] === id){
				rowId = (i+2);
				valu[27] = '';
			}
		})}
		
        var params = {
            spreadsheetId: spreadsheetID,
            range: this.state.selectedSheet+'!AB'+rowId,
        };
		let that = this;
        var request = gapi.client.sheets.spreadsheets.values.clear(params);
        request.then(function(response) {
			
			let checkedNeeds = that.state.checkedNeeds;
			checkedNeeds.delete(id);
			that.setState({checkedNeeds});
			that.getMdsData();
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        }); 
        $('#needs'+id).modal('hide');
    }
	
	sendTracking=()=>{
		$('#sendTracking').modal('show');
		this.getTrackerData();
	}
	
	sendToFab=()=>{
		
		let fId = $( "#fabFacility option:selected" ).val();
		let fabPractitioner = $( "#fabPractitioner option:selected" ).val();
		let fabPractitionerEmail = $( "#fabPractitioner option:selected" ).attr('data-email');
		let patientId = $( "#patientId" ).val();
		let patientFirstName = $( "#patientFirstName" ).val();
		let patientLastName = $( "#patientLastName" ).val();
		if(fId){
			let returnback = this.handleSubmitSave(true);
			$('#sendTracking').modal('hide');
			
			if(returnback == undefined){

				let allData = this.state.allData;
				let kioskResponse = this.state.kioskResponse;
				
				$(".question").addClass('not-response');
				
				allData.map((val,i)=>{
					let res = kioskResponse.get(val[0]);
					if(res){
						$("#"+val[0]).removeClass('not-response');
					}
				})
				this.getStatusByFacility(fId);
				let that = this;
				setTimeout(function(){
					$(".not-response").remove();
					$(".header-m").addClass('collapsed');
					$(".childMain").addClass('show');
					$(".header-m i").removeClass('fa-plus-square-o');
					$(".header-m i").addClass('fa-minus-square-o');
					$(".need-attention-footer, .answer .MuiSvgIcon-root, .upload-btn-type, .save-output-response, .save-output-send, .select-delegate-filter, .select-attention-filter").hide();
					$(".answer ").removeClass('offset-1');
					//$('.question').css('display','block');
					$('.comments input, .comments select, .comments textarea, .react-timer button, .answer button').attr('disabled', 'disabled');$("input[name='showOutputText']").prop('checked', true);
					$(".filter-attention").val("all");
					$("#wrapper").addClass('toggled-2');
					$(".fadeInRight").addClass('is-open');
					$(".fadeInRight").removeClass('is-closed');
					let fabJobNumber = that.state.trackerTableData.length+1;
					that.setState({trackingFacility:fId,fabPractitioner,fabPatientId:patientId,fabPractitionerEmail,outputText:true,showResponseData:true,showTrackerDetails:true,personRole:'',personName:'',dueDate:'',markCompleted:'',fabCompleted:'',allParts:'',redoOption:'',redoInfo:'',editorState:'',editorSourceHTML:'',redoInformation:'',showBuildEdit:false,currentStatusChanged:false,patientFirstName,patientLastName,statusLogs:[],fabJobNumber});
				}, 2000);
				
				$('#fabFacility').val('');
				$('#fabPractitioner').val('');
				$('#patientId').val('');
				$('#patientFirstName').val('');
				$('#patientLastName').val('');
			}
		}
		
		/* let formRank = this.state.formRank;
		if(formRank == 0){
			let optionRank = this.state.trackerTableData.length;
			formRank = Number(optionRank)+1;
		} */
		let optionRank = this.state.trackerTableData.length;
		let formRank = Number(optionRank)+1;
		this.setState({formRank});
	}
	
	updateTrackerStatus=()=>{
		let selectedSheet = this.state.selectedSheet;
		let cFormUID = this.state.cFormUID;
		let personRole = this.state.personRole;
		let personName = this.state.personName;
		let personEmail = this.state.personEmail;
		let currentStatus = this.state.currentStatus;
		let dueDate = this.state.dueDate;
		let markCompleted = this.state.markCompleted;
		let trackingFacility = this.state.trackingFacility;
		let noteHtml = this.state.editorSourceHTML;
		let regX = /(<([^>]+)>)/ig;
		let note = noteHtml.replace(regX, "");
		let redoOption = '';
		let redoInformation = '';
		let redoInfo = this.state.redoInfo;
		let formPriority = this.state.formPriority;
		let statusDueDate = this.state.statusDueDate;
		let formRank = this.state.formRank;
		let statusWithMail = this.state.statusWithMail;
		let sendToPract = this.state.sendToPract;
		let fabPractitioner = this.state.fabPractitioner;
		let fabPractitionerEmail = this.state.fabPractitionerEmail;
		let fabPatientId = this.state.fabPatientId;
		let patientFirstName = this.state.patientFirstName;
		let patientLastName = this.state.patientLastName;
		let createdBy = this.state.createdBy;
		let fabCompleted = this.state.fabCompleted;
		let allParts = this.state.allParts;
		
		let formContent = this.state.allData;
		
		let orderId = ulid();
		
		//console.log('formContent->',formContent);
		
		let formFacilityId = '';
		let formDeviceTypes	 = '';
		
		if(formContent.length > 0){
			formFacilityId = formContent[0][120];
			formDeviceTypes = formContent[0][94];
		}
		
		if(formRank == 0){
			let optionRank = this.state.trackerTableData.length;
			formRank = Number(optionRank)+1;
		}
		
		if(redoInfo == 'Y'){
			redoOption = this.state.redoOption;
			redoInformation = this.state.redoInformation;
		}

		if(markCompleted == 'Y'){
			currentStatus = 'Complete-Hidden';
		}

		let url = this.state.ApiUrl+'tracker/tracker_form.php';
		
		let formData = new FormData();
		formData.append('selectedSheet', selectedSheet);
		formData.append('orderId', orderId);
		formData.append('cFormUID', cFormUID);
		formData.append('facilityID', trackingFacility);
		formData.append('personRole', personRole);
		formData.append('personName', personName);
		formData.append('currentStatus', currentStatus);
		formData.append('statusDueDate', statusDueDate);
		formData.append('formPriority', formPriority);
		formData.append('formRank', formRank);
		formData.append('dueDate', dueDate);
		formData.append('noteHtml', note);
		formData.append('redoError', redoOption);
		formData.append('redoInfo', redoInformation);
		formData.append('markCompleted', markCompleted ? markCompleted : 'N');
		formData.append('fabPractitioner', fabPractitioner ? fabPractitioner : '');
		formData.append('fabCompleted', fabCompleted ? fabCompleted : 'N');
		formData.append('allParts', allParts ? allParts : 'N');
		formData.append('fabPrctrEmail', fabPractitionerEmail ? fabPractitionerEmail : '');
		formData.append('patientId', fabPatientId ? fabPatientId : '');
		formData.append('pFirstName', patientFirstName ? patientFirstName : '');
		formData.append('pLastName', patientLastName ? patientLastName : '');
		formData.append('createdBy', createdBy);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			if(response.data){
				alert(response.data);
				let that = this;
				//let user = localStorage.getItem('username');
				let user = fabPractitioner;
				that.setTrackerStatusLog(cFormUID,personName,currentStatus,user);
				setTimeout(function(){
					that.getTrackerData();
					that.getStatusLog(cFormUID);
					$('#select-view-option').val('status_tracker').trigger('change');
					$("#wrapper").removeClass('toggled-2');
					that.setState({showTrackerTable:true,showTrackerDetails:false,showView:false,trackerTableUpdate:true});
				}, 2000);
				
				let currentStatusChanged = this.state.currentStatusChanged;
				
				if(currentStatusChanged && statusWithMail.indexOf(currentStatus) > -1){
					//console.log('currentStatus->',currentStatus);
					this.sendStatusEmail(cFormUID,personRole,personName,personEmail,currentStatus);
				}
				
				if(sendToPract.indexOf(currentStatus) > -1 && fabPractitionerEmail != personEmail){
					this.sendStatusEmail(cFormUID,personRole,personName,fabPractitionerEmail,currentStatus);
				}
				
				if(statusDueDate == 'Yes'){
					this.sendDueStatusEmail(cFormUID,fabPractitioner,fabPractitionerEmail);
				}
		
				this.setState({currentStatusChanged:false,fabPractitioner:'',fabPatientId:'',fabPractitionerEmail:'',patientFirstName:'',patientLastName:''});
			}
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	setTrackerStatusLog=(cFormUID,personName,currentStatus,user)=>{
		
		let formData = new FormData();
		formData.append('cFormUID', cFormUID);
		formData.append('personName', personName);
		formData.append('currentStatus', currentStatus);
		formData.append('user', user);
		let url = this.state.ApiUrl+'tracker/status_log.php';
		if(user && personName){
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
		
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	sendStatusEmail=(uid,personRole,name,email,currentStatus)=>{
		alert('ok');
		let formLink = window.location.href+'?uid='+uid;
		
		let templateParams = {
            emailId:email, 
            currentStatus:currentStatus,
            personRole:personRole,
            name:name,
            formLink:formLink,
        };
		
		if(email !== ''){
            emailjs.send('service_ywttkyp','template_0a460lc',templateParams,'fuhyVUaWX6S5mukJa')
            .then((result) => {
                //console.log('result->',result);
            }, (error) => {
                console.log(error.text);
            });
        }
	}
	
	sendDueStatusEmail=(uid,name,email)=>{
		let formLink = window.location.href+'?uid='+uid;
		
		let templateParams = {
			name:name,
            emailId:email, 
            formLink:formLink,
        };
		
		if(email !== ''){
            emailjs.send('service_ywttkyp','template_0kktub5',templateParams,'fuhyVUaWX6S5mukJa')
            .then((result) => {
                //console.log('result->',result);
            }, (error) => {
                console.log(error.text);
            });
        }
	}
	
	getLayoutData=(sheetName) => {
		let spreadsheetId = this.state.layoutSpreadsheetId;
		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:sheetName, 
		})
		.then(
			response => {
				const filedData = response.result.values;
				let allData = [];
				let ids =[];
				let mainData =[];
				{filedData.map((val,i)=>{
					if(i > 0){
						allData.push(val);
						let key = val[10];
						mainData[key] = val;
					}					
				})}
				let dbArray = this.checkKey2(mainData, '', ids, 2, 1);
				this.setState({sheetData:dbArray,allData});
				$(".need-attention-footer, .answer .MuiSvgIcon-root, .upload-btn-type, .save-output-response, .save-output-send, .select-delegate-filter, .select-attention-filter").hide();
				$("#select-view-option").val("mds_view");
			},function(reason) {
				console.log(reason.result.error.message);
			}
		);	
    }
	
	loadTrackerformResponse=(fromGuid)=>{
		let allData = this.state.allData;
		let kioskResponse = this.state.kioskResponse;
		let needsNote = this.state.needsNote;
		$("h3, h4, h5, h6").trigger('click');
		this.setState({kioskResponse:new Map(),needsNote:new Map()});
		let savedResponse = this.state.savedResponse;
		$(".question").addClass('not-response');
		savedResponse.map((val,i)=>{
			if(val[1] == fromGuid){
				val[4].map((res,i)=>{
					if(res.response){
						kioskResponse.set(res.FieldID, res.response);
						$("#"+res.FieldID).removeClass('not-response');
					}else{
						kioskResponse.delete(res.FieldID);
					}
					
					if(res.feedNote){
						needsNote.set(res.FieldID, res.feedNote);
					}else{
						needsNote.delete(res.FieldID); 
					}	
				})
			}
		})

		let that = this;
		$(".header-m").addClass('collapsed');
		$(".childMain").addClass('show');
		$(".header-m i").removeClass('fa-plus-square-o');
		$(".header-m i").addClass('fa-minus-square-o');
		$(".answer ").removeClass('offset-1');
		$('.comments input, .comments select, .comments textarea, .react-timer button, .answer button').attr('disabled', 'disabled');$("input[name='showOutputText']").prop('checked', true);
		that.setState({kioskResponse:kioskResponse,needsNote,outputText:true,showResponseData:true,trackerMode:true});
		
		/* let uid = that.getUrlParameter('uid');
		if(uid){
			$('.comments'). addClass('hide-for-now');
		} */
		setTimeout(function(){
			$(".not-response").remove();
			allData.map((comment,i)=>{
				if($('#'+comment[0]).find('.question').not(".not-include").length < 1 && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
					$('#'+comment[0]).addClass("not-include");	
				}
			});
			
		}, 2000);
	}
	
	showTrackerViewFun=()=>{
		if($("input[name='showViewForm']").prop("checked") == true){
			let trow = this.state.trackerRow;
			this.showTrackerView(trow.form_name,trow.form_id,trow);
		}else{
			this.setState({showTrackerTable:true,showView:false});
		}
	}
	
	showTrackerView=(name,uid,data)=>{
		$("#wrapper").removeClass('toggled-2');
		this.setState({allData:[],sheetData:[],showTrackerTable:false,showTrackerDetails:false,showView:true,showResponseData:true});
		this.getLayoutData(name);
		this.editStatusTracker(data);
		let that = this;
		setTimeout(function(){
			$("input[name='showViewForm']").prop('checked', true);
			that.loadTrackerformResponse(uid);
		}, 2000);	
	}
	
	trackerViewPdf=(name,uid,data)=>{
		this.getLayoutData(name);
		let that = this;
		setTimeout(function(){
			that.trackerformResponsePdf(uid);
		}, 2000);

		setTimeout(function(){
			that.createPdf(name,uid,data);
		}, 5000);
	}
	
	trackerformResponsePdf=(fromGuid)=>{
		let allData = this.state.allData;
		let kioskResponse = this.state.kioskResponse;
		this.setState({kioskResponse:new Map(),needsNote:new Map()});
		let savedResponse = this.state.savedResponse;
		$(".question").addClass('not-response');
		savedResponse.map((val,i)=>{
			if(val[1] == fromGuid){
				val[4].map((res,i)=>{
					if(res.response){
						kioskResponse.set(res.FieldID, res.response);
						$("#"+res.FieldID).removeClass('not-response');
					}else{
						kioskResponse.delete(res.FieldID);
					}
	
				})
			}
		})

		let that = this;
		that.setState({kioskResponse:kioskResponse,outputText:true,showResponseData:true,trackerMode:true});
		setTimeout(function(){
			$(".not-response").remove();
			allData.map((comment,i)=>{
				if($('#'+comment[0]).find('.question').not(".not-include").length < 1 && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
					$('#'+comment[0]).addClass("not-include");	
				}
			});
			$(".pdf-select").attr("selected","selected");
			$(".pdf-radio").attr("checked","checked");
			
		}, 2000);
	}
	
	createPdf=(name,uid,data)=>{
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'pdf/pdf.php';
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
		let formName = uid+'-'+date;
		let con = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'content-type': 'multipart/form-data'
			}
		}
		
		let priority ='Normal';
		if(data.priority == 1){
			priority ='Low';
		}else if(data.priority == 3){
			priority ='High';
		}
		let redoHtml = '';
		if(data.redo_error){
			redoHtml = '<div><b>Redo dropdown Value:</b> '+data.redo_error+'</div><div><b>Redo Notes:</b> '+data.redo_info+'</div>';
		}
		
		let createdDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
		let html = '<div style="display: block;margin-bottom:20px"><div style="margin-bottom:10px"><img src="'+window.location.href+'/MicrosoftTeams-image.png" width="260px"><img src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='+window.location.href+'?uid='+uid+'" alt="QR" width="150" height="150" style="float:right;"></div><div style="text-align:right;">Job #:'+data.id+'</div><div><b>Patient ID:</b> '+data.patient_id+'</div><div><b>Sending practitioner:</b> '+data.practitioner+'</div><div><b>Patient First name:</b> '+data.patient_firstname+'</div><div><b>Patient Last name:</b>  '+data.patient_lastname+'</div><div><b>Date:</b> '+date+'</div><br /><div><b>Role of responsible person:</b> '+data.role+'</div><div><b>Assigned to:</b> '+data.person+'</div><div><b>Due Date:</b> '+data.due_date+'</div><div><b>At risk of missing due date:</b> '+data.due_date_status+'</div><div><b>Priority:</b> '+priority+'</div><div><b>Rank:</b> '+data.rank+'</div><div><b>Additional Notes:</b> '+data.note+'</div>'+redoHtml+'</div>';
		
		html = html.concat($(".tracker-pdf-data").html());
		let footer = '';
		let form_data = new FormData();
		form_data.append('name', formName);
		//form_data.append('header', header);
		form_data.append('footer', footer);
		form_data.append('html', html);
		post(url, form_data, con)
		.then(response => {
			window.open(response.data, "_blank");
			let that = this;
			/* setTimeout(function(){
				that.setState({sheetData:[],allData:[]});
			}, 2000); */
		})
	}
	
	editStatusTracker=(data)=>{
		let fabJobNumber = data.id;
		let cFormUID = data.form_id;
		let selectedSheet = data.form_name;
		let trackingFacility = data.facilityID;
		let personRole = data.role;
		let personName = data.person;
		let currentStatus = data.status;
		let dueDate = data.due_date;
		let noteHtml = data.note;
		let redoInfo = 'N';
		let redoOption = data.redo_error;
		let redoInformation = data.redo_info;
		let formPriority = data.priority;
		let statusDueDate = data.due_date_status;
		let formRank = data.rank;
		let fabPractitioner = data.practitioner;
		let fabPractitionerEmail = data.practitioner_email;
		let patientId = data.patient_id;
		let patientFirstName = data.patient_firstname;
		let patientLastName = data.patient_lastname;
		let fabCompleted = data.fab_complete;
		let allParts = data.all_parts;
		
		this.getStatusByFacility(trackingFacility);

		if(redoOption){
			$('#redoInfo').show();
			redoInfo = 'Y';
		}else{
			$('#redoInfo').hide();
		}
		
		let markCompleted = data.mark;
		
		if(markCompleted == 'Y'){
			currentStatus = 'Complete-Hidden';
		}

		let roleName = this.state.responsibleRole;
		let facilityData = this.state.facilityData;
		roleName.map((val, i) => {
			if(val === personRole){
				let persons = facilityData[trackingFacility][val];
				
				let personNames = [];
				let email = '';
				persons.map((name, i) => {
					name = name.split('|');
					personNames.push(name[0]);
					if(name[0] == personName){
						email = name[1];
					}
				})
				
				this.setState({persons,responsiblePerson:personNames,personEmail:email});
			}
		})
		
		
		if(typeof noteHtml !== 'undefined' && noteHtml !== ''){
			let addNoteText = noteHtml;
			let imgUrl  = addNoteText;
			const html = `<p>`+imgUrl+`</p>`;
			const contentBlock = htmlToDraft(html);

			if (contentBlock) {
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
				const editorState = EditorState.createWithContent(contentState);
				this.setState({
				  editorState,editorSourceHTML:addNoteText
				});
			}
		}else{
			this.setState({editorState:'',editorSourceHTML:''});
		}
		
		if($("input[name='showViewForm']").prop("checked") == true){
			$("input[name='showViewForm']").prop('checked', false);
		}
		
		$("#wrapper").addClass('toggled-2');
		$(".fadeInRight").addClass('is-open');
		$(".fadeInRight").removeClass('is-closed');
		this.getStatusLog(cFormUID);
		this.setState({trackerRow:data,showTrackerDetails:true,selectedSheet,cFormUID,trackingFacility,personRole,personName,currentStatus,dueDate,statusDueDate,formPriority,formRank,redoInfo,redoOption,redoInformation,markCompleted,fabCompleted,allParts,showFieldEdit:false,trackerTableUpdate:false,fabPractitioner,fabPractitionerEmail,fabPatientId:patientId,patientFirstName,patientLastName,fabJobNumber});
		//alert(uid);
	}
	
	getStatusLog=(cFormUID)=>{
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'tracker/get_status_log.php?formId='+cFormUID;
		let data = [];
		let con = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'content-type': 'multipart/form-data'
			}
		}
        axios.get(url,con)
        .then(response => {
            data = response.data;
			this.setState({statusLogs:data});
        })
	}
	
	getStatusByFacility=(fid=null)=>{
		
		if(!fid){
			fid = this.state.trackingFacility;
		}
		//console.log('fid->',fid);
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'tracker/get_status.php?fId='+fid;
		let statusByfacility = [];
		let con = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        axios.get(url,con)
        .then(response => {
            let data = response.data;
			//console.log('data->',data);
			if(data){
				data.map((val,i)=>{
					if(val.visible == 'Y' && i==0 && this.state.currentStatus == ''){
						this.setState({currentStatus:val.status});
					}
					if(val.visible == 'Y'){
						statusByfacility.push(val.status);
					}
				});
			}
			this.setState({statusByfacility});
        })
	}
	
	handelMdsGuid = (id,name) => {
		//$("#guidIconModal").show();
		$("#guidReasonNote").val('');
		this.setState({reasonNote:'',sheetIdToUpdate:id,sheetNameToUpdate:name});
		
	}
	
	handelMdsGuid2 = (id,name,reasonNote) => {
		if(reasonNote){
			let rNoteSplit = reasonNote.split('|');
			let rnote = rNoteSplit[1];
			let rnoteDate = rNoteSplit[2];
			this.setState({rnote,rnoteDate});
		}

	}
	
	resetMdsGuid = () => {
		let note = this.state.reasonNote;
		let user_id = localStorage.getItem('user_id');
		let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
		
		let reasonNote = user_id+'|'+note+'|'+currentDate;
		$("#guidIconModal").hide();
		
		let sheetName = this.state.sheetNameToUpdate;
		let spreadsheetId = this.state.mdsSpreadsheetId;
		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId, 
			range:sheetName, 
		})
		.then(
			response => {
			let allData = response.result.values;
			//console.log('allData->',allData);
			let updateRows = [];
			let rowId='';
			let parentGuid = '';
			let parentGuid1 = '';
			let parentGuid2 = '';
			let parentGuid3 = '';
			let mds_guid = ulid();
			allData.map((field,i)=>{
				if(i > 0){
					rowId = (i+1);
					let guids_dn = ulid();
					field[117] = guids_dn;
					
					if(field[5].toLowerCase() == 'parent header'){
						parentGuid = field[117];
						parentGuid1 = field[117];
					}else if(field[5].toLowerCase() == 'child header 1'){
						allData[i][11] = parentGuid1;
						parentGuid = field[117];
						parentGuid2 = field[117];
					}else if(field[5].toLowerCase() == 'child header 2'){
						allData[i][11] = parentGuid2 ? parentGuid2 : parentGuid;
						parentGuid = field[117];
						parentGuid3 = field[117];
					}else if(field[5].toLowerCase() == 'child header 3'){
						allData[i][11] = parentGuid3 ? parentGuid3 : parentGuid;
						parentGuid = field[117];
					}else{
						allData[i][11] = parentGuid;
					}
				
					let row1 = {
						"range": sheetName+'!L'+rowId,
						"majorDimension": "ROWS",
						"values": 
						[
							[allData[i][11]]
						]
					};
					
					let row2 = {
						"range": sheetName+'!DN'+rowId,
						"majorDimension": "ROWS",
						"values": 
						[
							[guids_dn]
						]
					};
					
					let row3 = {
						"range": sheetName+'!CN'+rowId,
						"majorDimension": "ROWS",
						"values": 
						[
							[mds_guid]
						]
					};
					
					let row4 = {
						"range": sheetName+'!CU'+rowId,
						"majorDimension": "ROWS",
						"values": 
						[
							[currentDate]
						]
					};
					
					updateRows.push(row1);
					updateRows.push(row2);
					updateRows.push(row3);
					updateRows.push(row4);
					
					if(i == 1){
						let row5 = {
							"range": sheetName+'!CS'+rowId,
							"majorDimension": "ROWS",
							"values": 
							[
								[reasonNote]
							]
						};
						updateRows.push(row5);
					}
					
					
				}
			})
			
			var params = {
				spreadsheetId: spreadsheetId,
			}
			let batchUpdateValuesRequestBody = {
				valueInputOption: 'USER_ENTERED',
				data: updateRows, 
			};
			//console.log('updateRows->',updateRows);
			let that = this;
			let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
			updateRequest.then(function(response) {
				alert('Field GuidId updated Successfully!');
			}, function(reason) {
				console.error('error: ' + reason.result.error.message);
			});
			
		})

	}
	
	createNewMDs=(mdsDataName)=>{
		
		let spreadsheetId = this.state.mdsCopyFieldSpreadsheetId;
		let selectedSheetId = this.state.mdsCopyFieldSheetId;
		
		let params = {
            spreadsheetId: spreadsheetId,
            sheetId:selectedSheetId,
        };
		
        let copySheetToAnotherSpreadsheetRequestBody = {
            destinationSpreadsheetId: spreadsheetId,
        };
		
        let that=this;
        let request = gapi.client.sheets.spreadsheets.sheets.copyTo(params, copySheetToAnotherSpreadsheetRequestBody);
		request.then(
			response => {
				
				let params1 =  {
					spreadsheetId: spreadsheetId,
				};
				let getRequest = gapi.client.sheets.spreadsheets.get(params1);
				getRequest.then(function(response) {
					
					let length = response.result.sheets.length;
					let lastSheetId = response.result.sheets[length-1].properties.sheetId;
					
					let requests ={
						"requests": [
							{
							  "updateSheetProperties": {
								"properties": {
								  "sheetId": lastSheetId,
								  "title": mdsDataName,
								},
								"fields": "title"
							  }
							}
						]
					}
					let renameRequest = gapi.client.sheets.spreadsheets.batchUpdate(params1,requests);
					renameRequest.then(function(response) {
						alert('The New MDS file created successfully!');
						$('#newMdsName').val('');
						that.getCopyMdsFile();
						
					}, function(reason) {
						alert(reason.result.error.message);
					});
				}, function(reason) {
					alert(reason.result.error.message);
				});

			}, function(reason) {
				alert(reason.result.error.message);
		});
	}
	
	handleMdsCopyFields = (e) => {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({
            checkedCopyFields: prevState.checkedCopyFields.set(item, isChecked)
        }));
    }
	
	handleMdsCopyAllFields = (e) => {
		let item = e.target.name;
		let allData = this.state.allData;
		let isChecked = e.target.checked;

		if(isChecked == true){
			$('#copyAllFields').modal('show');
		}else{
			this.mdsCopyAllFields(isChecked=false);
		}
		this.setState({copyChecked:isChecked,copySelectedItem:item});
		this.setState(prevState => ({
            checkedCopyFields: prevState.checkedCopyFields.set(item, isChecked)
        }));
	}
	
	mdsCopyAllFields = (isChecked) => {

        let item = this.state.copySelectedItem;
		let allData = this.state.allData;
		
		if(isChecked != false){
			isChecked = this.state.copyChecked;
		}

		let copyHeaderFiled = this.state.copyHeaderFiled;
		
		let pos = '';
		let type = '';
		let newSets = [];
		allData.map((val,i)=>{
			if(item == val[0] && (val[5] == 'Parent Header' || val[5] == 'Child Header 1' || val[5] == 'Child Header 2' || val[5] == 'Child Header 3')){
				pos = val[10];
				type = val[5];
				newSets.push(val);
			}
		})
		
		if(copyHeaderFiled == 'all'){
			let parentdata = allData.sort(function(a, b) {
				return a[10] - b[10];
			});
			
			
			let end = 0;
			for (var i = pos; i < parentdata.length; i++) {
				if(i > pos && (parentdata[i][5] == type || (type.toLowerCase() == 'child header 1' && parentdata[i][5].toLowerCase() == 'parent header') || (type.toLowerCase() == 'child header 2' && (parentdata[i][5].toLowerCase() == 'child header 1' || parentdata[i][5].toLowerCase() == 'parent header')) || (type.toLowerCase() == 'child header 3' && (parentdata[i][5].toLowerCase() == 'child header 2' || parentdata[i][5].toLowerCase() == 'child header 1' || parentdata[i][5].toLowerCase() == 'parent header'))) ){
					break;
				}
				
				/* if(type.toLowerCase() == 'boxbegin' && end > 0){
					break;
				}
				
				if(i > pos && (parentdata[i][5] == type || (type.toLowerCase() == 'boxbegin' && parentdata[i][5].toLowerCase() == 'boxend')) ){
					end = end+1;
				} */
				
				
				
				newSets.push(parentdata[i]);  
			}
		}
		
		if(newSets.length > 0){
			newSets.map((val,i)=>{
				this.setState(prevState => ({
					checkedCopyFields: prevState.checkedCopyFields.set(val[0], isChecked)
				}));
			})
		}
    }
	
	copyMdsFields = () => {
		let allData = this.state.allData;
		let copyData = [];
		let rows = Object.assign([], allData);
		rows.map((val,i)=>{
			if(this.state.checkedCopyFields.get(val[0])){
				let timestamp = Date.now();
				//val[134] = timestamp;
				val[134] = '';
				val[135] = val[91];
				copyData.push(val);
			}
		})
		
		if(copyData){
			this.copyToMdsFields(copyData);
		}
	}
	
	copyAddNewField=(optionType,value) =>{
		this.setState({	MDGroups:0});
		let allData = this.state.copyAllData;
		let spreadsheetId = this.state.mdsCopyFieldSpreadsheetId;
		let sheetName = this.state.copySelectedSheet;
        
		let fieldPrefix = this.state.fieldPrefix;
		let id = allData.length+1
		let ttevalId = fieldPrefix+'-'+id;
		let fieldID = '';
		let parentId = '';
		let position = id;
		let PosId = '';
		let guids = '';
		let guids_dn = ulid();
		let tagId = '';
		let mds_guids = '';
		let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
		var params = {
			spreadsheetId:spreadsheetId,
			range:sheetName+'!A:EB', 
			valueInputOption: "RAW", 
			insertDataOption: "INSERT_ROWS",
			resource:{
				"values": 
				[
					[ ttevalId,fieldID,parentId,'','',optionType,'','','','',position,'','','','Y','N',PosId,'','','','','','','','','','','','','','','','Y','N','',guids,'','N','',tagId,'','','','','','','','','V1','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','',mds_guids,'','','','','','',currentDate,'','','','','','','','','','','','','Y','','','','','',guids_dn,'','','','','','','','' ]
				],
				"majorDimension": "ROWS"
			  }
		};
		let that = this;
		var request = gapi.client.sheets.spreadsheets.values.append(params);
		request.then(function(response) {
			that.getCopyMdsData(sheetName);
			if(that.state.fieldPos){
				setTimeout(function(){
					that.copyMdsUpdatePostion();
				}, 2000);
			}
		}, function(reason) {
			console.error('error: ' + reason.result.error.message);
		});
	}
	
	updateEeMdsData(copySheetName){
		let allData = this.state.allData;
		let sheetName = this.state.selectedSheet;
		
		let params =  {
            spreadsheetId: this.state.mdsSpreadsheetId,
        };
		
		let rowId ='';
		let updateData=[];
		allData.map((rowDatas,i)=>{
			rowId = (i+2);
			if(this.state.checkedCopyFields.get(rowDatas[0])){
				
				let eea = '';
				let ee = rowDatas[134].split(",");
				
				if(ee.indexOf(copySheetName) !== -1){
					eea = rowDatas[134];
				}else if(rowDatas[134] == ''){
					eea = copySheetName;
				}else{
					eea = rowDatas[134]+','+copySheetName;
				}
				
				let row = {
					range: sheetName+'!EE'+rowId,
					values: [[eea]]
				};
				updateData.push(row)
				
			}
		});
		
		let that = this;
		let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
		
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
			that.getMdsData();
        }, function(reason) {
            alert(reason.result.error.message);
        });
	}
	
	copyToMdsFields(data){
		let allData = this.state.copyAllData;
		let sheetName = this.state.copySelectedSheet;
		let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
        
		let row = Object.assign([], data);
	
		this.setState({	MDGroups:row.length});
		var params = {
			spreadsheetId:this.state.mdsCopyFieldSpreadsheetId,
			range:sheetName+'!A:EO', 
			valueInputOption: "RAW", 
			insertDataOption: "INSERT_ROWS",
			resource:{
				"values":row,
				"majorDimension": "ROWS"
			}
		};
		let that = this;
		var request = gapi.client.sheets.spreadsheets.values.append(params);
		request.then(function(response) {
			that.getCopyMdsData(sheetName);
			that.updateEeMdsData(sheetName);
			that.setState({checkedCopyFields:new Map()});
			if(that.state.fieldPos){
				setTimeout(function(){
					that.copyMdsUpdatePostion();
				}, 1000);
			}else{
				setTimeout(function(){
					that.copyMdsUpdateData('save');
				}, 1000);
			}
		}, function(reason) {
			console.error('error: ' + reason.result.error.message);
		}); 

	}
	
	copyMdsUpdatePostion=()=>{
		let sheetName = this.state.copySelectedSheet;
		let allData = this.state.copyAllData;
        
		let params =  {
            spreadsheetId: this.state.mdsCopyFieldSpreadsheetId,
        };
		
		let rowId ='';
		let updateData=[];
		allData.map((rowDatas,i)=>{
			rowId = (i+2);
			let row = {
				range: sheetName+'!A'+rowId+':EO'+rowId,
				values: [ rowDatas ]
			};
			updateData.push(row)
		});
		
		let that = this;
		let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateData, 
        };
		
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
			that.copyMdsUpdateData('save');
        }, function(reason) {
            alert(reason.result.error.message);
        });
	}
	
	copyMdsUpdateData=(msg=null) =>{
		let allData = this.state.copyAllData;
		let spreadsheetId = this.state.mdsCopyFieldSpreadsheetId;
		let sheetName = this.state.copySelectedSheet;
		
		let fieldID = '';
		let j = 0;
		let n = 0;
		let s = 0;
		let q = 0;
		let parent1 = '';
		let parent2 = '';
		let parent3 = '';
		let parentID = '';
		let parentGuid = '';
		let parentGuid1 = '';
		let parentGuid2 = '';
		let parentGuid3 = '';
		
		let characters = "abcdefghijklmnopqrstuvwxyz";
		let length = 3;
		let randomStr = "";

		for (let i = 0; i < length; i++) {
		  const randomNum = Math.floor(Math.random() * characters.length);
		  randomStr += characters[randomNum];
		}
		//console.log('allData->',allData);
		//let fieldPrefix = this.state.fieldPrefix;
		let guids_cn = ulid();
		let mdsGuid = allData[0][91] ? allData[0][91] : guids_cn;
		
		if(msg == 'new'){
			mdsGuid = guids_cn;
		}
		
		let mdsName = sheetName;
		let chtr = 0;
		allData.map((field,i)=>{
			let id = i+1;
			allData[i][0] = sheetName+'-'+id;	
			allData[i][10] = id;
			
			if(field[5].toLowerCase() == 'parent header'){
				
				if(field[4]){
					fieldID = field[4].substring(0, 3)+characters[chtr];
				}else if(field[1] == ''){
					fieldID = randomStr
				}
				
				allData[i][1] = fieldID;
				allData[i][2] = 'MDS';
				parentID = fieldID;
				parent1 = fieldID;
				parentGuid = field[117];
				parentGuid1 = field[117];
				j = 0;
				n = 0;
				s = 0;
				chtr++;
			}else if(field[5].toLowerCase() == 'child header 1'){
				j = j+1
				let child = 'c'+j;
				allData[i][1] = fieldID+child;
				allData[i][2] = parent1;
				allData[i][11] = parentGuid1;
				parentID = fieldID+child
				parent2 = fieldID+child
				parentGuid = field[117];
				parentGuid2 = field[117];
				//n = 0;
			}else if(field[5].toLowerCase() == 'child header 2'){
				n = n+1
				let child = 'cc'+n;
				allData[i][1] = fieldID+child;
				allData[i][2] = parent2 ? parent2 : parentID;
				allData[i][11] = parentGuid2 ? parentGuid2 : parentGuid;
				parentID = fieldID+child
				parent3 = fieldID+child
				parentGuid = field[117];
				parentGuid3 = field[117];
			}else if(field[5].toLowerCase() == 'child header 3'){
				s = s+1
				let child = 'ccc'+s;
				allData[i][1] = fieldID+child;
				allData[i][2] = parent3 ? parent3 : parentID;
				allData[i][11] = parentGuid3 ? parentGuid3 : parentGuid;
				parentID = fieldID+child
				parentGuid = field[117];
			}else{
				q = q+1
				allData[i][1] = 'q'+q;
				allData[i][2] = parentID;
				allData[i][11] = parentGuid;
			}

		});
		
		let updateRows=[];
		let dates=[];
		let rowId='';
		

		allData.map((rowDatas, i) => {
			rowId = (i+2);
			let guids_dn = ulid();
			let userTypes = ',,,,,,,CPO,,';
			
			let parentID = rowDatas[2];
			if(rowDatas[5].toLowerCase() == 'parent header'){
				parentID ='';
			}
			let positionConfig = rowDatas[0]+',,'+parentID+','+rowDatas[7]+','+rowDatas[8]+','+rowDatas[14]+','+rowDatas[15]+','+rowDatas[10]+','+rowDatas[37]+','+userTypes;
			
			let row1 = {
				"range": sheetName+'!A'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[rowDatas[0]]
				]
			};
			let row2 = {
				"range": sheetName+'!B'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[rowDatas[1]]
				]
			};
			let row3 = {
				"range": sheetName+'!C'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[rowDatas[2]]
				]
			};
			
			let row4 = {
				"range": sheetName+'!K'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[rowDatas[10]]
				]
			};
			
			let row5 = {
				"range": sheetName+'!L'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[rowDatas[11]]
				]
			};
			
			let row6 = {
				"range": sheetName+'!Q'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[positionConfig]
				]
			};
			
			
			let row7 = {
				"range": sheetName+'!CN'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[mdsGuid]
				]
			};
			
			let row8 = {
				"range": sheetName+'!DO'+rowId,
				"majorDimension": "ROWS",
				"values": 
				[
					[mdsName]
				]
			};
			
			updateRows.push(row1)
			updateRows.push(row2)
			updateRows.push(row3)
			updateRows.push(row4)
			updateRows.push(row5)
			updateRows.push(row6)
			updateRows.push(row7)
			updateRows.push(row8)
			
			if(msg == 'new'){
				let row9 = {
					"range": sheetName+'!DN'+rowId,
					"majorDimension": "ROWS",
					"values": 
					[
						[guids_dn]
					]
				};
				updateRows.push(row9)
			}
			
			
			
			let date = new Date(rowDatas[98]);
			if(date != 'Invalid Date'){
				dates.push(date);
			}
		});
	
		var params = {
			spreadsheetId: spreadsheetId,
		}
		let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: updateRows, 
        };
		let that = this;
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
			that.setState({fieldPos:''});
			if(msg == 'del' || msg == 'save'){
				that.getCopyMdsData(sheetName);
			}else{
				that.setState({fieldPos:'',copySelectedSheet:'',copySheetData:[],copyAllData:[]});
				$('#loadCopySelectMds').val('');
				alert('The Copy MDS updated Successfully!');
			}
			
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        });
	}
	
	copyDeleteField(filed){
		let allData = this.state.copyAllData;
		let newData = [];
		let startIndex = '';
		allData.map((rowDatas, i) => {
			if(rowDatas[0] != filed){
				newData.push(rowDatas);
			}
			if(rowDatas[0] == filed){
				startIndex = i+1;
			}
		});
		//console.log('startIndex->',startIndex);
		this.setState({copyAllData:newData});
		$('.btn-delete').prop('disabled', true);
		let that = this;
		setTimeout(function(){
			that.copyClearUpdateData(startIndex);
		}, 2000);
	}
	
	copyClearUpdateData=(startIndex)=>{
		let spreadsheetId = this.state.mdsCopyFieldSpreadsheetId;
		let copyMdsSheetNames = this.state.copyMdsSheetNames;
		let selectedSheetId = '';
		let sheetName = this.state.copySelectedSheet;
		
		copyMdsSheetNames.map((val, i) => {
			//console.log('startIndex->',val);
			if(sheetName == val.name){
				selectedSheetId = val.sheetId;
			}
		});
        
		let params =  {
            spreadsheetId: spreadsheetId,
        };
		let that = this;
		let endIndex = startIndex+1;
        let deleteRow = {
            "requests": [
            {
              "deleteDimension": {
                "range": {
                  "sheetId": selectedSheetId,
                  "dimension": "ROWS",
                  "startIndex": startIndex,
                  "endIndex": endIndex
                }
              }
            }]
        }
		
		let request = gapi.client.sheets.spreadsheets.batchUpdate(params, deleteRow);
        request.then(function(response) {
			alert('Field Deleted Successfully!');
			that.copyMdsUpdateData('del');
		}, function(reason) {
            alert(reason.result.error.message);
        });
		
	}
	
	editHeadersField(filed,ftype,data){
		let showEditHeaders = true;
		let chooseFieldType = ftype;
		let copyQueInpText = data[4];
		let copyFiledNote = data[41];
		let asGroup = data[127];
		if(chooseFieldType == 'Parent Header'){
			asGroup = data[126];
		}
		this.setState({copyFieldID:filed,showEditHeaders,copyQueInpText,chooseFieldType,copyFiledNote,asGroup});
	}
	
	copyUpdateField=()=>{
		let fieldId = this.state.copyFieldID;
		let spreadsheetId = this.state.mdsCopyFieldSpreadsheetId;
		let sheetName = this.state.copySelectedSheet;
		let allData = this.state.copyAllData;
		let question = this.state.copyQueInpText;
		let answerType = this.state.chooseFieldType;
		let asGroup = this.state.asGroup;
		let copyFiledNote = this.state.copyFiledNote;
		let rowId = '';
		let data = [];
		allData.map((valu, i) => { 
			if(valu[0] == fieldId){
				rowId = (i+2);
			}
		});
		let column = 'DX';
		if(answerType == 'Parent Header'){
			column = 'DW';
		}

		let row1 = {
			range: sheetName+'!E'+rowId+':F'+rowId,   
			values: [
				[ question,answerType]
			]
		}
		
		let row2 = {
			range: sheetName+'!'+column+rowId,   
			values: [
				[ asGroup ]
			]
		};
		
		let row3 = {
			range: sheetName+'!AP'+rowId,   
			values: [
				[ copyFiledNote ]
			]
		};
		
		
		
		data.push(row1);
		data.push(row2);
		data.push(row3);

		var params = {
			spreadsheetId: spreadsheetId,
		}
		let batchUpdateValuesRequestBody = {
            valueInputOption: 'USER_ENTERED',
            data: data, 
        };
		let that = this;
        let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
        updateRequest.then(function(response) {
            alert('Field updated Successfully!');
			that.copyMdsUpdateData('save');
        }, function(reason) {
            console.error('error: ' + reason.result.error.message);
        });
	}

	viewFieldsMode=()=>{
		let fieldView = this.state.fieldView;
		if($("input[name='showFieldView']").prop("checked") == true){
			this.setState({fieldView:true});
		}else{
			this.setState({fieldView:false});
		}
	}
	
	getMdsJsonFileVersion=()=>{
		let mdsSheetGuids = [];
		this.state.mdsSheetNames.map((sheet,i)=>{
			let sheetGuids = this.state.sheetGuids;
			let sheetGuid = sheetGuids.get(sheet.name);
			mdsSheetGuids.push(sheetGuid);	
		});
		
		let mongoApiUrl = $('#mongoApiUrl').val();
		let url = mongoApiUrl+'mds-json-version';
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(mdsSheetGuids),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			
			let files = response.data;
			if(files){
				files.map((file,i)=>{
					if(file.Version){
						let mdsJsonFilesVersion = this.state.mdsJsonFilesVersion;
						mdsJsonFilesVersion.set(file.MDSGuid,file.Version);
						this.setState({mdsJsonFilesVersion});
					}
				});
			}
			
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	getLayoutJsonFileVersion=()=>{
		let mdsSheetGuids = [];
		this.state.childSheetNames.map((sheet,i)=>{
			let sheetGuids = this.state.sheetGuids;
			let sheetGuid = sheetGuids.get(sheet.name);
			mdsSheetGuids.push(sheetGuid);	
		});
		
		let mongoApiUrl = $('#mongoApiUrl').val();
		let url = mongoApiUrl+'layout-json-version';
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(mdsSheetGuids),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			
			let files = response.data;
			files.map((file,i)=>{
				if(file.Version){
					let layoutJsonFilesVersion = this.state.layoutJsonFilesVersion;
					layoutJsonFilesVersion.set(file.DefaultLayoutGuid,file.Version);
					let layoutTranslation = this.state.layoutTranslation;
					if(file.translation){
						layoutTranslation.set(file.DefaultLayoutGuid,file.translation);
					}
					
					this.setState({layoutJsonFilesVersion,layoutTranslation});
				}
			});
			
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	getLayoutLauguageJson=(event)=>{
		
		let layoutGuid = event.target.name;
		let layoutLanguage = event.target.value;
		this.setState({layoutLanguageData:[],selectedLanguage:layoutGuid+'_'+layoutLanguage});
		let layout = {
			"layoutGuid": layoutGuid,
			"layoutLanguage": layoutLanguage,
		}
		
		let mongoApiUrl = $('#mongoApiUrl').val();
		let url = mongoApiUrl+'layout-language-json';
		axios({
			method: 'POST',
			url: url,
			data: layout,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			let files = response.data;
			if(files.length > 0){
				this.setState({layoutLanguageData:files,selectedLanguage:layoutGuid+'_'+layoutLanguage});
			}else{
				this.setState({layoutLanguageData:[],selectedLanguage:''});
			}
		}).catch(error => {
			console.log('error::', error);
		}) 
	}
	
	selectInstance=(event)=>{
		let value = event.target.value;
		let instances = this.state.instances;
		
		instances.map((val,i)=>{
			if(val.id == value){
				this.setState({instanceSetting:val,instanceId:val.id});
			}
		});
		let that = this;
		setTimeout(function(){
			that.changeInstance(value);
		}, 1000);
		
    }
	
	uploadOldMdsJsonMongoDb = (spreadsheetId,sheetName) => {
		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:sheetName, 
		})
		.then(
			response => {
				const allData = response.result.values;
				let currentDate = new Date().toLocaleString("en-US").replace(',','');
				let parentMds = [];
				if(allData.length > 0){
					let mdsName = allData[1][118];
					
					let mdsGuid = allData[1][91];
					let associatedType = allData[1][92];
					let associatedForm = allData[1][93];
					let associatedDevice = allData[1][94];
					let associatedVisit = allData[1][95];
					
					let Types = allData[1][100];
					let DeviceType = allData[1][101];
					let VisitType = allData[1][102];
					let totalFields = allData.length-1;
					let Industry = allData[1][80];
					let Profession = allData[1][81];
					let Subspecialty = allData[1][82];
					
					let delegateTypes = allData[1][119];
					let facilityId = allData[1][120] ? allData[1][120] : this.state.defaultLayoutFacility;
					let groupId = allData[1][121];
					let requiredGroups = allData[1][115];
					let version = allData[1][59] ? allData[1][59] : '1.01';
					let friendly_name = allData[1][62] ? allData[1][62] : '';
					let dates = [];
					let fields = [];
					allData.map((field,i)=>{
						if(i > 0){
							
							let date = new Date(field[98]);
							if(date != 'Invalid Date'){
								dates.push(date);
							}
							
							let notes = field[12] = field[12].replaceAll('\n','');
							notes = notes.replaceAll('<p></p>','');
							let customNotes = field[116] = field[116].replaceAll('\n','');
							customNotes = customNotes.replaceAll('<p></p>','');
							
							let preId = '';
							let parentGuid = '';
							allData.map((pre_field,i)=>{
								if(field[7] && field[7] == pre_field[0]){
									preId = pre_field[117];
								}
								
								if(pre_field[1] == field[2]){
									parentGuid = pre_field[117];
								}
							})
							
							
							
							let oldP = field[16];
							let userArray = oldP.split(',');
							userArray.splice(0, 9);
							
							let uArray = userArray.filter(function(v){return v!==''});
							let kioskTypes = uArray.toString();
							kioskTypes = kioskTypes.replaceAll(',','|');

							let strArray = oldP.split(',');
							strArray.splice(9, 15);

							let configStr = strArray.toString();
							configStr = configStr+",["+kioskTypes+"]";
							
							let rqFields = [];
							let requiredFields = '';
							if(field[142] && field[5] == 'launchpad'){
								let rfStr = field[142].split(',');
								rfStr.map((rfield,i)=>{
									rfield = rfield.split('|');
									rqFields.push(rfield[0]+','+rfield[1]);
								});
								requiredFields = rqFields.join('|');
								
							}
							
							let opFields = [];
							let optionalFields = '';
							if(field[143] && field[5] == 'launchpad'){
								let opStr = field[143].split(',');
								opStr.map((ofield,i)=>{
									ofield = ofield.split('|');
									opFields.push(ofield[0]+','+ofield[1]);
								});
								optionalFields = opFields.join('|');
								
							}
							let options = [];
							
							let lastDate = new Date(field[98]).toLocaleString("en-US").replace(',','');
							let optionValue = [];
							let gridLabel = [];
							let SharedformID = "Shared form ID's";
							
							if(field[6]){
								options = field[6].split(',');
							}
							if(field[5] == 'basicgrid' || field[5] == 'grid5point' || field[5] == 'grid-multicheck' || field[5] == 'grid-singlecheck'){
								let str = field[41] ? field[41].split('_') : [];
								
								if(str.length > 0){
									let columns = str[0].split(',');
									let rows = str[1].split(',');
									gridLabel = {
										"ColumnLabelValues": columns,
										"RowLabelValues": rows,
									}
								}
							}
							
							if(field[5] == 'basicgrid2'){
								let str = field[41] ? field[41].split('_') : [];
								
								if(str.length > 0){
									let columns = str[0].split('|');
									let rows = str[1].split(',');
									
									let columnslable = [];
									let columnstd = [];
									columns.map((val,i)=>{
										let cm = val.split(':');
										columnslable.push(cm[0]);
										columnstd.push(cm[1]);
									});
									gridLabel = {
										"ColumnLabelValues": columnslable,
										"RowLabelValues": rows,
										"ColumnDataType": columnstd,
									}
								}
							}
							
							if(field[5] == 'weighted'){
								let str = field[41] ? field[41].split(',') : [];
								
								str.map((val,i)=>{
									let op = val.split(':');
									options.push(op[0]);
									optionValue.push(op[1]);
								});
							}
							
							if(field[5] == 'grid-dropdown'){
								let str = field[41] ? field[41].split('_') : [];
								
								if(str.length > 0){
									let columns = str[0].split(',');
									let rows = str[1].split('|');
									
									//let columnslable = [];
									let rowValue = [];
									rows.map((val,i)=>{
										let cm = val.split(':');
										//columnslable.push(cm[0]);
										rowValue.push(cm[0]);
									});
									gridLabel = {
										"ColumnLabelValues": columns,
										"RowLabelValues": rowValue,
										//"ColumnDataType": columnstd,
									}
								}
							}
							
							if(field[5] == 'slider'){
								let str = field[41].split(',');
								if(str.length > 1){
									options = {
										"LabelLeft": str[0],
										"LabelRight": str[1],
									}
								}
							}
							
							SharedformID = 'LabelValues';
							
							
							let fieldData = {
								"Field Name": field[0],
								"Parent #": field[2],
								"Data Agg": field[3],
								"Visible Text": field[4],
								"Field Type": field[5],
								"Options": field[6],
								"NewOptions": options.length > 0 ? options : '',
								"optionValue": optionValue.length > 0 ? optionValue : '',
								"GridLabelValue": gridLabel,
								//"Conditional Field Name": field[7],
								"Conditional Field Name": preId,
								"Conditional Option": field[8],
								
								"Output Text": field[9],
								"Position #": field[10],
								"Parent GUID": parentGuid,
								"Notes": notes,
								"Tags": field[13],
								"Include": field[14] == 'Y' ? true:false,
								"Required": field[15] == 'Y' ? true:false,
								
								"Position-Configuration": configStr,
								//"Position-Configuration": field[16],
								//"Custom Question-title-header GUID": field[35],
								"Delegate Types": "["+kioskTypes+"]",
								"Delegate Mode": field[37] == 'Y' ? true:false,
								"language": field[38],
								//"TAG-ID": field[39],
								"Button Label": field[40],
								[SharedformID]: field[41],
								"Value": field[48],
								"Unit": field[49],
								
								"data low limit": field[50],
								"data high limit": field[51],
								"Date Last Edited": moment(lastDate).format('YYYY-MM-DD HH:mm:ss'),
								"Image Location reference info": field[99],
								"Web Site Link": field[78],
								//"Public Field Bank": field[111] == 'Y' ? true:false,
								//"PFB=No Description": field[112],
								"Calculation Type": field[113],
								
								"Calculation Fields": field[114],
								"Company Name": field[115],
								"Custom Field Note": customNotes,
								"Field GUID": field[117],
								"Master Data Set GUID": field[129],
								//"Parent Form GUID": field[129],
								"Master Data Set Field Name": field[130],
								//"Parent Field GUID": field[131],
								"Selected Delegates": field[140],
								"Grid Columns": field[136],
								"Grid Rows": field[137],
								"RequiredFields": requiredFields,
								"OptionalFields": optionalFields,
								"OG Auto Tags": field[144],
								"Internal Data Persistence Level": field[110],
								"Tracking Code": field[125] ? field[125].split(',') : '',
								"Auto Tags": field[106] ? field[106] : '',
								"External Data Key": field[42] ? field[42] : '',
								"Internal Data Key": field[39] ? field[39] : '',
								"responseAction": field[44] ? field[44] : '',
							}
							
							const filteredObj = (obj) =>
							  Object.entries(obj)
								.filter(([_, value]) => !!value || typeof value === "boolean")
								.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
							
							fieldData = filteredObj(fieldData);
							fields.push(fieldData);
							
						}
						
						fields = fields.map(item=>{
						  return Object
							.entries(item)
							.reduce((acc,[key,value])=>{
							  return {[key.replace(/\s/g, '')]:value, ...acc};
							}, {});
						});
					})
					
					let maximumDate = new Date(Math.max.apply(null, dates));
					let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
					let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
					
					parentMds = parentMds.filter(function(x) {
						 return x !== undefined;
					});
					let parentMdsGuid = this.uniqueArray2(parentMds);
					let itemString = parentMdsGuid.toString();
					
					let setGuid = 'Master Data Set GUID';
					let facilityID = 'MDS_Facility_ID';
					
					let layout ={
						"CreationDate": moment(currentDate).format('YYYY-MM-DD HH:mm:ss'),
						"ModifiedDate": moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
						"Form Name": sheetName,
						"Friendly Name": friendly_name,
						[setGuid]: mdsGuid,
						"Included MDS GUIDs": itemString,
						"Associated Type": associatedType,
						"Asociated Forms": associatedForm,
						"Associated Device Types": associatedDevice,
						"Associated Visit Types": associatedVisit,
						"Type Master List": Types.replaceAll('\n',''),
						"Device Type Master List": DeviceType.replaceAll('\n',''),
						"Visit Type Master List": VisitType.replaceAll('\n',''),
						"Industry": Industry,
						"Profession": Profession,
						"Subspecialty": Subspecialty,
						[facilityID]: facilityId,
						"Required Groups": requiredGroups ? requiredGroups.split(',') : '',
						"Filtering Groups": groupId ? groupId.split(',') : '',
						"All Delegate Types": delegateTypes,
						"Version": version,
						'Fields': fields,
					}
					
					layout = this.fixKeys(layout);
					
					this.insertJsonMongo('mds',JSON.stringify(layout));
				}
			},function(reason) {
				alert(reason.result.error.message);
			}
		);
	}
	
	uploadMdsJsonMongoDb =(spreadsheetId,sheetName)=> {					
		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:sheetName, 
		})
		.then(
			response => {
				const allData = response.result.values;
				let currentDate = new Date().toLocaleString("en-US").replace(',','');
				let parentMds = [];
				if(allData.length > 0){
					let mdsGuid = allData[1][91];
					let version = allData[1][59] ? allData[1][59] : '1.01';
					
					let dates = [];
					let fields = [];
					allData.map((field,i)=>{
						if(i > 0){
							
							let date = new Date(field[98]);
							if(date != 'Invalid Date'){
								dates.push(date);
							}
							
							let notes = field[12] = field[12].replaceAll('\n','');
							notes = notes.replaceAll('<p></p>','');
							let customNotes = field[116] = field[116].replaceAll('\n','');
							customNotes = customNotes.replaceAll('<p></p>','');
							
							let preId = '';
							let parentGuid = '';
							allData.map((pre_field,i)=>{
								if(field[7] && field[7] == pre_field[0]){
									preId = pre_field[117];
								}
								
								if(pre_field[1] == field[2]){
									parentGuid = pre_field[117];
								}
							})

							let oldP = field[16];
							let userArray = oldP.split(',');
							userArray.splice(0, 9);
							
							let uArray = userArray.filter(function(v){return v!==''});
							//let kioskTypes = uArray.toString();
							//kioskTypes = kioskTypes.replaceAll(',','|');
							
							let kioskT = [];
							uArray.map((val,i)=>{
								let fieldk = 'delegate'+parseInt(i+1);
								let fd = {
									[fieldk]: val.trim(),
								}
								kioskT.push(fd);
							});

							/* let strArray = oldP.split(',');
							strArray.splice(9, 15); */

							/* let configStr = strArray.toString();
							configStr = configStr+",["+kioskTypes+"]"; */
							
							field[91] = '';
							field[129] = '';
							field[130] = '';
							field[131] = '';
							
							let rqFields = [];
							let requiredFields = '';
							if(field[142] && field[5] == 'launchpad'){
								let rfStr = field[142].split(',');
								rfStr.map((rfield,i)=>{
									rfield = rfield.split('|');
									rqFields.push(rfield[0]+','+rfield[1]);
								});
								requiredFields = rqFields.join('|');
								
							}
							
							let opFields = [];
							let optionalFields = '';
							if(field[143] && field[5] == 'launchpad'){
								let opStr = field[143].split(',');
								opStr.map((ofield,i)=>{
									ofield = ofield.split('|');
									opFields.push(ofield[0]+','+ofield[1]);
								});
								optionalFields = opFields.join('|');
								
							}
							let createdDate = new Date(field[97]).toLocaleString("en-US").replace(',','');
							let lastDate = new Date(field[98]).toLocaleString("en-US").replace(',','');
							
							let options = [];
							if(field[6]){
								options = field[6].split(',');
							}
							/* let dateValidation = [];
							if(field[48] == 'V9' || field[48] == 'V10' || field[48] == 'V11'){
								dateValidation = ["Any", "Past", "Today", "Future"];
							} */
							let dateValidation = '';
							if(field[48] == 'V9'){
								dateValidation = 'past'
							}else if(field[48] == 'V10'){
								dateValidation = 'today'
							}else if(field[48] == 'V11'){
								dateValidation = 'future'
							}
							
							let gridLabel = [];
							if(field[5] == 'basicgrid' || field[5] == 'grid5point' || field[5] == 'grid-multicheck' || field[5] == 'grid-singlecheck'){
								let str = field[41] ? field[41].split('_') : [];
								
								if(str.length > 0){
									
									let columns = str[0].split(',');
									let clabel = [];
									columns.map((val,i)=>{
										let fieldN = 'columnlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										clabel.push(fd);
									});
									
									let rows = str[1].split(',');
									let crow = [];
									rows.map((val,i)=>{
										let fieldN = 'rowlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										crow.push(fd);
									});
									gridLabel = {
										"ColumnLabelValues": clabel,
										"RowLabelValues": crow,
									}
								}
							}
							
							if(field[5] == 'basicgrid2'){
								let str = field[41] ? field[41].split('_') : [];
								
								if(str.length > 0){
									let columns = str[0].split('|');
									let rows = str[1].split(',');
									let crow = [];
									rows.map((val,i)=>{
										let fieldN = 'rowlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										crow.push(fd);
									});
									
									let columnslable = [];
									let columnstd = [];
									columns.map((val,i)=>{
										let cm = val.split(':');
										let fieldN = 'columnlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: cm[0].trim(),
										}
										
										columnslable.push(fd);
										columnstd.push(cm[1]);
									});
									
									let columnstd2 = [];
									columnstd.map((val,i)=>{
										let fieldm = 'column'+parseInt(i+1);
										let fds = {
											[fieldm]: val.trim(),
										}
										
										columnstd2.push(fds);
									});
									
									gridLabel = {
										"ColumnLabelValues": columnslable,
										"RowLabelValues": crow,
										"ColumnDataType": columnstd2,
									}
								}
							}
							let optionValue = [];
							if(field[5] == 'weighted'){
								let str = field[41] ? field[41].split(',') : [];
								str.map((val,i)=>{
									let op = val.split(':');
									options.push(op[0]);
									optionValue.push(op[1]);
								});
							}
							
							if(field[5] == 'grid-dropdown'){
								let str = field[41] ? field[41].split('_') : [];
								
								if(str.length > 0){
									let columns = str[0].split(',');
									let clabel = [];
									columns.map((val,i)=>{
										let fieldN = 'columnlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										clabel.push(fd);
									});
									let rows = str[1].split('|');
									
									//let columnslable = [];
									let rowValue = [];
									let coption = [];
									rows.map((val,i)=>{
										let cm = val.split(':');
										//columnslable.push(cm[0]);
										//rowValue.push(cm[0]);
										let fieldN = 'rowlabel'+parseInt(i+1);
										let fd = {
											[fieldN]: cm[0].trim(),
										}
										rowValue.push(fd);
										
										cm.map((val,j)=>{
											if(j > 0){
												let fieldN = 'row'+parseInt(i+1)+'_columnoption'+parseInt(j);
												let fd = {
													[fieldN]: val.split(','),
												}
												coption.push(fd);
											}
											
										});
										
									});
									
									gridLabel = {
										"ColumnLabelValues": clabel,
										"RowLabelValues": rowValue,
										"ColumnOption": coption,
										//"ColumnDataType": columnstd,
									}
								}
							}
							
							let ctype = field[114];
							let ctypeVal = [];
							let calculationType = [];
							if(field[5] == 'calculation'){
								if(ctype.indexOf(',') > -1) {
									let ctypearr = ctype.split(',');
									ctypearr.map((val,i)=>{
										let fieldN = 'field'+parseInt(i+1);
										let fd = {
											[fieldN]: val.trim(),
										}
										ctypeVal.push(fd);
									});
								}
								
								calculationType = ["Sum", "Average", "Subtract", "Highest Number", "Lowest Number", "Longest Time", "Shortest Time"]
							}
							
							let opVal = [];
							if(options.length > 0){
								options.map((val,i)=>{
									let fieldN = 'option'+parseInt(i+1);
									let fd = {
										[fieldN]: val.trim(),
									}
									opVal.push(fd);
								});
							}
							
							let TrackingCode = {'trackingcodelabel1':field[125]};
							
							if(field[125].indexOf(',') > -1) {
								TrackingCode = [];
								let arr = field[125].split(',');
								arr.map((val,i)=>{
									let fieldN = 'trackingcodelabel'+parseInt(i+1);
									let fd = {
										[fieldN]: val.trim(),
									}
									TrackingCode.push(fd);
								});
							}
							
							let FieldTypeOptions = {
								"Options": opVal.length > 0 ? opVal : '',
								//"OptionsValue": optionValue.length > 0 ? optionValue : '',
								"GridColumns": field[136],
								"GridRows": field[137],
								"GridLabelValue": Object.keys(gridLabel).length !== 0 ? gridLabel : '',
								"CalculationType": field[113],
								//"CalculationType": calculationType.length > 0 ? calculationType : '',
								"CalculationFields": ctypeVal.length > 0 ? ctypeVal : '',
								"WebsiteLink": field[78],
								//"TrackingCode": field[125] ? field[125].split(',') : '',
								"TrackingCode": TrackingCode.length > 0 ? TrackingCode : '',
								//"DateValidation": dateValidation.length > 0 ? dateValidation : '',
								"DateValidation": dateValidation,
							}
							
							const filteredObj2 = (obj) =>
							  Object.entries(obj)
								.filter(([_, value]) => !!value || typeof value === "boolean")
								.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
							
							FieldTypeOptions = filteredObj2(FieldTypeOptions);
							
							let DataLowLimit = '';
							let DataHighLimit = '';
							if(field[5] == 'Q6'){
								DataLowLimit = {
									'number':field[50],
								}
								
								DataHighLimit = {
									'number':field[51],
								}
							}
							
							if(field[5] == 'timer'){
								DataLowLimit = {
									'time':field[50],
								}
								
								DataHighLimit = {
									'time':field[51],
								}
							}
							
							let fieldData = {
								"FieldGUID": field[117],
								"MasterDataSetGUID": mdsGuid,
								"FieldName": field[0],
								"FieldNameIdentifier_STATIC": field[61],
								"VisibleText": field[4],
								"FieldType": field[5],
								"FieldTypeOptions": Object.keys(FieldTypeOptions).length !== 0 ? FieldTypeOptions : '',
								"PrefilteredDelegates": kioskT.length > 0 ? kioskT : '',
								"ButtonLabel": field[40],
								"ImageLocationReferenceInfo": field[99],
								"OutputText": field[9],
								"Notes": notes,
								"InternalDataPersistenceLabel": field[110],
								"Tags": field[13],
								"DataAgg": field[3] == 'Y' ? true:false,
								"AutoTags": field[106] ? field[106] : '',
								"ValidationType": field[48],
								"DataLowLimit": field[5] != 'Q8' ? DataLowLimit : '',
								"DataHighLimit":field[5] != 'Q8' ? DataHighLimit : '',
								"CreatedDate": field[97] ? moment(createdDate).format('YYYY-MM-DD HH:mm:ss') : moment(currentDate).format('YYYY-MM-DD HH:mm:ss'),
								"ModifiedDate": moment(lastDate).format('YYYY-MM-DD HH:mm:ss'),
								"IsActive": field[60] == 'Y' || field[60] == '' ? true:false,
								"Translations": false,
							}
							
							const filteredObj = (obj) =>
							  Object.entries(obj)
								.filter(([_, value]) => !!value || typeof value === "boolean")
								.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
							
							fieldData = filteredObj(fieldData);
							fields.push(fieldData);
							
						}
					})

					let maximumDate = new Date(Math.max.apply(null, dates));
					let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
					//let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
					
					parentMds = parentMds.filter(function(x) {
						 return x !== undefined;
					});
					let parentMdsGuid = this.uniqueArray2(parentMds);
					let itemString = parentMdsGuid.toString();
					
					let layout ={
						"MDSGuid": mdsGuid,
						"MDSName": sheetName,
						"CreatedDate": moment(currentDate).format('YYYY-MM-DD HH:mm:ss'),
						"ModifiedDate": moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
						'Version': version,
						'Fields': fields,
					}
					
					//console.log('layout->',layout);
					
					this.insertMdsJsonMongo(JSON.stringify(layout));
				}

			},function(reason) {
				alert(reason.result.error.message);
			} 
		);
	}
	
	/* uploadLayoutJsonMongoDb =(sheetName)=> {
		
		let spreadsheetId = this.state.childSpreadsheetId;
		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:sheetName, 
		})
		.then(
			response => {
				const allData = response.result.values;
				let currentDate = new Date().toLocaleString("en-US").replace(',','');
				//console.log('allData->',allData);
				let parentMds = [];
				if(allData.length > 0){
					let layoutGuid = allData[1][91];
					let associatedType = allData[1][92];
					let associatedForm = allData[1][93];
					let associatedDevice = allData[1][94];
					let associatedVisit = allData[1][95];
					
					
					let version = allData[1][59] ? allData[1][59] : '1.01';
					let friendly_name = allData[1][62] ? allData[1][62] : '';
					
					let dates = [];
					let fields = [];
					allData.map((field,i)=>{
						if(i > 0){
							let date = new Date(field[98]);
							if(date != 'Invalid Date'){
								dates.push(date);
							}
							
							parentMds.push(field[129]);
							
							
							let preId = '';
							let parentGuid = '';
							allData.map((pre_field,i)=>{
								if(field[7] && field[7] == pre_field[0]){
									preId = pre_field[117];
								}
								
								if(pre_field[1] == field[2]){
									parentGuid = pre_field[117];
								}
							})

							let oldP = field[16];
							let userArray = oldP.split(',');
							userArray.splice(0, 9);
							
							let uArray = userArray.filter(function(v){return v!==''});
							let kioskTypes = uArray.toString();
							kioskTypes = kioskTypes.replaceAll(',','|');

							
							let fieldData = {
								"FieldGUID": field[131] ? field[131] : field[117],
								"Position#": field[10],
								"Include": field[14] == 'Y' ? true:false,
								"Required": field[15] == 'Y' ? true:false,
								"DelegateTypes": "["+kioskTypes+"]",
								"ParentGUID": parentGuid,
							}
							
							const filteredObj = (obj) =>
							  Object.entries(obj)
								.filter(([_, value]) => !!value || typeof value === "boolean")
								.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
							
							fieldData = filteredObj(fieldData);
							fields.push(fieldData);
							
						}
					})

					let maximumDate = new Date(Math.max.apply(null, dates));
					let newDate = new Date(maximumDate).toLocaleString("en-US").replace(',','');
					//let lastUpDate = new Date(maximumDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
					
					parentMds = parentMds.filter(function(x) {
						 return x !== undefined;
					});
					let parentMdsGuid = this.uniqueArray2(parentMds);
					let itemString = parentMdsGuid.toString();
					
					let layout ={
						"DefaultLayoutName": sheetName,
						"DefaultLayoutGuid": layoutGuid,
						"FriendlyName": friendly_name,
						"IncludedMDSGUIDs": itemString,
						"AssociatedType": associatedType,
						"AssociatedDeviceTypes": associatedDevice,
						"AssociatedVisitTypes": associatedVisit,
						"AssociatedTrack": [],
						"Version": version,
						"CreatedDate": moment(currentDate).format('YYYY-MM-DD HH:mm:ss'),
						"ModifiedDate": moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
						"Fields": fields,
					}
					
					this.insertLayoutJsonMongo(JSON.stringify(layout));
				}

			},function(reason) {
				alert(reason.result.error.message);
			}
		);
	} */
	
	insertMdsJsonMongo(data){
		let mongoApiUrl = $('#mongoApiUrl').val();
		let url = mongoApiUrl+'insert-mds-v2';
		axios({
			method: 'POST',
			url: url,
			data: data,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			console.log('response::', response.data);
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	insertLayoutJsonMongo(data){
		let mongoApiUrl = $('#mongoApiUrl').val();
		let url = mongoApiUrl+'insert-layout-v2';
		axios({
			method: 'POST',
			url: url,
			data: data,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			console.log('response::', response.data);
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
/* 	uploadMdsInMongo=()=>{
		let mdsSheets = this.state.mdsSheetNames;
		
		//console.log('mdsSheets->',mdsSheets);
		
		mdsSheets.map((sheet,i)=>{
			this.uploadMdsJsonMongoDb(sheet.name);
		})
		
		let that = this;
		setTimeout(function(){
			that.getMdsSheets();
			that.getMdsJsonFileVersion();
		}, 4000);
	} */
	
	/* uploadLayoutInMongo=()=>{
		let childSheetNames = this.state.childSheetNames;
		
		//console.log('childSheetNames->',childSheetNames);
		childSheetNames.map((sheet,i)=>{
			this.uploadLayoutJsonMongoDb(sheet.name);
		})
		
		let that = this;
		setTimeout(function(){
			that.getMdsSheets();
			that.getLayoutJsonFileVersion();
		}, 4000);
	} */
	
	selectFormVersion=(event)=>{
		let value = event.target.value;
		
		if(value == 'form-1'){
			$('.frm2').css('display', 'none');
			$('.frm1').css('display', 'inline-block');
		}else if(value == 'form-2'){
			$('.frm1').css('display', 'none');
			$('.frm2').css('display', 'inline-block');
		}
	}
	
	getTrackMongo=()=>{
		let trackName = this.state.trackName;
		let mongoApiUrl = $('#mongoApiUrl').val();
		let url = mongoApiUrl+'get-track';
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			console.log('assignTracks::', response.data);
			this.setState({assignTracks: response.data});
		}).catch(error => {
			console.log('error::', error);
		})
		
	}
	
	addTrackMongo=()=>{
		let trackName = this.state.trackName;
		let mongoApiUrl = $('#mongoApiUrl').val();
		let url = mongoApiUrl+'add-track';
		let data = {
			"trackName": trackName,
		}
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			//console.log('response::', response.data);
			$('.add-track-n').val('');
			this.getTrackMongo();
		}).catch(error => {
			console.log('error::', error);
		})
		
	}
	
	deleteTrack=(track)=>{
		let trackName = this.state.trackName;
		let mongoApiUrl = $('#mongoApiUrl').val();
		let url = mongoApiUrl+'delete-track';
		let data = {
			"trackName": track,
		}
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			//console.log('response::', response.data);
			this.getTrackMongo();
		}).catch(error => {
			console.log('error::', error);
		})
		
	}
	
	render() {
		
		const {allData, copyAllData, copySheetData, showBuilder, mdsSheets, mdsSheetNames, childSheetNames, layoutSheetNames, delegateTypes, dataMode, loadDataSet, showView,kioskResponse, savedResponse, savedResponseForm, selectedRForm, facilityIds, showTrackerTable,practitioners, reasonNotes, mdsHasFieldSet, showMdsList,checkedCopyFields,mdsSpreadsheets,mdsJsonFilesVersion,layoutJsonFilesVersion,layoutTranslation,showMongoSection,showMongoMdsSection,showTranslationManager,showMdsManageSection,assignTracks} = this.state;
		let trackList = '';
		let that = this;
		if(assignTracks){
			trackList = assignTracks.map(function(val,i) {
				return (
					<li><div className="list-li-name"><p className="mb-0">{val.trackName}</p><button type="button" style={{color:'red'}} className="btn code-dialog btn-delete track-delete" onClick={() => {if (window.confirm('Are you sure you want to Delete this Track Name?')) that.deleteTrack(val.trackName)}}><i className="fa fa-trash"></i></button></div> </li>
				);
			})
		}
	
		let role = localStorage.getItem('role');
		let href = window.location.href.split('?')[0];
		const DragHandle = sortableHandle(() => <span className="showcase"></span>);
		let copyClass = '';
		if(dataMode == 'mdsFieldCopy'){
			copyClass = 'toggle-btn-sm';
		}
		let totalFieldCountM = 0;
		let totalFieldCountC = 0;
		let optionsHtml = delegateTypes.map(function(val,i) {
			let delegateName = val.split(':');
			return (
				<option value={delegateName[1]} key={i}>{delegateName[0]}</option>
			);
			
		})
		
		let optionInstance = '';
		
		if(this.state.instances){
			let ins = this.state.instances;
			let instanceName = this.state.instanceName;
			
			optionInstance = ins.map(function(val,i) {
				//if(instanceName != val.instance_name){
					return (
						<option value={val.id} key={i}>{val.instance_name}</option>
					);
				//}
			})
		}
			
		let completedForms = layoutSheetNames.map(function(val,i) {
			
			if(savedResponseForm.indexOf(val.name) > -1){
				return (
					<option data-type="" key={i} value={val.name}>{val.name}</option>
				);
			}
		})
		
		let facilityOption = facilityIds.map(function(val,i) {
			let facility = val.split('|');
			return (
				<option value={facility[1]} key={i}>{facility[0]}</option>
			);
		})
		
		let practitionerOption = practitioners.map(function(val,i) {
			let optn = val.split('|');
			return (
				<option data-email={optn[1]} value={optn[0]} key={i}>{optn[0]}</option>
			);
		})
		
		let fieldButtons = props => {
			if(props){
				let include = false;
				let require = false;
				let kiosk = false;
				
				let deleStyle = {
					display: "none"
				};
				
				if(this.state.checkedInc.get(props[0]) == 'Y'){
					include = true;
				}
				
				if(this.state.checkedReq.get(props[0]) == 'Y'){
					require = true;
				}
				
				if(this.state.checkedKiosk.get(props[0]) == 'Y'){
					kiosk = true;
				}
				
				if(kiosk){
					deleStyle = {
						display: "inline-block"
					};
				}
				
				return(<div>
				<input className="btn code-dialog inc-btn" name={props[0]} type="checkbox" checked={include} onChange={this.handleInclude}/>
				<input className="btn code-dialog" name={props[0]} type="checkbox" checked={require} onChange={this.handleRequire}/>
				<input className="btn code-dialog" name={props[0]} type="checkbox" checked={kiosk} onChange={this.handleKiosk} />
				<button style={deleStyle} id={'btn'+props[0]} type="button" onClick={()=>this.showAddUsersModal(props[0])} className="btn code-dialog btn-edit add-delegate"><i className="fa fa-users"></i></button>
				</div>);
			}
		}
		
		const SortableItem = sortableElement(({value}) => {
			
			let placeholder = this.getPlaceholder(value[5]);
			let include = false;
			let require = false;
			let kiosk = false;
			let pos = false;
			
			if(this.state.checkedInc.get(value[0]) == 'Y'){
				include = true;
			}
			
			if(this.state.checkedReq.get(value[0]) == 'Y'){
				require = true;
			}
			
			if(this.state.checkedKiosk.get(value[0]) == 'Y'){
				kiosk = true;
			}
			
			if(this.state.fieldPos && this.state[value[0]]){
				pos = true;
			}
			
			let deleStyle = {
			    display: "none"
			};
			
			if(kiosk){
				deleStyle = {
					display: "inline-block"
				};
			}
			
			let qTitle = value[4] ? value[4].substr(0,22) : placeholder;
			let fsqTitle = value[4] ? value[4].substr(0,32) : placeholder;
			
			if(this.state.outputText){
				qTitle = value[9] ? value[9].substr(0,22) : value[4] ? value[4].substr(0,22) : placeholder;
				fsqTitle = value[9] ? value[9].substr(0,32) : value[4] ? value[4].substr(0,32) : placeholder;
			}
			
			if(dataMode == 'MDS'){
				return (<div id={value[0]} className={'field-div '+this.classTypes(value)}><DragHandle /><span className="input-title">{qTitle}</span>
				<div className="edit-btns">
				
				<button type="button" className="btn code-dialog btn-copy" onClick={()=>this.showCopyModal(value)}><i className="fa fa-copy"></i></button>
				
				<button id={'fId-'+value[0]} type="button" className="btn code-dialog btn-edit" onClick={()=>this.editField(value[0],value[5],value)}><i className="fa fa-edit"></i></button>
				<button type="button" style={{color:'red'}} className="btn code-dialog btn-delete " onClick={() => {if (window.confirm('Are you sure you want to Delete this field?')) this.deleteField(value[0])}}><i className="fa fa-trash"></i></button>
				</div>
				<div className="edit-btns1">
					<input className="btn code-dialog" name={value[0]} type="checkbox" checked={include} onChange={this.handleInclude}/>
					<input className="btn code-dialog" name={value[0]} type="checkbox" checked={require} onChange={this.handleRequire}/>
					<input className="btn code-dialog" name={value[0]} type="checkbox" checked={kiosk} onChange={this.handleKiosk} />
					<button style={deleStyle} id={'btn'+value[0]} type="button" onClick={()=>this.showAddUsersModal(value[0])} className="btn code-dialog btn-edit add-delegate"><i className="fa fa-users"></i></button>
					<input className="btn code-dialog add-new-pos select-pos" name={value[0]} value={value[10]} type="checkbox" checked={pos} onChange={this.handleAddNewFiled} />
					<button id={'btn-eye'+value[0]} type="button" onClick={()=>this.showPreviewModal(value)} className="btn code-dialog btn-eye"><i className="fa fa-eye"></i></button>
				</div>
				</div>);
			}else if(dataMode == 'Layout'){
				return (<div id={value[0]} className={'field-div '+this.classTypes(value)}><DragHandle /><span className="fs-input-title">{qTitle}</span>
				<div className="edit-btns">
					<input className="btn code-dialog inc-btn" name={value[0]} type="checkbox" checked={include} onChange={this.handleInclude}/>
					<input className="btn code-dialog" name={value[0]} type="checkbox" checked={require} onChange={this.handleRequire}/>
					<input className="btn code-dialog" name={value[0]} type="checkbox" checked={kiosk} onChange={this.handleKiosk} />
					<button style={deleStyle} id={'btn'+value[0]} type="button" onClick ={()=>this.showAddUsersModal(value[0])} className="btn code-dialog btn-edit add-delegate"><i className="fa fa-users"></i></button>
					<button id={'btn-eye'+value[0]} type="button" onClick={()=>this.showPreviewModal(value)} className="btn code-dialog btn-eye"><i className="fa fa-eye"></i></button>
				</div>
				</div>);
			}else if(dataMode == 'TestForm'){
				return (<div id={value[0]} className={'field-div '+this.classTypes(value)}><span className="fs-input-title">{fsqTitle}</span>
				<div className="edit-btns">
				<button id={'btn-eye'+value[0]} type="button" onClick={()=>this.showPreviewModal(value)} className="btn code-dialog btn-eye-fset"><i className="fa fa-eye"></i></button>
				</div>
				</div>);
			}else{
				return (<div id={value[0]} className={'field-div '+this.classTypes(value)}><DragHandle /><span className="fs-input-title">{fsqTitle}</span>
				<div className="edit-btns1">
					<input className="btn code-dialog" name={value[0]} type="checkbox" checked={include} onChange={this.handleInclude}/>
					<input className="btn code-dialog" name={value[0]} type="checkbox" checked={require} onChange={this.handleRequire}/>
					<input className="btn code-dialog" name={value[0]} type="checkbox" checked={kiosk} onChange={this.handleKiosk} />
					<button style={deleStyle} id={'btn'+value[0]} type="button" onClick ={()=>this.showAddUsersModal(value[0])} className="btn code-dialog btn-edit add-delegate"><i className="fa fa-users"></i></button>
					<input className="btn code-dialog add-new-pos select-pos" name={value[0]} value={value[10]} type="checkbox" checked={pos} onChange={this.handleAddNewFiled} />
					<button id={'btn-eye'+value[0]} type="button" onClick={()=>this.showPreviewModal(value)} className="btn code-dialog"><i className="fa fa-eye"></i></button>
					<button type="button" style={{color:'red'}} className="btn code-dialog btn-delete" onClick={() => { if (window.confirm('Are you sure you want to Delete this field?')) this.deleteField(value[0])}}><i className="fa fa-trash"></i></button>
					
				</div>
				
				</div>);
			}
		});
		
		const SortableItemCopy = sortableElement(({value}) => {
			
			let placeholder = this.getPlaceholder(value[5]);
			let include = false;
			let require = false;
			let kiosk = false;
			let pos = false;
			
			if(this.state.checkedInc.get(value[0]) == 'Y'){
				include = true;
			}
			
			if(this.state.checkedReq.get(value[0]) == 'Y'){
				require = true;
			}
			
			if(this.state.checkedKiosk.get(value[0]) == 'Y'){
				kiosk = true;
			}
			
			if(this.state.fieldPos && this.state[value[0]]){
				pos = true;
			}
			
			let deleStyle = {
			    display: "none"
			};
			
			if(kiosk){
				deleStyle = {
					display: "inline-block"
				};
			}
			
			let qTitle = value[4] ? value[4].substr(0,22) : placeholder;
			let fsqTitle = value[4] ? value[4].substr(0,32) : placeholder;
			
			if(this.state.outputText){
				qTitle = value[9] ? value[9].substr(0,22) : value[4] ? value[4].substr(0,22) : placeholder;
				fsqTitle = value[9] ? value[9].substr(0,32) : value[4] ? value[4].substr(0,32) : placeholder;
			}
			
			return (<div id={value[0]} className={'field-div '+this.classTypes(value)}><DragHandle /><span className="input-title">{qTitle}</span>
			<div className="edit-btns">
			{value[5] == 'Parent Header' || value[5] == 'Child Header 1' || value[5] == 'Child Header 2' || value[5] == 'Child Header 3' || value[5] == 'Title' || value[5] == 'Text' ? 
				<button id={'fId-'+value[0]} type="button" className="btn code-dialog btn-edit" onClick={()=>this.editHeadersField(value[0],value[5],value)}><i className="fa fa-edit"></i></button>
			:null}
				<input className="btn code-dialog add-new-pos select-pos" name={value[0]} value={value[10]} type="checkbox" checked={pos} onChange={this.handleCopyAddNewFiled} />
				<button type="button" style={{color:'red'}} className="btn code-dialog btn-delete" onClick={() => {if (window.confirm('Are you sure you want to Delete this field?')) this.copyDeleteField(value[0])}} ><i className="fa fa-trash"></i></button>
			</div>
			</div>);
		});
		
		const SortableSheetItemMds = sortableElement(({value}) => {
			let sheetVisibility = this.state.sheetVisibility;
			let visibility = sheetVisibility.get(value.name);
			let sheetVersion = this.state.sheetVersion;
			let version = sheetVersion.get(value.name);
			let sheetApiName = this.state.sheetApiName;
			let apiName = sheetApiName.get(value.name);
			let sheetGuids = this.state.sheetGuids;
			let sheetGuid = sheetGuids.get(value.name);
			
			let mdsJsonFilesVersion = this.state.mdsJsonFilesVersion;
			let mongoVersion = mdsJsonFilesVersion.get(sheetGuid);
			let classn = 'not-in-mongo';
			if(mongoVersion && (mongoVersion == version || version == '')){
				classn = 'has-in-mongo';
			}
			
			let totalRows = '';
			let lastUpdate = '';
			if(apiName){
				apiName = apiName.split('_');
				lastUpdate = apiName[2];
				totalRows = apiName[3];
				//totalFieldCountM = parseInt(totalFieldCountM) + parseInt(totalRows);
			}
			let rnote = '';
			let rnoteDate = '';
			let reasonNote = reasonNotes.get(value.name);
			let hasFieldSet = mdsHasFieldSet.get(value.name);
			
			return (<div id={value.name} className={'mdsfield-div '}><DragHandle />
			<div className="sheet-name-i">{value.name} </div>
			<div className="sheet-row-count">{totalRows} </div>
			<div className="sheet-last-update">{lastUpdate} </div>
			<div className="mds-download-icon">
				<i className="frm1 mds-action-btn fa fa-download mds-json" aria-hidden="true" onClick={() => { this.downloadMsdJson(value.name,'download','mds') } }></i>
				<i className="frm2 mds-action-btn fa fa-download mds-json" aria-hidden="true" onClick={() => { this.downloadMsdJsonV2(value.name,'download','mds') } }></i>
				<i className="frm1 mds-action-btn fa fa-file-archive-o mds-json" aria-hidden="true" onClick={() => { this.downloadZip(value.name,'mds') } }></i>
				<i className="frm2 mds-action-btn fa fa-file-archive-o mds-json" aria-hidden="true" onClick={() => { this.newDownloadZip(value.name,'mds') } }></i>
			</div>
			
			</div>);
			
		});
		
		const SortableSheetItemChild = sortableElement(({value}) => {
			
			let sheetVisibility = this.state.sheetVisibility;
			let visibility = sheetVisibility.get(value.name);
			let sheetApiName = this.state.sheetApiName;
			let apiName = sheetApiName.get(value.name);
			let totalRows = '';
			let lastUpdate = '';
			if(apiName){
				apiName = apiName.split('_');
				lastUpdate = apiName[2];
				totalRows = apiName[3];
				//totalFieldCountM = parseInt(totalFieldCountM) + parseInt(totalRows);
			}
			let rnote = '';
			let rnoteDate = '';
			let reasonNote = reasonNotes.get(value.name);
			let hasFieldSet = mdsHasFieldSet.get(value.name);
			
			let sheetVersion = this.state.sheetVersion;
			let version = sheetVersion.get(value.name);

			let sheetGuids = this.state.sheetGuids;
			let sheetGuid = sheetGuids.get(value.name);
			
			let layoutJsonFilesVersion = this.state.layoutJsonFilesVersion;
			let mongoVersion = layoutJsonFilesVersion.get(sheetGuid);
			let classn = 'not-in-mongo';
			if(mongoVersion && (mongoVersion == version || version == '')){
				classn = 'has-in-mongo';
			}
			
			let layoutTranslation = this.state.layoutTranslation;
			let translationOption = layoutTranslation.get(sheetGuid);
			
			let optionsHtml = '';
			if(translationOption){
				optionsHtml = translationOption.map(function(val,i) {
					return (
						<option value={val['LanguageCode']} key={i}>{val['Language']}</option>
					);
					
				})
			}
			
			let selectedLanguage = this.state.selectedLanguage;
			let language = 'eng';
			if(selectedLanguage){
				let str = selectedLanguage.split('_');
				if(str.length > 1){
					language = str[0] == sheetGuid ? str[1]: 'eng'
				}
			}
			
			return (<div id={value.name} className={'childfield-div '}><DragHandle />
			<div className="sheet-name-child-i">{value.name} </div>
			
			<div className="sheet-row-count">{totalRows} </div>
			<div className="sheet-last-update">{lastUpdate} </div>
			
			<div className="mds-download-icon">
				<div className="sheet-language frm1">
				{ classn == 'has-in-mongo' && translationOption ?
					<select className="select-lan has-language" name={sheetGuid} value={language} onChange={this.getLayoutLauguageJson}>
						<option value="eng">English</option>
						{optionsHtml}
					</select>
				: 	<select className="select-lan nor-language" disabled>
						<option value="eng">English</option>
					</select> 
				}
				</div>
				<i className="frm1 mds-action-btn fa fa-download mds-json" aria-hidden="true" onClick={() => { this.downloadMsdJson(value.name,'download','child') } }></i>
				<i className="frm2 mds-action-btn fa fa-download mds-json" aria-hidden="true" onClick={() => { this.defaultLayoutJsonV2(value.name,'download','child') } }></i>
				
				{language != '' && language != 'eng' ?
					<i className="frm1 mds-action-btn fa fa-file-archive-o mds-json" aria-hidden="true" onClick={() => { this.downloadZipWithLanguage(value.name,'child') } }></i>
				:
					<i className="frm1 mds-action-btn fa fa-file-archive-o mds-json" aria-hidden="true" onClick={() => { this.downloadZip(value.name,'child') } }></i>
				}

				<i className="frm2 mds-action-btn fa fa-file-archive-o mds-json" aria-hidden="true" onClick={() => { this.newDownloadZip(value.name,'child') } }></i>
				
			</div>
			</div>);
		});
		
		const SortableContainer = sortableContainer(({children}) => {
			return <div>{children}</div>;
		});
		
		let optionHtmlsheets = '';
		if(this.state.mdsSpreadSheetData){
			optionHtmlsheets = this.state.mdsSpreadSheetData.map(function(val,i) {
				return (
					<option value={val["spreadsheetId"]} key={i}>{val["title"]}</option>
				);
			})
		}
		
		let sheetVersion = this.state.sheetVersion;
		let selectedSheetversion = sheetVersion.get(this.state.selectedSheet);
		if(selectedSheetversion == '' || selectedSheetversion == undefined || selectedSheetversion == '1'){
			selectedSheetversion = '1.01'
		}
		
        return(
            <div className="full-container">
				<div className="card">
					<MainHeader builderOption = {this.builderOption} dataMode = {dataMode} />
					<div className="loaded-file-name">{this.state.selectedSheet && !this.state.showMongoSection && !this.state.showMongoMdsSection && !this.state.showTranslationManager && !this.state.instanceManager && !this.state.showMdsList && dataMode != 'mdsFieldCopy' ? this.state.selectedSheet : ''} <span className="top-version">{this.state.selectedSheet && !this.state.showMongoSection && !this.state.showMongoMdsSection && !this.state.showTranslationManager && !this.state.instanceManager && !this.state.showMdsList && dataMode != 'mdsFieldCopy' ? ' V'+selectedSheetversion : ''}</span></div>
					<div id="wrapper" className="toggled">
						<nav className="custom-navbar navbar-inverse navbar-fixed-top" id="sidebar-wrapper" role="navigation">
							<div className="nav-content">
								<h5 className="page-title">MDS Builder</h5>
								<div className="panel-btn">
									{/*<button type="button" className="btn btn-info" onClick={this.buildDetails}>Build New MDS</button>
										<button type="button" className="btn btn-primary">Load MDS</button>*/}
									{role == 'Administrator' ?
									<div className="load-mds-button">
										<label>Instance</label>
										<select id="selectMode" className="form-control" name="dataMode" value={this.state.instanceId} onChange={this.selectInstance}>
											{optionInstance}
										</select>
										
									</div>
									:null}
									<div className="load-mds-button">
										<label> Select Mode </label>
										{role == 'Administrator' || role == 'Editor' ?
										<select id="selectMode" className="form-control" name="dataMode" onChange={this.selectMode}>
											<option value="">Please Select</option>
											<option value="MDS">MDS</option>
											<option value="Child">Default Layout</option>
											<option value="Layout">Layout</option>
											<option value="TestForm">Test Form</option>
											<option value="mdsFieldCopy">MDS Field Copy</option>
										</select>
										:null}
										{role == 'Fab' ?
										<select id="selectMode" className="form-control" name="dataMode" onChange={this.selectMode}>
											<option value="">Please Select</option>
											<option value="TestForm">Test Form</option>
										</select>
										:null}
									</div>
									{(dataMode == 'MDS' || dataMode == 'mdsFieldCopy') && mdsSpreadsheets.length > 0 ?
									<div className="load-mds-button">
										<label> Select MDS File </label>
										<select className="form-control" value={this.state.mdsSpreadsheetId} name="mdsSpreadsheetId" onChange={this.loadMdsSpreadSheet}>
											<option value="">Please Select</option>
											{optionHtmlsheets}
										</select>
									</div>
									:null}
									{ dataMode == 'MDS' || dataMode == 'mdsFieldCopy' ?
									<div className="load-mds-button">
										<label> Filter MDS </label>
										<input type="text" className="form-control" name="filterMds" onChange={this.filterMdsForms}/>
									</div>
									:null}
									
									{ dataMode == 'MDS' || dataMode == 'mdsFieldCopy' ?
									<div className="load-mds-button">
										<label> {dataMode == 'mdsFieldCopy' ? 'Select "Copy from" MDS file' : 'Load MDS'}  </label>
										<select id="loadSelectMds" className="form-control load-select-mds" name="loadMds" onChange={this.loadMdsSheet}>
											<option value="">Please Select</option>
											{mdsSheetNames.map((val, i) => (
												<option key={i} value={val.name}>{val.name}</option>
											))}
										</select>
									</div>
									:null}
									
									{ this.state.copyFieldsNumber != '' && dataMode == 'mdsFieldCopy' ?
										<div className="copy-field-number">
										{this.state.copyFieldsNumber} Fields Left to Copy
										</div>
									:null}
									
									{ dataMode == 'Child' || dataMode == 'Layout' ?
									<div className="load-mds-button">
										<label> Filter Default Layout </label>
										<input type="text" className="form-control" name="filterSet" onChange={this.filterFieldSet}/>
									</div>
									:null}
									
									{ dataMode == 'Child' || dataMode == 'Layout' ?
									<div className="load-mds-button2">
										<label> Load Default Layout </label>
										<select id="loadFeildSet" className="form-control load-select-child" name="loadChild" onChange={this.loadMdsSheet}>
											<option value="">Please Select</option>
											{childSheetNames.map((val, i) => (
												<option key={i} value={val.name}>{val.name}</option>
											))}
										</select>
									</div>
									:null}
									
									{dataMode == 'Layout' ?
									<div className="load-mds-button">
										<label> Filter Layout </label>
										<input type="text" className="form-control" name="filterSet" onChange={this.filterLayout}/>
									</div>
									:null}
									
									{dataMode == 'TestForm' ?
									<div className="load-mds-button">
										<label> Layout Type</label>
										<select className="form-control load-select-child" name="layoutType" onChange={this.filterLayoutType}>
											<option value="">Please Select</option>
											<option value="Clinical">Clinical</option>
											<option value="Fabrication">Fabrication</option>
											<option value="Admin">Admin</option>
											<option value="Workflow 1">Workflow 1</option>
											<option value="Workflow 2">Workflow 2</option>
											<option value="Workflow 3">Workflow 3</option>
											<option value="Workflow 4">Workflow 4</option>
											<option value="Workflow 5">Workflow 5</option>
										</select>
									</div>
									:null}
									
									{ dataMode == 'Layout' || dataMode == 'TestForm' ?
									<div className="load-mds-button2">
										<label> Load Layout </label>
										<select id="loadLayout" className="form-control load-select-child" name="loadLayout" onChange={this.loadMdsSheet}>
											<option value="">Please Select</option>
											{layoutSheetNames.map((val, i) => (
												<option data-type="" key={i} value={val.name}>{val.name}</option>
											))}
										</select>
									</div>
									:null}
									
									{dataMode == 'TestForm' && role != 'Fab' ?
									
										<div className="load-mds-button2 completed-forms">
											<div>
											<h6> View Completed Forms </h6>
											<label> Select completed  form </label>
											<select id="loadCform" className="form-control load-select-child" name="loadCform" onChange={this.loadMdsSheet}>
												<option value="">Please Select</option>
												{completedForms}
											</select>
											</div>
											<br />
											<div>
											<label> Select completed form date </label>
											<select id="responseByDate" className="form-control load-select-child" name="responseByDate" onChange={this.loadformResponse}>
												<option value="">Please Select</option>
												{selectedRForm.map((val, i) => (
													<option data-type="" key={i} value={val[1]}>{val[2]}</option>
												))}
											</select>
											</div>
										</div>
									
									:null}

								</div>
								
								<div className="item-fields">
									<LeftPanel
										loadMdsSpreadSheet = {this.loadMdsSpreadSheet}
										addField = {this.addNewField}
										addNewPreField = {this.addNewPreField}
										addParentSets = {this.addParentSets}
										mdsSheets = {this.state.mdsSheets}
										parentDataSets = {this.state.parentDataSets}
										textfields = {this.state.textfields}
										basicfields = {this.state.basicfields}
										functionfields = {this.state.functionfields}
										gridfields = {this.state.gridfields}
										formattingfields = {this.state.formattingfields}
										addNewGroup = {this.addNewGroup}
										getPlaceholder = {this.getPlaceholder}
										dataMode = {this.state.dataMode}
										mdsSpreadsheetId = {this.state.mdsSpreadsheetId}
										mdsSpreadsheets = {this.state.mdsSpreadSheetData}
									/>
								</div>
							</div>
						</nav>
						
						{showTrackerTable ? 
							 <StatusTracker data={this.state.trackerTableData} editTracker={this.editStatusTracker} showTrackerView={this.showTrackerView} trackerViewPdf={this.trackerViewPdf} statusByfacility = {this.state.statusByfacility} statusType = {this.state.statusType} statusWithMail = {this.state.statusWithMail} statusWithPos = {this.state.statusWithPos} facilityIds = {this.state.facilityIds} trackerTableUpdate = {this.state.trackerTableUpdate}/>
						:null}
						
						<div id="page-content-wrapper" className={this.state.copySelectedSheet ? 'has-copy-sheet custom-'+dataMode : 'custom-'+dataMode}>
							
							<button type="button" className={"hamburger animated fadeInLeft is-open"} data-toggle="offcanvas"></button>
							
							{ showView && this.state.sheetData.length > 0 ? 
								<div className="from-mds-view">
								<h4 className="from-title">{dataMode == 'mdsFieldCopy' ? 'Copy From: '+this.state.selectedSheet : ''}</h4>
								<div className="preview-mode-off" onClick={this.preViewModeOff}><i className="fa fa-eye-slash" aria-hidden="true"></i> Off Preview Mode</div>
								{!this.state.showResponseData && dataMode == 'TestForm' ?
								<div className="select-attention-filter">
									<b>Needs attention:</b>
									<select className="form-control filter-attention" onChange={this.setAttentionFilter}>
										<option value="all">All</option>
										<option value="attention">Needs attention</option>
									</select>	
								</div>
								:null}
								{!this.state.showResponseData && (dataMode == 'Layout' || dataMode == 'TestForm') ?
								<div className="select-delegate-filter">
									<b>Delegate Mode:</b>
									<select className="form-control filter-delegate" onChange={this.setDelegateFilter}>
										<option value="">All</option>
										{optionsHtml}
									</select>	
								</div>
								:null}
								<MainData 
                                    sheetData = {this.state.sheetData}
                                    showFieldCopyView = {this.state.showFieldCopyView}
									getPlaceholder = {this.getPlaceholder}
									outputText = {this.state.outputText}
									dataMode = {this.state.dataMode}
									loadDataSet = {this.state.loadDataSet}
									showResponseData = {this.state.showResponseData}
									apiPdfs = {this.state.apiPdfs}
									delegateUsers = {this.state.delegateUsers}
									delegateFilter = {this.state.delegateFilter}
									allData = {this.state.allData}
									copyAllData = {this.state.copyAllData}
									kioskResponse = {this.state.kioskResponse}
									needsNote = {this.state.needsNote}
									checkedNeeds = {this.state.checkedNeeds}
									handleNeeds = {this.handleNeeds}
									checkedCopyFields = {this.state.checkedCopyFields}
									handleMdsCopyFields = {this.handleMdsCopyFields}
									handleMdsCopyAllFields = {this.handleMdsCopyAllFields}
									getResponseValue={this.getResponseValue}
									saveNeedsNote={this.saveNeedsNote}
									removeNeedsNote={this.removeNeedsNote}
									
                                />
								{dataMode == 'TestForm' && loadDataSet != 'loadCform' ?
								<div className="save-output-response"><Button type="button" className="float-right" onClick={()=> this.handleSubmitSave(false)}>Save </Button></div>
								:null}
								{dataMode == 'TestForm' && this.state.layoutType == 'Fabrication' ?
									<div className="save-output-send">
									<Button type="button" className="btn btn-send-tracking" onClick={()=>this.sendTracking()}>Save & Send to Fab Tracking</Button>
									</div>
								:null}
								<div className="show-output-txt">
								<label className="showAllSwitch">
									<input id="showOutputText" type="checkbox" name="showOutputText" onClick={this.showOutputText}/>
									<span className="slider round showAllSlide"></span>
								</label> Show Output Text
								</div>
								
								</div>
							: !showTrackerTable ?
							<div className="container-2 build-section">
								<div className="build-page-title">
								{dataMode != 'TestForm' && dataMode != 'mdsFieldCopy'?
								<h5 className="page-title">Build Page {dataMode == 'MDS' ? ' - MDS Mode' : ''} {dataMode == 'Child' ? ' - Default Layout Mode' : ''} {dataMode == 'Layout' ? ' - Layout Mode' : ''} {dataMode == 'mdsFieldCopy' ? ' - Copy from MDS file' : ''}</h5>
								:null}
								{this.state.allData.length > 0 && dataMode == 'Layout' && this.state.viewMode == 'builder' ?
								<div className="include-btn">
								<button className="btn btn-primary mr-2 clear-temp-top" onClick={() => { if(window.confirm('Do you want to include all?')) this.setAllChecked() }}>Include All </button>
								<button className="btn btn-danger mr-2 clear-temp-top" onClick={() => { if(window.confirm('Do you want to exclude all?')) this.setAllUnChecked() }}>Exclude All </button>
								</div>
								:null}
								{ this.state.allData.length > 0 && dataMode ?
								<div className="top-right-btn">
								
								<div className="preview-mode" onClick={this.preViewModeOn}><i className="fa fa-eye" aria-hidden="true"></i> Preview Mode</div>
								
								{dataMode == 'MDS' ?
								<button type="button" className="btn btn-info clear-temp-top" onClick={this.buildDetails}>
								 {this.state.loadDataSet == 'loadMds' ? 'Edit MDS Details':'Save New MDS'}
								</button>
								: dataMode == 'Child' ?
								<button type="button" className="btn btn-info clear-temp-top" onClick={this.buildDetails}>
								 {/*this.state.loadDataSet == 'loadChild' ? 'Edit Field Set Details':'Save New Field Set'*/}
								 Save New Default Layout
								</button>
								:null}
								
								{dataMode == 'Layout' ?
								<button type="button" className="btn btn-info clear-temp-top" onClick={this.buildDetails}>
								 {this.state.loadDataSet == 'loadLayout' ? 'Edit Layout Details':'Save New Layout'}
								</button>
								:null}
								
								{/*<button type="button" className="btn btn-success clear-temp-top" onClick={() => { if (window.confirm('Are you sure you want to Save?')) this.saveTempData('not') } }>Save without Clear</button><button type="button" className="btn btn-warning clear-temp-top" onClick={() => { if (window.confirm('Are you sure you want to Save and Clear?')) this.saveTempData() } }>Save & Clear Build Page</button>*/}
								
								{dataMode != 'Layout' && dataMode != 'mdsFieldCopy' ?
								<button type="button" className="btn btn-danger clear-temp-top" onClick={() => { if (window.confirm('Are you sure you want to Clear the Page?')) this.clearTempData() } }>Clear Build Page</button>
								:
								<button type="button" className="btn btn-danger clear-temp-top" onClick={() => { if (window.confirm('Are you sure you want to Clear the Page?')) this.clearLayoutData() } }>Clear Build Page</button>
								}
								
								</div>
								:null}
								</div>
								<div className="page-builder">
								{ this.state.allData && dataMode ?
									<SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
										{allData.map((value, index) => (
										  <SortableItem key={index} index={index} value={value} /> 
										))}
									</SortableContainer>
								:null}
								</div>
								  
								{ this.state.allData.length > 0 && dataMode && dataMode != 'mdsFieldCopy' ?
									<div className="bottom-btn">
										<div className="show-output-text">
										<label className="showAllSwitch">
											<input type="checkbox" name="showOutputText" onClick={this.showOutputText}/>
											<span className="slider round showAllSlide"></span>
										</label> Show Output Text
										</div>
										{(dataMode != 'Layout' && dataMode != 'TestForm') || (this.state.loadDataSet == 'loadLayout' && dataMode != 'TestForm') ?
										<button type="button" className="btn btn-info btn-update-data" onClick={this.clearUpdateData}>Update</button>
										:null}
										<button type="button" className="btn btn-danger btn-undo-data" onClick={this.clearLastData} style={{display:'none'}}>Undo</button>
										{this.state.loadDataSet == 'loadMds' ?
										<button type="button" className="btn btn-info" data-target="#newMdsPopup" data-toggle="modal">Save AS New MDS</button>
										:null}
										
										{dataMode == 'Layout' ?
										<button type="button" className="btn btn-info" onClick={() => { if (window.confirm('Are you sure to make a New Layout?')) this.saveAsCopyLayout() }}>Save AS New Layout</button>
										:null}
									</div>
								:null}
							</div>
							:null}
							
							{dataMode == 'mdsFieldCopy' && this.state.copySelectedSheet ? 
								<div className="to-mds-view">
									<h4 className="from-title copy-h4">{dataMode == 'mdsFieldCopy' && this.state.copySelectedSheet ? 'Copy To: '+this.state.copySelectedSheet : ''}</h4>
									{ this.state.checkedCopyFields.size > 0 && this.state.copySelectedSheet && dataMode == 'mdsFieldCopy' ?
										<button type="button" className="btn btn-sm btn-primary btn-copy-to" onClick={this.copyMdsFields}>Copy <i className="fa fa-angle-right" aria-hidden="true"></i></button>
									:null}
									<div className="to-copty-section build-section">
										{this.state.copyAllData && dataMode ?
										<div className="page-builder">
										
										{this.state.fieldView ? 
											<CopyMainData 
												sheetData = {this.state.copySheetData}
												outputText = {this.state.outputText}
												allData = {this.state.copyAllData}
												copyAllData = {this.state.copyAllData}
												kioskResponse = {this.state.kioskResponse}
											/>
										: 
											<SortableContainer onSortEnd={this.onSortEndCopyMds} useDragHandle>
												{copyAllData.map((value, index) => (
												  <SortableItemCopy key={index} index={index} value={value} /> 
												))}
											</SortableContainer>
										}
										</div>
										:null }
										
										  
									</div>
									<div className="show-copy-view">
										<label className="showAllSwitch">
											<input id="showFieldView" type="checkbox" name="showFieldView" onClick={this.viewFieldsMode}/>
											<span className="slider round showAllSlide"></span>
										</label> View Fields mode
									</div>
								</div>
							:null}
							<button type="button" className={"hamburger animated fadeInRight is-closed "+copyClass} data-toggle="offcanvas-2"></button>
						</div>
						{dataMode == 'mdsFieldCopy' ?
							<nav className="custom-navbar-2 to-copy-from" id="sidebar-wrapper-right" role="navigation">
								<div className="nav-content-right-2">
									<h5 className="page-title">Details Panel</h5>
									<div className="item-fields">
										<CopyFieldDetails 
											copyProps = {this.state}
											getValue = {this.getValue}
											setAsGroup = {this.setAsGroup}
											copyUpdateField = {this.copyUpdateField}
											copyAddNewField = {this.copyAddNewField}
											getCopyMdsData = {this.getCopyMdsData}
											copyMdsSheets = {this.state.copyMdsSheets}
											copyMdsSheetNames = {this.state.copyMdsSheetNames}
											textfields = {this.state.textfields}
											showEditHeaders = {this.state.showEditHeaders}
											copyAllData = {this.state.copyAllData}
											copyMdsUpdateData = {this.copyMdsUpdateData}
										/>
									</div>
									
								</div>
							</nav>
						:
							<nav className="custom-navbar-2" id="sidebar-wrapper-right" role="navigation">
								<div className="nav-content-right">
									<h5 className="page-title">Details Panel</h5>
									{this.state.showBuildEdit ?
									<div className="panel-btn">
									{/*<button type="button" className="btn btn-info">Build New MDS Details</button>*/}
									</div>
									:null}
									<div className="item-fields">
										<BuildDetails 
											getValue = {this.getValue}
											getDueDate = {this.getDueDate}
											getRqAndOpFields = {this.getRqAndOpFields}
											handleLaunchPadItem = {this.handleLaunchPadItem}
											delegateTypes = {this.state.delegateTypes}
											types = {this.state.types}
											oDeviceTypes = {this.state.oDeviceTypes}
											pDeviceTypes = {this.state.pDeviceTypes}
											showFieldEdit = {this.state.showFieldEdit}
											showBuildEdit = {this.state.showBuildEdit}
											visitTypes = {this.state.visitTypes}
											companies = {this.state.companies}
											//trackingCode = {this.state.trackingCode}
											groupId = {this.state.groupId}
											mdsSheets = {this.state.mdsSheets}
											facilityIds = {this.state.facilityIds}
											responsibleRole = {this.state.responsibleRole}
											responsiblePerson = {this.state.responsiblePerson}
											persons = {this.state.persons}
											statusType = {this.state.statusType}
											statusByfacility = {this.state.statusByfacility}
											facilityData = {this.state.facilityData}
											fileArray = {this.state.fileArray}
											imagePath = {this.state.imagePath}
											defaultProps = {this.state}
											_uploadImageCallBack = {this._uploadImageCallBack}
											onEditorStateChange = {this.onEditorStateChange}
											updateField = {this.updateField}
											uploadVideoPath = {this.uploadVideoPath}
											setAsGroup = {this.setAsGroup}
											getDirectoryImages = {this.getDirectoryImages}
											calculationFields = {this.calculationFields}
											wcalculationFields = {this.wcalculationFields}
											parentDataSets = {this.state.parentDataSets}
											addParentSets = {this.addParentSets}
											dataMode = {this.state.dataMode}
											showTrackerDetails = {this.state.showTrackerDetails}
											updateTrackerStatus = {this.updateTrackerStatus}
											showTrackerViewFun = {this.showTrackerViewFun}
											trackerRow = {this.state.trackerRow}
											trackerTableData = {this.state.trackerTableData}
											ogTags = {this.state.ogTags}
											statusLogs = {this.state.statusLogs}
											assignTracks = {this.state.assignTracks}
										/>
										<div className="panel-btn">
										<br />
										{this.state.showBuildEdit ?
											<div>
											{this.state.loadDataSet == 'loadMds' || this.state.loadDataSet == 'loadLayout' ?
												<button type="button" className="btn btn-primary" onClick = {()=>this.buildMds('Update')} >Update</button>
											: 
												<button type="button" data-target="#createBuild" data-toggle="modal" className="btn btn-primary" >Build </button>
											}
											</div>
										:null}
										</div>
									</div>
								</div>
							</nav>
						}
						{showTrackerTable && kioskResponse ? 
							<div className="tracker-pdf-data">
							 <PdfData 
                                    sheetData = {this.state.sheetData}
									getPlaceholder = {this.getPlaceholder}
									outputText = {this.state.outputText}
									dataMode = {this.state.dataMode}
									loadDataSet = {this.state.loadDataSet}
									showResponseData = {this.state.showResponseData}
									apiPdfs = {this.state.apiPdfs}
									delegateUsers = {this.state.delegateUsers}
									delegateFilter = {this.state.delegateFilter}
									allData = {this.state.allData}
									kioskResponse = {this.state.kioskResponse}
									needsNote = {this.state.needsNote}
									checkedNeeds = {this.state.checkedNeeds}
									handleNeeds = {this.handleNeeds}
									getResponseValue={this.getResponseValue}
									saveNeedsNote={this.saveNeedsNote}
									removeNeedsNote={this.removeNeedsNote}
									
                                />
							</div>
						:null}
						<div className="modal" id={'redoModal'}>
							<div className="modal-dialog modal-lg modal-sm redo-modal">
								<div className="modal-content">
									<div className="modal-header">
										<h4 className="modal-title">Select why this is a Redo</h4>
										<button type="button" className="close" data-dismiss="modal">&times;</button>
									</div>
									<div className="modal-body">
										<div>
										<select name="redoOption" value={this.state.redoOption} className="form-control" onChange={this.getValue}>
											<option value=''>Please Select</option>
											<option value='Fabrication Error'>Fabrication Error</option>
											<option value='Clinical Error'>Clinical Error</option>
											<option value='Patient Rejected Original'>Patient Rejected Original</option>
											<option value='Replace Broken Device'>Replace Broken Device</option>
											<option value='Other'>Other</option>
										</select>
										<div id="redoInfo" style={{display:'none'}}>
										<textarea name="redoInformation" className="form-control" value={this.state.redoInformation} onChange={this.getValue}></textarea></div>
										</div>
									</div>
									<div className="modal-footer">
										<button type="button" className="btn btn-primary float-left" data-dismiss="modal" > Save </button>
										<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
									</div>
								</div>
							</div>
						</div>
						
						<div className="modal" id={'redoErrorInfo'}>
							<div className="modal-dialog modal-lg modal-sm redo-modal">
								<div className="modal-content">
									<div className="modal-header">
										<h4 className="modal-title">{this.state.redoOption}</h4>
									</div>
									<div className="modal-body">
										<p>{this.state.redoInformation}</p>
									</div>
									<div className="modal-footer">
										<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
									</div>
								</div>
							</div>
						</div>
						
						<div className="modal" id="directory">
							<div className="modal-dialog modal-lg">
								<div className="modal-content">
								  <div className="modal-header">
									<h5 className="modal-title">Upload Images</h5>
									<button type="button" className="close" data-dismiss="modal">&times;</button>
								  </div>
								  <div className="modal-body">
									<form>
										<div className="row">
											{(this.state.fileArray || []).map(url => (
												<div className="col-md-4">
													<img className="view-image" id={'image_'+url.id} src={url.path} alt="..." style={{cursor: 'pointer'}} width="259px" height="200px" onClick = {()=>this.getImagePath(url.path,url.id)}/>
												</div>
											))}
											<input type="hidden" id="imageId" value="" />
										</div>
									</form >
								  </div>
								  <div className="modal-footer">
								  <button type="button" className="btn btn-primary float-left" onClick={this.uploadImagePath}>Upload</button>
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
								  </div>

								</div>
							</div>
						</div>
						
						<div className="modal" id="assignTrack">
							<div className="modal-dialog modal-lg">
								<div className="modal-content">
								  <div className="modal-header">
									<h5 className="modal-title">Assigned Track</h5>
									<button type="button" className="close" data-dismiss="modal">&times;</button>
								  </div>
								  <div className="modal-body">
									<div className="add-track">
									<input type="text" className="form-control add-track-n"  name="trackName" onChange={this.getValue} /> 
									<button type="button" className="btn btn-info" onClick={this.addTrackMongo}>Add</button>
									</div>
									<div className="track-list">
									   <h6 className="modal-title">Track List</h6>
									   <ul className="track-list-ul">
									   {trackList}
									   </ul>
									</div>
								  </div>
								  <div className="modal-footer">
								  <button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
								  </div>

								</div>
							</div>
						</div>
					
						
						<div className="modal" id="createBuild">
							<div className="modal-dialog modal-lg">
								<div className="modal-content">
									<div className="modal-header">
										<button type="button" className="close" data-dismiss="modal">&times;</button>
									</div>
									<div className="modal-body">
										{this.state.dataMode == 'MDS' ? 'The MDS was saved!':''}
										{this.state.dataMode == 'Child' ? 'The Default Layout was saved!':''}
										{this.state.dataMode == 'Layout' ? 'The Layout was saved!':''}
									</div>
									<div className="modal-footer">
										{this.state.dataMode == 'Layout' ?
										<button type="button" className="btn btn-primary float-left" onClick={this.buildLayout} >Ok</button>
										:null}
										{this.state.dataMode == 'MDS' ?
											<button type="button" className="btn btn-primary float-left" onClick={this.buildMds} >Ok</button>
										:null}
										{this.state.dataMode == 'Child' ?
											<button type="button" className="btn btn-primary float-left" onClick={this.buildDefautLayout} >Ok</button>
										:null}
										<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Cancel</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					{ this.state.instanceManager ?
						<InstanceManager getValue={this.getValue} defaultProps={this.state} showAddNewDestination={this.showAddNewDestination} showAddNewSection={this.showAddNewSection} getAllInstances={this.getAllInstances} addDestination={this.addDestination} addInstance={this.addInstance} instanceUpdate={this.instanceUpdate} changeInstance={this.changeInstance} mdsSheets={this.state.mdsSheets} mdsSpreadsheetId = {this.state.mdsSpreadsheetId} />
					:null}
					
					{this.state.trackerStatusManager ?
						<StatusManager trackerData={this.state.trackerData} facilityIds={facilityIds} facilityOption={facilityOption} statusList = {this.state.statusList} getLookUpData={this.getLookUpData}/>
					:null}
					
					{this.state.taskManager ?
						<TaskManager />
					:null}
					
					{this.state.workFlow ?
						<WorkFlowBuilder />
					:null}
					
					{this.state.workFlowDetails ?
						<WorkFlowDetails />
					:null}
					
					{this.state.workFlowManger ?
						<WorkFlowManger />
					:null}
					
					{this.state.workFlowProgress ?
						<WorkFlowProgress />
					:null}
					
					{this.state.scheduledworkFlow ?
						<ScheduledworkFlow />
					:null}
					
					{showTranslationManager ?
					<TranslationManager 
						sheetApiName={this.state.sheetApiName}
						childSheetNames={this.state.childSheetNames}
						sheetVersion={this.state.sheetVersion}
						sheetGuids={this.state.sheetGuids}
						childSpreadsheetId={this.state.childSpreadsheetId}
					/>
					:null}
					
					{showMongoMdsSection ?
					<MongoDBMdsManager 
						mdsSpreadsheets={mdsSpreadsheets} 
						mdsSpreadsheetId={this.state.mdsSpreadsheetId}
						mdsSpreadSheetData={this.state.mdsSpreadSheetData}
						sheetApiName={this.state.sheetApiName}
						mdsSheetNames={this.state.mdsSheetNames}
						sheetVersion={this.state.sheetVersion}
						sheetGuids={this.state.sheetGuids}
					/>
					:null}
					
					{showMongoSection ?
					<MongoDBManager 
						sheetApiName={this.state.sheetApiName}
						childSheetNames={this.state.childSheetNames}
						sheetVersion={this.state.sheetVersion}
						sheetGuids={this.state.sheetGuids}
						childSpreadsheetId={this.state.childSpreadsheetId}
					/>
					:null}
					
					{showMdsManageSection ?
					<div className="mds-manager container">
						<div className="row">
								<div className="col-md-2">
									<div className="form-group select-mds-filter">
										<label> Fiter by Category </label>
										<select className="form-control" onChange={this.setFilter}>
											<option value="fieldSet">Default Layout</option>
											<option value="mds">MDS</option>
										</select>	
									</div>
								</div>
								
								<div className="col-md-2">
									<div className="select-mds-filter form-filter">
										<label> Select Form Version </label>
										<select className="form-control" name="mdsSpreadsheetId" onChange={this.selectFormVersion}>
											<option value="form-1">Form 1.0</option>
											<option value="form-2">Form 2.0</option>
										</select>
									</div>
								</div>
								
								{mdsSpreadsheets.length > 0 ?
								<div className="col-md-3">
									<div className="select-mds-filter mds-ssheets">
										<label> Select MDS File </label>
										<select className="form-control" value={this.state.mdsSpreadsheetId} name="mdsSpreadsheetId" onChange={this.loadMdsSpreadSheet2}>
											<option value="">Please Select</option>
											{optionHtmlsheets}
										</select>
									</div>
								</div>
								:null}
						</div>
						<div>
							<div className="mds-sheets">
								<div className="top-action-icon">
									<div className="top-action-span">
										<span>Total # of Fields</span>
										<span>Last Updated</span>
									</div>
									<div className="top-action-span2">
										<span className="btn-json frm1">Json 1.0</span>
										<span className="btn-json frm2">Json 2.0</span>
										<span className="btn-json frm1">Zip 1.0</span>
										<span className="btn-json frm2">Zip 2.0</span>
									</div>
								</div>
								<div className="mds-sheet-div">
									{mdsSheetNames && showMdsList ?
									<div className="mds-file-list">
									<SortableContainer onSortEnd={this.onSortEndMds} useDragHandle>
											{mdsSheetNames.map((value, index) => (
											  <SortableSheetItemMds key={index} index={index} value={value} /> 
											))}
									</SortableContainer>
									</div>
									:null}
								</div>
							
							<div className="bottom-action-icon"><span className="total-field">Total Field Count : {totalFieldCountM}</span></div>
							</div>
							<div className="child-sheets">
								<div className="top-action-icon">
									<div className="top-action-span">
										<span>Total # of Fields</span>
										<span>Last Updated</span>
									</div>
									<div className="top-action-span2">
										<span className="btn-json frm1">Language</span>
										<span className="btn-json frm1">Json 1.0</span>
										<span className="btn-json frm2">Json 2.0</span>
										<span className="btn-json frm1">Zip 1.0</span>
										<span className="btn-json frm2">Zip 2.0</span>
									</div>
								</div>
								<div className="mds-sheet-div">
									{childSheetNames && showMdsList ?
									<div className="mds-file-list">
									<SortableContainer onSortEnd={this.onSortEndChild} useDragHandle>
											{childSheetNames.map((value, index) => (
											  <SortableSheetItemChild key={index} index={index} value={value} /> 
											))}
									</SortableContainer>
									</div>
									:null}
								</div>
							<div className="bottom-action-icon"><span className="total-field">Total Field Count : {totalFieldCountC}</span></div>
							</div>
						</div>
					</div>
					:null}
				</div>
				
				<div className="modal" id={"addChildDescription"} role="dialog">
                    <div className="modal-dialog modal-lg custom-modal mds-description-modal">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Add File Description</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                          </div>
                          <div className="modal-body">
								<DebounceInput element="textarea" debounceTimeout={4000} className={'form-control'} rows="3" value={this.state.fileDescription} onChange={this.addDescription} maxLength="50"/>
                          </div>
                          <div className="modal-footer">
                                <div className="image-text" id="fileText"></div>
                                <div className="popup-btn-com">
                                    <button type="button" className="btn btn-primary float-left" onClick={() => { this.downloadMsdJson(this.state.sheetName, 'post', 'child') } }>Save</button>
                                    <button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
                                </div>
                          </div>

                        </div>
                    </div>
                </div>
				
				<div className="modal" id={'kioskUser'}>
					<div className="modal-dialog modal-lg modal-sm select-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Delegate Mode</h4>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<label> Select Users Type: </label>
								<select className="form-control" name={this.state.fieldId} onChange={this.selectKioskUsers} value={this.state.delegateUsers.get(this.state.fieldId)} multiple>
								{optionsHtml}
								</select>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary float-left" data-dismiss="modal" onClick={()=>this.updateData('kiosk')}> Save </button>
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={'fieldPreview'}>
					<div className="modal-dialog modal-lg modal-sm select-modal modal-dialog-2">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Field Preview</h4>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								{this.state.previewData ? 
									generatehtmlforFiled(this.state.previewData,this.subQuestions,this.state.kioskResponse,this.state.apiPdfs,this.state.outputText)
								:null}
							</div>
							<div className="modal-footer custom-preview">
								{fieldButtons(this.state.previewData)}
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={'copyModal'}>
					<div className="modal-dialog modal-lg modal-sm select-modal">
						<div className="modal-content">
							<div className="modal-header">
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<label> Suffix (copy): </label>
								<input className="form-control" type="text" name='suffix' onChange={this.getValue} value={this.state.suffix} />
								<label> Include (copy): </label>
								<select className="form-control" name='IncludeCopy' onChange={this.getValue}>
								<option value="Yes">Yes</option>
								<option value="No">No</option>
								</select>
							</div>
							<div className="modal-footer">
								{ this.state.copyValue && this.state.copyValue[126] == 'Y' ||  this.state.copyValue[127] == 'Y' ?
								<button type="button" className="btn btn-primary float-left" data-dismiss="modal" onClick={()=>this.copyGroupFields(this.state.copyValue)}> Save </button>
								:
								<button type="button" className="btn btn-primary float-left" data-dismiss="modal" onClick={()=>this.copyField(this.state.copyValue)}> Save </button>
								}
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"newMdsPopup"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
						  <div className="modal-header">
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						  </div>
						  <div className="modal-body">
								<label> New MDS file name: </label>
								<input id="newMdsName" className="form-control" type="text" name='newMdsName' onChange={this.getValue} />
						  </div>
						  <div className="modal-footer">
								<div className="popup-btn-com">
								{this.state.dataMode == 'mdsFieldCopy' ? 
									<button type="button" className="btn btn-primary float-left mr-2"  onClick={() => { this.createNewMDs(this.state.newMdsName) } } data-dismiss="modal">Save</button>
								:
									<button type="button" className="btn btn-primary float-left mr-2" onClick={this.saveAsCopyMds} data-dismiss="modal">Save</button>
								}
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
								</div>
						  </div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"layoutFriendlyName"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
						  <div className="modal-header">
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						  </div>
						  <div className="modal-body">
								<label> Layout Friendly Name: </label>
								<input id="layoutFriendlyName" className="form-control" type="text" name='layoutFriendlyName' onChange={this.getValue} />
						  </div>
						  <div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-primary float-left mr-2" onClick={this.downloadZipWithLanguage} data-dismiss="modal">Save</button>
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
								</div>
						  </div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"copyAllFields"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
						  <div className="modal-header">
						  <h5 className="mb-0"> Copy Headers & Fields: </h5>
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						  </div>
						  <div className="modal-body">
							
							<div className="form-check">
								<input className="form-check-input" id="copySingle" type="radio" name='copyHeaderFiled' value="single" onChange={this.getValue} /> 
								<label className="form-check-label" for="copySingle">Only Header</label>
							</div>
							<div className="form-check">
								<input className="form-check-input" id="copyAll" type="radio" name='copyHeaderFiled' value="all" onChange={this.getValue} /> <label className="form-check-label" for="copyAll">With all Fields inside the Header</label>
							</div>
						  </div>
						  <div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-primary float-left mr-2" onClick={this.mdsCopyAllFields} data-dismiss="modal">Select</button>
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
								</div>
						  </div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={'sendTracking'}>
					<div className="modal-dialog modal-lg modal-sm select-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Select Fabrication Lab</h4>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<div className="fab-tr-opt">
								<lable>Fabrication Lab</lable>
								<select id='fabFacility' className="form-control" name="fabFacility" onChange={this.getValue}>
								<option value=''>Please Select</option>
								{facilityOption}
								</select>
								</div>
								<div className="fab-tr-opt">
								<lable>Sending practitioner</lable>
								<select id='fabPractitioner' className="form-control" name="fabPractitioner" onChange={this.getValue}>
								<option value=''>Please Select</option>
								{practitionerOption}
								</select>
								</div>
								
								{this.state.fabPractitioner ? 
								<div className="fab-tr-opt">
									<label>Patient ID</label>
									<input id="patientId" className="form-control" type="text" name='patientId' />
								</div>
								:null}
								
								{this.state.fabPractitioner ? 
								<div className="fab-tr-opt">
									<div className="fab-first-name">
										<label>Patient firstname</label>
										<input id="patientFirstName" className="form-control" type="text" name='patientFirstName' />
									</div>
									<div className="fab-last-name">
										<label>Patient lastname</label>
										<input id="patientLastName" className="form-control" type="text" name='patientLastName' />
									</div>
								</div>
								:null}
								
							</div>
							
							<div className="modal-footer">
								<button type="button" className="btn btn-primary float-left" onClick={()=>this.sendToFab()}> Send to Fab </button>
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				
                    
            </div>
        );
    }
}

export default MdsBuilder;