import React, { Component, PropTypes } from 'react'
import 'bootstrap';
import $ from 'jquery';
import config from "../../config";
import { gapi } from 'gapi-script';
import { Form,Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { generatehtmlfromlocation } from "../../Helpers/Common";
import Checkbox from '@mui/material/Checkbox';

import FieldQ13 from "../Fields/Q13.js";
import Launchpad from "../Fields/Launchpad";
//import FieldInlineImage from "../Fields/InlineImage.js";
import FieldInlineVideo from "../Fields/InlineVideo.js";
import FieldPdfApi from "../Fields/PdfApi.js";
import FieldAssessment from "../Fields/Assessment.js";
import FieldSubjective from "../Fields/Subjective.js";
import FieldCalculation from "../Fields/Calculation.js";
import Checklist from "../Fields/Checklist";

import { noteHtml,noteModal,attentHTML} from "../Modal";

export default class CopyMainData extends Component {
    constructor(props){
        super(props)
        this.state = {
            checkedNeeds:new Map(),
            checkedApprove:new Map(),
        }
    }
	
    classTypes(comment){
        let className = 'question';
		let notInclude = '';
        if(comment[5].toLowerCase() == 'parent header'){
            className = 'parent-header';
        }else if(comment[5].toLowerCase() == 'child header 1'){
			className = 'header-1 ';
		}else if(comment[5].toLowerCase() == 'child header 2'){
			className = 'header-2 ';
		}else if(comment[5].toLowerCase() == 'child header 3'){
			className = 'header-3 ';
		}
		
		if(comment[5].toLowerCase() == 'boxbegin' || comment[5].toLowerCase() == 'boxend'){
			className = ' '+comment[5]+'-div ';
		}
		
		if(comment[5] !== 'Parent Header' && comment[14] == 'N'){
            className = className+' not-include';
        }
		
		return className;
    }
	
	toggle(id){
		if(!$('#copy'+id.toLowerCase()).prop('disabled')){
			$('#copy'+id.toLowerCase()).find('i:first').toggleClass('fa fa-plus-square-o fa-1x mr-2  fa fa-minus-square-o fa-1x mr-2');
		}
		$('#copy'+id.toLowerCase()).prop('disabled', true);
		setTimeout(function(){
			$('#copy'+id.toLowerCase()).prop('disabled', false);
		}, 1000);
    }
	

	getQueType(type,id,res){
		
        switch(type){
            case 'sig':
                return <i className="fas fa-edit" onClick={()=>this.showSign2(type,id)}></i>
            break;
            case 'q10':
                return <Checkbox id={'type'+id} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} onClick={()=>this.showSign(type,id)} checked={res ? true : false}/>
            break;
            default:
                return null
        }
    }
	
	showSign=(type,id)=>{
		if($("#type"+id).prop("checked") == true){
			$('#'+type+id).css('display','block');
		}else if($("#type"+id).prop("checked") == false){
			$('#'+type+id).css('display','none');
		}
    }
	
	showSign2=(type,id)=>{
        $('#'+type+id).css('display','block')
    }
	
	subQuestions = (id,value) => {
		this.props.getResponseValue(id,value);
	}
	

	getValue=(event)=>{
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
	
	
	render() {
        const {sheetData, getPlaceholder, queId, comment, outputText,kioskResponse} = this.props;
		//console.log('showFieldCopyView->',showFieldCopyView);
		return (
          <div id={queId} className={queId ? 'inner-'+ comment[0] +' childMain col-11 offset-1 '+queId+' collapse' : 'copy-comments '} >
            {sheetData.map((comment,i) =>
              <div id={comment[0]} data-pos={comment[10]} key={i}  className={this.classTypes(comment)}>
                {(() => {
					
					let question = comment[4];
					if(outputText){
						question = comment[9] ? comment[9] : comment[4];
					}
					
					let answer = generatehtmlfromlocation(comment,this.subQuestions,kioskResponse,outputText);
					let res = kioskResponse.get(comment[0]);
					let that = this;
					switch (comment[5].toLowerCase()) {
                        case 'parent header':
                            return <h3 data-target={'.custom'+comment[1].toLowerCase()} id ={'copy'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'header-m headers-'+comment[0]}> <i className="fa fa-plus-square-o fa-1x mr-2"></i>{ question } </h3>;
                        case 'child header 1':
                            return <div className="child-headers">
                                        <div className="row">
                                            <div className={'col-md-12'}>
                                                <h4 data-target={'.custom'+comment[1].toLowerCase()} id ={'copy'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'header-m headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>
                                                    {question}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                        case 'child header 2':
                            return <div className="child-headers">
                                    <div className="row">
										<div className={'col-md-12'}>
											<h5 data-target={'.custom'+comment[1].toLowerCase()} id={'copy'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'header-m headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>{question} </h5>
										</div>
                                    </div>  
                            </div>;
                        case 'child header 3':
                            return <div className="child-headers">
								<div className="row">
                                    <div className={'col-md-12'}>
                                        <h6 data-target={'.custom'+comment[1].toLowerCase()} id ={'copy'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'header-m headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>{question} </h6>
                                    </div>
                                </div>
                            </div>;
						
                        case 'title':
						return (
						<div className="full-width default-div">
							<div className="row">
								<div className={'col-md-10 input-text'}>
									<h5>{question}</h5>
								</div>  
							</div>
							 
						</div>)
						
						case 'text':
						return (
						<div className="full-width default-div">
							<div className="row">
								<div className={'col-md-10 input-text'}>
									<b>{question}</b>
								</div>  
							</div>
							 
						</div>)
						
						case 'link':
						return (
						<div className="full-width default-div">
							<div className="row">
								<div className={'col-md-10 input-text'}>
									{answer}
								</div>
							</div>
							 
						</div>)
						
						case 'q13' :
						return (
						<div className="full-width default-div">
							<div className="row">
								<div className={'col-md-10 answer'}>
									{ answer }
								</div>
							</div>
							 
						</div>)
						
						
						case 'assessment' :
                        return  <FieldAssessment fieldId={comment[0]} required={comment[15]} question={question} />
						
						case 'subjective' :
                        return  <FieldSubjective fieldId={comment[0]} required={comment[15]} question={question} />
						
						case 'begin':
                            return (<div className="full-width"></div>)
							
						/* case 'calculation':
						case 'wcalculation':
                        return  <FieldCalculation fieldId={comment[0]} question={question} />
						 */
						case 'checklist':
                        return  <Checklist fieldId={comment[0]} required={comment[15]} fieldGuid={comment[117]} question={question} outputText={outputText}/>
							
						case 'end':
						return (<div className="full-width"></div>)
						
						case 'calculation':
						case 'wcalculation':
						return (
						<div className="full-width default-div">
							<div className="row">
								<div className={'col-md-10 input-text'}>
									{ answer }
								</div>
							</div>
						</div>)
						
						case 'image' :
						return (
						<div className="full-width default-div">
							<div className="row">
								<div className={'col-md-10 input-text'}>
									{ answer }
								</div>
								
							</div>
							<div className="row">
								<div className={'col-md-10 answer'}>
									{question}
								</div>   
							</div> 
						</div>)
						
						case 'inlinevideo' :
						return (
						<div className="full-width default-div">
							<div className="row">
								<div className={'col-md-10 input-text'}>
									{ answer }
								</div>
								
							</div>
							<div className="row">
								<div className={'col-md-10 answer'}>
									{question}
								</div>   
							</div> 
						</div>)
						
						case 'q5':
						return (
						<div className="full-width default-div">
							<div className="row">
								<div className={'col-md-10 input-text'}>
									 { answer } <div className="question-q5">{question}{ comment[15] == 'Y' ? <span className="required">*</span> : ''}</div>
								</div>
								
							</div>
							 
						</div>)
						
						case 'q10':
						return (
						<div className="full-width default-div">
							<div className="row">
								<div className={'col-md-10 input-text'}>
									{this.getQueType(comment[5].toLowerCase(),comment[0],res)} {question} { comment[15] == 'Y' ? <span className="required">*</span> : ''}
								</div>
							</div>
							<div className="row">
								<div className={'col-md-10 answer'}>
									{ answer }
								</div>   
							</div> 
							 
						</div>)
						
						case 'sig':
						return (
						<div className="full-width default-div">
							<div className="row">
								<div className={'col-md-10 input-text'}>
									{this.getQueType(comment[5].toLowerCase(),comment[0])} {question} { comment[15] == 'Y' ? <span className="required">*</span> : ''}
								</div>
								
							</div>
							<div className="row">
								<div className={'col-md-10 answer'}>
									{ answer }
								</div>   
							</div> 
							 
						</div>)
						
						default:
						return (
						<div className="full-width default-div">
							<div className="row">
								<div className={'col-md-10 input-text'}>
									{question} { comment[15] == 'Y' ? <span className="required">*</span> : ''}
								</div>
							</div>
							<div className="row">
								<div className={'col-md-10 answer'}>
									{ answer }
								</div>
							</div> 
						</div>)                         
                    }
                })()}
                {comment.sub 
                    && 
                    <CopyMainData 
                        sheetData={comment.sub} 
                        queId={'custom'+comment[1].toLowerCase()}  
                        comment= {comment}
                        outputText= {this.props.outputText}
                        allData= {this.props.allData}
                        copyAllData= {this.props.copyAllData}
                        kioskResponse= {kioskResponse}
                    />
                }
              </div>
            )}
          </div>
        )
    }
}