import React, { Component } from 'react';
import Box from '@mui/material/Box';
import $ from 'jquery';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CSVLink } from "react-csv";

import DataTable from 'react-data-table-component';

export default class StatusTracker extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			trackerData:[],
			filteredPerson:[],
			csvdata:[],
			asc:0,
			desc:0,
			tableUpdate:false,
		}
		this.csvLink = React.createRef();
    }
	
	componentWillReceiveProps() {
		if(this.props.trackerTableUpdate){
			this.setState({trackerData:[],trackerFormFilter:'',trackerRoleFilter:'',trackerPersonFilter:'',trackerFacilityFilter:'',tableUpdate:true});
		}
	}
	
	/* setFacilityFilter=(event)=>{
		$("#statusFilter option:selected").prop("selected", false);
		let item = event.target.name;
		let value = event.target.value;
		$('.status-row').addClass('hide-row');	
		if(value){
			$('.'+value).show();
			$('.'+value).removeClass('hide-row');
		}else{
			$('.status-row').show();
			$('.status-row').removeClass('hide-row');
		};
	} */
	
	setStatusFilter=(event)=>{
		let item = event.target.name;
		let value = event.target.value.replace(/ /g, '');
		if(value == 'In-Progress'){
			$('.status-row').show();
			$('.Complete, .Cancelled, .Complete-Hidden, .OnHoldforAuthorization, .OnHold').hide();
			//$('.'+value).show();
		}else if(value == 'Complete-Hidden'){
			$('.status-row').hide();
			$('.'+value).show();
		}else if(value == 'Cancelled-Hidden'){
			$('.status-row').hide();
			$('.'+value).show();
		}else if(value == 'Complete'){
			$('.status-row').hide();
			$('.'+value).show();
		}else if(value == 'Cancelled'){
			$('.status-row').hide();
			$('.'+value).show();
		}else if(value == 'On-Hold'){
			$('.status-row').hide();
			$('.OnHoldforAuthorization, .OnHold').show();
		}else{
			$('.status-row').show();
		};
	}
	
	showNote=(formId)=>{
		$('#'+formId).modal('show');
	}
	
	sortStartDate=()=>{
		let data = [];
		if(this.state.trackerData.length > 0 || this.state.trackerFormFilter || this.state.trackerRoleFilter || this.state.trackerPersonFilter){
			data = this.state.trackerData;
		}else{
			data = this.props.data;
		}
		let asc = this.state.asc;
		if(asc == 0){
			data.sort(function(a, b){
				return new Date(b.date) - new Date(a.date);
			});
			this.setState({trackerData:data,asc:1});
		}else{
			data.sort(function(a, b){
				return new Date(a.date) - new Date(b.date);
			});
			this.setState({trackerData:data,asc:0});
		}
		this.setState({tableUpdate:false});
		$("#statusFilter option:selected, #facilityFilter option:selected").prop("selected", false);
	}
	
	sortDueDate=()=>{
		let data = [];
		if(this.state.trackerData.length > 0 || this.state.trackerFormFilter || this.state.trackerRoleFilter || this.state.trackerPersonFilter){
			data = this.state.trackerData;
		}else{
			data = this.props.data;
		}
		
		let desc = this.state.desc;
		if(desc == 0){
			data.sort(function(a, b){
				return new Date(b.due_date) - new Date(a.due_date);
			});
			this.setState({trackerData:data,desc:1});
		}else{
			data.sort(function(a, b){
				return new Date(a.due_date) - new Date(b.due_date);
			});
			this.setState({trackerData:data,desc:0});
		}
		this.setState({tableUpdate:false});
		$("#statusFilter option:selected, #facilityFilter option:selected").prop("selected", false);
	}
	
	sortRank=()=>{
		let data = [];
		if(this.state.trackerData.length > 0 || this.state.trackerFormFilter || this.state.trackerRoleFilter || this.state.trackerPersonFilter){
			data = this.state.trackerData;
		}else{
			data = this.props.data;
		}
		
		let ascr = this.state.ascr;
		if(ascr == 0){
			data.sort(function(a, b){
				return b.rank - a.rank;
			});
			this.setState({trackerData:data,ascr:1});
		}else{
			data.sort(function(a, b){
				return a.rank - b.rank;
			});
			this.setState({trackerData:data,ascr:0});
		}
		this.setState({tableUpdate:false});
		$("#statusFilter option:selected").prop("selected", false);
		$(".status-row").show();
	}
	
	sortPriority=()=>{
		let data = [];
		if(this.state.trackerData.length > 0 || this.state.trackerFormFilter || this.state.trackerRoleFilter || this.state.trackerPersonFilter){
			data = this.state.trackerData;
		}else{
			data = this.props.data;
		}
		let ascp = this.state.ascp;
		if(ascp == 0){
			data.sort(function(a, b){
				return b.priority - a.priority;
			});
			this.setState({trackerData:data,ascp:1});
		}else{
			data.sort(function(a, b){
				return a.priority - b.priority;
			});
			this.setState({trackerData:data,ascp:0});
		}
		this.setState({tableUpdate:false});
		$("#statusFilter option:selected").prop("selected", false);
	}
	
	showAtRiskStatus=()=>{
		if($("input[name='showAtRiskStatus']").prop("checked") == true){
			$('.status-row').hide();
			$('.at-risk').show();
		}else{
			$('.status-row').show();
		}
		$("#statusFilter option:selected").prop("selected", false);
	}
	
	setFormFilter=(event)=>{
		let name = event.target.name;
		let value = event.target.value;
		let data = this.props.data;
		let filterData = [];
		data.map((row,i) => {
			if(row.form_name == value){
				filterData.push(row);
			}
		})
		this.setState({[name]:value,trackerData:filterData,trackerRoleFilter:'',trackerPersonFilter:'',trackerFacilityFilter:'',tableUpdate:false});
		$("#roleFilter option:selected, #statusFilter option:selected, #facilityFilter option:selected").prop("selected", false);
	}
	
	setRoleFilter=(event)=>{
		let name = event.target.name;
		let value = event.target.value;
		let data = this.props.data;
		let trackerFormFilter = this.state.trackerFormFilter;
		let filterData = [];
		let filteredPerson = [];
		if(trackerFormFilter){
			data.map((row,i) => {
				if(row.role == value && row.form_name == trackerFormFilter){
					filterData.push(row);
					filteredPerson.push(row.person);
				}else if(!value && row.form_name == trackerFormFilter){
					filterData.push(row);
					filteredPerson.push(row.person);
				}
			})
		}else{
			data.map((row,i) => {
				if(row.role == value){
					filterData.push(row);
					filteredPerson.push(row.person);
				}
			})
		}
		this.setState({[name]:value,trackerData:filterData,trackerPersonFilter:'',trackerFacilityFilter:'',filteredPerson,tableUpdate:false});
		$("#personFilter option:selected, #statusFilter option:selected, #facilityFilter option:selected").prop("selected", false);
	}
	
	setPersonFilter=(event)=>{
		let name = event.target.name;
		let value = event.target.value;
		let data = this.props.data;
		let trackerFormFilter = this.state.trackerFormFilter;
		let trackerRoleFilter = this.state.trackerRoleFilter;
		let filterData = [];
		
		if(trackerFormFilter && trackerRoleFilter){
			data.map((row,i) => {
				if(row.person == value && row.form_name == trackerFormFilter && row.role == trackerRoleFilter){
					filterData.push(row);
				}else if(!value && row.form_name == trackerFormFilter && row.role == trackerRoleFilter){
					filterData.push(row);
				}
			})
		}else if(trackerFormFilter && !trackerRoleFilter){
			data.map((row,i) => {
				if(row.person == value && row.form_name == trackerFormFilter){
					filterData.push(row);
				}else if(!value && row.form_name == trackerFormFilter){
					filterData.push(row);
				}
			})
		}else if(!trackerFormFilter && trackerRoleFilter){
			data.map((row,i) => {
				if(row.person == value && row.role == trackerRoleFilter){
					filterData.push(row);
				}else if(!value && row.role == trackerRoleFilter){
					filterData.push(row);
				}
			})
		}else{
			data.map((row,i) => {
				if(row.person == value){
					filterData.push(row);
				}
			})
		}
		this.setState({[name]:value,trackerData:filterData,tableUpdate:false,trackerFacilityFilter:''});
		$("#statusFilter option:selected, #facilityFilter option:selected").prop("selected", false);
	}

	setFacilityFilter=(event)=>{
		let name = event.target.name;
		let value = event.target.value;
		let data = this.props.data;
		let trackerFormFilter = this.state.trackerFormFilter;
		let trackerRoleFilter = this.state.trackerRoleFilter;
		let trackerPersonFilter = this.state.trackerPersonFilter;
		let filterData = [];
		
		if(trackerFormFilter && trackerRoleFilter && trackerPersonFilter){
			data.map((row,i) => {
				if(row.facilityID == value && row.form_name == trackerFormFilter && row.role == trackerRoleFilter && row.person == trackerPersonFilter){
					filterData.push(row);
				}else if(!value && row.form_name == trackerFormFilter && row.role == trackerRoleFilter && row.person == trackerPersonFilter){
					filterData.push(row);
				}
			})
		}else if(trackerFormFilter && trackerRoleFilter && !trackerPersonFilter){
			data.map((row,i) => {
				if(row.facilityID == value && row.form_name == trackerFormFilter && row.role == trackerRoleFilter){
					filterData.push(row);
				}else if(!value && row.form_name == trackerFormFilter && row.role == trackerRoleFilter){
					filterData.push(row);
				}
			})
		}else if(trackerFormFilter && !trackerRoleFilter && !trackerPersonFilter){
			data.map((row,i) => {
				if(row.facilityID == value && row.form_name == trackerFormFilter){
					filterData.push(row);
				}else if(!value && row.form_name == trackerFormFilter){
					filterData.push(row);
				}
			})
		}else if(!trackerFormFilter && trackerRoleFilter && trackerPersonFilter){
			data.map((row,i) => {
				if(row.facilityID == value && row.role == trackerRoleFilter && row.person == trackerPersonFilter){
					filterData.push(row);
				}else if(!value && row.role == trackerRoleFilter && row.person == trackerPersonFilter){
					filterData.push(row);
				}
			})
		}else if(!trackerFormFilter && trackerRoleFilter && !trackerPersonFilter){
			data.map((row,i) => {
				if(row.facilityID == value && row.role == trackerRoleFilter){
					filterData.push(row);
				}else if(!value && row.role == trackerRoleFilter){
					filterData.push(row);
				}
			})
		}else if(!trackerFormFilter && !trackerRoleFilter && trackerPersonFilter){
			data.map((row,i) => {
				if(row.facilityID == value && row.person == trackerPersonFilter){
					filterData.push(row);
				}else if(!value && row.person == trackerPersonFilter){
					filterData.push(row);
				}
			})
		}else{
			data.map((row,i) => {
				if(row.facilityID == value){
					filterData.push(row);
				}
			})
		}
		this.setState({[name]:value,trackerData:filterData});
		$("#statusFilter option:selected").prop("selected", false);
	}
	
	fabExportData=()=>{
		let trackerData = this.props.data;
		let statusWithMail = this.props.statusWithMail;
		let statusWithPos = this.props.statusWithPos;
		let currentDate = new Date().toLocaleString("en-US").replace(',','');
		
		let fields = [];
		trackerData.map((field,i)=>{
			
			let priority ='Normal';
			if(field['priority'] == 1){
				priority ='Low';
			}else if(field['priority'] == 3){
				priority ='High';
			}
			
			let orderstatus = 'In-Progress';
			
			if(field['status'] == 'On Hold' || field['status'] == 'Complete' || field['status'] == 'Cancelled'){
				orderstatus = field['status'];
			}
			let sendMessage = 'No';
			let messagerecipient = '';
			if(statusWithMail.indexOf(field['status']) > -1){
				sendMessage = 'Yes';
				messagerecipient = field['person'];
			}
			
			let statusLogs = [];
			if(field['logs']){
				field['logs'].map((field,i)=>{
					let log = field['date']+':'+field['status']+':'+field['person']+':'+field['user'];
					statusLogs.push(log);
				});
			}
			let fabcomplete = '';
			if(field['fab_complete'] == 'Y'){
				fabcomplete = 'Yes';
			}
			
			let allParts = '';
			if(field['all_parts'] == 'Y'){
				allParts = 'Yes';
			}
			
			let assignedfablab = '';
			this.props.facilityIds.map(function(val,i) {
				let facility = val.split('|');
				if(field['facilityID'] == facility[1]){
					assignedfablab = facility[0];
				}
			})
			
			let fieldData = {
				"type": 'object',
				"name": 'faborder',
				"required": {
					facilityguid:field['facilityID'],
					faborderguid:field['order_id'],
					formname:field['form_name'],
					facilitybranch:'Get from OPIE',
				},
				"properties": {
					facilityguid:field['facilityID'],
					faborderguid:field['order_id'],
					faborderfriendlynumber:field['id'],
					fabordercreatedby:field['practitioner'],
					rank:field['rank'],
					treatingpractitioner:field['practitioner'],
					taskstatus:field['status'],
					taskpriority:priority,
					taskstartdate:field['date'],
					taskduedate:field['due_date'],
					atriskofmissingduedate:field['due_date_status'],
					taskfinisheddate:field['finished_date'] ? field['finished_date']:'',
					taskresponsiblerole:field['role'],
					taskresponsibleperson:field['person'],
					patientguid:field['patient_id'],
					patientassignedid:field['patient_id'],
					patientfirstnamelastname:field['patient_firstname']+' '+field['patient_lastname'],
					assignedfacilityguid:field['facilityID'],
					assignedfacilitybranch:'Get from OPIE',
				},
				"completion": {
					fabcomplete:fabcomplete,
					allpartsready:allParts,
					markcompletehidefromview:field['mark'] == 'Y' ? field['mark']:'N',
					thisisaredo:field['redo_error'] ? field['redo_error']:'',
					redonotes:field['redo_info'] ? field['redo_info']:'',
					orderstate:orderstatus,
				},
				"devicetype":'Get from OPIE',
				"fabformname":field['form_name'],
				"viewformlink":window.location.href+'?uid='+field['form_id'],
				"viewpdflink":'link to PDF',
				"patientchartlink":'Get from OPIE',
				"statuschangearray":'N/A',
				"statuslog":statusLogs,
				"assignedfablab":assignedfablab,
				"fabtrackingnotes": {
					trackingnoteulid:'N/A',
					trackingnoteauthor:'N/A',
					trackingnotedate:'N/A',
					trackingnotetext:field['note'],
					trackingnoteuserguid:'N/A',
					sendasmesage:sendMessage,
					messagerecipient:messagerecipient,
				}	
			}
			const filteredObj = (obj) =>
			  Object.entries(obj)
				.filter(([_, value]) => !!value || typeof value === "boolean")
				.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
			
			fieldData = filteredObj(fieldData);
			fields.push(fieldData);
		});
		
		let layout ={
			"CreationDate": moment(currentDate).format('YYYY-MM-DD HH:mm:ss'),
			"Selectedstatuspriorities":statusWithPos,
			'Fields': fields,
		}
		
		let fileName = 'Fab_Tracking_Data_'+ currentDate;
		this.download(JSON.stringify(layout), fileName+'.json', "text/plain;charset=utf-8");
		this.downloadCsv();
	}
	
	download(content, fileName, contentType) {
		const a = document.createElement("a");
		const file = new Blob([content], { type: contentType });
		a.href = URL.createObjectURL(file);
		a.download = fileName;
		a.click();
	}
	
	downloadCsv = () => {
		let statusWithPos = this.props.statusWithPos;
		let currentDate = new Date().toLocaleString("en-US").replace(',','');
		let trackerData = this.props.data;
		let statusWithMail = this.props.statusWithMail;
		let data = [
		  ["CreationDate", moment(currentDate).format('YYYY-MM-DD HH:mm:ss')],
		  ["Selectedstatuspriorities", statusWithPos],
		  ["", ""],
		  ["type", "name", "facilityguid", "assignedfablab", "faborderguid", "faborderfriendlynumber", "fabordercreatedby", "fabformname", "rank", "treatingpractitioner", "taskstatus", "taskpriority", "taskstartdate", "taskduedate", "atriskofmissingduedate", "taskfinisheddate", "taskresponsiblerole", "taskresponsibleperson", "patientguid", "patientfirstnamelastname", "patientassignedid", "markcompletehidefromview", "thisisaredo", "redonotes", "orderstate","trackingnotetext", "sendasmesage", "messagerecipient", "statuslog", "fabcomplete", "allpartsready"],
		];
		
		let fields = [];
		trackerData.map((field,i)=>{
			
			let priority ='Normal';
			if(field['priority'] == 1){
				priority ='Low';
			}else if(field['priority'] == 3){
				priority ='High';
			}
			
			let orderstatus = 'In-Progress';
			
			if(field['status'] == 'On Hold' || field['status'] == 'Complete' || field['status'] == 'Cancelled'){
				orderstatus = field['status'];
			}
			let sendMessage = 'No';
			let messagerecipient = '';
			if(statusWithMail.indexOf(field['status']) > -1){
				sendMessage = 'Yes';
				messagerecipient = field['person'];
			}
			
			let statusLogs = [];
			if(field['logs']){
				field['logs'].map((field,i)=>{
					let log = field['date']+':'+field['status']+':'+field['person']+':'+field['user'];
					statusLogs.push(log);
				});
			}
			
			let fabcomplete = '';
			if(field['fab_complete'] == 'Y'){
				fabcomplete = 'Yes';
			}
			
			let allParts = '';
			if(field['all_parts'] == 'Y'){
				allParts = 'Yes';
			}
			
			let assignedfablab = '';
			this.props.facilityIds.map(function(val,i) {
				let facility = val.split('|');
				if(field['facilityID'] == facility[1]){
					assignedfablab = facility[0];
				}
			})
			
			fields = ['object','faborder',field['facilityID'],assignedfablab,field['order_id'],field['id'],field['practitioner'],field['form_name'],field['rank'],field['practitioner'],field['status'],priority,field['date'],field['due_date'],field['due_date_status'],field['finished_date'],field['role'],field['person'],field['patient_id'],field['patient_firstname']+' '+field['patient_lastname'],field['patient_id'],field['mark'],field['redo_error'],field['redo_info'],orderstatus,field['note'],sendMessage,messagerecipient,statusLogs,fabcomplete,allParts];
			
			data.push(fields);
		});

		let fileName = 'Fab_Tracking_Data_'+ currentDate+'.csv'
		this.setState({ csvdata:data, fabCsvName:fileName }, () => {
			let that = this;
			setTimeout(function(){ 
				that.csvLink.current.link.click()
			}, 2000);
		})	
	}
	
	render() {
		const {data,editTracker,showTrackerView,trackerViewPdf,statusType,facilityIds} = this.props;
		
		let facilityOption = facilityIds.map(function(val,i) {
			let facility = val.split('|');
			return (
				<option value={facility[1]} key={i}>{facility[0]}</option>
			);
		})
		
		let formName = [];
		let roleName = [];
		let personName = [];
		
		data.map(function(row,i) {
			formName.push(row.form_name);
			roleName.push(row.role);
			personName.push(row.person);
		})
		
		if(this.state.filteredPerson.length > 0){
			personName = this.state.filteredPerson;
		}
		
		let formNames = formName.filter(function(elem, index, self) {
			return index === self.indexOf(elem);
		});
		
		let roleNames = roleName.filter(function(elem, index, self) {
			return index === self.indexOf(elem);
		});
		
		let personNames = personName.filter(function(elem, index, self) {
			return index === self.indexOf(elem);
		});
		
		let formFilterOption = formNames.map(function(row,i) {
			return (
				<option value={row} key={i}>{row}</option>
			);
		})
		
		let roleFilterOption = roleNames.map(function(row,i) {
			return (
				<option value={row} key={i}>{row}</option>
			);
		})
		
		let personFilterOption = personNames.map(function(row,i) {
			return (
				<option value={row} key={i}>{row}</option>
			);
		})
		
		let that = this;
		let rows = [];
		if(!this.state.tableUpdate && (this.state.trackerData.length > 0 || this.state.trackerFormFilter || this.state.trackerRoleFilter || this.state.trackerPersonFilter || this.state.trackerFacilityFilter)){
			rows = this.state.trackerData;
		}else{
			rows = data;
		}
		
		let rowHtml = '';
		let modals = '';
		let href = window.location.href.split('?')[0];

		if(rows){
			
			rowHtml = rows.map(function(row,i) {
				let status = row.status.replace(/ /g, '');
				let facilityName = '';
				facilityIds.map(function(val,i) {
					let str = val.split('|');
					let fId = str[1];
					if(fId == row.facilityID){
						facilityName = str[0];
					}
				});
				
				let priority ='Normal';
				if(row.priority == 1){
					priority ='Low';
				}else if(row.priority == 3){
					priority ='High';
				}
				let wip_status = 'x';
				if(row.wip_status){
					wip_status = row.wip_status.toLowerCase();
				}
				let classs = '';
				if(row.due_date_status == 'Yes'){
					classs = 'at-risk';
				}
				
				return (<tr className={'status-row '+status+' '+row.facilityID+' '+classs}>
					<td>{row.id}</td>
					<td>{moment(row.date).format('MM/DD/YYYY')}</td>
					<td>{row.due_date ? moment(row.due_date).format('MM/DD/YYYY') : ''}</td>
					<td>{row.patient_id}</td>
					<td>{row.form_name}</td>
					<td>{row.role}</td>
					<td>{row.person}</td>
					<td>{row.status}</td>
					<td>{facilityName}</td>
					<td>{priority}</td>
					<td>{row.rank}</td>
					<td><button className="btn btn-modal-tracker" onClick={()=>that.showNote(row.order_id)}><i className="fa fa-sticky-note-o" aria-hidden="true"></i></button></td>
					<td><button id={'btn-eye-'+row.form_id} type="button" onClick={()=>showTrackerView(row.form_name,row.form_id,row)} className="btn btn-eye-tracker"><i className="fa fa-eye"></i></button></td>
					<td><button id={'btn'} type="button" onClick ={()=>editTracker(row)} className="btn btn-edit-tracker"><i className="fa fa-edit"></i></button></td>
					<td><button type="button" onClick={()=>trackerViewPdf(row.form_name,row.form_id,row)} className="btn mb-2"> <i className="fa fa-print" aria-hidden="true"></i></button></td>
					<td>{ row.redo_error ? <i className="fa fa-flag" aria-hidden="true"></i> :''}</td>
					<td><img src={href+'/'+wip_status+'.png'} alt="WIP Status" width="15" height="15" /></td>
				</tr>);
			})
			
			modals = data.map(function(row,i) {
				let regX = /(<([^>]+)>)/ig;
				let note = row.note.replace(regX, "");
				return (<div className="modal" key={i} id={row.order_id}>
					<div className="modal-dialog modal-lg modal-sm tracker-modal">
						<div className="modal-content">
							<div className="modal-body">
							{note}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				);
			})
        }
		
		return (
			<div className="status-tracker-tbl">
				<div className="status-tracker-div">
					<div className="find-status-filter">
						<b>Job State:</b>
						<select id="statusFilter" className="form-control filter-form" onChange={this.setStatusFilter}>
							<option value="">All</option>
							<option value="In-Progress">In-Progress</option>
							<option value="On-Hold">On Hold</option>
							<option value="Complete">Complete</option>
							<option value="Cancelled">Cancelled</option>
							<option value="Complete-Hidden">Complete-Hidden</option>
							<option value="Cancelled-Hidden">Cancelled-Hidden</option>
						</select>	
					</div>
					<div className="show-at-risk-filter">
						 <span><b>At Risk</b></span>&nbsp;&nbsp;
						 <label className="showAllSwitch">
							<input id="showAtRiskStatus" type="checkbox" name="showAtRiskStatus" onClick={()=>this.showAtRiskStatus()} />
							<span className="slider round showAllSlide"></span>
						</label>
					</div>
					<div className="find-facility-filter">
						<b>Fab Lab:</b>
						<select id="facilityFilter" className="form-control filter-form" name="trackerFacilityFilter" value={this.state.trackerFacilityFilter} onChange={this.setFacilityFilter}>
							<option value="">All</option>
							{facilityOption}
						</select>	
					</div>
					
					<div className="find-person-filter">
						<b>Person:</b>
						<select id="personFilter" className="form-control filter-form" name="trackerPersonFilter" value={this.state.trackerPersonFilter} onChange={this.setPersonFilter}>
							<option value="">All</option>
							{personFilterOption}
						</select>	
					</div>
					
					<div className="find-role-filter">
						<b>Role:</b>
						<select id="roleFilter" className="form-control filter-form" name="trackerRoleFilter" value={this.state.trackerRoleFilter} onChange={this.setRoleFilter}>
							<option value="">All</option>
							{roleFilterOption}
						</select>	
					</div>
					
					<div className="find-form-filter">
						<b>Form Name:</b>
						<select className="form-control filter-form" name="trackerFormFilter" value={this.state.trackerFormFilter} onChange={this.setFormFilter}>
							<option value="">All</option>
							{formFilterOption}
						</select>	
					</div>
					
					<table className="table table-bordered tracker-table">
					<tr><th>#</th>
						<th className="k-sort" onClick={this.sortStartDate}>Start Date <i className="fa fa-sort"></i></th>
						<th className="k-sort" onClick={this.sortDueDate}>Due Date <i className="fa fa-sort"></i></th>
						<th>Patient Id</th>
						<th>Form Name</th>
						<th>Responsible Role</th>
						<th>Responsible Person</th>
						<th>Status</th>
						<th>Fab Lab</th>
						<th className="k-sort" onClick={this.sortPriority}>Priority <i className="fa fa-sort"></i></th>
						<th className="k-sort" onClick={this.sortRank}>Rank <i className="fa fa-sort"></i></th>
						<th>Note</th>
						<th>View</th>
						<th>Edit</th>
						<th>PDF</th>
						<th>Redo</th>
						<th>WIP</th>
					</tr>
					{rowHtml}
					</table>
					
					{modals}
				</div>
				<div className="fab-export-btn"><button type="button" className="float-right btn btn-outline-info" onClick={this.fabExportData}>Data Export</button></div>
				<CSVLink
					data={this.state.csvdata}
					filename={this.state.fabCsvName}
					className="hidden"
					ref={this.csvLink}
					target="_blank" 
				/>
			</div>
		);
	}
}
