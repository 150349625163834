import React, { Component } from 'react'; 
import $ from 'jquery';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

export default class Q8 extends Component {
	constructor(props) {
        super(props);
        this.state = {
            startDate: '',
        };
        this.handleChange = this.handleChange.bind(this); 
        
    }
    handleChange = date => {
        var newDate = date.getDate()+'/'+(date.getMonth()+1)+'/'+date.getFullYear();
        this.props.func(this.props.data.fieldId,newDate)
        this.setState({
          startDate: date,date
        });
      };

    render(){
        const {data} = this.props;
        let id = data.fieldId;
		let kioskResponse = data.kioskResponse;
		//console.log('kioskResponse->',kioskResponse);
        if(kioskResponse.size > 0 ){
            var dateString = kioskResponse.get(id);
            if(dateString !== undefined){
                var dateParts = dateString.split("/");
                var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            }
        }
		
		return (
		<Box>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Stack spacing={3}>
					<DesktopDatePicker
					  label=""
					  inputFormat="MM/dd/yyyy"
					  value={dateObject ? dateObject : this.state.startDate}
					  onChange={this.handleChange}
					  renderInput={(params) => <TextField {...params} />}
					/>
				</Stack>
			</LocalizationProvider>
		</Box>);
    }
}
