import React, { Component } from 'react';
import Box from '@mui/material/Box';
import $ from 'jquery';

export default class Q3 extends Component {
	
	constructor(props) {
        super(props);
        this.state = { value: [''] }
        this.shoot = this.shoot.bind(this); 
    }

    shoot(event) {
        var resArray = Array.from(event.target.selectedOptions, (item) => item.value)
        var sh =resArray.toString();
        this.setState({value: Array.from(event.target.selectedOptions, (item) => item.value)});
        this.props.func(this.props.data.fieldId,sh)
        this.setState({value:['']});
    }
   
    render() {
        const {data} = this.props;
        let id = data.fieldId;
		let options = data.options.split(',');
		let kioskResponse = data.kioskResponse;
		
        var newArray  =[];
        if(kioskResponse.size > 0){
            var multiRes = kioskResponse.get(id);
            if(multiRes !== undefined){
                newArray = multiRes.split(',')
            }
        }
		let optionsHtml = '';
        if(options){
            optionsHtml = options.map(function(val,i) {
                if(newArray.indexOf(val) !== -1){
                    return (
                        <option value={val} selected key={i}>{val}</option>
                    );  
                }else{
                    return (
                        <option value={val} key={i}>{val}</option>
                    );
                }
            })
        }
		
		return (<Box><select className="form-control" multiple={true} onChange={this.shoot}>
			<option value=""> Please Select</option>
			{optionsHtml}
		</select></Box>);
    }
}
