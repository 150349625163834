import React, { Component } from 'react'; 
import Box from '@mui/material/Box';
import $ from 'jquery';
import Checkbox from '@mui/material/Checkbox';

export default class Q11b extends Component {
	
	constructor(props) {
    super(props);
        this.state = {itemArray:[],ItemsDes: new Map() }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        const item = event.target.name;
        const isChecked = event.target.checked;
        const value = event.target.value;
        let atr = event.target.getAttribute('data_atr');
        let itemArray = this.state.itemArray
        let ItemsDes = this.state.ItemsDes
		
		if(isChecked==true){
			$('#q11b'+item).css('display','block');
            itemArray.push(value)
        }else{
			$('#q11b'+item).css('display','none');
            if(itemArray.indexOf(value) !==-1){
                itemArray = itemArray.filter(e => e !== value)
            }
        }
		
		this.setState({itemArray});
		
		if(atr == 2){
			if(itemArray.indexOf(item) !==-1){
				ItemsDes.set(item, value)
			}
		}

		let itemArray2 = [];
		itemArray.map((itm, i) => {
			let des = '';
			if(ItemsDes.get(itm) && ItemsDes.get(itm) != undefined){
				des = ItemsDes.get(itm);
			}
			
			itemArray2[i] = itm+':'+des;
			
		});
		
		let itemString = itemArray2.toString();
		this.props.func(this.props.data.fieldId,itemString);
    }
	
    render(){
        const {data} = this.props;
        let id = data.fieldId;
		let options = data.options.split(',');
		let kioskResponse = data.kioskResponse;
		//console.log('kioskResponse->',kioskResponse);
        let response = '';
        if(kioskResponse.size > 0){
            let resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                response = resAns.split(',');
            }
        }
		
        let optionsHtml = '';
        if(options){
            optionsHtml = options.map(function(val,i) {
				let name = id+'-'+i;
				let res = '';
				let des = '';
				
				if(response.length > 0){
					response.map((str, j) => {
						let n = str.split(':');
						if(n[0] == val){
							res = n[0];
							des = n[1];
						}
					})
				} 
				
				if(res == val){
					return (<Box key={i}>
						<label className="form-check-label">
							<Checkbox value={val} checked={true} name={name} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} /> {val}
						</label>
						<div id={"q11b"+name}>
							Description : <input data_atr={2} text="type" name={val} value={des} className="form-control" />
						</div>
					</Box>);  
				}else{
					return (<Box key={i}>
						<label className="form-check-label">
							<Checkbox value={val} checked={false} name={name} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} /> {val}
						</label>
						<div id={"q11b"+name} style={{display:'none'}} >
							Description : <input data_atr={2} text="type" name={val} className="form-control" />
						</div>
					</Box>);
                }
            })
        }
       
		return (<div onChange={this.shoot} className="ver-checkbox">
		   {optionsHtml}
		</div>);
    }
}
